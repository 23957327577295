import DocusaurusFooter from '../DocusaurusFooter'
import { LinkToTestConfigs } from '@eggplant/routing'
import { Paragraph } from '@eggplant/pattern-library'
import { runner } from '../../docs-routes'

const Runner = () =>
  <Paragraph>
    Use the Runner to run and schedule test executions, and then view results from those test executions.
    <br />
    <br />
    Before you can use the Runner, you must have at least one <LinkToTestConfigs>Test Config</LinkToTestConfigs>.
    <DocusaurusFooter path={runner} />
  </Paragraph>

export default Runner
