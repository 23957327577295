import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { steps } from '../../docs-routes'

const NoSteps = () =>
  <Paragraph>
    No steps were executed during this test.
    <DocusaurusFooter path={steps} />
  </Paragraph>

export default NoSteps
