import {
  Card,
  Header
} from '@eggplant/pattern-library'
import type { CSSProperties, ReactNode } from 'react'

interface Props {
  title: string
  children?: ReactNode
  hasPadding?: boolean
}

const wrapperPadding: CSSProperties = {
  padding: '0 16px'
}

const wrapperStyle: CSSProperties = {
  display: 'grid',
  padding: '16px',
  justifyContent: 'center',
  justifyItems: 'center',
  rowGap: '8px',
  textAlign: 'center'
}

const EmptyState = ({
  title,
  children,
  hasPadding = true
}: Props) =>
  <div
    style={
      hasPadding
        ? wrapperPadding
        : undefined
    }
  >
    <Card hasShadow={false}>
      <div style={wrapperStyle}>
        <Header
          hasNoTopMargin
          rank={4}
        >
          {title}
        </Header>
        {children}
      </div>
    </Card>
  </div>

export default EmptyState
