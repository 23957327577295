import Redirect from './Redirect'
import {
  SETUP,
  SYSTEM
} from '../routes'

const RedirectToSetup = () =>
  <Redirect to={'/' + SYSTEM + '/' + SETUP} />

export default RedirectToSetup
