import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  RESULTS
} from '../routes'
import type { Props } from '../types'

const base = `/${CONTROLLER}/${RESULTS}`

const LinkToResults = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base}
  >
    {children}
  </StyledLink>

export default LinkToResults
