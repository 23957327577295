import classes from './Alert.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const Alert = ({ children }: Props) =>
  <div className={classes.Alert}>
    {children}
  </div>

export default Alert
