import classes from './List.module.scss'
import { Children } from 'react'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode | ReactNode[]
  isOrderedList?: boolean
}

const List = ({
  isOrderedList = false,
  children
}: Props) => {
  const isArray = Array.isArray(children)

  const ListTag = isOrderedList
    ? 'ol'
    : 'ul'

  return (
    isArray
      ? (
        <ListTag className={classes.List}>
          {
            Children.map(children, (child: ReactNode, index: number) =>
              <li key={index}>
                {child}
              </li>
            )
          }
        </ListTag>
      )
      : (
        <ListTag className={classes.List}>
          <li>
            {children}
          </li>
        </ListTag>
      )
  )
}

export default List
