import classes from './LogItem.module.scss'
import type {
  LogSeverity,
  LogStage
} from '@eggplant/types'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  severity?: LogSeverity
  stage?: LogStage
}

const LogItem = ({
  children,
  severity,
  stage
}: Props) => {
  const severityClass = (severity && classes[severity]) || ''

  const stageClass = (stage && classes[stage]) || ''

  const className = (
    classes.LogItem + ' ' + severityClass + ' ' + stageClass
  ).trim()

  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default LogItem
