import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAddTestConfig } from '@eggplant/routing'
import { LinkToTestConfigDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = 'Test Configs are combinations of test cases and exploratory tests grouped in specific sequences to run against systems under test (SUTs).'

const renderButton = () =>
  <AccessControl isHiddenIfDenied>
    <LinkButton variant='primary'>
      <LinkToAddTestConfig>
        <HiPlus
          aria-hidden
          size='19.2px'
        /> New Test Config
      </LinkToAddTestConfig>
    </LinkButton>
  </AccessControl>

const TestConfigIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Path />}
        renderLink={<LinkToTestConfigDoc />}
        title='Test Configs'
      />
    </div>
  </div>

export default TestConfigIntro
