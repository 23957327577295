import './index.css'
import '@eggplant/layout-css/src/lib/styling/card.css'
import '@eggplant/layout-css/src/lib/styling/content.css'
import '@eggplant/layout-css/src/lib/styling/header.css'
import LicenseCheckpoint from './lib/components/Router/GateKeeper/LicenseCheckpoint/LicenseCheckpoint'
import queryString from 'query-string'
import {
  AuthProvider,
  DataCollectionProvider,
  ExecutionEnvironmentProvider,
  ModelProvider,
  PreferencesProvider,
  ThemeConsumer,
  ThemeProvider
} from '@eggplant/context'
import { LocationProvider } from '@reach/router'
import { Telemetry } from '@eggplant/telemetry'
import { ThemeWatcher } from '@eggplant/pattern-library'
import { createRoot } from 'react-dom/client'

// // TODO: Only allow props from address bar in development
// const props = process.env.NODE_ENV === 'development'
//   ? queryString.parse(window.location.search, { arrayFormat: 'comma' })
//   : {}

const props = queryString.parse(window.location.search, { arrayFormat: 'comma' })

const authToken = props.authToken
  ? props.authToken.toString()
  : undefined

const collapsedState = props.collapsedState
  ? props.collapsedState.toString()
  : undefined

const modelId = props.modelId
  ? props.modelId.toString()
  : undefined

const root = createRoot(document.getElementById('root') as Element)

root.render(
  <LocationProvider>
    <ModelProvider selectedModelId={modelId}>
      <PreferencesProvider isNavCollapsed={collapsedState === 'true'}>
        <ThemeProvider>
          <ThemeConsumer>
            {
              ({ theme }) =>
                <ThemeWatcher theme={theme} />
            }
          </ThemeConsumer>
          <AuthProvider
            authToken={authToken}
            isKeycloakOn={!process.env.REACT_APP_IS_AUTH_DISABLED}
          >
            <ExecutionEnvironmentProvider>
              <DataCollectionProvider>
                <Telemetry>
                  <LicenseCheckpoint />
                </Telemetry>
              </DataCollectionProvider>
            </ExecutionEnvironmentProvider>
          </AuthProvider>
        </ThemeProvider>
      </PreferencesProvider>
    </ModelProvider>
  </LocationProvider>

)

