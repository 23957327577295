import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { licenses } from '../../docs-routes'

const LicensesInfo = () =>
  <Paragraph>
    The licenses tab is where you manage your Eggplant DAI licenses:
    <br />
    <br />
    <b>Developer licenses</b>
    <br />
    The number of concurrent users that can access and use Eggplant DAI to build tests and models and manually run tests.
    <br />
    <br />
    <b>Execution licenses</b>
    <br />
    The number of concurrent headless tests that can run simultaneously.
    <br />
    <br />
    To add a new license, click Add. The License Management page opens. Enter your DAI license key, review the Software License Management Agreement, and click Agree.
    <br />
    <br />
    If the license is invalid, please contact our customer support.
    <DocusaurusFooter path={licenses} />
  </Paragraph>

export default LicensesInfo
