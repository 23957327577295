import PropTypes from 'prop-types'
import classes from './SortIndicator.module.scss'
import {
  HiArrowSmDown,
  HiArrowSmUp
} from 'react-icons/hi'
import { Icons } from '../..'

interface Props {
  isAscending?: boolean | undefined
}

const sortIcon = (isAscending: boolean | undefined) => {
  if (isAscending === undefined) {
    return (
      <Icons.HiArrowSmUpDown
        aria-label='HiArrowSmUpDown'
        size='14'
      />
    )
  }

  if (isAscending) {
    return (
      <HiArrowSmUp
        aria-label='HiArrowSmUp'
        size='14px'
      />
    )
  }

  return (
    <HiArrowSmDown
      aria-label='HiArrowSmDown'
      size='14px'
    />
  )
}

const SortIndicator = ({
  isAscending
}: Props) =>
  <span
    aria-hidden
    className={classes.SortIndicator}
  >
    {sortIcon(isAscending)}
  </span>

SortIndicator.propTypes = {
  isAscending: PropTypes.bool // can be undefined
}

export default SortIndicator
