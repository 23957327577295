import {
  Header,
  List,
  Paragraph
} from '@eggplant/pattern-library'

const ResultByStatusInfo = () =>
  <div className='flex flex-col gap-4'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Test case results by status
    </Header>
    <Paragraph>
      The Test Case Results by Status report and accompanying Donut Chart provide a
      comprehensive overview of test case distribution based on their status.
      This visualization displays the percentage of test cases for each status,
      along with the total number of test cases that fall within the applied filters at the dashboard level.
    </Paragraph>
    <div>
      <Paragraph>Key Features:</Paragraph>
      <List>
        <Paragraph>
          Status Breakdown: The donut chart presents a representation of the distribution of
          test cases across different statuses, enabling quick comprehension of their relative proportions.
        </Paragraph>
        <Paragraph>
          Statuses: By clicking on each status, you can access a detailed view of test case results.
          This facilitates focused analysis and investigation.
        </Paragraph>
      </List>
    </div>
    <Paragraph>
      The Test Case Results by Status report and Donut Chart provide valuable insights into the status distribution of your test cases,
      allowing you to easily track and understand their overall performance. This empowers you to identify trends,
      focus on specific status, and make data-driven decisions to enhance your testing process.
    </Paragraph>
  </div>

export default ResultByStatusInfo
