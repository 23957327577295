import {
  BUGHUNTING,
  COVERAGE,
  renderLinkTo,
  routeLabels
} from '@eggplant/routing'
import {
  FaBug,
  FaLayerGroup
} from 'react-icons/fa'
import { NavItemVerticalMini } from '@eggplant/pattern-library'

interface Props {
  base: string
  isCollapsed: boolean
}

const InsightsNav = ({
  base,
  isCollapsed
}: Props) =>
  <>
    <NavItemVerticalMini
      icon={<FaLayerGroup aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[COVERAGE]}
    >
      {renderLinkTo(base + '/' + COVERAGE)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<FaBug aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[BUGHUNTING]}
    >
      {renderLinkTo(base + '/' + BUGHUNTING)}
    </NavItemVerticalMini>
  </>

export default InsightsNav
