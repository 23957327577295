import classes from './FieldSetCollapseBar.module.scss'
import {
  FiChevronLeft,
  FiChevronRight
} from 'react-icons/fi'
import { IconButton } from '../..'
import { useState } from 'react'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const FieldSetCollapseBar = ({
  children
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleClick = () => {
    setIsCollapsed(!isCollapsed)
  }

  const title = isCollapsed
    ? 'Expand'
    : 'Collapse'

  return (
    <div className={classes.PanelCollapseBar}>
      {
        isCollapsed
          ? <div className={classes.emptyChildren} />
          : children
      }
      <span
        aria-hidden // There is no need for a screen reader to collapse content
        className={classes.collapseButtonWrapper}
      >
        {
          isCollapsed
            ? (
              <IconButton
                hasOutline
                isWhiteBackground
                onClick={handleClick}
                title={title}
              >
                <FiChevronRight />
              </IconButton>
            )
            : (
              <IconButton
                hasOutline
                isWhiteBackground
                onClick={handleClick}
                title={title}
              >
                <FiChevronLeft />
              </IconButton>
            )
        }
      </span>
    </div>
  )
}

export default FieldSetCollapseBar
