import BackButton from '../BackButton/BackButton'
import Divider from '../Divider/Divider'
import Header from '../Header/Header'
import classes from './Breadcrumb.module.scss'
import type {
  ReactElement,
  ReactNode
} from 'react'

interface Props {
  link?: ReactElement<{ href: string }>
  children?: ReactNode
}

const Breadcrumb = ({
  link,
  children
}: Props) =>
  <span className={classes.Breadcrumb}>
    {
      link &&
        <BackButton>
          {link}
        </BackButton>
    }
    {
      children &&
        <>
          <div className={classes.divider}>
            <Divider isVertical />
          </div>
          <Header
            hasNoTopMargin
            rank={2}
          >
            {children}
          </Header>
        </>

    }
  </span>

export default Breadcrumb
