import IconCard from '../presentation/IconCard'
import LinkToDocusaurus from '../LinkToDocusaurus'
import Version from '@eggplant/router/src/lib/components/Version/Version'
import {
  Artwork,
  CardTable,
  Hyperlink,
  Paragraph
} from '@eggplant/pattern-library'

const DocsLink =
  <Hyperlink
    href='http://docs.eggplantsoftware.com/'
    isExternal
  >
    external documentation
  </Hyperlink>

const Help = () =>
  <CardTable>
    <IconCard
      artwork={<Artwork.Path />}
      title='Documentation'
    >
      <Paragraph>
        This installation of DAI includes version-specific <LinkToDocusaurus>embedded documentation</LinkToDocusaurus>.
        <br />
        <br />
        Visit our {DocsLink} for all products.
        <br />
        <br />
        <Version />
      </Paragraph>
    </IconCard>
  </CardTable>

export default Help
