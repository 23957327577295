import exportFromJSON from 'export-from-json'
import { AuthenticatedDataRequest } from '@eggplant/data-request'

interface Props {
  onSuccess: (data: object) => object
  url: string
  fileName: string
}

const AuthenticatedJsonExport = ({
  url,
  fileName,
  onSuccess
}: Props) => {
  const handleSuccess = (data: object) => {
    const newData = onSuccess(data)

    exportFromJSON({
      data: newData,
      fileName,
      exportType: 'json',
      space: 2
    })
  }

  return (
    <AuthenticatedDataRequest
      method='get'
      onSuccess={handleSuccess}
      url={url}
    />
  )
}

export default AuthenticatedJsonExport
