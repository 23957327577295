import TruncatedString from '../TruncatedString/TruncatedString'
import classes from './Tag.module.scss'

interface Props {
  color: string
  label: string
  limit: number
}

const Tag = ({
  color,
  label,
  limit
}: Props) =>
  <span className={classes.Tag}>
    <span
      aria-hidden // Hide from screen readers
      style={{ color, userSelect: 'none' }} // Can't select this dot
    >
      &#11044;
    </span>
    {' '}
    <TruncatedString
      limit={limit}
      value={label || ''}
    />
  </span>

export default Tag
