import StyledLink from '../StyledLink'
import {
  DESIGNER,
  EDITOR,
  MODEL_HUB
} from '../routes'
import type { Props } from '../types'

interface Params {
  modeluuid: string
}

const LinkToModelEdit = ({
  children,
  label,
  modeluuid,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    aria-label={label}
    isHyperlink={isHyperlink}
    to={`/${DESIGNER}/${MODEL_HUB}/${modeluuid}/${EDITOR}`}
  >
    {children}
  </StyledLink>

export default LinkToModelEdit
