import classes from './Chip.module.scss'

interface ChipProps {
  children: React.ReactNode
  noWrap?: boolean
}

const Chip = ({
  children,
  noWrap = false
}: ChipProps) => (
  <span
    className={`${classes.Chip} ${noWrap &&
  classes.noWrap
    }`}
  >
    {children}
  </span>
)

export default Chip
