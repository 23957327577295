import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { testConfig } from '../../docs-routes'

const TestResultsStep = () =>
  <Paragraph>
    Step logs show you information about the execution of each step in a test config, which can help in troubleshooting.
    <DocusaurusFooter path={testConfig} />
  </Paragraph>

export default TestResultsStep
