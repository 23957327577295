import Footer from './Footer'
import LinkToKickStartVideos from './LinkToKickStartVideos'
import { FaRocket } from 'react-icons/fa'

const KickStartFooter = () =>
  <Footer Icon={FaRocket}>
    Get started with a tutorial in <LinkToKickStartVideos>KickStart Testing</LinkToKickStartVideos>.
  </Footer>

export default KickStartFooter
