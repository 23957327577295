import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  EDIT,
  ENVIRONMENTS
} from '../routes'
import type { Props } from '../types'

interface Params {
  environmentId: string
}

const LinkToEditEnvironment = ({
  children,
  environmentId,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + CONTROLLER + '/' + ENVIRONMENTS + '/' + environmentId + '/' + EDIT}
  >
    {children}
  </StyledLink>

export default LinkToEditEnvironment
