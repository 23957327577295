import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  Paragraph
} from '@eggplant/pattern-library'
import { FaClipboardCheck } from 'react-icons/fa'
import { MODELER_SERVICE } from '@eggplant/api-hosts'
import type {
  ButtonShape,
  GetScriptedTestCasesResponse,
  Options,
  ScriptedTestCase
} from '@eggplant/types'

interface Props {
  asset_id?: string
  initialOptions?: {
    label: string
    value: string
  }[]
  onChange: (value: string) => void
  value: string
  isRequired?: boolean
}

const baseUrl = MODELER_SERVICE + '/test_cases/scripted'

const onlyTestCasesWithIds = (item: ScriptedTestCase) => !!item.id

const getOptions = (items: ScriptedTestCase[]) =>
  items
    .filter(onlyTestCasesWithIds)
    .map((item: ScriptedTestCase) => ({
      label: item.name,
      value: item.id
    }))

const SelectScriptedTestCase = ({
  asset_id,
  initialOptions = [],
  onChange,
  value,
  isRequired
}: Props) => {
  const handleChange = (value: string) => {
    onChange(value)
  }

  const getDefaultLabel = () => {
    if (initialOptions.length) {
      const defaultLabel = initialOptions.find(item => item.value === '')

      return defaultLabel?.label || 'Select a Scripted Test Case'
    }

    return 'Select a Scripted Test Case'
  }

  const getRenderButton = (options: Options) => {
    const currentOption = options.find(item =>
      item.value === value
    )

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={FaClipboardCheck}
        label={
          currentOption?.value
            ? currentOption.label
            : undefined
        }
      >
        {
          currentOption
            ? currentOption.label
            : getDefaultLabel()
        }
      </DropdownButton>
  }

  const url = asset_id
    ? `${baseUrl}?asset_id=${asset_id}`
    : baseUrl

  return (
    <AuthenticatedDataRequest
      method='get'
      url={url}
    >
      {
        ({ items, total_count }: GetScriptedTestCasesResponse) => total_count
          ? (
            <Combobox
              initialOptions={initialOptions}
              isRequired={isRequired}
              onChange={handleChange}
              options={getOptions(items)}
              renderButton={getRenderButton(getOptions(items))}
              value={value}
            />
          )
          : (
            <Paragraph>
              There are currently no test cases associated with this suite
            </Paragraph>
          )

      }
    </AuthenticatedDataRequest>
  )
}

export default SelectScriptedTestCase
