import Tooltip from '../Tooltip/Tooltip'
import classes from './DeleteButton.module.scss'
import {
  Color,
  Paragraph
} from '../..'
import { FiTrash2 } from 'react-icons/fi'
import { forwardRef } from 'react'

type size = 'md' | 'sm'

interface Props {
  onClick: () => void
  isDisabled?: boolean
  size?: size
  children?: string
  title?: string
  label: string
}

// forwardRef is used here because this button can be used as the anchor for a PopOut
// this works the same as any other component
const DeleteButton = forwardRef((
  {
    onClick,
    isDisabled = false,
    title,
    label,
    size = 'md',
    children
  }: Props,
  ref: any
) => {
  // secondary danger
  const getButtonClasses = () => {
    const baseClass = classes.DeleteButton

    const isSmallClass = size === 'sm'
      ? classes.condense
      : ''

    const isWithTextClass = children
      ? classes.withText
      : ''

    const isSmallWithoutText = size === 'sm' && !children
      ? classes.condenseWithoutText
      : ''

    const buttonClasses = [
      baseClass,
      isSmallClass,
      isWithTextClass,
      isSmallWithoutText
    ].filter(classname => classname)

    return buttonClasses.join(' ')
  }

  const getChildrenColor = () => {
    if (isDisabled && children) {
      return 'gray'
    }

    return isDisabled || (size === 'sm' && !children)
      ? 'theme-filter-icon'
      : 'theme-delete-danger'
  }

  const getIconSize = () => {
    const defaultSize = '20px'

    if (children) {
      return '16px'
    }

    if (size === 'sm') {
      return '18px'
    }

    return defaultSize
  }

  const renderContent = () =>
    <>
      <Color variant={getChildrenColor()}>
        <FiTrash2
          aria-hidden
          aria-label={label}
          size={getIconSize()}
        />
      </Color>
      {
        children &&
          <Paragraph>
            <strong>
              <Color variant={getChildrenColor()}>
                {children}
              </Color>
            </strong>
          </Paragraph>
      }
    </>

  return (
    <button
      className={getButtonClasses()}
      disabled={isDisabled}
      onClick={onClick}
      ref={ref}
      type='button'
    >
      {
        title && !isDisabled
          ? (
            <Tooltip label={title}>
              {renderContent()}
            </Tooltip>
          )
          : (
            <span>
              {renderContent()}
            </span>
          )
      }
    </button>
  )
}
)

export default DeleteButton
