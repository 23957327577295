import {
  Header,
  List,
  Paragraph
} from '@eggplant/pattern-library'

const MostCommonFailsInfo = () =>
  <div className='grid gap-3'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Top 10 test case failures
    </Header>
    <Paragraph>
      The top 10 test case failures report offers valuable insights into the test cases that have encountered the highest number of failures within the applied filters at the dashboard level. This report helps you prioritize troubleshooting efforts and focus on areas that require immediate attention.
      <br />
      <br />
      Key Features:
    </Paragraph>
    <List>
      <Paragraph>
        Fail Frequency Analysis: Identify the top 10 test cases with the highest number of failures, allowing you to pinpoint recurring issues and prioritize resolution.
      </Paragraph>
      <Paragraph>
        Model or Suite Association: Each test case is associated with a specific model or suite, providing context and facilitating targeted investigation.
      </Paragraph>
      <Paragraph>
        Detailed Test Case View: Access specific test cases directly from the report using the actions icon, enabling a focused examination of individual test case.
      </Paragraph>
      <Paragraph>
        Comprehensive Fail Overview: Explore all failures within each specific test case to gain a deeper understanding of the encountered issues.
      </Paragraph>
    </List>
    <Paragraph>
      With the 10 test case failures report, you can efficiently address common failure scenarios, streamline debugging efforts, and improve the overall quality and reliability of your testing process.
    </Paragraph>
  </div>

export default MostCommonFailsInfo
