import LinkToDocusaurus from '../LinkToDocusaurus'
import {
  FormAlert,
  Paragraph
} from '@eggplant/pattern-library'
import { logs } from '../../docs-routes'

const description =
  <Paragraph>
    Logs download is only available on local Windows installations of Eggplant DAI.
  </Paragraph>

const renderInfo =
  <FormAlert
    title='Logs download not available'
    type='info'
  >
    {description}
  </FormAlert>

const LinkToLogsDoc = () =>
  <>
    {renderInfo}
    <LinkToDocusaurus path={logs}>
      Learn more about Server Logs
    </LinkToDocusaurus>
  </>

export default LinkToLogsDoc
