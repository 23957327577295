import PropTypes from 'prop-types'
import SingleSelect from '../SingleSelect/SingleSelect'

const formatDisplayUnit = value => (
  value < 10
    ? '0' // a leading zero string
    : '' // just a string
) + value

const minuteOptions = Array(60).fill().map((item, index) =>
  ({
    label: formatDisplayUnit(index),
    value: index + ''
  })
)

const ChooseMinute = ({
  isDisabled = false,
  onChange,
  value
}) => {
  const minutes = value.getMinutes()

  const handleMinuteChange = minuteString => {
    const newMinutes = parseInt(minuteString)

    if (minutes === newMinutes) return

    const newDate = new Date(value)

    newDate.setMinutes(newMinutes)

    onChange(newDate)
  }

  return (
    <SingleSelect
      isDisabled={isDisabled}
      label='Minute'
      onChange={handleMinuteChange}
      options={minuteOptions}
      value={minutes.toString()}
    />
  )
}

ChooseMinute.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired
}

export default ChooseMinute
