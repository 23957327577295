import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { snippetEditor } from '../../docs-routes'

const SnippetEditor = () =>
  <Paragraph>
    You can associate Eggplant Functional code snippets with states and actions in your model. Those snippets then execute on your system under test as the model runs.
    <br />
    <br />
    To view snippets here, you must first download relevant suites.
    <DocusaurusFooter path={snippetEditor} />
  </Paragraph>

export default SnippetEditor
