import StyledLink from '../StyledLink'
import makeQueryString from './makeUrl/makeUrl'
import {
  CONTROLLER,
  RESULTS
} from '../routes'
import type { Props } from '../types'

interface Base {
  modelId?: string
}

interface WithTestCaseId extends Base {
  testCaseId?: string
  testCaseName?: never
  modelId?: string
  testStatus?: string
  startTime?: string
  endTime?: string
  assetId?: string
  sort?: string
  order?: '+' | '-'
  variance?: 'primary' | 'secondary'
}

interface WithTestCaseName extends Base {
  testCaseId?: never
  testCaseName?: string
  modelId?: string
  testStatus?: string
  startTime?: string
  endTime?: string
  assetId?: string
  sort?: string
  order?: '+' | '-'
  variance?: 'primary' | 'secondary'
}

type Params = WithTestCaseId | WithTestCaseName

const base = '/' + CONTROLLER + '/' + RESULTS

const LinkToTestResults = ({
  children,
  modelId,
  testCaseName,
  testCaseId,
  testStatus,
  label,
  isHyperlink,
  startTime,
  endTime,
  assetId,
  sort,
  order,
  variance = 'primary'
}: Props & Params) => {
  const params = {
    model_id: modelId,
    testcase: testCaseName, // will be encoded
    test_case_ids: testCaseId,
    teststatus: testStatus,
    starttime: startTime,
    endtime: endTime,
    asset_id: assetId,
    sort,
    order
  }

  return (
    <StyledLink
      aria-label={label}
      isHyperlink={isHyperlink}
      to={makeQueryString(base, params)}
      variance={variance}
    >
      {children}
    </StyledLink>
  )
}

export default LinkToTestResults
