import LoadingPane from './LoadingPane'
import { AuthenticatedPolling } from '@eggplant/data-request'
import {
  Card,
  ChartEmptyState,
  Combobox,
  DropdownButton,
  Icons,
  Paragraph
} from '@eggplant/pattern-library'
import { VAM_SERVICE } from '@eggplant/api-hosts'
import { useState } from 'react'
import type {
  AvailableGitSuite,
  ButtonShape,
  GetAvailableGitSuitesResponse,
  OptionType,
  Options
} from '@eggplant/types'
import type { CSSProperties } from 'react'
import type { Props } from '../interfaces'

interface CustomProps extends Props {
  initialOptions?: Options
  gitConnectionId: string
  onAvailableSuitesCountChange: (count: number) => void
}

const mapSutieToOption = (suite: AvailableGitSuite) => ({ label: suite.relative_path, value: suite.relative_path } as OptionType)

const suiteFilter = (suite: AvailableGitSuite) => suite.imported === false

// This could be a blank object with a 204 response if we are still cloning
// or a GetAvailableGitSuitesResponse if we have finished cloning
const getResponseHasItems = (data: GetAvailableGitSuitesResponse) =>
  !!data.items

const SelectGitSuite = ({
  initialOptions = [],
  onChange,
  value,
  gitConnectionId,
  onAvailableSuitesCountChange
}: CustomProps) => {
  const [hasData, setHasData] = useState(false)

  const url = VAM_SERVICE + '/repositories/' + gitConnectionId + '/available_suites'

  const getOptions = (suites: GetAvailableGitSuitesResponse) => {
    if (!suites.items.length) return initialOptions

    return suites.items
      .filter(suiteFilter)
      .map(mapSutieToOption)
  }

  const getDefaultLabel = () => {
    const defaultLabel = 'Choose a suite'

    if (initialOptions.length) {
      const initialLabel = initialOptions.find(item => item.value === '')

      return initialLabel?.label || defaultLabel
    }

    return defaultLabel
  }

  const handleSuccess = (data: GetAvailableGitSuitesResponse) => {
    if (getResponseHasItems(data)) {
      setHasData(true)
    }
  }

  const getRenderButton = (options: Options) => {
    const currentOption = options.find(item =>
      item.value === value
    )

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={Icons.Suites}
        label={
          currentOption?.value
            ? currentOption.label
            : undefined
        }
      >
        {
          currentOption
            ? currentOption.label
            : getDefaultLabel()
        }
      </DropdownButton>
  }

  const emptyStateStyle: CSSProperties = {
    display: 'grid',
    padding: '12px 16px'
  }

  const emptyState =
    <Card>
      <div
        style={emptyStateStyle}
      >
        <ChartEmptyState
          title='No suites found'
        >
          <Paragraph>We couldn't find any suites in your Git repository. Please ensure that your repository contains suites and try again.</Paragraph>
        </ChartEmptyState>
      </div>
    </Card>

  return (
    <AuthenticatedPolling
      intervalMs={1000}
      isPolling={!hasData}
      onSuccess={handleSuccess}
      url={url}
    >
      {
        (response: GetAvailableGitSuitesResponse) => {
          if (!getResponseHasItems(response)) return <LoadingPane />

          onAvailableSuitesCountChange(response.items.length)

          return response.items.length
            ? (
              <Combobox
                initialOptions={initialOptions}
                onChange={onChange}
                options={getOptions(response)}
                renderButton={getRenderButton(getOptions(response))}
                value={value}
              />
            )
            : emptyState
        }
      }
    </AuthenticatedPolling>
  )
}

export default SelectGitSuite
