import LinkToDocusaurus from '../LinkToDocusaurus'
import { BsInfoCircle } from 'react-icons/bs'
import { managingSuites } from '../../docs-routes'

const LinkToSuitesDoc = () =>
  <span>
    <BsInfoCircle
      aria-hidden
      size={13}
      style={{ paddingRight: '0.5em' }}
    />
    <LinkToDocusaurus path={managingSuites}>
      Managing Suites in DAI
    </LinkToDocusaurus>
  </span>

export default LinkToSuitesDoc
