import styles from './TestCaseResultChart.module.scss'
import { DOUGHNUT_CHART_OPTIONS } from '../../constants'
import { Doughnut } from 'react-chartjs-2'
import type { ChartOptions } from 'chart.js'

interface Props {
  cancelled: number
  error: number
  fail: number
  pass: number
  onChartClick: (label: string) => void
}

const generateChartData = (
  fail: number,
  pass: number,
  cancelled: number,
  error: number
) => ({
  labels: [
    'Passed',
    'Failed',
    'Error',
    'Cancelled'
  ],
  datasets: [
    {
      backgroundColor: [
        styles.colorpass,
        styles.colorfail,
        styles.colorerror,
        styles.colorcancelled
      ],
      data: [
        pass,
        fail,
        error,
        cancelled
      ]
    }
  ]
})

const TestCaseResultChart = ({
  pass,
  fail,
  error,
  cancelled,
  onChartClick
}: Props) => {
  const baseChartOptions: ChartOptions<'doughnut'> = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          useBorderRadius: true,
          borderRadius: 1
        }
      },
      tooltip: {
        boxPadding: 4,
        caretPadding: 10,
        padding: 10,
        callbacks: {
          title: () => '',
          label (context: any) {
            return `${context.label}: ${context.raw}%`
          }
        }
      }
    },
    onHover: (evt: any, chartElement: any) => {
      // eslint-disable-next-line fp/no-mutation
      evt.native.target.style.cursor = chartElement[0]
        ? 'pointer'
        : 'default'
    },
    onClick: (event: any, activeEls: any) => {
      const dataIndex = activeEls[0].index
      const label = event.chart.data.labels[dataIndex]

      onChartClick(label)
    }
  }

  const chartOptions = {
    ...DOUGHNUT_CHART_OPTIONS,
    ...baseChartOptions,
    borderWidth: 0
  }

  return (
    <div className={styles.ChartWrapper}>
      <Doughnut
        data={generateChartData(fail, pass, cancelled, error)}
        options={chartOptions}
      />
    </div>
  )
}

export default TestCaseResultChart
