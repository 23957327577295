import classes from './CollapseButton.module.scss'
import {
  FaChevronLeft,
  FaChevronRight
} from 'react-icons/fa'

interface Props {
  isCollapsed: boolean
  onClick: (value: boolean) => void
}

const CollapseButton = ({
  isCollapsed = false,
  onClick
}: Props) => {
  const handleClick = () => {
    onClick(!isCollapsed)
  }

  const title = isCollapsed
    ? 'Expand'
    : 'Collapse'

  const Icon = isCollapsed
    ? FaChevronRight
    : FaChevronLeft

  return (
    <button
      className={classes.CollapseButton}
      onClick={handleClick}
      title={title}
      type='button'
    >
      <Icon
        aria-hidden
        size='18px'
      />
    </button>
  )
}

export default CollapseButton
