import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  RESULTS
} from '../routes'
import { PROP_TASK_INSTANCE_ID } from '../constants'
import type { Props } from '../types'

const base = '/' + CONTROLLER + '/' + RESULTS

interface Params {
  [PROP_TASK_INSTANCE_ID]: string
}

const LinkToRunResults = ({
  children,
  [PROP_TASK_INSTANCE_ID]: taskInstanceId,
  isHyperlink
}: Props & Params) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base + '/runs/' + taskInstanceId}
  >
    {children}
  </StyledLink>

export default LinkToRunResults
