import { PageEmptyState } from '@eggplant/pattern-library'
import { useAccess } from '@eggplant/routing'
import type { ReactNode } from 'react'

interface Props {
  action?: ReactNode
  description: string
  icon: ReactNode
  renderLink: ReactNode
  title: string
}

const EmptyStatesPreview = ({
  action,
  description,
  icon,
  renderLink,
  title
}: Props) => {
  const [isAccess] = useAccess()

  return (
    <div style={{ paddingTop: '110px' }}>
      <PageEmptyState
        action={action}
        description={isAccess && description}
        documentLink={renderLink}
        icon={icon}
        title={title}
      />
    </div>
  )
}

export default EmptyStatesPreview
