import classes from './StatusChip.module.scss'
import {
  HiCheckCircle,
  HiClock,
  HiExclamation,
  HiExclamationCircle,
  HiXCircle
} from 'react-icons/hi'
import { InProgressCircle } from '../Icons/Icons'
import type { ReactNode } from 'react'
import type { Status } from '../types'

interface Props {
  variant: Status
  label: string
  trailingIcon?: ReactNode
}

const iconMap = {
  fail: HiExclamationCircle,
  pass: HiCheckCircle,
  cancelled: HiXCircle,
  error: HiExclamation,
  queued: HiClock,
  inProgress: InProgressCircle
}

const StatusChip = ({
  label,
  variant,
  trailingIcon
}: Props) => {
  const Icon = iconMap[variant]

  return (
    <span className={classes.StatusChip + ' ' + classes[variant]}>
      <Icon
        aria-hidden
        size={18}
      />
      <span>{label}</span>
      {
        trailingIcon &&
          <>
            <div className={classes.Divider} />
            {trailingIcon}
          </>
      }
    </span>
  )
}

export default StatusChip
