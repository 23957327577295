import FieldSetCollapseBar from '../FieldSetCollapseBar/FieldSetCollapseBar'
import Header from '../Header/Header'
import InfoPopOut from '../InfoPopOut/InfoPopOut'
import classes from './GridFieldset.module.scss'
import type { ReactNode } from 'react'

interface BaseProps {
  children?: ReactNode
  hasBackground?: boolean
  hasOverflow?: boolean
  isCollapsible?: boolean
}

interface LabelWithoutDescription {
  label?: string
  sectionLabel: never
  hasDivider: never
  description: never
  isRequired?: boolean
}

interface LabelWithDescription {
  label: string
  sectionLabel?: never
  hasDivider?: never
  description?: ReactNode
  isRequired?: boolean
}

interface SectionWithoutDescription {
  label: never
  sectionLabel?: string
  hasDivider?: boolean
  description: never
  isRequired?: never
}

interface SectionWithDescription {
  label?: never
  sectionLabel: string
  hasDivider?: boolean
  description?: ReactNode
  isRequired?: never
}

interface WithoutEither {
  label?: never
  sectionLabel?: never
  hasDivider?: never
  description?: never
  isRequired?: never
}

type Props = BaseProps & (LabelWithoutDescription | LabelWithDescription | SectionWithoutDescription | SectionWithDescription | WithoutEither)

// overflow only works for single FieldLabel
// eslint-disable-next-line complexity
const GridFieldset = ({
  children,
  label,
  sectionLabel,
  hasDivider = true,
  isRequired,
  description,
  hasOverflow,
  hasBackground,
  isCollapsible = false
}: Props) => {
  const fieldsetWrapperClass = sectionLabel && hasDivider
    ? classes.GridFieldset + ' ' + classes.hasBorderTop
    : classes.GridFieldset

  const isWithoutDividerClass = sectionLabel && !hasDivider
    ? classes.extraBorderTop
    : ''

  const isOverflow = hasOverflow
    ? classes.isOverflow
    : classes.isOverflowHidden

  const backgroundClass = hasBackground
    ? classes.hasBackground
    : ''

  const childrenWrapperClass = [
    classes.GridChildren,
    isWithoutDividerClass,
    isOverflow,
    backgroundClass
  ].filter(value => value).join(' ')

  const renderChildren =
    <fieldset className={fieldsetWrapperClass}>
      {
        (label || sectionLabel) &&
          <legend>
            {
              !!sectionLabel &&
                <Header rank={3}>
                  {sectionLabel}
                </Header>
            }
            {
              !!label && label
            }
            <span>
              {
                isRequired
                  ? '*'
                  : ''
              }
            </span>
            {
              description &&
                <InfoPopOut>
                  {description}
                </InfoPopOut>
            }
          </legend>
      }
      <div className={childrenWrapperClass}>
        {children}
      </div>
    </fieldset>

  return isCollapsible
    ? (
      <FieldSetCollapseBar>
        {renderChildren}
      </FieldSetCollapseBar>
    )
    : renderChildren
}

export default GridFieldset
