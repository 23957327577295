import {
  Header,
  List,
  Paragraph
} from '@eggplant/pattern-library'

const SlowestTestCaseInfo = () =>
  <div className='grid gap-3'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Top 10 slowest running test cases
    </Header>
    <Paragraph>
      This report highlights the top 10 slowest test case runs, providing crucial insights into the execution time of your tests. It showcases both the maximum and average time taken for each test case, allowing you to identify potential bottlenecks and optimize your testing process.
      <br />
      <br />
      Key Features:
    </Paragraph>
    <List>
      <Paragraph>
        Detailed Execution Time: View the maximum and average execution time for each test run, enabling you to understand the performance of your test cases.
      </Paragraph>
      <Paragraph>
        Model and Suite: Associate which model or suite each test case belongs to, facilitating targeted analysis and troubleshooting.
      </Paragraph>
      <Paragraph>
        Access Test Case Results: Dive deeper into individual test case results to gain a comprehensive understanding of their execution details.
      </Paragraph>
      <Paragraph>
        Test Case Actions: Easily access the actions menu to view a specific test case, allowing for edit and further investigation.
      </Paragraph>
    </List>
    <Paragraph>
      With the Top 10 Slowest Test Case Runs Report, you have a powerful tool to identify and address performance issues, optimize your testing efforts, and ensure efficient execution of your test cases.
    </Paragraph>
  </div>

export default SlowestTestCaseInfo
