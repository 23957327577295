import classes from './InputNumber.module.scss'
import type { ChangeEvent } from 'react'

interface Props {
  isDisabled?: boolean
  isReadOnly?: boolean
  isRequired?: boolean
  label?: string
  max?: number
  min?: number
  onChange: (value: number) => void
  onClick?: () => void
  value?: number
  id?: string
}

const InputNumber = ({
  isDisabled = false,
  isReadOnly = false, // needed to allow click event instead of disabled prop
  isRequired = true,
  label,
  max = 999,
  min = 0,
  onChange,
  onClick,
  id,
  value = 1
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(event.target.value))
  }

  return (
    <input
      aria-label={label}
      className={classes.InputNumber}
      disabled={isDisabled}
      id={id}
      max={max}
      min={min}
      onChange={handleChange}
      onClick={onClick}
      readOnly={isReadOnly}
      required={isRequired}
      type='number'
      value={value}
    />
  )
}

export default InputNumber
