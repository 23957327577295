import LogOut from './LogOut'
import {
  LOG_OUT,
  RedirectToSetup,
  Router as RoutingRouter,
  SETUP,
  SYSTEM
} from '@eggplant/routing'
import { lazy } from 'react'

const Setup = lazy(() => import(/* webpackChunkName: 'Setup' */ '../../Licenses/Setup/Setup'))

const SetupRouter = () =>
  <RoutingRouter>
    <Setup
      // @ts-expect-error
      path={SYSTEM + '/' + SETUP}
    />
    <LogOut
      // @ts-expect-error
      path={'/' + LOG_OUT}
    />
    <RedirectToSetup
      // @ts-expect-error
      default
    />
  </RoutingRouter>

export default SetupRouter
