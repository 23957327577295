import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  RUNNER
} from '../routes'
import type { Props } from '../types'

const base = '/' + CONTROLLER + '/' + RUNNER

interface Params {
  task_id?: string
  test_config_id: string
}

const LinkToEditSchedule = ({
  children,
  test_config_id,
  task_id,
  isHyperlink
}: (Props & Params)) => {
  const task = task_id
    ? '/' + task_id // Edit an existing schedule
    : '' // create a new schedule for the test_config

  return (
    <StyledLink
      isHyperlink={isHyperlink}
      to={base + '/' + test_config_id + task}
    >
      {children}
    </StyledLink>
  )
}

export default LinkToEditSchedule
