import DataRequest from '../DataRequest/DataRequest'
import { AuthConsumer } from '@eggplant/context'
import type { DataRequestProps } from '../types'
import type { Error } from '@eggplant/types'

const AuthenticatedDataRequest = (props: DataRequestProps) =>
  <AuthConsumer>
    {
      ({
        authToken,
        onAuthReject
      }) => {
        const handleError = (error: Error) => {
          const isAuthError =
            error.status &&
            error.status.toString().includes('401')

          if (isAuthError && authToken) onAuthReject()
        }

        const {
          extraHeaders: extraHeadersProp,
          ...allOtherProps
        } = props

        const extraHeaders = {
          Authorization: `Bearer ${authToken}`,
          ...extraHeadersProp
        }

        return (
          <DataRequest
            extraHeaders={extraHeaders}
            onError={handleError}
            {...allOtherProps}
          />
        )
      }
    }
  </AuthConsumer>

export default AuthenticatedDataRequest
