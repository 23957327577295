import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { bugHuntingHistory } from '../../docs-routes'

const BugHuntingHistory = () =>
  <Paragraph>
    The Bug Hunting report shows you how many failures occurred in a given model, and where the failures happened. To get started, use the drop-down menu to select a model that has failed at least once.
    <DocusaurusFooter path={bugHuntingHistory} />
  </Paragraph>

export default BugHuntingHistory
