import RemoveButton from '../RemoveButton/RemoveButton'
import classes from './RemovableItem.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  isDisabled: boolean
  onRemoveClick: () => void
}

const RemovableItem = ({
  children,
  isDisabled = false,
  onRemoveClick
}: Props) => {
  const classNames = [
    classes.RemovableItem,
    !isDisabled || classes.RemovableItemDisabled
  ].join(' ')

  return (
    <span className={classNames}>
      {children}
      {' '}
      {
        !isDisabled &&
        (
          <RemoveButton
            onClick={onRemoveClick}
          />
        )
      }
    </span>
  )
}

export default RemovableItem
