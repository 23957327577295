import EmptyState from '../EmptyState'
import { Paragraph } from '@eggplant/pattern-library'

const TestCaseNoParameters = () =>
  <EmptyState
    hasPadding={false}
    title='No parameters found'
  >
    <Paragraph>
      There is no parameters available, please add parameters in Models &gt; Edit Models if needed.
    </Paragraph>
  </EmptyState>

export default TestCaseNoParameters
