import classes from './TruncatedString.module.scss'

interface Props {
  limit: number
  value: string
  wrap?: boolean
}

const TruncatedString = ({
  value,
  limit,
  wrap = false
}: Props) => {
  const isTruncated = value.length > limit

  const displayValue = isTruncated
    ? value.substring(0, limit) + '...'
    : value

  const title = isTruncated
    ? value
    : ''

  const textWrap = wrap
    ? 'wrapped'
    : 'notWrapped'

  return (
    <span
      className={classes.TruncatedString + ' ' + classes[textWrap]}
      title={title}
    >
      {displayValue}
    </span>
  )
}

export default TruncatedString
