import '@reach/tooltip/styles.css'
import classes from './Tooltip.module.scss'
import {
  TooltipPopup,
  useTooltip
} from '@reach/tooltip'
import { cloneElement } from 'react'
import type {
  ReactNode
} from 'react'

// From https://reacttraining.com/reach-ui/tooltip/#demos

interface Props {
  // An optional prop to help screen readers determine what message you are trying to convey.
  // Useful when the item being hovered isn't text.
  ariaLabel?: string
  // The content that will become hoverable to trigger the tooltip
  children: ReactNode
  // The simple content of the tooltip itself.
  label: ReactNode
  backgroundVariance?: 'white' | 'black'
}

const Tooltip = ({
  children,
  label,
  ariaLabel,
  backgroundVariance = 'black'
}: Props) => {
  const [trigger, tooltip] = useTooltip()

  const wrappedChildren =
    <span>
      {children}
    </span>

  const backgroundClass = backgroundVariance !== 'black'
    ? classes.white
    : classes.black

  const tooltipClassName = [
    classes.Tooltip,
    backgroundClass
  ].join(' ')

  return (
    <>
      {cloneElement(wrappedChildren, trigger)}
      <TooltipPopup
        {...tooltip}
        aria-label={ariaLabel}
        className={tooltipClassName}
        label={label}
      />
    </>
  )
}

export default Tooltip
