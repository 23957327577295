import {
  Card,
  Color,
  Paragraph
} from '@eggplant/pattern-library'
import { FaInfoCircle } from 'react-icons/fa'
import type {
  CSSProperties,
  ReactNode
} from 'react'
import type { IconType } from 'react-icons/lib'

interface Props {
  children: ReactNode
  Icon?: IconType
}

const wrapperStyle: CSSProperties = {
  display: 'inline-flex',
  padding: '2em',
  gap: '.5em'
}

const Hint = ({
  children,
  Icon = FaInfoCircle
}: Props) =>
  <Card>
    <Paragraph>
      <span style={wrapperStyle}>
        <Color variant='theme-filter-icon'>
          <Icon
            aria-hidden
            size='1.5em'
          />
        </Color>
        {children}
      </span>
    </Paragraph>
  </Card>

export default Hint
