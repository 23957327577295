import StyledLink from '../StyledLink'
import { HELP } from '../routes'
import type { Props } from '../types'

const LinkToHelp = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + HELP}
  >
    {children}
  </StyledLink>

export default LinkToHelp
