import Tooltip from '../Tooltip/Tooltip'
import classes from './InfoButton.module.scss'
import { FiHelpCircle } from 'react-icons/fi'
import { forwardRef } from 'react'

interface Props {
  onClick: () => void
  withTooltips?: boolean
}

// forwardRef is used here because this button can be used as the anchor for a PopOut
// this works the same as any other component
const InfoButton = forwardRef((
  {
    onClick,
    withTooltips = true
  }: Props,
  ref: any
) =>
  <button
    className={classes.InfoButton}
    onClick={onClick}
    ref={ref}
    title='What&apos;s This?'
    type='button'
  >
    {
      withTooltips
        ? (
          <Tooltip label='What&apos;s This?'>
            <FiHelpCircle
              aria-hidden
              size='20px'
            />
          </Tooltip>
        )
        : (
          <FiHelpCircle
            aria-hidden
            size='20px'
          />
        )
    }
  </button>
)

export default InfoButton
