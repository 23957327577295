import Fieldset from '../Fieldset/Fieldset'
import InvisiButton from '../InvisiButton/InvisiButton'
import RemovableItem from '../RemovableItem/RemovableItem'
import TagComponent from '../Tag/Tag'
import TagInput from '../TagInput/TagInput'
import classes from './TagsInput.module.scss'
import { sortBy } from 'lodash'
import { useState } from 'react'
import type { ReactNode } from 'react'
import type { Tag } from '@eggplant/types'

/*
  Pre-determined color list,
  as defined in https://app.zeplin.io/project/5cd2dc3b70cbdf68502b5cfd/screen/5cd5a25129a16a6808dd9c67
*/
const colors = [
  'DDA0DD', '87CEFA', '66CDAA', 'DC143C', 'F5E469', 'FF69B4', 'FFA500',
  '9932CC', '4682B4', '98FB98', 'FF0000', 'DDD378', 'DB98A3', 'FF7F50',
  '8888AF', '0000FF', '008B8B', 'F08080', 'FFFF00', 'FF1493', 'FF4500',
  '9370DB', 'ADD8E6', 'AFEEEE', 'B22222', 'EDC803', 'FF00FF', 'D95C00'
]

const countOccurrences = (array: any[], value: string) =>
  array.reduce<number>((count, val) =>
    count + (
      val === value
        ? 1
        : 0
    )
  , 0)

const sortTags = (tagA: Tag, tagB: Tag) => {
  const aName = tagA.name.toLowerCase()
  const bName = tagB.name.toLowerCase()

  if (aName < bName) return -1

  if (aName > bName) return 1

  return 0
}

interface Props {
  availableTags: Tag[]
  isDisabled?: boolean
  isNewItemAllowed?: boolean
  isRequired?: boolean
  label?: string
  maxLength: number
  onSelectedTagsChanged: (tags: Tag[]) => void
  placeholder: string
  selectedTags: Tag[]
  description?: ReactNode
}

const TagsInput = ({
  availableTags: availableTagsProp,
  label,
  isDisabled = false,
  isRequired = false,
  maxLength,
  placeholder,
  onSelectedTagsChanged,
  selectedTags: selectedTagsProp,
  isNewItemAllowed = true,
  description
}: Props) => {
  const selectedTagNames = selectedTagsProp.map(item => item.name)

  const allTagsFiltered = availableTagsProp.filter(item =>
    !selectedTagNames.includes(item.name)
  )

  const [availableTags, setAvailableTags] = useState(allTagsFiltered)
  const [selectedTags, setSelectedTags] = useState(selectedTagsProp)
  const [newTags, setNewTags] = useState([] as Tag[])

  /*
    Pick the color from a pre-determined list.
    If there are no more colors, go back to the beginning.
    If the color has already been used, pick the next available one.
  */
  const getNextColor = () => {
    // Get a list of all the colors used so far
    const usedColors = availableTags.map(item => item.color.toUpperCase()).concat(selectedTags.map(item => item.color.toUpperCase()))

    // Count how many times each color in the colors array has been used so far
    const colorCounts = colors.reduce<Record<string, number>>((obj, color) => ({
      ...obj,
      ['#' + color]: countOccurrences(usedColors, '#' + color)
    }), {})

    // Sort the list of colors by the number of times they have been used
    // Use lodash.sortBy because it is guaranteed to be stable
    const sortedColors = sortBy(Object.keys(colorCounts), color => colorCounts[color])

    // Pick the color that has been used the least number of times
    return sortedColors[0]
  }

  const updateSelectedTags = (selectedTag: Tag) => {
    const newSelectedTags = selectedTags.concat([selectedTag])

    setSelectedTags(newSelectedTags)
    onSelectedTagsChanged(newSelectedTags)
  }

  /*
    A Tag was selected from the dropdown. Remove it from
    the Available Tags list and add it to the Selected Tags list.
  */
  const onSelectionChanged = (selectedTag: Tag) => {
    const newAvailableTags = availableTags.filter(item => item.name !== selectedTag.name)

    setAvailableTags(newAvailableTags)
    updateSelectedTags(selectedTag)
  }

  /*
    Create a new Tag based on the value given.
    If the entered Tag already exists (Selected or Available):
      if it is an existing (Selected) Tag, do nothing,
      else use the matched one from the Available list.
  */
  const onValueEntered = (searchText: string) => {
    // If the new Tag is an empty string (whitespace or Enter)
    // reject it and do a return to exit out
    if (!searchText) {
      return
    }

    // If the new Tag matches an existing Tag
    // (that is, either already exists or was created
    // in this session) use that instead.
    const existingTag = selectedTags.find(item => item.name === searchText)
    const availableTag = availableTags.find(item => item.name === searchText)

    const newTag = {
      color: getNextColor(),
      id: '',
      name: searchText
    }

    if (!existingTag && !availableTag) {
      updateSelectedTags(newTag)

      const updatedNewTags = newTags.concat([newTag])

      setNewTags(updatedNewTags)
    }

    // Update the Available Tags list if the Tag was in that list.
    if (availableTag) {
      const newAvailableTags = availableTags.filter(item => item.name !== newTag.name)
      const selectedAvailableTag = availableTags.filter(item => item.name === newTag.name)

      setAvailableTags(newAvailableTags)
      // Add to the Selected Tags too.
      updateSelectedTags(selectedAvailableTag[0])
    }
  }

  /*
    A Tag (RemovableItem) was deleted. Remove the Tag from
    the Selected Tags list and add it back to the Available Tags list
    (unless it was a new one to begin with).
  */
  const removeTag = (selectedTag: Tag) => {
    const newSelectedTags = selectedTags.filter(item => item.name !== selectedTag.name)

    setSelectedTags(newSelectedTags)
    onSelectedTagsChanged(newSelectedTags)

    const newTag = newTags.find(item => item.name === selectedTag.name)

    if (newTag) {
      const updatedNewTags = newTags.filter(item => item.name !== selectedTag.name)

      setNewTags(updatedNewTags)
    } else {
      /* eslint-disable fp/no-mutating-methods */
      // NB: Cannot avoid sort mutation. Use a custom comparator function to sort by Tag name.
      const newAvailableTags = availableTags.concat([selectedTag]).sort(sortTags)

      /* eslint-enable fp/no-mutating-methods */
      setAvailableTags(newAvailableTags)
    }
  }

  const handleClearAll = () => {
    // eslint-disable-next-line fp/no-mutating-methods
    const newAvailableTags = [
      ...availableTags,
      ...selectedTags
    ].sort(sortTags)

    setAvailableTags(newAvailableTags)
    setSelectedTags([])
    onSelectedTagsChanged([])
  }

  return (
    <Fieldset
      description={description}
      isGrid
      isRequired={isRequired}
      label={label || ''}
    >
      <div className={classes.TagsInput}>
        <TagInput
          availableTags={availableTags}
          isDisabled={isDisabled}
          isNewItemAllowed={isNewItemAllowed}
          isRequired={isRequired && selectedTags.length === 0} // labels the input box as required
          label={label}
          maxLength={maxLength}
          onSelectionChanged={onSelectionChanged}
          onValueEntered={onValueEntered}
          placeholder={placeholder}
        />
        <ul>
          {
            selectedTags.map(selectedTag =>
              <li key={selectedTag.name}>
                <RemovableItem
                  isDisabled={isDisabled}
                  onRemoveClick={() => removeTag(selectedTag)}
                >
                  <TagComponent
                    color={selectedTag.color}
                    label={selectedTag.name}
                    limit={maxLength}
                  />
                </RemovableItem>
              </li>
            )
          }
        </ul>
      </div>
      {
        !isDisabled &&
          <div className={classes.TagsClearAllButton}>
            <InvisiButton
              onClick={handleClearAll}
              title='Clear all'
            >
              Clear all
            </InvisiButton>
          </div>
      }
    </Fieldset>
  )
}

export default TagsInput
