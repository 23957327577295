import classes from './PercentBar.module.scss'
import type { CSSProperties } from 'react'

export interface Props {
  value: number
}

const isSmall = (value: number) => value < 10

const isFull = (value: number) => value === 100

const getFillStyle = (value: number): CSSProperties => ({
  flexBasis: value
    ? value + '%'
    : 0,
  paddingRight: isSmall(value)
    ? 0
    : '0.5em'
})

const getPaddingStyle = (value: number): CSSProperties => ({
  flexBasis: isFull(value)
    ? 0
    : 100 - value + '%',
  marginLeft: isFull(value)
    ? 0
    : '0.5em'
})

const normalizeValue = (value: number) => {
  const result = Number(value) >= 0
    ? Number(value)
    : 0

  return result > 100
    ? 100
    : result
}

const PercentBar = ({
  value
}: Props) => {
  const normalizedValue = normalizeValue(value)

  return (
    <span
      className={classes.PercentBar}
    >
      <span
        className={classes.Fill}
        style={getFillStyle(normalizedValue)}
      >
        {
          !isSmall(normalizedValue)
            ? normalizedValue + '%'
            : ''
        }
      </span>
      <span
        className={classes.NoFill}
        style={getPaddingStyle(normalizedValue)}
      >
        {
          isSmall(normalizedValue)
            ? normalizedValue + '%'
            : ''
        }
      </span>
    </span>
  )
}

export default PercentBar
