import type { Role } from '../types'

export const realmAccessRoles = [
  'dai:admin',
  'dai:user',
  'dai:viewer'
] as const

export const defaultAllowedRoles: Role[] = [
  'dai:admin',
  'dai:user'
]
