import classes from './TestCaseResultTrendChart.module.scss'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import type {
  Align,
  LayoutPosition
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
)

type LabelType = 'passed' | 'failed' | 'error' | 'cancelled'
interface Props {
  timeItems: string[]
  passItems: number[]
  failItems: number[]
  errorItems: number[]
  cancelledItems: number[]
  onClick: (label: LabelType, date: string) => void
}

const TestCaseResultTrendChart = ({
  timeItems,
  passItems,
  failItems,
  errorItems,
  cancelledItems,
  onClick
}: Props) => {
  const statusItems = [
    {
      label: 'Passed',
      color: '#36B15B',
      pointColor: 'rgba(54, 177, 91, 0.5)',
      items: passItems
    },
    {
      label: 'Failed',
      color: '#CC0024',
      pointColor: 'rgba(204, 0, 36, 0.5)',
      items: failItems
    },
    {
      label: 'Error',
      color: '#FFA600',
      pointColor: 'rgba(255, 166, 0, 0.5)',
      items: errorItems
    },
    {
      label: 'Cancelled',
      color: '#CCCCCC',
      pointColor: 'rgba(204, 204, 204, 0.5)',
      items: cancelledItems
    }
  ]

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      // eslint-disable-next-line id-length
      y: {
        grid: {
          color: () => {
            const style = getComputedStyle(document.body)

            return style.getPropertyValue('--grid-color') || '#C0C0C0'
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as LayoutPosition,
        align: 'start' as Align,
        labels: {
          padding: 16
        }
      },
      tooltip: {
        boxPadding: 4,
        caretPadding: 10,
        padding: 10

      }
    },
    onHover: (event: any, chartElement: any) => {
      // eslint-disable-next-line fp/no-mutation
      event.native.target.style.cursor = chartElement[0]
        ? 'pointer'
        : 'default'
    },
    onClick: (event: any, activeEls: { index: any }[]) => {
      if (activeEls.length === 0) {
        return
      }

      const dataIndex = activeEls[0].index

      const label = event.chart.data.labels[dataIndex]

      const status = event.chart.tooltip.dataPoints.length > 1
        ? ''
        : event.chart.tooltip.dataPoints[0].dataset.label

      onClick(status, label)
    }
  }

  const datasets = statusItems.map(status => ({
    label: status.label,
    data: status.items,
    fill: false,
    borderColor: status.color,
    backgroundColor: status.color,
    pointHoverBackgroundColor: status.pointColor,
    tension: 0
  }
  ))

  const data = {
    labels: timeItems,
    datasets
  }

  return (
    <div className={classes.ChartContainer}>
      <Line
        data={data}
        height={0}
        options={chartOptions}
      />
    </div>
  )
}

export default TestCaseResultTrendChart
