import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { LinkToLogsDoc } from '../links'
import { Logs } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = 'Here you can download a .zip package of log files from all DAI services. You can use these files in troubleshooting.'

const LogsIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Logs />}
        renderLink={<LinkToLogsDoc />}
        title='Server Logs'
      />
    </div>
  </div>

export default LogsIntro
