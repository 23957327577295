import {
  createContext,
  useState
} from 'react'
import {
  erase,
  get,
  set
} from 'browser-cookies'
import type { ReactNode } from 'react'

type AgentIdType = number

interface Context {
  agentId?: AgentIdType
  setAgentId: (agent_id?: AgentIdType) => void
}

const ExecutionEnvironment = createContext<Context | undefined>(undefined)

const COOKIE_NAME = 'agentId'

interface ProviderProps {
  children: ReactNode
  agentId?: AgentIdType // can be used to change the cookie
}

export const ExecutionEnvironmentProvider = ({
  children,
  agentId: agentIdProp
}: ProviderProps) => {
  const cookie = Number(get(COOKIE_NAME)) || undefined

  const [agentId, setAgentIdState] = useState<AgentIdType | undefined>(agentIdProp || cookie)

  const updateCookie = (newAgentId?: AgentIdType) => {
    if (newAgentId !== undefined && !isNaN(newAgentId)) {
      set(COOKIE_NAME, newAgentId.toString())
    } else {
      erase(COOKIE_NAME)
    }
  }

  if (agentId !== cookie) updateCookie(agentId)

  const setAgentId = (newAgentId?: AgentIdType) => {
    updateCookie(newAgentId)
    setAgentIdState(newAgentId)
  }

  const value = {
    agentId,
    setAgentId
  }

  return (
    <ExecutionEnvironment.Provider value={value}>
      {children}
    </ExecutionEnvironment.Provider>
  )
}

interface ConsumerProps {
  children: (context: Context) => ReactNode
}

export const ExecutionEnvironmentConsumer = ({
  children: renderChildren
}: ConsumerProps) =>
  <ExecutionEnvironment.Consumer>
    {
      (context?: Context) => {
        if (context === undefined) {
          throw new Error('<ExecutionEnvironmentConsumer /> must be used within <ExecutionEnvironmentProvider />')
        }

        return renderChildren(context)
      }
    }
  </ExecutionEnvironment.Consumer>
