/* eslint-disable complexity */
import filterParams from '../filterParams'
import renderError from '../renderError'
import requestData from '../requestData'
import { LoadingPlaceholder } from '@eggplant/pattern-library'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { useState } from 'react'
import type { DataRequestProps } from '../types'
import type { Error } from '@eggplant/types'
import type { ReactElement } from 'react'

const DataRequest = ({
  children: renderChildren,
  isUrlEncoded = false,
  method,
  onError,
  onSuccess,
  params,
  responseType = 'json',
  url,
  extraHeaders,
  shortErrorMessage
}: DataRequestProps) => {
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState<object | null | string>(null)

  const handleData = (data: any) => {
    if (onSuccess) onSuccess(data)

    setData(data)
  }

  const handleError = (error: Error) => {
    if (onError) onError(error)

    setError(error)
  }

  // Arrays can also be sent and should not be filtered
  const filteredParams = params && !Array.isArray(params)
    ? filterParams(params)
    : params

  // useDeepCompareEffectNoCheck will check the properties of params
  // rather than the object reference of params
  // "NoCheck" means do not complain that not all checked dependencies are objects
  useDeepCompareEffectNoCheck(() => {
    // Clear the existing data to restore the spinner between requests
    setData(null)
    setError(null)

    return requestData(
      url,
      method,
      handleData,
      handleError,
      filteredParams,
      responseType,
      isUrlEncoded,
      extraHeaders
    )
  }, [url, method, filteredParams])

  if (error) return renderError(error, shortErrorMessage)

  // Empty files can be empty strings
  if (!data && data !== '') return <LoadingPlaceholder />

  if (renderChildren) return renderChildren(data) as ReactElement

  // Some consumers just want to trigger from onSuccess
  return null
}

export default DataRequest
