import classes from './StopButton.module.scss'
import { FaStop } from 'react-icons/fa'

interface Props {
  'aria-label'?: string
  onClick: () => void
  title: string
}

const StopButton = ({
  'aria-label': ariaLabel,
  onClick,
  title
}: Props) => {
  const handleClick = () => {
    onClick() // Prevent the event from being passed
  }

  return (
    <button
      aria-label={ariaLabel}
      className={classes.StopButton}
      onClick={handleClick}
      title={title}
      type='button'
    >
      <FaStop
        aria-hidden
        size='15px'
      />
    </button>
  )
}

export default StopButton
