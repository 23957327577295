import StyledLink from '../StyledLink'
import {
  DESIGNER,
  TESTCASES
} from '../routes'
import type { Props } from '../types'

const base = `/${DESIGNER}/${TESTCASES}/scripted/`

interface Params {
  test_case_id: string
}

const LinkToScriptedTestCaseView = ({
  children,
  test_case_id,
  isHyperlink
}: Props & Params) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base + test_case_id}
  >
    {children}
  </StyledLink>

export default LinkToScriptedTestCaseView
