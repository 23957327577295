import classes from './FormAlert.module.scss'
import { FiAlertCircle, FiAlertTriangle, FiCheckCircle, FiInfo } from 'react-icons/fi'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { useId } from 'react'
import type { IconType } from 'react-icons/lib'
import type { ReactNode } from 'react'

type AlertType = 'info' | 'success' | 'warning' | 'error' | 'tip' | 'none'

interface Props {
  children: ReactNode
  title?: string
  type?: AlertType
}

const icons: Record<AlertType, IconType> = {
  info: FiInfo,
  success: FiCheckCircle,
  warning: FiAlertTriangle,
  error: FiAlertCircle,
  tip: HiOutlineLightBulb,
  none: FiInfo
}

const FormAlert = ({
  children,
  title = '',
  type = 'none'
}: Props) => {
  // There can be multiple errors on the page
  // So each needs a unique ID to prevent conflict
  const id = useId()

  const labelId = id + 'label'
  const descriptionId = id + 'description'

  const Icon = icons[type]

  const getClasses = (type: AlertType) => [
    classes.Root,
    classes[type]
  ].join(' ')

  return (
    <div
      aria-describedby={descriptionId}
      aria-labelledby={labelId}
      className={getClasses(type)}
      role='alert'
    >
      {
        type !== 'none' &&
          <Icon
            aria-hidden
            className={classes.icon}
            size='22px'
          />
      }
      <div className={classes.content}>
        {
          title &&
            <span
              className={classes.title}
              id={labelId}
            >
              {title}
            </span>
        }
        <div id={descriptionId}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default FormAlert
