import classes from './HelpBanner.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const HelpBanner = ({ children }: Props) =>
  <div className={classes.HelpBanner}>
    {children}
  </div>

export default HelpBanner
