import CheckedIndicator from '../CheckedIndicator/CheckedIndicator'
import Downshift from 'downshift'
import classes from './MultiselectDropdown.module.scss'
import type { ControllerStateAndHelpers, DownshiftState, GetItemPropsOptions, StateChangeOptions } from 'downshift'

export interface ButtonProps {
  getInputProps: () => void
  getItemProps: () => void
  getLabelProps: () => void
  getMenuProps: () => void
  getRootProps: () => void
  getToggleButtonProps: () => void
  isOpen: boolean
}

interface Option {
  label: string
  value: string
}

export interface Group {
  label: string
  options: Option[]
}

interface Props {
  onChange: (value: string) => void
  optionListGroups: Group[]
  renderButton: (props: ButtonProps) => void
  selectedValueList: string[]
}

const {
  keyDownEnter,
  clickItem
} = Downshift.stateChangeTypes

const itemToString = (option?: Option | null) => option
  ? option.value
  : ''

// Prevent the default behavior of closing the menu upon item selection
const stateReducer = (state: DownshiftState<Option>, changes: StateChangeOptions<Option>) => {
  const isOpeningChangeType =
    changes.type === keyDownEnter ||
    changes.type === clickItem

  return isOpeningChangeType
    ? {
      ...changes,
      highlightedIndex: state.highlightedIndex,
      isOpen: true
    }
    : changes
}

const MultiselectDropdown = ({
  optionListGroups,
  onChange,
  renderButton,
  selectedValueList
}: Props) => {
  const handleChange = (selectedItem: Option | null, stateAndHelpers: ControllerStateAndHelpers<Option>) => {
    if (!selectedItem) return

    onChange(selectedItem.value)

    // Clear the input
    stateAndHelpers.setState({ inputValue: '' })
  }

  const renderOptions = (options: Option[], getItemProps: (options: GetItemPropsOptions<Option>) => any) =>
    options.map(option => {
      const itemProps = getItemProps({
        item: option
      })

      const isChecked =
        !!selectedValueList.length &&
        selectedValueList.includes(option.value)

      // aria-selected is used by Downshift for basic highlighting, but we need it to show selections
      const className = itemProps['aria-selected']
        ? classes.highlighted
        : undefined

      return (
        <li
          {...itemProps}
          // When an item is actually chosen, not just highlighted
          aria-selected={isChecked}
          className={className}
          key={option.value}
          role='option'
        >
          <CheckedIndicator isChecked={isChecked} />
          {' '}
          {option.label}
        </li>
      )
    })

  return (
    <Downshift
      itemToString={itemToString}
      onChange={handleChange}
      selectedItem={null}
      stateReducer={stateReducer}
    >
      {
        ({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getRootProps,
          getToggleButtonProps,
          isOpen
        }) =>
          // The span is not really a combobox since it does not contain a text input
          <span
            className={classes.MultiselectDropdown}
            {...getRootProps(undefined, { suppressRefError: true })}
            role={undefined}
          >
            {
              renderButton({
                ...getToggleButtonProps(),
                ...getLabelProps(), // The button contains the labeling text
                'aria-activedescendant': getInputProps()['aria-activedescendant']
              })
            }
            <ul
              {...getMenuProps()}
              // something using activedescendant must be focusable
              aria-multiselectable
              role='listbox'
            >
              {
                isOpen &&
                optionListGroups.map(group =>
                  <li key={group.label}>
                    <span className={classes.groupLabel}>
                      {group.label}
                    </span>
                    <ul>
                      {renderOptions(group.options, getItemProps)}
                    </ul>
                  </li>
                )
              }
            </ul>
          </span>
      }
    </Downshift>
  )
}

export default MultiselectDropdown
