import Tooltip from '../Tooltip/Tooltip'
import classes from './IconButton.module.scss'
import type { ReactNode } from 'react'

type size = 'lg' | 'md' | 'sm'

interface Props {
  children: ReactNode
  onClick: () => void
  isDisabled?: boolean
  title: string
  size?: size
  hasOutline?: boolean
  isWhiteBackground?: boolean
}

const IconButton = ({
  children,
  onClick,
  title,
  isDisabled,
  size = 'md',
  hasOutline = false,
  isWhiteBackground = false
}: Props) => {
  const outlined = hasOutline
    ? ' ' + classes.border
    : ''

  const whiteBackground = isWhiteBackground
    ? ' ' + classes.isWhiteBackground
    : ''

  const iconButtonClassName = classes.IconButton + ' ' + classes[size] + outlined + whiteBackground

  const renderButton =
    <button
      aria-label={title}
      className={iconButtonClassName}
      disabled={isDisabled}
      onClick={onClick}
      type='button'
    >
      {children}
    </button>

  return (
    title
      ? (
        <Tooltip label={title}>
          {renderButton}
        </Tooltip>
      )
      : renderButton
  )
}

export default IconButton
