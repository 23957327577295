import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { managingSuites } from '../../docs-routes'

const Suites = () =>
  <Paragraph>
    Eggplant DAI models can be paired with Eggplant Functional suites to automate testing.
    <br />
    <br />
    You can add suites using our <strong>internal storage</strong> via the Agent or you can add suites from your <strong>Git repository</strong> (repo).
    <br />
    <br />
    <strong>To add suites to your project using our internal storage</strong>, open the Agent. Make sure you have a Suites root folder defined, then click Manage Suites. Choose the suite you want to add, then click Upload.
    <br />
    <br />
    <strong>To add suites from your Git repository</strong>, set up a Git connection to your repo and add suites from your repo to DAI via the Manage Suites page. You can get to the Manage Suites page from the action menu on the Git Connections page under System &gt; Git Connections.
    <DocusaurusFooter path={managingSuites} />
  </Paragraph>

export default Suites
