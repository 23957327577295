import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { Bug } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { LinkToBugHuntingHistoryDoc } from '../links'

const description = 'The Bug Hunting report shows you how many failures occurred in a given model, and where the failures happened. To get started, use the drop-down menu to select a model that has failed at least once.'

const BugHuntingHistory = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Bug />}
        renderLink={<LinkToBugHuntingHistoryDoc />}
        title='Bug Hunting History'
      />
    </div>
  </div>

export default BugHuntingHistory
