import Downshift from 'downshift'
import TagComponent from '../Tag/Tag'
import classes from './TagInput.module.scss'
import { useState } from 'react'
import type {
  ChildrenFunction,
  ControllerStateAndHelpers,
  GetItemPropsOptions,
  StateChangeOptions
} from 'downshift'
import type { Tag } from '@eggplant/types'

interface Props {
  availableTags: Tag[] // use tags from types
  isDisabled: boolean
  isNewItemAllowed: boolean
  isRequired?: boolean
  label?: string
  maxLength: number
  onSelectionChanged: (value: Tag) => void
  onValueEntered: (value: string) => void
  placeholder: string
}

const itemToString = (option?: Tag | null) => option
  ? option.name
  : ''

const TagInput = ({
  availableTags,
  isDisabled = false,
  isRequired,
  label,
  maxLength,
  placeholder,
  onSelectionChanged,
  onValueEntered,
  isNewItemAllowed = true
}: Props) => {
  const [isHighlighted, setIsHighlighted] = useState(false)

  const handleInputKeyDown = (event: KeyboardEvent, inputValue: string, setState: (state: Partial<StateChangeOptions<Tag>>) => void) => {
    if (event.key !== 'Enter') return

    // Prevent the enter key from triggering a form submit
    event.preventDefault()

    /*
      Only want to handle 'Enter' key if nothing is currently
      highlighted in the Downshift options (dropdown).
    */
    if (!isNewItemAllowed || isHighlighted) return

    onValueEntered(inputValue.trimStart())

    setState({
      // Clear the input
      inputValue: '',
      isOpen: false
    })
  }

  /*
    Only need the selected item from Downshift's onChange handler.
  */
  const handleChange = (selectedItem: Tag | null, stateAndHelpers: ControllerStateAndHelpers<Tag>) => {
    if (!selectedItem) return

    onSelectionChanged(selectedItem)

    // Clear the input
    stateAndHelpers.setState({ inputValue: '' })
  }

  /*
    Monitor the state changes of Downshift.
    If at any time one of the options is highlighted
    in the dropdown list, set the state of isHighlighted.
  */
  const handleStateChange = (state: StateChangeOptions<Tag>) => {
    /* Previous checking using !!state.highlightedIndex && state.highlightedIndex >= 0 wont work,
    as !!state.highlightedIndex will return false even state.highlightedIndex got value
    */
    const hasOptionHighlighted = state.highlightedIndex === null || state.highlightedIndex === undefined

    setIsHighlighted(!hasOptionHighlighted)
  }

  const classNames = isDisabled
    ? classes.InputText + ' ' + classes.InputTextDisabled
    : classes.InputText

  const renderItem = (item: Tag, itemProps: GetItemPropsOptions<Tag>) =>
    // @ts-expect-error // this claims to require unsupported HTML li attributes: value, type
    <li
      key={item.id}
      {...itemProps}
    >
      <TagComponent
        color={item.color}
        label={item.name}
        limit={50}
      />
    </li>

  const renderChildren: ChildrenFunction<Tag> = ({
    closeMenu,
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    inputValue,
    openMenu,
    setState
  }) =>
    <div className={classes.TagInput}>
      <input
        aria-label={label}
        className={classNames}
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={placeholder}
        required={isRequired}
        {
        /* eslint-disable indent */
        ...getInputProps(
          {
            onKeyDown: (event: KeyboardEvent) => {
              handleInputKeyDown(event, inputValue || '', setState)
            },
            onClick: isOpen
              ? closeMenu
              : openMenu
          }
        )
          /* eslint-enable indent */
        }
        autoComplete='new-password' // nothing else disables autocomplete
      />
      <ul {...getMenuProps()}>
        {
          isOpen &&
          availableTags
            .filter(item =>
              !inputValue ||
              item.name.toLowerCase().includes(
                // This is a false positive because the array method is available to all arrays
                inputValue.toLowerCase()
              )
            )
            .map(item =>
              renderItem(item, getItemProps({ item }))
            )
        }
      </ul>
    </div>

  return (
    <Downshift
      itemToString={itemToString}
      onChange={handleChange}
      onStateChange={handleStateChange}
      selectedItem={null}
    >
      {renderChildren}
    </Downshift>
  )
}

export default TagInput
