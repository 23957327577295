import {
  Combobox,
  DropdownButton
} from '@eggplant/pattern-library'
import { FaGlobe } from 'react-icons/fa'
import {
  getCodes,
  getName,
  overwrite
} from 'country-list'
import type { ButtonShape, OptionType } from '@eggplant/types'

interface CountryInfo {
  code: string
  name: string
}

interface Props{
  onChange: (value: string) => void
  value: string
  excludedCountryCodes?: string[]
  renamesCountry?: CountryInfo[]
  isRequired?: boolean
}

const SelectCountry = ({
  onChange,
  value,
  excludedCountryCodes = [],
  renamesCountry = [],
  isRequired = false
}: Props) => {
  overwrite(renamesCountry)

  const countryCodes = getCodes().filter(countryCode => !excludedCountryCodes.some(excludedCountryCode => excludedCountryCode === countryCode))

  const countryOptions = countryCodes.map(country => ({
    label: getName(country),
    value: country
  } as OptionType))

  const currentOption = countryOptions.find(item =>
    item.value === value
  )

  const Globe = () => (
    <FaGlobe
      aria-hidden
      size={20}
    />
  )

  const renderButton = (buttonProps: ButtonShape) =>
    <DropdownButton
      {...buttonProps}
      Icon={Globe}
      label={
        currentOption?.value
          ? currentOption.label
          : undefined
      }
    >
      {
        currentOption
          ? currentOption.label
          : 'Select a country'
      }
    </DropdownButton>

  return (
    <Combobox
      customValidity='Please select a country.'
      isRequired={isRequired}
      onChange={onChange}
      options={countryOptions}
      renderButton={renderButton}
      value={value}
    />
  )
}

export default SelectCountry
