import Color from '../Color/Color'
import classes from './StatusBadgeIcon.module.scss'
import { HiCheckCircle, HiExclamationCircle } from 'react-icons/hi'

interface Props {
  variant: 'Success' | 'Error'
  value: string
}

const StatusBadgeIcon = ({ value, variant }: Props) => {
  const Icon = variant === 'Success'
    ? HiCheckCircle
    : HiExclamationCircle

  const colorVariant = variant === 'Success'
    ? 'secondarygreen'
    : 'red'

  return (
    <span className={classes.StatusBadgeIcon + ' ' + classes[variant]}>
      <Color variant={colorVariant}>
        <div className={classes.iconContainer}>
          <Icon
            size='16px'
          />
        </div>
      </Color>
      <span className={classes.value}>{value}</span>
    </span>
  )
}

export default StatusBadgeIcon
