import ExecutionDetailsLegend from './ExecutionDetailsLegend'
import Header from '../Header/Header'
import styles from './ExecutionDetailsChart.module.scss'
import { DOUGHNUT_CHART_OPTIONS } from '../../constants'
import { Doughnut } from 'react-chartjs-2'

/* eslint-disable fp/no-mutation */
const createDiagonalPattern = (color = 'black') => {
  const patternSize = 5
  const shape = document.createElement('canvas')

  shape.width = patternSize
  shape.height = patternSize

  const ctx = shape.getContext('2d')

  if (!ctx) {
    return color
  }

  ctx.strokeStyle = color
  ctx.beginPath()
  ctx.moveTo(patternSize * 0.8, 0)
  ctx.lineTo(0, patternSize * 0.8)
  ctx.stroke()
  ctx.beginPath()
  ctx.moveTo(patternSize, patternSize * 0.8)
  ctx.lineTo(patternSize * 0.8, patternSize)
  ctx.stroke()

  const pattern = ctx.createPattern(shape, 'repeat')

  if (pattern) { return pattern }

  return color
}

const generateChartData = (
  failures: number,
  incomplete: number,
  passes: number,
  cancelled: number,
  errors: number,
  reruns: number
) => ({
  labels: [
    'Passed first time',
    'Passed after rerun',
    'Failures',
    'In Progress',
    'Cancelled',
    'Errors'
  ],
  datasets: [
    {
      borderWidth: 1,
      borderColor: [
        styles.colorpass,
        styles.colorpass,
        styles.colorfail,
        styles.colorincomplete,
        styles.colorcancelled,
        // workaround - this is to avoid white line at 12 o'clock when using borderWidth=1
        [
          { value: passes, color: styles.colorpass },
          { value: reruns, color: styles.colorpass },
          { value: failures, color: styles.colorfail },
          { value: incomplete, color: styles.colorincomplete },
          { value: cancelled, color: styles.colorcancelled },
          { value: errors, color: styles.colorerror }
        ].reduce((lastColor, item) => {
          // eslint-disable-next-line no-param-reassign
          if (item.value !== 0) lastColor = item.color

          return lastColor
        }, '') || styles.colorerror
      ],
      backgroundColor: [
        styles.colorpass,
        createDiagonalPattern(styles.colorpass),
        styles.colorfail,
        styles.colorincomplete,
        styles.colorcancelled,
        styles.colorerror
      ],
      data: [
        passes,
        reruns,
        failures,
        incomplete,
        cancelled,
        errors
      ]
    }
  ]
})

interface Props {
  cancelled: number
  errors: number
  failures: number
  incomplete: number
  passes: number
  reruns?: number
  title?: string
}

const chartOptions = {
  ...DOUGHNUT_CHART_OPTIONS,
  plugins: {
    legend: {
      display: false // use custom legend
    },
    tooltip: {
      callbacks: {
        title: () => '',
        label (context: any) {
          return `${context.label}:  ${context.raw}`
        }
      }
    }
  },
  onHover: (evt: any, chartElement: any) => {
    evt.native.target.style.cursor = chartElement[0]
      ? 'pointer'
      : 'default'
  }
}

const ExecutionDetailsChart = ({
  cancelled,
  errors,
  failures,
  incomplete,
  passes,
  reruns = 0,
  title
}: Props) =>
  <>
    {
      title &&
        <div className={styles.ChartTitle}>
          <Header
            hasNoBottomMargin
            hasNoTopMargin
            rank={5}
          >
            {title}
          </Header>
        </div>
    }
    <div className={styles.ChartContainer}>
      <div className={styles.ChartWrapper}>
        <Doughnut
          data={generateChartData(failures, incomplete, passes, cancelled, errors, reruns)}
          // height for react-chartjs-2 cannot be omitted when using div-wrapper to control height
          height={0}
          options={chartOptions}
        />
      </div>
      <ExecutionDetailsLegend
        {...{
          cancelled,
          errors,
          failures,
          incomplete,
          passes,
          reruns
        }}
      />
    </div>
  </>

export default ExecutionDetailsChart
