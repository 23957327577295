import CheckedIndicator from '../CheckedIndicator/CheckedIndicator'
import classes from './LabeledCheckbox.module.scss'
import { Paragraph } from '../..'

interface Props {
  formId?: string
  isChecked: boolean
  label: string
  onChange: (value: any) => void // TODO: VncConnectionDetailsFieldset.tsx need to update to assign value as boolean
  'aria-labelledby'?: string // TODO: create type unions where either labeledBy or label are provided, but not both
  isDisabled?: boolean
  title?: string
  isIndeterminate?: boolean
}

const LabeledCheckbox = ({
  isChecked = false,
  label,
  onChange,
  formId,
  title,
  isDisabled = false,
  'aria-labelledby': ariaLabelledby,
  isIndeterminate = false
}: Props) => {
  const handleChange = () => {
    !isDisabled && onChange(!isChecked)
  }

  const getClasses = () => {
    const baseClass = classes.LabeledCheckbox

    const hoverClass = isChecked || isIndeterminate
      ? classes.isCheckedHover
      : classes.isNotCheckedHover

    const accessibleClass = !isDisabled
      ? hoverClass
      : ''

    const labelWrapperClass = [
      baseClass,
      accessibleClass
    ].filter(classname => classname)

    return labelWrapperClass.join(' ')
  }

  return (
    <label className={getClasses()}>
      <span className={classes.checkWrapper}>
        <CheckedIndicator
          isChecked={isChecked}
          isDisabled={isDisabled}
          isIndeterminate={isIndeterminate}
        />
        <input
          aria-labelledby={ariaLabelledby}
          checked={isChecked}
          form={formId}
          onChange={handleChange}
          readOnly={isDisabled}
          title={title}
          type='checkbox'
        />
      </span>
      <Paragraph>
        <strong>
          {label}
        </strong>
      </Paragraph>
    </label>
  )
}

export default LabeledCheckbox
