import { AI_API_SERVICE } from '@eggplant/api-hosts'
import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { ComboboxSingleSelect } from '@eggplant/pattern-library'

interface Response {
  id: number
  groupname: string
}

interface Props {
  onChange: (value: string) => void
  prompt?: string
  value: string
  hasNameForValue?: boolean
}

const url = AI_API_SERVICE + '/groups'

const getOptions = (items: Response[], hasNameForValue: boolean) =>
  items.map((item: Response) => ({
    label: item.groupname,
    value: hasNameForValue
      ? item.groupname
      : item.id.toString()
  }))

const SelectGroup = ({
  onChange,
  prompt = 'Select a Group',
  value,
  hasNameForValue = true
}: Props) =>
  <AuthenticatedDataRequest
    method='get'
    url={url}
  >
    {
      (data: Response[]) =>
        <ComboboxSingleSelect
          isHighlighted={value !== ''}
          isRequired
          onChange={onChange}
          options={getOptions(data, hasNameForValue)}
          prompt={prompt}
          value={value}
        />
    }
  </AuthenticatedDataRequest>

export default SelectGroup
