import classes from './Separator.module.scss'
import { FaLevelUpAlt } from 'react-icons/fa'

const Separator = () => (
  <div className={classes.Wrapper}>
    <div className={classes.Separator} />
    <FaLevelUpAlt
      aria-hidden
    />
    <div className={classes.Separator} />
  </div>
)

export default Separator
