import { Paragraph } from '@eggplant/pattern-library'

const IosDevCertDescription = () =>
  <Paragraph>
    Enter your Apple-issued code signing certificate here. These certificates are obtained by signing up
    for the Apple Developer Program and are necessary for signing applications that can run on a limited
    number of devices, especially iOS devices. You can manage these certificates using Xcode, the Apple
    Developer portal, or third-party tools like Fastlane.
  </Paragraph>

export default IosDevCertDescription
