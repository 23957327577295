import { LoadingPlaceholder } from '@eggplant/pattern-library'
import { Suspense as ReactSuspense } from 'react'
import type {
  CSSProperties,
  ReactNode
} from 'react'

// Apply a custom loading placeholder to React's Suspense

const fallbackStyle: CSSProperties = {
  height: '100%',
  display: 'flex',
  width: '100%'
}

const fallback =
  <div style={fallbackStyle}>
    <LoadingPlaceholder />
  </div>

interface Props {
  children: ReactNode
}

const Suspense = ({
  children
}: Props) =>
  <ReactSuspense fallback={fallback}>
    {children}
  </ReactSuspense>

export default Suspense
