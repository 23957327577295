import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { gitConnectionSetup } from '../../docs-routes'

const GitConnectionCredentialsHelp = () =>
  <Paragraph>
    <strong>Authenticate with Git</strong><br />
    Provide your Git repository URL, username and access token in this dialog.
    <br /><br />
    Once complete, click the button to validate the details and confirm the connection.
    <br /><br />
    <strong>Connect to a Git URL</strong><br />
    Enter the URL of your Git repository to establish a connection and start collaborating with your team. Make sure to use the correct URL and authentication credentials to ensure secure access to your codebase.
    <br /><br />
    <strong>Git Username</strong><br />
    Enter your Git username to connect to your Git hosting service and access your repositories. Your username is typically associated with your account and can be found in your Git hosting service profile settings. Make sure to enter the correct username to ensure proper authentication and access to your codebase.
    <br /><br />
    <strong>Git Password/Personal Token</strong><br />
    Enter your Git hosting service password or personal access token to securely authenticate and access your repositories. Your password or token is typically associated with your account and can be found in your Git hosting service profile settings.
    <br /><br />
    <strong>Note:</strong> A personal access token is not the same as your password. You must create a personal access token to use this feature.
    <DocusaurusFooter path={gitConnectionSetup} />
  </Paragraph>

export default GitConnectionCredentialsHelp
