import type { ChartOptions } from 'chart.js'
import type { StatusMap } from './components/types'

export const COOKIE_NAME = 'theme'

export const LOCALE_DATE_STRING_OPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
} as Intl.DateTimeFormatOptions

export const DOUGHNUT_CHART_OPTIONS: ChartOptions<'doughnut'> = {
  aspectRatio: 1,
  layout: {
    padding: {
      top: 5,
      bottom: 5
    }
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        title: () => '',
        label (context: any) {
          return `${context.label}:  ${context.raw}`
        }
      }
    }
  },
  cutout: '50%'
}

export const STATUS_LABEL_MAP: StatusMap = {
  CANCELLED: {
    label: 'Cancelled',
    variant: 'cancelled'
  },
  CANCELLING: {
    label: 'Cancelling',
    variant: 'cancelled'
  },
  ERROR: {
    label: 'Error',
    variant: 'error'
  },
  FAILED: {
    label: 'Failed',
    variant: 'fail'
  },
  PENDING: {
    label: 'Queued',
    variant: 'queued'
  },
  STARTED: {
    label: 'In Progress',
    variant: 'inProgress'
  },
  RUNNING: {
    label: 'In Progress',
    variant: 'inProgress'
  },
  INCOMPLETE: {
    label: 'In Progress',
    variant: 'inProgress'
  },
  SUCCESS: {
    label: 'Passed',
    variant: 'pass'
  },
  PASSED: {
    label: 'Passed',
    variant: 'pass'
  }
}
