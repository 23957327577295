import {
  FieldLabel,
  InputNumber
} from '@eggplant/pattern-library'
import type { CSSProperties } from 'react'

interface Props {
  isRequired?: boolean
  isDisabled?: boolean
  width: number
  height: number
  minWdith?: number
  minHeight?: number
  maxWidth?: number
  maxHeight?: number
  onChange: (width: number, height: number) => void
}

const inputWrapperStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '1em'
}

const ResolutionFields = ({
  isRequired = false,
  isDisabled = false,
  width,
  height,
  minWdith = 1,
  minHeight = 1,
  maxWidth = 2147483647,
  maxHeight = 2147483647,
  onChange
}: Props) => {
  const handleWidthChange = (value: number) => {
    onChange(value, height)
  }

  const handleHeightChange = (value: number) => {
    onChange(width, value)
  }

  return (
    <div style={inputWrapperStyle}>
      <FieldLabel
        isRequired={isRequired}
        label='Width'
        minCharWidth={10}
      >
        <InputNumber
          isDisabled={isDisabled}
          isRequired={isRequired}
          label='width'
          max={maxWidth}
          min={minWdith}
          onChange={handleWidthChange}
          value={width}
        />
      </FieldLabel>
      <FieldLabel
        isRequired={isRequired}
        label='Height'
        minCharWidth={10}
      >
        <InputNumber
          isDisabled={isDisabled}
          isRequired={isRequired}
          label='height'
          max={maxHeight}
          min={minHeight}
          onChange={handleHeightChange}
          value={height}
        />
      </FieldLabel>
    </div>
  )
}

export default ResolutionFields
