import DataCollectionCheckpoint from './DataCollectionCheckpoint'
import SetupRouter from '../SetupRouter'
import { AI_API_SERVICE } from '@eggplant/api-hosts'
import { AuthConsumer } from '@eggplant/context'
import { DataRequest } from '@eggplant/data-request'
import type { AiStatusResponse } from '@eggplant/types'

const LicenseCheckpoint = () =>
  <DataRequest
    method='get'
    url={AI_API_SERVICE + '/status'}
  >
    {
      (
        {
          eula_accepted,
          has_valid_license
        }: AiStatusResponse
      ) =>
        <AuthConsumer>
          {
            ({ accessibleRoles }) =>
              eula_accepted &&
              has_valid_license &&
              accessibleRoles?.length
                ? <DataCollectionCheckpoint />
                : <SetupRouter />
          }
        </AuthConsumer>
    }
  </DataRequest>

export default LicenseCheckpoint
