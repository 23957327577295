import { CLOUD_EULA_URL } from '../routes'
import { Hyperlink } from '@eggplant/pattern-library'

const LinkToCloudEula = () =>
  <Hyperlink
    href={'/' + CLOUD_EULA_URL}
    isExternal
  >
    On-Premises, Cloud (SaaS) Terms
  </Hyperlink>

export default LinkToCloudEula
