import classes from './Card.module.scss'
import { Children } from 'react'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  id?: string
  hasShadow?: boolean
}

const Card = ({
  children,
  id,
  hasShadow = false
}: Props) => {
  const shadowClass = hasShadow
    ? ' ' + classes.shadow
    : ''

  const cardClass = classes.Card + shadowClass

  return (
    <div
      className={cardClass}
      data-testid={id}
    >
      {
        Children.count(children) > 1
          ? Children.map(children, (child, index) =>
            <div key={index}>
              {child}
            </div>
          )
          : children
      }
    </div>
  )
}

export default Card
