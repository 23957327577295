import classes from './ProgressFlow.module.scss'
import {
  Divider,
  NumberCircle,
  Paragraph
} from '../..'
import { HiCheck } from 'react-icons/hi'

interface Props {
  selectedIndex: 1 | 2 | 3 | 4 | 5
  flowLabels: string[]// [string, string?, string?, string?, string?]
}

const ProgressFlow = ({
  selectedIndex,
  flowLabels
}: Props) => {
  const getVariant = (index: number) => {
    if (selectedIndex > index + 2) {
      return 'green'
    }

    if (selectedIndex < index + 2) {
      return 'themeDefault'
    }

    return 'purple'
  }

  return (
    <div className={classes.ProgressFlow}>
      {
        flowLabels.map((label, index) =>
          <div
            className={classes.FlowItem}
            key={index}
          >
            {
              selectedIndex > index + 1
                ? (
                  <NumberCircle
                    size='md'
                    value={
                      <HiCheck
                        aria-label='check'
                        size={18}
                      />
                    }
                    variant='success'
                  />
                )
                : (
                  <NumberCircle
                    size='md'
                    value={index + 1}
                    variant={
                      selectedIndex === index + 1
                        ? 'purple'
                        : 'disabled'
                    }
                  />
                )
            }
            <Paragraph>
              <strong>
                {label}
              </strong>
            </Paragraph>
            {
              index < flowLabels.length - 1
                ? (
                  <div className={classes.Divider}>
                    <Divider variant={getVariant(index)} />
                  </div>
                )
                : null
            }
          </div>
        )
      }
    </div>
  )
}

export default ProgressFlow
