import ControllerNav from './ControllerNav'
import DashboardsNav from './DashboardsNav'
import DesignerNav from './DesignerNav'
import InsightsNav from './InsightsNav'
import {
  CONTROLLER,
  DASHBOARDS,
  DESIGNER,
  INSIGHTS
} from '@eggplant/routing'
import { FaPlayCircle } from 'react-icons/fa'
import {
  Icons,
  NavGroup
} from '@eggplant/pattern-library'
import { Match } from '@reach/router'

interface Props {
  isCollapsed: boolean
}

const WILDCARD = '/*'

// The "key"s reset the NavGroup state on every route change.

const Top = ({ isCollapsed }: Props) =>
  <>
    <Match path={'/' + DASHBOARDS + WILDCARD}>
      {
        ({
          match
        }) =>
          <NavGroup
            icon={<Icons.Rocket aria-hidden />}
            isCollapsed={isCollapsed}
            isCurrent={!!match}
            label='Dashboard'
          >
            <DashboardsNav
              base={DASHBOARDS}
              isCollapsed={isCollapsed}
            />
          </NavGroup>
      }
    </Match>
    <Match path={'/' + DESIGNER + WILDCARD}>
      {
        ({
          match
        }) =>
          <NavGroup
            icon={<Icons.Designer aria-hidden />}
            isCollapsed={isCollapsed}
            isCurrent={!!match}
            label='Designer'
          >
            <DesignerNav
              base={DESIGNER}
              isCollapsed={isCollapsed}
            />
          </NavGroup>
      }
    </Match>
    <Match path={'/' + CONTROLLER + WILDCARD}>
      {
        ({
          match
        }) =>
          <NavGroup
            icon={<FaPlayCircle aria-hidden />}
            isCollapsed={isCollapsed}
            isCurrent={!!match}
            label='Controller'
          >
            <ControllerNav
              base={CONTROLLER}
              isCollapsed={isCollapsed}
            />
          </NavGroup>
      }
    </Match>
    <Match path={'/' + INSIGHTS + WILDCARD}>
      {
        ({
          match
        }) =>
          <NavGroup
            icon={<Icons.Insights aria-hidden />}
            isCollapsed={isCollapsed}
            isCurrent={!!match}
            label='Insights'
          >
            <InsightsNav
              base={INSIGHTS}
              isCollapsed={isCollapsed}
            />
          </NavGroup>
      }
    </Match>
  </>

export default Top
