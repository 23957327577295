import PropTypes from 'prop-types'
import SingleSelect from '../SingleSelect/SingleSelect'

const TWELVE = 12
const AM = 'AM'
const PM = 'PM'

const meridianOptions = [
  {
    label: AM,
    value: AM
  }, {
    label: PM,
    value: PM
  }
]

const ChooseMeridian = ({
  isDisabled = false,
  onChange,
  value
}) => {
  const hours = value.getHours()

  const isPm = hours >= TWELVE

  const handleMeridianChange = meridian => {
    const isSame =
      (isPm && meridian === PM) ||
      (!isPm && meridian === AM)

    if (isSame) return

    const hourChange = isPm
      ? -TWELVE
      : TWELVE

    const newDate = new Date(value)

    newDate.setHours(
      value.getHours() + hourChange
    )

    onChange(newDate)
  }

  return (
    <SingleSelect
      isDisabled={isDisabled}
      label='AM or PM'
      onChange={handleMeridianChange}
      options={meridianOptions}
      value={
        isPm
          ? PM
          : AM
      }
    />
  )
}

ChooseMeridian.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired
}

export default ChooseMeridian
