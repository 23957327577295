import EmptyStatesPreview from './EmptyStatesPreview'
import LinkToDocusaurus from '../LinkToDocusaurus'
import {
  Button,
  Color
} from '@eggplant/pattern-library'
import { FaFileAlt } from 'react-icons/fa'
import { testConfig } from '../../docs-routes'
import { useAccess } from '@eggplant/routing'
import type { CSSProperties } from 'react'

interface Props {
  onActionClick?: () => void
}

const wrapperStyle: CSSProperties = {
  padding: '10px 12px 12px'
}

const TestConfigNoSteps = ({
  onActionClick
}: Props) => {
  const [hasAccess] = useAccess()

  const goToGeneralTab = () =>
    <Button
      onClick={onActionClick}
      variant='secondary'
    >
      Basic settings
    </Button>

  return (
    <div style={wrapperStyle}>
      <EmptyStatesPreview
        action={
          hasAccess
            ? goToGeneralTab()
            : undefined
        }
        description='Please select a model/suite in basic settings to enable this feature.'
        icon={
          <Color variant='stateGray'>
            <FaFileAlt size={120} />
          </Color>
        }
        renderLink={<LinkToDocusaurus path={testConfig}> Learn more in DAI documentation </LinkToDocusaurus>}
        title='Steps / Actions'
      />
    </div>
  )
}

export default TestConfigNoSteps
