import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { gitConnectionEdit } from '../../docs-routes'

const EditGitConnection = () =>
  <Paragraph>
    Keep your Git connection details up-to-date and test the health of your Git connections.
    You can also request an immediate sync of your Git repository enabling you to work with your latest suites and scripts.
    <DocusaurusFooter path={gitConnectionEdit} />
  </Paragraph>

export default EditGitConnection
