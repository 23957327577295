import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'

const MaxInfo = () =>
  <div className='grid gap-3'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Maximum time to execute
    </Header>
    <Paragraph>
      The maximum time it took for a test run to execute.
    </Paragraph>
  </div>

export default MaxInfo
