import StyledLink from '../StyledLink'
import {
  ADD,
  CONTROLLER,
  TEST_CONFIG
} from '../routes'
import type { Props } from '../types'

const LinkToAddTestConfig = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + CONTROLLER + '/' + TEST_CONFIG + '/' + ADD}
  >
    {children}
  </StyledLink>

export default LinkToAddTestConfig
