import classes from './Paragraph.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const Paragraph = ({
  children
}: Props) =>
  <p className={classes.Paragraph}>
    {children}
  </p>

export default Paragraph
