import { AI_API_SERVICE } from '@eggplant/api-hosts'
import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  Icons,
  NoOptionsPlaceholder
} from '@eggplant/pattern-library'
import { FiPlus } from 'react-icons/fi'
import {
  LinkToAddModel,
  useAccess
} from '@eggplant/routing'
import type {
  ButtonShape,
  GetModelsResponse,
  Options
} from '@eggplant/types'
import type { ReactNode } from 'react'

interface Props {
  initialOptions?: Options
  onChange: (value: string) => void
  placeholder?: string
  value: string
  isDisabled?: boolean
  isFilter?: boolean
  isShowSubmodel?: boolean
  shortErrorMessage?: string
  externalQuery?: ReactNode
  onClearSubmodelToggleButton?: () => void
}

const url = AI_API_SERVICE + '/models'

const getOptions = (items: GetModelsResponse) =>
  items.map(item => ({
    // group: item.group,
    label: item.name,
    value: item.modeluuid
  }))

const SelectModel = ({
  initialOptions = [],
  onChange,
  placeholder,
  value,
  isDisabled,
  isFilter = false,
  shortErrorMessage,
  externalQuery,
  onClearSubmodelToggleButton,
  isShowSubmodel = true
}: Props) => {
  const [isAccessible] = useAccess()

  const getDefaultLabel = () => {
    if (!isShowSubmodel) {
      return 'Hide submodels'
    }

    if (placeholder) {
      return placeholder
    }

    if (initialOptions.length) {
      const defaultLabel = initialOptions.find(item => item.value === '')

      return defaultLabel?.label || 'Models'
    }

    return 'Models'
  }

  const getIsHighlighted = () => {
    if (!isFilter) return false

    return !!value || !isShowSubmodel
  }

  const getRenderButton = (options: Options) => {
    const combinedOptions = [...initialOptions, ...options]

    const currentOption = combinedOptions.find(item =>
      item.value === value
    )

    const label = currentOption?.value
      ? currentOption.label
      : placeholder

    const labelWithSubmodelToggleButtonOff = !isShowSubmodel
      ? 'Hide submodels'
      : label

    const textWithSubmodelToggleButtonOff = !isShowSubmodel
      ? ' & hide submodels'
      : ''

    const filterTooltip = currentOption?.value
      ? 'Model: ' + currentOption.label + textWithSubmodelToggleButtonOff
      : labelWithSubmodelToggleButtonOff

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={Icons.Models}
        isDisabled={isDisabled}
        isFilter={isFilter}
        isHighlighted={getIsHighlighted()}
        isSolidIcon
        label={label}
        title={isFilter
          ? filterTooltip
          : label}
      >
        {
          currentOption
            ? currentOption.label + textWithSubmodelToggleButtonOff
            : getDefaultLabel()
        }
      </DropdownButton>
  }

  const noDataPlaceholder =
    <NoOptionsPlaceholder
      icon={<Icons.ModelHub size='24px' />}
      linkTo={
        isAccessible
          ? (
            <LinkToAddModel>
              <FiPlus
                aria-hidden
                size={16}
              />
              New model
            </LinkToAddModel>
          )
          : undefined
      }
      title='No models found'
    />

  return (
    <AuthenticatedDataRequest
      method='get'
      shortErrorMessage={shortErrorMessage}
      url={url}
    >
      {
        (data: GetModelsResponse) =>
          <Combobox
            externalQuery={externalQuery}
            initialOptions={initialOptions}
            isDisabled={isDisabled}
            isFilter={isFilter}
            noOptionsPlaceholder={noDataPlaceholder}
            onChange={onChange}
            onClear={onClearSubmodelToggleButton}
            options={getOptions(data)}
            renderButton={getRenderButton(getOptions(data))}
            value={value}
          />
      }
    </AuthenticatedDataRequest>
  )
}

export default SelectModel
