import {
  Color,
  FormAlert,
  LoadingPlaceholder,
  Paragraph
} from '@eggplant/pattern-library'
import { FiCoffee } from 'react-icons/fi'
import type { CSSProperties } from 'react'

const containerStyle: CSSProperties = {
  height: '100%',
  display: 'grid',
  width: '100%',
  textAlign: 'center',
  padding: '1.4em',
  boxSizing: 'border-box',
  gap: '18px'
}

const LoadingPane = () =>
  <FormAlert>
    <div style={containerStyle}>
      <Color variant='purple'>
        <FiCoffee
          aria-hidden='true'
          size='22px'
        />
      </Color>
      <Paragraph>
        <strong>Loading your suites...</strong>
      </Paragraph>
      <LoadingPlaceholder />
      <Paragraph>
        Please wait while we clone your repository.
      </Paragraph>
    </div>
  </FormAlert>

export default LoadingPane
