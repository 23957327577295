import classes from './ListItem.module.scss'
import { Children, type ReactNode } from 'react'

interface Props {
  children: ReactNode | ReactNode[]
}

const ListItem = ({
  children
}: Props) => {
  const isArray = Array.isArray(children)

  return (
    isArray
      ? (
        <ul className={classes.ListItem}>
          {
            Children.map(children, (child: ReactNode, index: number) =>
              <li key={index}>
                {child}
              </li>
            )
          }
        </ul>
      )
      : (
        <ul className={classes.ListItem}>
          <li>
            {children}
          </li>
        </ul>
      )
  )
}

export default ListItem
