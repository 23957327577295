import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  ConfirmActionDialog,
  SuccessMessage
} from '@eggplant/pattern-library'
import { TASK_SERVICE } from '@eggplant/api-hosts'
import { useState } from 'react'
import type { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = { width: 500 }

const url = TASK_SERVICE + '/abort'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  taskInstanceId: string
}

const TaskAbort = ({
  onSuccess,
  onCancel,
  taskInstanceId
}: Props) => {
  const [isAborting, setIsAborting] = useState(false)

  const handleConfirm = () => {
    setIsAborting(true)
  }

  return (
    <div style={wrapperStyle}>
      {
        isAborting
          ? (
            <AuthenticatedDataRequest
              method='post'
              onSuccess={onSuccess}
              url={url + '/' + taskInstanceId}
            >
              {
                () =>
                  <SuccessMessage>
                    Abort successfully requested.
                  </SuccessMessage>
              }
            </AuthenticatedDataRequest>
          )
          : (
            <ConfirmActionDialog
              actionText='Abort'
              message='This action cannot be undone.'
              onCancel={onCancel}
              onConfirm={handleConfirm}
              title='This test can only be stopped by aborting the entire test configuration run.
              Would you like to abort the test configuration?'
            />
          )
      }
    </div>
  )
}

export default TaskAbort
