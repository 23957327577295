import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  SUTS
} from '../routes'
import type { Props } from '../types'

const base = '/' + CONTROLLER + '/' + SUTS

interface Params {
  sutId?: string
}

const LinkToSuts = ({
  children,
  isHyperlink,
  sutId
}: Props & Params) => {
  const url = sutId
    ? `${base}/${sutId}`
    : base

  return (
    <StyledLink
      isHyperlink={isHyperlink}
      to={url}
    >
      {children}
    </StyledLink>
  )
}

export default LinkToSuts
