import StyledLink from '../StyledLink'
import {
  DESIGNER,
  EDIT,
  SCRIPTED,
  TESTCASES
} from '../routes'
import type { Props } from '../types'

interface Params {
  testCaseId: string
}

const LinkToEditScriptedTestCase = ({
  children,
  testCaseId,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={`/${DESIGNER}/${TESTCASES}/${SCRIPTED}/${testCaseId}/${EDIT}`}
  >
    {children}
  </StyledLink>

export default LinkToEditScriptedTestCase
