import classes from './SmallLinkButton.module.scss'
import type { ReactElement } from 'react'

interface BaseProps {
  children: ReactElement<{ href: string }> // The element must have an href
  isDisabled?: boolean
  title?: string
}
interface PrimaryProps {
  variant?: 'primary'
  isOutline?: never
  isTransparentBackground?: never
}
interface SecondaryProps {
  variant?: 'secondary'
  isOutline?: boolean
  isTransparentBackground?: boolean
}

type Props = BaseProps & (PrimaryProps | SecondaryProps)

const SmallLinkButton = ({
  children,
  isDisabled = false,
  title,
  isOutline,
  isTransparentBackground = true,
  variant = 'secondary'
}: Props) => {
  const disabledStyle = isDisabled
    ? ' ' + classes.disabled
    : ''

  const outlined = isOutline
    ? ''
    : ' ' + classes.text

  const withBackgroundStyle = isTransparentBackground
    ? ''
    : ' ' + classes.withBackground

  const SmallLinkButtonClassName = classes.SmallLinkButton + ' ' + classes[variant] + disabledStyle + outlined + withBackgroundStyle

  return (
    <span
      className={SmallLinkButtonClassName}
      title={title}
    >
      {children}
    </span>
  )
}

export default SmallLinkButton
