import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { TOGGLES } from '@eggplant/api-hosts'
import type { ReactNode } from 'react'
import type { TogglesResponse } from '@eggplant/types'

interface Props {
  children: ReactNode
}

const Toggles = ({
  children
}: Props) =>
  <AuthenticatedDataRequest
    method='get'
    url={TOGGLES + '/toggles'}
  >
    {
      (data: TogglesResponse) =>
        data.deployment_flags.ENABLE_DAI_CLOUD_SUT &&
          children
    }
  </AuthenticatedDataRequest>

export default Toggles
