import classes from './PreviewEmptyState.module.scss'
import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'
import { Journey } from '../Artwork/Artwork'
import type {
  ReactNode
} from 'react'

interface Props {
  description: ReactNode
  documentLink?: ReactNode
  action?: ReactNode
  title: string
  isRenderJourney?: boolean
}

const PreviewEmptyState = ({
  action,
  description,
  documentLink,
  title,
  isRenderJourney = true
}: Props) => (
  <div className={classes.PreviewEmptyState}>
    {
      isRenderJourney &&
        <Journey />
    }
    <Header rank={3}>
      {title}
    </Header>
    {
      typeof description === 'string'
        ? (
          <Paragraph>
            {description}
          </Paragraph>
        )
        : description
    }
    {action && action}
    {documentLink && documentLink}
  </div>
)

export default PreviewEmptyState
