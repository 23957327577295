import Tooltip from '../Tooltip/Tooltip'
import classes from './EnabledIndicator.module.scss'
import {
  FaCheck,
  FaTimes
} from 'react-icons/fa'

interface Props {
  isEnabled?: boolean
}

const EnabledIndicator = ({
  isEnabled = false
}: Props) => {
  const Icon = isEnabled
    ? FaCheck
    : FaTimes

  const alt = isEnabled
    ? 'Enabled'
    : 'Disabled'

  return (
    <Tooltip
      label={alt}
    >
      <Icon
        aria-hidden
        className={classes.EnabledIndicator + ' ' + classes[alt]}
        role='img'
      />
    </Tooltip>
  )
}

export default EnabledIndicator
