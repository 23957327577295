import { Hyperlink } from '@eggplant/pattern-library'
import { useKeycloak } from '@eggplant/context'
import type { Props } from '../types'

const LinkToKeycloakMyAccount = ({
  children
}: Props) => {
  const { keycloak: keycloakInstance } = useKeycloak()

  return (
    <Hyperlink
      href={keycloakInstance.authServerUrl + 'realms/' + keycloakInstance.realm + '/account/'}
      isExternal
    >
      {children}
    </Hyperlink>
  )
}

export default LinkToKeycloakMyAccount
