/* eslint-disable no-useless-escape */
// Can visit https://regexr.com to try out your generated regex to test the pattern.

export const modelName = /^[^\W_][\w_~\-]{0,49}$/

export const dataPath = /[\w\s:\/._\-\\]*/

export const windowsDataPath = /^[a-zA-Z]:\\[\\a-zA-Z0-9_\-]+.(csv)$/ // TODO

export const linuxDataPath = /^(\/[a-zA-Z0-9]+)+.(csv)$/ // TODO

export const executionEnvironmentName = /^$|^[A-Za-z0-9_\/:.\-]+$/

export const realNumbers = /^[+\-]?(?:\d+\.?\d*|\d*\.\d+)$/

export const positiveIntegers = /^[0-9]*$/

export const allIntegers = /^-?[0-9]*$/

export const testCaseName = /^[^\\,';"\{\}:]{1,200}$/

export const apiAccessName = testCaseName

export const ip4 = /((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))){4}$/

export const ipAddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/

export const hostName = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?=([a-z0-9]{1,61}\.)*[a-z0-9]{1,61}\.[a-z]+$)[a-z0-9.]{1,255}$/

// port

// allow spaces in middle of name
export const sutName = /^[^\\,';"\{\}:]{1,50}$/

export const testConfigName = /^[^\\,';"\{\}:]{1,100}$/

// Works for linux and windows environments both absolute and relative file paths
export const suitePath = /^(?![A-Za-z]:\\)[\\\/]?(?:(?:[^\\\/<>:.]|.{1}[\/\\]{1}[^\\\/<>:.]+)[\/\\]{0,2})*[^\\<>:\/#]+\.[sS][uU][iI][tT][eE]$/

// alphanumeric and space, between 3 and 50 characters, not counting whitespace

// reference: https://www.labnol.org/internet/regular-expressions-forms/28380/
export const email = /[a-zA-Z0-9_.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+/

// reference: https://www.labnol.org/internet/regular-expressions-forms/28380/
export const phoneNumber = /\+?\(?\d{2,4}\)?[\d\s\-]{3,}/

export const imageFileExtension = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i

export const sauceLabUserName = /^[a-zA-Z0-9\-_.]{2,70}$/

export const sauceLabTunnel = /^[a-zA-Z0-9\-/\s_~@#]*$/

export const url = /(?:(?<scheme>https?):\/\/)(?:(?<ipv4>(?:\d{1,3}\.){3}\d{1,3})(?=$|[\/:#?])|(?<ipv6>\[[A-F0-9]*:[A-F0-9:]+\])(?=$|[\/:#?])|(?<domain>[^\s\/:?#]+))(?::(?<port>\d+))?(?:(?<user>[^\s:\/]*)(?::(?<password>[^\s\/]*))?@)?(?<path>\/[^\s?#]*)?(?:\?(?<query>[^\s#]*))?(?:#(?<fragment>[^\s#]*))?/i

export const gitConnectionCredentialsExp = /^[^\r\n]{1,255}$/

export const textTypeParameterValue = /^[^;"]{0,500}$/

export const udid = /^[a-f0-9]{40}$|^[A-F0-9]{8}-[A-F0-9]{16}$/

export const androidDeviceId = /^[a-zA-Z0-9\-_.]*$/

export const cronString = /^(@(yearly|annually|monthly|weekly|daily|midnight|hourly)|((\*|([0-9]|[1-4][0-9]|5[0-9])|\*\/([1-9]|[1-4][0-9]|5[0-9])|([0-9]|[1-4][0-9]|5[0-9])\-([0-9]|[1-4][0-9]|5[0-9])|([0-9]|[1-4][0-9]|5[0-9]),([0-9]|[1-4][0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([1-9]|1[0-9]|2[0-3])|([0-9]|1[0-9]|2[0-3])\-([0-9]|1[0-9]|2[0-3])|([0-9]|1[0-9]|2[0-3]),([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|[12][0-9]|3[01])|\*\/([1-9]|[12][0-9]|3[01])|([1-9]|[12][0-9]|3[01])\-([1-9]|[12][0-9]|3[01])|([1-9]|[12][0-9]|3[01]),([1-9]|[12][0-9]|3[01])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])|([1-9]|1[0-2])\-([1-9]|1[0-2])|([1-9]|1[0-2]),([1-9]|1[0-2])) (\*|[0-6]\-[0-6]|([0-6]|sun|mon|tue|wed|thu|fri|sat|SUN|MON|TUE|WED|THU|FRI|SAT)|\*\/[1-6]|[0-6],[0-6]))|(@reboot))$/
