import classes from './TrackerBadge.module.scss'

interface Props {
  counter: number
  variant: 'filter' | 'tag'
}

const TrackerBadge = ({
  counter,
  variant
}: Props) => {
  const leadingSymbol = variant !== 'filter'
    ? '+'
    : ''

  return (
    <span className={classes.TrackerBadge + ' ' + classes[variant]}>
      <span>{leadingSymbol}{counter}</span>
    </span>
  )
}

export default TrackerBadge
