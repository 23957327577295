import ChartLegend from '../ChartLegend/ChartLegend'
import styles from './CoverageChart.module.scss'
import { DOUGHNUT_CHART_OPTIONS } from '../../constants'
import { Doughnut } from 'react-chartjs-2'

const generateChartData = (covered: number) => ({
  labels: [
    'Covered',
    'Remaining'
  ],
  datasets: [
    {
      backgroundColor: [
        styles.colorcovered,
        styles.colorremaining
      ],
      data: [
        covered,
        100 - covered // remaining
      ]
    }
  ]
})

const legendRows = [{
  label: 'Covered',
  color: styles.colorcovered,
  amount: null
}, {
  label: 'Remaining',
  color: styles.colorremaining,
  amount: null
}]

interface Props {
  percentCovered: number
}

const CoverageChart = ({
  percentCovered
}: Props) =>
  <div>
    <div className={styles.CoverageChartPercent}>
      <Doughnut
        data={generateChartData(percentCovered)}
        options={DOUGHNUT_CHART_OPTIONS}
      />
      <div
        className={styles.percent}
      >
        {percentCovered + '%'}
      </div>
    </div>
    <ChartLegend rows={legendRows} />
  </div>

export default CoverageChart
