import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAddTestCase } from '@eggplant/routing'
import { LinkToTestCasesDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = "Test Cases shows an overview of all the test cases you've configured. Here you can create a new test case, delete test cases, and export a list of your test cases in .csv format."

const renderButton = () =>
  <AccessControl isHiddenIfDenied>
    <LinkButton
      title='New Test Case'
      variant='primary'
    >
      <LinkToAddTestCase>
        <HiPlus
          aria-hidden
          size='19.2px'
        />
        New Test Case
      </LinkToAddTestCase>
    </LinkButton>
  </AccessControl>

const TestCasesIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Path />}
        renderLink={<LinkToTestCasesDoc />}
        title='Test Cases'
      />
    </div>
  </div>

export default TestCasesIntro
