import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { gitManageSuites } from '../../docs-routes'

const ManageSuites = () =>
  <Paragraph>
    Add suites from your Git repository to DAI to enable you to start using snippets managed by Git.
    <DocusaurusFooter path={gitManageSuites} />
  </Paragraph>

export default ManageSuites
