import StyledLink from '../StyledLink'
import {
  ADD,
  GIT_CONNECTIONS,
  SYSTEM
} from '@eggplant/routing'
import type { Props } from '../types'

const LinkToAddGitConnection = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + SYSTEM + '/' + GIT_CONNECTIONS + '/' + ADD}
  >
    {children}
  </StyledLink>

export default LinkToAddGitConnection
