import StyledLink from '../StyledLink'
import { EDIT, GIT_CONNECTIONS, SYSTEM } from '@eggplant/routing'
import type { Props } from '../types'

interface Params {
  id: string
}

const LinkToEditGitConnection = ({
  children,
  id,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + SYSTEM + '/' + GIT_CONNECTIONS + '/' + id + '/' + EDIT}
  >
    {children}
  </StyledLink>

export default LinkToEditGitConnection
