import { parse } from 'query-string'
import {
  useLocation,
  useNavigate
} from '@reach/router'
import type {
  KeysGeneric,
  QueryGeneric
} from '@eggplant/types'

// Prevent the param changes from adding to history so the user does not need to back through them all
// This is also consistent with query controls that do not cause params changes
const navigateOptions = { replace: true }

const useUrlParams = <T>(allowedKeys?: KeysGeneric<T>): [QueryGeneric<T>, (newParams: QueryGeneric<T>) => void] => {
  type QueryParams = QueryGeneric<T>

  const navigate = useNavigate()

  const setValues = async (newParams: QueryParams) => {
    const currentUrlParams = new URLSearchParams(window.location.search)

    Object.keys(newParams)
      .forEach(key => {
        const value = newParams[key as keyof QueryParams]

        value
          ? currentUrlParams.set(key, value)
          : currentUrlParams.delete(key)
      })

    const stringParams = currentUrlParams.toString()

    const newAddress = window.location.pathname + (
      stringParams
        ? '?' + stringParams
        : ''
    )

    await navigate(newAddress, navigateOptions)
  }

  const location = useLocation()

  const searchParams = parse(location.search) as QueryParams

  const keys = allowedKeys?.length
    ? allowedKeys
    : Object.keys(searchParams)

  const values = keys.reduce((params, key) => {
    const value = searchParams[key as keyof QueryParams] as (string | null)

    return {
      ...params,
      [key]: value === null // params without values (?isSubmodel) are null
        ? '' // so substitute null for '' to match the string type
        : value // this is either undefined or a string value
    }
  }, {}) as QueryParams

  return [values, setValues]
}

export default useUrlParams
