import StyledLink from '../StyledLink'
import {
  ADD,
  DESIGNER,
  MODEL_HUB
} from '../routes'
import type { Props } from '../types'

const LinkToAddModel = ({
  children,
  isHyperlink,
  openInNewTab
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    openInNewTab={openInNewTab}
    to={'/' + DESIGNER + '/' + MODEL_HUB + '/' + ADD}
  >
    {children}
  </StyledLink>

export default LinkToAddModel
