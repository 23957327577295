import classes from './Log.module.scss'
import { ScrollIntoView } from '../..'
import type { ReactElement, ReactNode } from 'react'

// Simple wrapper that applies general styling and permits almost any type of LogItem to be used

interface Props {
  children: ReactNode[]
}

const checkHasKey = (node: ReactNode): node is ReactElement =>
  !!node &&
  typeof node !== 'string' &&
  typeof node !== 'number' &&
  typeof node !== 'boolean'

const getKey = (child: ReactNode, index: number) =>
  checkHasKey(child)
    ? child.key ?? index.toString()
    : index.toString()

const Log = ({ children }: Props) =>
  <ul className={classes.Log}>
    {
      children.map((child: ReactNode, index: number) =>
        <li key={getKey(child, index)}>
          {child}
          {
            index === (children.length - 1) &&
              <ScrollIntoView />
          }
        </li>
      )
    }
  </ul>

export default Log
