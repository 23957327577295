import classes from './Timezone.module.scss'
import { FiGlobe } from 'react-icons/fi'
import { getShortTimezone } from './getShortTimeZone'

interface Props {
  timeZone?: string
  value?: string
}

const getLocaleString = (date: Date, options: Intl.DateTimeFormatOptions) =>
  date.toLocaleString(undefined, options)

const currentDate = new Date().toISOString()

const Timezone = ({
  timeZone,
  value
}: Props) => {
  const date = new Date(value || currentDate)

  const longOptions: Intl.DateTimeFormatOptions = {
    timeZoneName: 'long',
    timeZone
  }

  const longDate = getLocaleString(date, longOptions)

  const shortOptions: Intl.DateTimeFormatOptions = {
    timeZoneName: 'short',
    timeZone
  }

  const shortDate = getLocaleString(date, shortOptions)

  return (
    <time
      className={classes.Timezone}
      dateTime={longDate}
    >
      <FiGlobe aria-hidden />
      {getShortTimezone(shortDate)}
    </time>
  )
}

export default Timezone
