import Router from '../../Router'
import SetupRouter from '../SetupRouter'
import { DataCollectionConsumer } from '@eggplant/context'

const renderRouter = (isUsageReportingOn: boolean|undefined, isDataCollectionAllowed: boolean|undefined) => {
  if (!isUsageReportingOn) return <Router />

  if (isDataCollectionAllowed === undefined) return <SetupRouter />

  return <Router />
}

const DataCollectionCheckpoint = () =>
  <DataCollectionConsumer>
    {
      ({
        isUserReportingEnabled,
        isDataCollectionAllowed
      }) =>
        renderRouter(isUserReportingEnabled, isDataCollectionAllowed)
    }
  </DataCollectionConsumer>

export default DataCollectionCheckpoint
