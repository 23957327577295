import { NoOptionsPlaceholder } from '@eggplant/pattern-library'

interface Props {
  variant: 'sauceLabOwner' | 'sauceLabName'
}

const emptyTunnelOwnerMessage =
'There is no tunnel owner in opened tunnel.' +
'\nPlease configure it in Sauce Labs.'

const emptyTunnelNameMessage =
'There are no open tunnels with this name. Please check the Tunnels' +
'\npage in Sauce Labs to see if a tunnel with this names exists and but is' +
'\nnot running or configure a new tunnel.'

const InputSauceLabEmptyState = ({
  variant
}: Props) =>
  <NoOptionsPlaceholder
    hasIcon={false}
    icon=''
    title={
      variant === 'sauceLabOwner'
        ? emptyTunnelOwnerMessage
        : emptyTunnelNameMessage
    }
  />

export default InputSauceLabEmptyState
