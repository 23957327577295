import {
  List,
  Paragraph
} from '@eggplant/pattern-library'

const AndroidDeviceIdDescription = () =>
  <>
    <Paragraph>
      Device ID is a unique alphanumeric code generated
      when first set up to distinguish Android device.
      Device ID can be retrieve with any Device ID app.
      <br />
      <br />
      Example are shown below:
    </Paragraph>
    <List>
      <Paragraph>792cb6f8fea571e9</Paragraph>
      <Paragraph>d2fec8869a847ef0</Paragraph>
    </List>
  </>

export default AndroidDeviceIdDescription
