import Tooltip from '../Tooltip/Tooltip'
import classes from './InfoExpand.module.scss'
import { FaInfoCircle } from 'react-icons/fa'

interface Props {
  onClick: () => void
  isSelected: boolean
  label: string
}

const InfoExpand = ({
  onClick,
  isSelected = false,
  label
}: Props) =>
  <Tooltip
    label={label}
  >
    <button
      aria-label={label}
      aria-selected={isSelected}
      className={classes.InfoExpand}
      onClick={() => { onClick() }}
      role='tab'
      type='button'
    >
      <FaInfoCircle
        aria-hidden
        size='1.5em'
      />
    </button>
  </Tooltip>

export default InfoExpand
