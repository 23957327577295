import classes from './CheckedIndicator.module.scss'

interface Props {
  isChecked: boolean
  isIndeterminate?: boolean
  isDisabled?: boolean
}

const CheckedIndicator = ({
  isChecked = false,
  isIndeterminate = false,
  isDisabled = false
}: Props) => {
  const getClasses = () => {
    const baseClass = classes.CheckedIndicator

    const hasIcon = isChecked || isIndeterminate

    const iconClass = isIndeterminate
      ? classes.isIndeterminate // render dash
      : classes.isChecked // render tick

    const checkboxClass = hasIcon
      ? iconClass
      : undefined

    const contentReadOnlyClass = hasIcon
      ? classes.CheckedReadOnly // checked and indeterminate sharing same style
      : classes.UncheckedReadOnly

    const disabledClass = isDisabled
      ? contentReadOnlyClass
      : undefined

    const finalClasses = [
      baseClass,
      checkboxClass,
      disabledClass
    ].filter(classname => classname)

    return finalClasses.join(' ')
  }

  return (
    <span
      aria-hidden
      className={getClasses()}
    />
  )
}

export default CheckedIndicator
