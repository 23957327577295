import PropTypes from 'prop-types'
import classes from './CustomRow.module.scss'

const CustomRow = ({
  children: tdList,
  expandedChildren,
  isSelected
}) => {
  const basicRow =
    <tr
      aria-selected={isSelected}
      className={
        expandedChildren
          ? classes.CustomRow + ' ' + classes.isExpanded
          : classes.CustomRow
      }
    >
      {tdList}
    </tr>

  if (!expandedChildren) return basicRow

  return (
    <>
      {basicRow}
      <tr>
        <td colSpan={999}>
          {expandedChildren}
        </td>
      </tr>
    </>
  )
}

CustomRow.propTypes = {
  children: PropTypes.node.isRequired,
  expandedChildren: PropTypes.node,
  isSelected: PropTypes.bool.isRequired
}

CustomRow.defaultProps = {
  isSelected: false
}

export default CustomRow
