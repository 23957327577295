import classes from './ChartErrorState.module.scss'
import {
  Color,
  ErrorPlaceholder,
  Header,
  Paragraph
} from '@eggplant/pattern-library'
import { HeartBroken } from '../Icons/Icons'
import type { Error } from '@eggplant/types'

interface Props {
  error: Error
}

const ChartErrorState = ({
  error
}: Props) => (
  <div className={classes.ChartErrorState}>
    <Color variant='highlightred'>
      <HeartBroken
        aria-hidden
        size='24px'
      />
    </Color>
    <Header
      hasNoTopMargin
      rank={4}
    >
      Oops... Something went wrong!
    </Header>
    <Paragraph>
      Please see the error details below, and reload the page.
    </Paragraph>
    <div className={classes.ErrorMessage}>
      <ErrorPlaceholder
        status={(error.status || '').toString()}
        title={error.title || ''}
      >
        {error.detail}
      </ErrorPlaceholder>
    </div>
  </div>
)

export default ChartErrorState
