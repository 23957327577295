import SingleSelect from '../SingleSelect/SingleSelect'

const TWELVE = 12

const formatDisplayUnit = (value: number) => (
  value < 10
    ? '0' // a leading zero string
    : '' // just a string
) + value

const hourOptions = Array(12).fill(undefined).map((_item, index) =>
  ({
    label: formatDisplayUnit(index + 1),
    value: index + 1 + ''
  })
)

// Standardize the input back to 24hr format - 3:00PM => 15
const standardizeHours = (isPm: boolean, newHour: number) =>
  isPm && newHour < TWELVE
    ? newHour + TWELVE
    : newHour

interface Props {
  isDisabled?: boolean
  onChange: (value: Date) => void
  value: Date
}

const ChooseHour = ({
  isDisabled = false,
  onChange,
  value
}: Props) => {
  const hours = value.getHours()

  const isPm = hours >= TWELVE

  const displayHours = (
    isPm
      ? hours - TWELVE
      : hours
  ) || TWELVE // in case it is 0 for midnight

  const handleHourChange = (hourString: string) => {
    const newHour = parseInt(hourString)

    const standardHour = standardizeHours(isPm, newHour)

    // No actual change
    if (hours === standardHour) return

    // if it's AM and the hour is 12
    const isMidnight = !isPm && standardHour === TWELVE

    if (hours === 0 && isMidnight) return

    // Copy to prevent mutation
    const newDate = new Date(value)

    newDate.setHours(
      isMidnight
        ? 0 // Prevent am AM/PM turnover
        : standardHour
    )

    onChange(newDate)
  }

  return (
    <SingleSelect
      isDisabled={isDisabled}
      label='Hour'
      onChange={handleHourChange}
      options={hourOptions}
      value={displayHours.toString()}
    />
  )
}

export default ChooseHour
