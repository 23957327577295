import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  TEST_CONFIG
} from '../routes'
import type { Props } from '../types'

const LinkToTestConfigs = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + CONTROLLER + '/' + TEST_CONFIG}
  >
    {children}
  </StyledLink>

export default LinkToTestConfigs
