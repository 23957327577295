import { getTestContext } from '../getTestContext'
import { useUrlParams } from '@eggplant/routing'
import type { Context } from '../../types'
import type { Query } from '../types'
import type { ReactElement, ReactNode } from 'react'

interface Props {
  children: (context: Context) => ReactNode
}

export const TestContext = ({
  children
}: Props) => {
  const [{ mockedRoles }] = useUrlParams<Query>()

  return children(getTestContext(mockedRoles)) as ReactElement
}
