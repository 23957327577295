import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  Icons,
  NoOptionsPlaceholder
} from '@eggplant/pattern-library'
import { FaFolderOpen } from 'react-icons/fa'
import { LinkToSuites } from '@eggplant/routing'
import { VAM_SERVICE } from '@eggplant/api-hosts'
import type {
  ButtonShape,
  GetVamAssetsResponse,
  Options
} from '@eggplant/types'
import type { Props } from '../interfaces'

interface CustomProps extends Props {
  initialOptions?: Options
  placeholder?: string
  shortErrorMessage?: string
  isDisabled?: boolean
  isFilter?: boolean
  isNameAsValue?: boolean
}

const url = VAM_SERVICE + '/assets'

const getOptions = (assets: GetVamAssetsResponse, isNameAsValue: boolean) =>
  assets.map(({
    id,
    name
  }) => ({
    label: name,
    value: isNameAsValue
      ? name
      : id
  }))

const SelectSuite = ({
  initialOptions = [],
  onChange,
  placeholder,
  value,
  shortErrorMessage,
  isDisabled = false,
  isFilter = false,
  isNameAsValue = false
}: CustomProps) => {
  const getDefaultLabel = () => {
    if (placeholder) {
      return placeholder
    }

    if (initialOptions.length) {
      const defaultLabel = initialOptions.find(item => item.value === '')

      return defaultLabel?.label || 'Suites'
    }

    return 'Suites'
  }

  const getIsHighlighted = () => {
    if (!isFilter) return false

    return !!value
  }

  const getRenderButton = (options: Options) => {
    const combinedOptions = [...initialOptions, ...options]

    const currentOption = combinedOptions.find(item =>
      item.value === value
    )

    const label = currentOption?.value
      ? currentOption.label
      : placeholder

    const filterTooltip = currentOption?.value
      ? 'Suite: ' + currentOption.label
      : placeholder

    const title = isFilter
      ? filterTooltip
      : label

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={Icons.Suites}
        isDisabled={isDisabled}
        isFilter={isFilter}
        isHighlighted={getIsHighlighted()}
        isSolidIcon
        label={label}
        title={title}
      >
        {
          currentOption
            ? currentOption.label
            : getDefaultLabel()
        }
      </DropdownButton>
  }

  const noDataPlaceholder =
    <NoOptionsPlaceholder
      icon={
        <FaFolderOpen
          aria-hidden
          size='24px'
        />
      }
      linkTo={
        <LinkToSuites>
          Go to suites
        </LinkToSuites>
      }
      title='No suites found'
    />

  return (
    <AuthenticatedDataRequest
      method='get'
      shortErrorMessage={shortErrorMessage}
      url={url}
    >
      {
        (data: GetVamAssetsResponse) =>
          <Combobox
            initialOptions={initialOptions}
            isDisabled={isDisabled}
            isFilter={isFilter}
            noOptionsPlaceholder={noDataPlaceholder}
            onChange={onChange}
            options={getOptions(data, isNameAsValue)}
            renderButton={getRenderButton(getOptions(data, isNameAsValue))}
            value={value}
          />
      }
    </AuthenticatedDataRequest>
  )
}

export default SelectSuite
