import Bottom from './Bottom'
import Top from './Top'
import {
  Logo,
  Sidebar
} from '@eggplant/pattern-library'
import { PreferencesConsumer } from '@eggplant/context'
import type { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = {
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  height: '100%',
  justifyContent: 'space-between',
  padding: '14px 0 60px',
  overflowY: 'auto',
  overflowX: 'hidden'
}

const logoWrapper = {
  padding: '0 24px 14px'
}

const SideNav = () =>
  <PreferencesConsumer>
    {
      ({
        isNavCollapsed: isCollapsed,
        setIsNavCollapsed
      }) =>
        <Sidebar
          isCollapsed={isCollapsed}
          onCollapse={setIsNavCollapsed}
        >
          <div
            style={{
              ...wrapperStyle,
              width: isCollapsed
                ? 79
                : 175
            }}
          >
            <div>
              <div style={logoWrapper}>
                <Logo isCollapsed={isCollapsed} />
              </div>
              <Top isCollapsed={isCollapsed} />
            </div>
            <div>
              <Bottom isCollapsed={isCollapsed} />
            </div>
          </div>
        </Sidebar>
    }
  </PreferencesConsumer>

export default SideNav
