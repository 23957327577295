import Color from '../Color/Color'
import classes from './Warning.module.scss'
import { FaInfoCircle } from 'react-icons/fa'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const Warning = ({
  children
}: Props) =>
  <div className={classes.Root}>
    <div>
      <Color variant='orange'>
        <FaInfoCircle
          aria-hidden
          size='28px'
        />
      </Color>
    </div>
    <div className={classes.Warning}>
      {children}
    </div>
  </div>

export default Warning
