import { ComboboxSingleSelect } from '@eggplant/pattern-library'
import type {
  DateRangeOptions,
  Options
} from '@eggplant/types'

interface Props{
  isDisabled?: boolean
  onChange: (value: DateRangeOptions) => void
  value: DateRangeOptions
}

const options: Options = [
  { label: 'Custom Range', value: 'custom-range' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 3 Days', value: 'last-3-days' },
  { label: 'Last 7 Days', value: 'last-7-days' },
  { label: 'Last 2 Weeks', value: 'last-2-weeks' },
  { label: 'Last 3 Weeks', value: 'last-3-weeks' },
  { label: 'Last Month', value: 'last-month' },
  { label: 'Last 3 Months', value: 'last-3-months' }
]

const SelectDateRange = ({
  isDisabled = false,
  onChange,
  value
}: Props) => {
  const handleDateRangeChange = (dateRange: string) => {
    const inputOrDefaultValue = (dateRange || 'last-7-days') as DateRangeOptions

    onChange(inputOrDefaultValue)
  }

  return (
    <ComboboxSingleSelect
      ariaLabel='Date Range Combobox'
      defaultSelectionValue='last-7-days'
      disableSorting
      isDisabled={isDisabled}
      isHighlighted={value !== 'last-7-days'}
      isRequired={false}
      onChange={handleDateRangeChange}
      options={options}
      prompt='Select Date Range'
      value={value}
    />
  )
}

export default SelectDateRange
