import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { apiAccess } from '../../docs-routes'

const ApiAccess = () =>
  <Paragraph>
    API access keys can be generated to enable direct API requests to our service endpoints for use with third party clients.
    <br />
    <DocusaurusFooter path={apiAccess} />
  </Paragraph>

export default ApiAccess
