import ChartLegend from '../ChartLegend/ChartLegend'
import styles from './AllRunsChart.module.scss'
import {
  ArcElement,
  Chart,
  Legend,
  Tooltip
} from 'chart.js'
import { DOUGHNUT_CHART_OPTIONS } from '../../constants'
import { Doughnut } from 'react-chartjs-2'

Chart.register(
  ArcElement,
  Tooltip,
  Legend)

const generateChartData = (passes: number, failures: number, incomplete: number) => ({
  labels: [
    'Passes',
    'Failures',
    'Incomplete'
  ],
  datasets: [
    {
      backgroundColor: [
        styles.colorpass,
        styles.colorfail,
        styles.colorincomplete
      ],
      data: [
        passes,
        failures,
        incomplete
      ]
    }
  ]
})

const generateLegendRows = (passes: number, failures: number, incomplete: number) =>
  [
    {
      label: 'Pass',
      amount: passes,
      color: styles.colorpass
    }, {
      label: 'Fail',
      amount: failures,
      color: styles.colorfail
    }, {
      label: 'Incomplete',
      amount: incomplete,
      color: styles.colorincomplete
    }
  ]

interface Props {
  passes: number
  failures: number
  incomplete: number
}

const AllRunsChart = ({
  passes,
  failures,
  incomplete
}: Props) =>
  <div>
    <div className={styles.chartContainerStyle}>
      <Doughnut
        data={generateChartData(passes, failures, incomplete)}
        options={DOUGHNUT_CHART_OPTIONS}
      />
    </div>
    <ChartLegend rows={generateLegendRows(passes, failures, incomplete)} />
  </div>

export default AllRunsChart
