import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  ConfirmActionDialog,
  SuccessMessage
} from '@eggplant/pattern-library'
import { EXECUTION_SERVICE } from '@eggplant/api-hosts'
import { useState } from 'react'
import type { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = { width: 500 }

interface Props {
  onCancel: () => void
  onSuccess: () => void
  execution_id: string
}

const AbortRun = ({
  execution_id,
  onCancel,
  onSuccess
}: Props) => {
  const [isAborting, setIsAborting] = useState(false)

  const handleConfirm = () => {
    setIsAborting(true)
  }

  return (
    <div style={wrapperStyle}>
      {
        isAborting
          ? (
            <AuthenticatedDataRequest
              method='delete'
              onSuccess={onSuccess}
              url={`${EXECUTION_SERVICE}/executions/${execution_id}`}
            >
              {
                () =>
                  <SuccessMessage>
                    Abort successfully requested.
                  </SuccessMessage>
              }
            </AuthenticatedDataRequest>
          )
          : (
            <ConfirmActionDialog
              actionText='Abort'
              message='This action cannot be undone.'
              onCancel={onCancel}
              onConfirm={handleConfirm}
              title='Abort this test?'
            />
          )
      }
    </div>
  )
}

export default AbortRun
