import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  ENVIRONMENTS
} from '../routes'
import type { Props } from '../types'

const LinkToEnvironments = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + CONTROLLER + '/' + ENVIRONMENTS}
  >
    {children}
  </StyledLink>

export default LinkToEnvironments
