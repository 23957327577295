import DocusaurusFooter from '../../DocusaurusFooter'
import {
  Color,
  Paragraph
} from '@eggplant/pattern-library'
import { FaSearch } from 'react-icons/fa'
import { modelPreview } from '../../../docs-routes'
import type { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = {
  display: 'flex',
  gap: '0.5em'
}

const ModelPreview = () =>
  <div style={wrapperStyle}>
    <div>
      <Color variant='purple'>
        <FaSearch
          aria-hidden
          size='1.8em'
        />
      </Color>
    </div>
    <div>
      <Paragraph>
        <strong>Click on an Element to view its properties.</strong>
        <br /><br />
      </Paragraph>
      <Paragraph>
        View the Global Properties and full variables of the model by clicking on the tabs at the top.
        <br /><br />
      </Paragraph>
      <Paragraph>
        You can also view any associated Test cases, Results, and Heat Maps by clicking on the links in the top bar.
        <br /><br />
      </Paragraph>
      <Paragraph>
        <DocusaurusFooter path={modelPreview} />
      </Paragraph>
    </div>
  </div>

export default ModelPreview
