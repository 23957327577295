import classes from './AddStepButton.module.scss'
import { FiPlus } from 'react-icons/fi'
import {
  Paragraph,
  Tooltip
} from '../..'
import type { ReactNode } from 'react'

interface BaseProp {
  onClick: () => void
  label: string
  children: ReactNode
}

interface WithCounterProp {
  counterLabel: string
  counterDescription: string
}

interface WithoutCounterProp {
  counterLabel?: never
  counterDescription?: never
}

type Props = BaseProp & (WithCounterProp | WithoutCounterProp)

const AddStepButton = ({
  onClick,
  label,
  children,
  counterLabel,
  counterDescription
}: Props) => {
  const renderCounter = () => {
    if (!counterLabel) return <span className={classes.NoCounter} />

    return (
      <span
        aria-label={`${label}-counter`}
        className={classes.Counter}
      >
        <Tooltip label={counterDescription}>
          <Paragraph>
            {counterLabel}
          </Paragraph>
        </Tooltip>
      </span>
    )
  }

  return (
    <div className={classes.AddStepButton}>
      <button
        aria-label={label}
        onClick={onClick}
        type='button'
      >
        <div className={classes.ContentWrapper}>
          {children}
          {
            renderCounter()
          }
          <FiPlus
            aria-hidden
            className={classes.PlusIcon}
            size={18}
          />
        </div>
      </button>
    </div>
  )
}

export default AddStepButton
