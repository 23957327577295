import { Paragraph } from '@eggplant/pattern-library'

const TestCaseExternalIdDescription = () =>
  <Paragraph>
    If the test case came from
    <br />
    an external system with
    <br />
    its own unique identifier,
    <br />
    that identifier can be
    <br />
    stored here.
  </Paragraph>

export default TestCaseExternalIdDescription
