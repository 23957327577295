import mockUseKeycloak from './mockUseKeycloak'
import { reactKeycloakWebContext } from './context'
import { useContext } from 'react'

export const useKeycloak = () => {
  const ctx = useContext(reactKeycloakWebContext)

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!ctx) {
    throw new Error(
      'useKeycloak hook must be used inside ReactKeycloakProvider context'
    )
  }

  const { authClient, initialized } = ctx

  if (!authClient) {
    // Allow Jest and Cypress to proceed
    if (process.env.REACT_APP_IS_AUTH_DISABLED) return mockUseKeycloak

    throw new Error('authClient has not been assigned to ReactKeycloakProvider')
  }

  return {
    initialized,
    keycloak: authClient
  }
}
