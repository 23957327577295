import Divider from '../Divider/Divider'
import styles from './ExecutionDetailsChart.module.scss'
import {
  HiCheckCircle,
  HiExclamation,
  HiExclamationCircle,
  HiXCircle
} from 'react-icons/hi'
import {
  InProgressCircle,
  StateRerunCircle
} from '../Icons/Icons'

interface Props {
  cancelled: number
  errors: number
  failures: number
  incomplete: number
  passes: number
  reruns?: number
}

const ExecutionDetailsLegend = ({
  cancelled,
  errors,
  failures,
  incomplete,
  passes,
  reruns = 0
}: Props) =>
  <div className={styles.StatusContainer}>
    <div className={styles.StatusItem}>
      <HiCheckCircle
        color={styles.colorpass}
        size={18}
      />
      <div className={styles.StatusLabel}>Passed first time:</div>
      <div>{passes}</div>
    </div>
    <div className={styles.StatusItem}>
      <StateRerunCircle />
      <div className={styles.StatusLabel}>Passed after rerun:</div>
      <div>{reruns}</div>
    </div>
    <Divider />
    <div className={styles.StatusItem}>
      <HiExclamationCircle
        color={styles.colorfail}
        size={18}
      />
      <div className={styles.StatusLabel}>Failed:</div>
      <div>{failures}</div>
    </div>
    <div className={styles.StatusItem}>
      <HiExclamation
        color={styles.colorerror}
        size={18}
      />
      <div className={styles.StatusLabel}>Error:</div>
      <div>{errors}</div>
    </div>
    <div className={styles.StatusItem}>
      <HiXCircle
        color={styles.colorcancelled}
        size={18}
      />
      <div className={styles.StatusLabel}>Cancelled:</div>
      <div>{cancelled}</div>
    </div>
    <Divider />
    <div className={styles.StatusItem}>
      <InProgressCircle />
      <div className={styles.StatusLabel}>In Progress:</div>
      <div>{incomplete}</div>
    </div>
  </div>

export default ExecutionDetailsLegend
