import DocusaurusFooter from '../DocusaurusFooter'
import KickStartFooter from '../KickStartFooter'
import {
  LinkToAddModel,
  LinkToImportModel,
  useAccess
} from '@eggplant/routing'
import { Paragraph } from '@eggplant/pattern-library'
import { models } from '../../docs-routes'

const Models = () => {
  const [isAccess] = useAccess()

  return (
    <Paragraph>
      {
        isAccess
          ? <>Eggplant DAI models are simple visual representations of the application, interface, or website that you want to test. You can <LinkToAddModel>create a new model</LinkToAddModel> or <LinkToImportModel>import a model</LinkToImportModel> using a .json file.</>
          : 'Eggplant DAI models are simple visual representations of the application, interface, or website that you want to test.'
      }
      {
        isAccess &&
          <KickStartFooter />
      }
      <DocusaurusFooter path={models} />
    </Paragraph>
  )
}

export default Models
