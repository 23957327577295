import classes from './InvisiButton.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  onClick: () => void
  isDisabled?: boolean
  title?: string
  hasHoverEffect?: boolean
  label?: string
}

const InvisiButton = ({
  children,
  onClick,
  title,
  isDisabled,
  hasHoverEffect = false,
  label = ''
}: Props) => {
  const backgroundStyle = hasHoverEffect
    ? ' ' + classes.HasHoverEffect
    : ''

  const buttonClass = classes.InvisiButton + backgroundStyle

  return (
    <button
      aria-label={label}
      className={buttonClass}
      disabled={isDisabled}
      onClick={() => { onClick() }}
      title={title}
      type='button'
    >
      {children}
    </button>
  )
}

export default InvisiButton
