import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { modelDashboard } from '../../docs-routes'

const QASummary = () =>
  <Paragraph>
    The Model dashboard page shows a recap of your recent directed and exploratory testing in DAI.
    <br />
    <br />
    To populate the summary, select a Model from the drop-down list. The summary shows results from the last 7 days by default, but you can customize the date range as needed.
    <DocusaurusFooter path={modelDashboard} />
  </Paragraph>

export default QASummary
