import {
  globalHistory,
  navigate,
  useLocation
} from '@reach/router'
import { useEffect } from 'react'

const alertUserClose = (event: BeforeUnloadEvent) => {
  event.preventDefault()

  // eslint-disable-next-line fp/no-mutation
  event.returnValue = '' // to ensure the event prompt for user input(reload or leave) and return blank value as we no need evaluate the value meaning
}

const usePreventNav = (
  offPrompt: boolean,
  confirmMessage: string
) => {
  const currentRoute = useLocation().pathname

  // to handle browser level event like tab close, brower close
  useEffect(() => {
    window.addEventListener('beforeunload', alertUserClose)

    return () => {
      window.removeEventListener('beforeunload', alertUserClose)
    }
  })

  // to handle any redirection happen on the URL address changes
  useEffect(() =>
    // to confirm the event is fully unsubscribe and not carry to other page
    globalHistory.listen(({ action, location }) => {
      const isUserLeaving = (
        !offPrompt &&
        action === 'PUSH' && // detected when new URL is added
        location.pathname !== currentRoute
      ) || action === 'POP' // detected when browser back is clicked

      if (isUserLeaving && !window.confirm(confirmMessage)) {
        void navigate(currentRoute)
      }
    })
  , [offPrompt, confirmMessage, currentRoute])
}

export default usePreventNav
