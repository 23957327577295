import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  RUNNER,
  STEPS
} from '../routes'
import { PROP_TASK_INSTANCE_ID } from '../constants'
import type { Props } from '../types'

const base = '/' + CONTROLLER + '/' + RUNNER + '/' + STEPS

interface Params {
  [PROP_TASK_INSTANCE_ID]: string
}

const LinkToTaskRunSteps = ({
  children,
  [PROP_TASK_INSTANCE_ID]: taskInstanceId,
  isHyperlink
}: Props & Params) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base + '/' + taskInstanceId}
  >
    {children}
  </StyledLink>

export default LinkToTaskRunSteps
