import StyledLink from '../StyledLink'
import {
  ADD_LICENSE,
  LICENSES,
  SYSTEM
} from '../routes'
import type { Props } from '../types'

const LinkToAddLicense = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + SYSTEM + '/' + LICENSES + '/' + ADD_LICENSE}
  >
    {children}
  </StyledLink>

export default LinkToAddLicense

