import { Paragraph } from '@eggplant/pattern-library'

const NotificationPasswordDescription = () =>
  <Paragraph>
    Passwords are write-only
    <br />
    and saved passwords are
    <br />
    not displayed. You must
    <br />
    re-enter the password for
    <br />
    each submission.
  </Paragraph>

export default NotificationPasswordDescription
