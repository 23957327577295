import EmptyStatesPreview from '../../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { Cogs } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAddGitConnection } from '@eggplant/routing'
import { LinkToGitConnectionsDoc } from '../../links'

const description = "Adding a connection to your Git repository allows you to access your project's suite(s) from DAI."

const renderButton = () =>
  <AccessControl
    allowedRoles={['dai:admin']}
    isHiddenIfDenied
  >
    <LinkButton variant='primary'>
      <LinkToAddGitConnection>
        <HiPlus
          aria-hidden
          size='19.2px'
        /> New Connection
      </LinkToAddGitConnection>
    </LinkButton>
  </AccessControl>

const GitConnectionsIntro = () =>
  <div className='grid w-[36em]'>
    <EmptyStatesPreview
      action={renderButton()}
      description={description}
      icon={<Cogs />}
      renderLink={<LinkToGitConnectionsDoc />}
      title='Git Connections'
    />
  </div>

export default GitConnectionsIntro
