import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAddEnvironment } from '@eggplant/routing'
import { LinkToEnvironmentsIntroStateDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = 'Execution environments are made up of three pieces: An Eggplant DAI agent, an instance of Eggplant Functional, and a system under test (SUT). Execution environments allow you to connect a model in Eggplant DAI to a suite in Eggplant Functional, and execute specific SenseTalk code snippets against a SUT.'

const renderButton = () =>
  <AccessControl isHiddenIfDenied>
    <LinkButton
      title='New Environment'
      variant='primary'
    >
      <LinkToAddEnvironment>
        <HiPlus
          aria-hidden
          size='19.2px'
        /> New Environment
      </LinkToAddEnvironment>
    </LinkButton>
  </AccessControl>

const EnvironmentIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Path />}
        renderLink={<LinkToEnvironmentsIntroStateDoc />}
        title='Environments'
      />
    </div>
  </div>

export default EnvironmentIntro
