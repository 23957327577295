import StyledLink from '../StyledLink'
import {
  BUGHUNTING,
  INSIGHTS
} from '../routes'
import type { Props } from '../types'

const LinkToBugHunting = ({
  children,
  label,
  isHyperlink
}: Props) =>
  <StyledLink
    aria-label={label}
    isHyperlink={isHyperlink}
    to={'/' + INSIGHTS + '/' + BUGHUNTING}
  >
    {children}
  </StyledLink>

export default LinkToBugHunting
