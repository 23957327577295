import {
  Color,
  Paragraph
} from '@eggplant/pattern-library'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import { useAccess } from '@eggplant/routing'
import type { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = {
  display: 'flex',
  columnGap: '4px',
  alignItems: 'start'
}

const centerAlignIconWithFirstParagraphRow: CSSProperties = {
  paddingTop: '1px'
}

const CronFieldDescription = () => {
  const [hasAccess] = useAccess()

  return (
    <div style={wrapperStyle}>
      <span style={centerAlignIconWithFirstParagraphRow}>
        <Color variant='theme-placeholder-text'>
          <HiOutlineExclamationCircle
            aria-hidden
            size={14}
          />
        </Color>
      </span>
      <Paragraph>
        <Color variant='theme-placeholder-text'>
          {hasAccess && 'Define a custom time interval using Cron syntax. '}You are starting from the time you selected on the calendar.
        </Color>
      </Paragraph>
    </div>
  )
}

export default CronFieldDescription
