import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  NoOptionsPlaceholder
} from '@eggplant/pattern-library'
import { FiPlus } from 'react-icons/fi'
import { HiOutlineDocumentText } from 'react-icons/hi'
import {
  LIVE_RUN,
  ONLY_LIVE_RUN_TOOLTIP,
  ONLY_TEST_CONFIG_TOOLTIP,
  TEST_CONFIG
} from './constants'
import {
  LinkToAddTestConfig,
  useAccess
} from '@eggplant/routing'
import { TEST_CONFIG_SERVICE } from '@eggplant/api-hosts'
import type {
  ButtonShape,
  GetFilterIdListResponse,
  Options
} from '@eggplant/types'

interface Props {
  onChange: (value: string) => void
  placeholder?: string
  value?: string
}
interface labelValue {
  label: string
  value: string
}

const initialOptions = [
  { label: ONLY_LIVE_RUN_TOOLTIP, value: LIVE_RUN },
  { label: ONLY_TEST_CONFIG_TOOLTIP, value: TEST_CONFIG }
]

const url = TEST_CONFIG_SERVICE + '/test_configuration_names'

const getOptions = (items: GetFilterIdListResponse[] | undefined, nonSearchableOptions?: labelValue[]) => {
  if (!Array.isArray(items)) return []

  const options = items.map(item => ({
    label: item.name,
    value: item.id_list.join(',')
  }))

  return nonSearchableOptions
    ? options.concat(nonSearchableOptions)
    : options
}

const SelectModelSuiteTestConfigs = ({
  onChange,
  placeholder = 'Test config / Live run',
  value
}: Props) => {
  const [isAccessible] = useAccess()

  const renderButton = (options: Options) => {
    const currentOption = value
      ? options.find(item => item.value.includes(value))
      : undefined

    const label = currentOption?.value
      ? currentOption.label
      : placeholder

    const testconfigTooltip = [LIVE_RUN, TEST_CONFIG].includes(String(value))
      ? label
      : `Test config: ${label}`

    const tooltip = currentOption?.value
      ? testconfigTooltip
      : placeholder

    const buttonText = currentOption?.value
      ? currentOption.label
      : placeholder

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={HiOutlineDocumentText}
        isFilter
        isHighlighted={!!value}
        label={label}
        title={tooltip}
      >
        {buttonText}
      </DropdownButton>
  }

  const noDataPlaceholder =
    <NoOptionsPlaceholder
      icon={<HiOutlineDocumentText size='24px' />}
      linkTo={
        isAccessible
          ? (
            <LinkToAddTestConfig>
              <FiPlus
                aria-hidden
                size={16}
              />
              New Test Config
            </LinkToAddTestConfig>
          )
          : undefined
      }
      title='No test config found'
    />

  return (
    <div className='flex'>
      <AuthenticatedDataRequest
        method='get'
        shortErrorMessage='Failed to load Test config'
        url={url}
      >
        {
          (data: GetFilterIdListResponse[]) =>
            <Combobox
              groupedOptions={
                [{
                  label: 'Available test configs',
                  options: getOptions(data)
                }]
              }
              initialOptions={initialOptions}
              isFilter
              noOptionsPlaceholder={noDataPlaceholder}
              onChange={onChange}
              renderButton={renderButton(getOptions(data, initialOptions))}
              value={value}
            />
        }
      </AuthenticatedDataRequest>
    </div>
  )
}

export default SelectModelSuiteTestConfigs
