import EmptyState from './EmptyState/EmptyState'
import classes from './Combobox.module.scss'
import { FiAlertCircle } from 'react-icons/fi'
import { FieldLabel, IconButton } from '../..'
import { IoClose } from 'react-icons/io5'
import { useCombobox } from 'downshift'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import type { BaseOptionType } from '@eggplant/types'
import type {
  FormEvent,
  ReactNode
} from 'react'

interface Props {
  customValidity?: string
  options: BaseOptionType[]
  pattern?: RegExp
  isDisabled?: boolean
  fieldLabel?: string
  defaultPlacholder?: string
  noOptionsPlaceholder?: ReactNode
  value: string
  onChange: (value: string) => void
}

const getInputFilter = (inputValue: string|undefined) => {
  const lowerCasedInputValue = (inputValue || '').toLowerCase()

  return (input: BaseOptionType) =>
    !inputValue ||
    input.label.toLowerCase().includes(lowerCasedInputValue)
}

// eslint-disable-next-line complexity
const ComboBoxAutoComplete = ({
  customValidity = 'Please select an item from the list',
  options,
  pattern,
  isDisabled = false,
  fieldLabel,
  defaultPlacholder,
  noOptionsPlaceholder = <EmptyState />,
  value,
  onChange
}: Props) => {
  const [items, setItems] = useState(options)

  const {
    isOpen,
    getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getInputProps,
    // highlightedIndex,
    getItemProps,
    // selectedItem,
    reset
  } = useCombobox({
    onInputValueChange ({ inputValue }) {
      setItems(options.filter(getInputFilter(inputValue)))
      onChange(inputValue?.trimStart() || '')
    },
    items,
    itemToString (item) {
      return item
        ? item.value
        : ''
    }
  })

  const displayedPlaceholder = isDisabled && !value
    ? ''
    : defaultPlacholder

  const handleClear = () => {
    onChange('')
    reset()
  }

  const handleOnBlur = (event: FormEvent) => {
    const target = event.target as HTMLInputElement
    const trimmedValue = target.value.trim()

    onChange(trimmedValue)
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const validate = () => {
    const ref = inputRef.current

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!ref) return

    // reset CustomValidity when value changed
    ref.setCustomValidity('')

    // place after setCustomValidity('') to reset customValidity that handle user backspace the field value until empty string
    if (!value || !customValidity) return

    // customValidity message only shows on submit
    const isValid = ref.checkValidity()

    if (!isValid) {
      ref.setCustomValidity(customValidity)
    }
  }

  useEffect(validate, [customValidity, value])

  const isInvalid = value && pattern && !pattern.test(value)

  const isInputDisableClass = isDisabled
    ? ' ' + classes.Disabled
    : ''

  const inputWrapperClass = classes.Layout + isInputDisableClass

  return (
    <div className={classes.ComboBoxAutoComplete}>
      <FieldLabel label={fieldLabel || ''}>
        <>
          <div className={inputWrapperClass}>
            <input
              {...getInputProps()}
              {...getToggleButtonProps()}
              disabled={isDisabled}
              onBlur={handleOnBlur}
              pattern={pattern?.source}
              placeholder={displayedPlaceholder}
              value={value}
            />
            {
              value &&
                <div className={classes.ClearBtn}>
                  <IconButton
                    onClick={handleClear}
                    title='Clear'
                  >
                    <IoClose aria-hidden />
                  </IconButton>
                </div>
            }
          </div>
          {
            isInvalid &&
              <div className={classes.ErrorMessage}>
                <FiAlertCircle
                  aria-hidden
                  className={classes.ErrorIcon}
                  size='12px'
                />
                <span>{customValidity}</span>
              </div>
          }
        </>
      </FieldLabel>
      {
        isOpen && !isDisabled &&
          <span className={classes.fullWidthDropdownWrapper}>
            <ul
              {...getMenuProps()}
            >
              {
                items.length > 0
                  ? items.map((item, index) => (
                    <li
                      key={index}
                      {...getItemProps({ item, index })}
                    >
                      <span>{item.label}</span>
                    </li>
                  ))
                  : noOptionsPlaceholder
              }
            </ul>
          </span>
      }
    </div>
  )
}

export default ComboBoxAutoComplete
