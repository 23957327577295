import TelemetryProvider from './TelemetryProvider'
import { DataCollectionConsumer } from '@eggplant/context'

interface Props {
  children: JSX.Element
}

const Telemetry = ({
  children
}: Props) => {
  const token = process.env.REACT_APP_PUBLIC_POSTHOG_KEY
  const host = process.env.REACT_APP_PUBLIC_POSTHOG_HOST
  const isPostHogEnabled = !!token && !!host
  const isTelemetryDisabled = process.env.REACT_APP_IS_TELEMETRY_DISABLED

  return (
    <DataCollectionConsumer>
      {
        ({
          isUserReportingEnabled,
          isDataCollectionAllowed
        }) => {
          if (!isPostHogEnabled) return children

          return isUserReportingEnabled && isDataCollectionAllowed && !isTelemetryDisabled
            ? (
              <TelemetryProvider
                host={host}
                token={token}
              >
                {children}
              </TelemetryProvider>
            )
            : children
        }
      }
    </DataCollectionConsumer>
  )
}

export default Telemetry
