import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { VAM_SERVICE } from '@eggplant/api-hosts'
import type { GetImportedGitSuitesResponse } from '@eggplant/types'
import type { ReactElement } from 'react'

interface Props {
  gitConnectionId: string
  children?: (response: GetImportedGitSuitesResponse) => ReactElement
}

const GetImportedGitSuitesRequest = ({ gitConnectionId, children }: Props) => {
  const url = VAM_SERVICE + '/repositories/' + gitConnectionId + '/suites'

  return (
    <AuthenticatedDataRequest
      children={children}
      method='get'
      url={url}
    />
  )
}

export default GetImportedGitSuitesRequest
