import ChartLegend from '../ChartLegend/ChartLegend'
import styles from './FailureTypeChart.module.scss'
import { DOUGHNUT_CHART_OPTIONS } from '../../constants'
import { Doughnut } from 'react-chartjs-2'

const generateChartData = (exploratoryRunFail: number, directRunFail: number) => ({
  labels: [
    'Exploratory Runs Fail',
    'Directed Runs Fail'
  ],
  datasets: [
    {
      backgroundColor: [
        styles.colorexploratoryrunfail,
        styles.colordirectrunfail
      ],
      data: [
        exploratoryRunFail,
        directRunFail
      ]
    }
  ]
})

interface Props {
  exploratory: number
  directed: number
}

const FailureTypeChart = ({
  exploratory,
  directed
}: Props) =>
  <div>
    <div className={styles.chartContainerStyle}>
      <Doughnut
        data={generateChartData(exploratory, directed)}
        options={DOUGHNUT_CHART_OPTIONS}
      />
    </div>
    <ChartLegend
      rows={[
        {
          label: 'Exploratory Runs Fail',
          amount: exploratory,
          color: styles.colorexploratoryrunfail
        }, {
          label: 'Directed Runs Fail',
          amount: directed,
          color: styles.colordirectrunfail
        }
      ]}
    />
  </div>

export default FailureTypeChart
