import MDSpinner from 'react-md-spinner'
import colors from './Spinner.module.scss'

const Spinner = () =>
  <MDSpinner
    aria-hidden
    singleColor={colors.singlecolor}
  />

export default Spinner
