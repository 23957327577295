import classes from './SuccessMessage.module.scss'
import { HiCheckCircle } from 'react-icons/hi'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  hasFixWidth?: boolean
}

const SuccessMessage = ({
  children,
  hasFixWidth = true
}: Props) =>
  <div
    className={
      hasFixWidth
        ? classes.SuccessMessage + ' ' + classes.hasFixWidth
        : classes.SuccessMessage
    }
    role='status'
  >
    <HiCheckCircle
      aria-hidden
      className={classes.HiCheckCircle}
      size='38px'
    />
    <strong>
      {children}
    </strong>
  </div>

export default SuccessMessage
