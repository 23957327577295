import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  InputText,
  SingleSelect
} from '@eggplant/pattern-library'
import { SUT_SERVICE } from '@eggplant/api-hosts'

interface Props {
  onChange: (value: string) => void
  value: string
  isRequired?: boolean
  isDisabled?: boolean
}

const getDataCenterOptions = (dataCenters: string[]) => dataCenters.map(dataCenter => ({
  label: dataCenter,
  value: dataCenter
}))

const SelectSauceLabDataCenter = ({
  onChange,
  value,
  isRequired = false,
  isDisabled = false
}: Props) => isDisabled // when disabled dont call endpoint to load and select the selected data center, directly display the value
  ? (
    <InputText
      customValidity=''
      isDisabled
      label='Data Center'
      onChange={() => {}}
      pattern={/.*/}
      placeholder=''
      value={value}
    />
  )
  : (
    <AuthenticatedDataRequest
      method='get'
      url={SUT_SERVICE + '/sauce_labs/data_centers'}
    >
      {
        (response: string[]) => // the response will alway has 3 data centers, so no need handle empty response
          <SingleSelect
            isDisabled={isDisabled}
            isRequired={isRequired}
            label='Data Center'
            onChange={onChange}
            options={getDataCenterOptions(response)}
            prompt='Select a Data Center'
            value={value}
          />
      }
    </AuthenticatedDataRequest>
  )

export default SelectSauceLabDataCenter
