import KeycloakPolling from './KeycloakPolling'
import TokenPolling from './TokenPolling/TokenPolling'
import { AuthConsumer } from '@eggplant/context'
import type {
  PollingProps
} from '../types'

const AuthenticatedPolling = <ResponseInterface, >(props: PollingProps<ResponseInterface>) =>
  <AuthConsumer>
    {
      ({ isKeycloakOn }) =>
        isKeycloakOn
          ? <KeycloakPolling<ResponseInterface> {...props} />
          : <TokenPolling<ResponseInterface> {...props} />
    }
  </AuthConsumer>

export default AuthenticatedPolling
