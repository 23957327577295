import Access from '../content/Access'
import IconCard from '../presentation/IconCard'
import { Artwork } from '@eggplant/pattern-library'
import { AuthConsumer } from '@eggplant/context'
import { LinkToManageUsers } from '@eggplant/routing'

const AccessIntro = () =>
  <IconCard
    artwork={<Artwork.Lock />}
    title='Access'
  >
    <Access />
    <br />
    <AuthConsumer>
      {
        ({ isAdmin }) =>
          isAdmin &&
            <LinkToManageUsers>
              Manage Users
            </LinkToManageUsers>
      }
    </AuthConsumer>
  </IconCard>

export default AccessIntro
