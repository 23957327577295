import Spinner from '../Spinner/Spinner'
import styles from './LoadingPlaceholder.module.scss'

const LoadingPlaceholder = () =>
  <div
    aria-busy
    aria-label='Loading'
    aria-live='polite'
    className={styles.LoadingPlaceholder}
    role='status'
  >
    <Spinner />
  </div>

export default LoadingPlaceholder
