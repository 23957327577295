import classes from './Overlay.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  variant?: 'purple' | 'default'
  isAbsoluteCenter?: boolean
}

const Overlay = ({
  children,
  variant = 'default',
  isAbsoluteCenter = true
}: Props) => {
  const centerAlignClass = isAbsoluteCenter
    ? ' ' + classes.AbsoluteCenter
    : ''

  const classname = classes.Overlay + ' ' + classes[variant] + centerAlignClass

  return (
    <div className={classname}>
      {children}
    </div>
  )
}

export default Overlay
