import { PostHogProvider } from 'posthog-js/react'

interface Props {
  token: string
  host: string
  children: JSX.Element
}

const TelemetryProvider = ({
  token,
  host,
  children
}: Props) => {
  const postHogOptions = {
    api_host: `https://${host}`
  }

  return (
    <PostHogProvider
      apiKey={token}
      options={postHogOptions}
    >
      {children}
    </PostHogProvider>
  )
}

export default TelemetryProvider
