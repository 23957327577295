import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'

const TotalNumberTestResultByStatus = () =>
  <div className='flex flex-col gap-4'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Total number of test results by status
    </Header>
    <Paragraph>
      The "Total Number” of test results by status link directs you to a Test Results page
      that displays results matching the selected status.
      Keep in mind that this page combines both test cases and exploratory runs under the "Type" column.
      As a result, the total count in the Test Results table may vary from the count shown in the Test Case
      Results by Status report.
    </Paragraph>
  </div>

export default TotalNumberTestResultByStatus
