import InputNumber from '../InputNumber/InputNumber'
import classes from './InputRange.module.scss'
import { FiAlertCircle } from 'react-icons/fi'
import type {
  CSSProperties,
  ChangeEvent
} from 'react'

interface Props {
  isDisabled?: boolean
  label: string
  max: number
  min: number
  onChange: (value: number) => void
  withInputNumber?: boolean
  value: number
}

interface RulesProps {
  test: (value: number) => boolean | undefined
  invalidMessage: string | undefined
}

const InputRange = ({
  isDisabled = false,
  label,
  max = 100,
  min = 1,
  onChange,
  withInputNumber,
  value = 1
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value)

    onChange(number)
  }

  const rules: RulesProps[] = [
    {
      test: (rangeValue: number) => isNaN(rangeValue),
      invalidMessage: 'Please enter a number.'
    },
    {
      test: (rangeValue: number) => rangeValue < min,
      invalidMessage: 'Please enter a number equal or greater than ' + min
    },
    {
      test: (rangeValue: number) => rangeValue > max,
      invalidMessage: 'Please enter a number equal or lesser than ' + max
    }
  ]

  const invalidRule = rules.find((rule: RulesProps) => rule.test(value))

  const isDisabledClass = isDisabled
    ? classes.Disabled
    : null

  const containerClasses = [
    classes.Layout,
    isDisabledClass
  ]
    .filter(cssClass => !!cssClass)
    .join(' ')

  const progressPercent = !isNaN(value)
    ? Math.abs(Math.round(((value - min) / (max - min)) * 100))
    : 50

  const progressStyle = { '--progress': `${progressPercent}%` } as CSSProperties

  return (
    <div className={classes.InputRange}>
      <div className={containerClasses}>
        {
          withInputNumber &&
            <InputNumber
              isDisabled={isDisabled}
              label={`${label} number input`}
              max={max}
              min={min}
              onChange={onChange}
              value={value}
            />
        }
        <span>{min}</span>
        <input
          aria-label={label}
          className={classes.RangeInput}
          disabled={isDisabled}
          max={max}
          min={min}
          onChange={handleChange}
          style={progressStyle}
          type='range'
          value={value}
        />
        <span>{max}</span>
      </div>
      {
        !!invalidRule && !isDisabled &&
          <div className={classes.ErrorMessage}>
            <FiAlertCircle
              aria-hidden
              className={classes.ErrorIcon}
              size='12px'
            />
            <span>{invalidRule.invalidMessage}</span>
          </div>
      }
    </div>
  )
}

export default InputRange
