import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  RUNHISTORY
} from '../routes'
import { PROP_TEST_CONFIG_ID } from '../constants'
import type { Props } from '../types'

const base = '/' + CONTROLLER + '/' + RUNHISTORY

interface Params {
  [PROP_TEST_CONFIG_ID]: string
}

const LinkToRunHistoryResults = ({
  children,
  [PROP_TEST_CONFIG_ID]: test_config_id,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base + '/' + test_config_id}
  >
    {children}
  </StyledLink>

export default LinkToRunHistoryResults
