import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox'
import classes from './MultiCheckbox.module.scss'
import type { OptionType } from '@eggplant/types'

interface Props {
  label?: string
  onChange: (value: string) => void
  options: OptionType[]
  selectedValueList: string[]
  isDisabled?: boolean
  title?: string
}

const MultiCheckbox = ({
  onChange,
  options,
  selectedValueList,
  label = '',
  isDisabled = false,
  title
}: Props) => {
  const labelId = label.replace(/ /g, '-')

  const mappedOptions = options.map(option => {
    const isSelected =
      !!selectedValueList.length &&
      selectedValueList.includes(option.value)

    const handleChange = () => {
      onChange(option.value)
    }

    return (
      <li
        aria-selected={isSelected}
        key={option.value}
        role='option'
      >
        <LabeledCheckbox
          isChecked={isSelected}
          isDisabled={isDisabled}
          label={option.label}
          onChange={handleChange}
          title={title}
        />
      </li>
    )
  })

  return (
    <div className={classes.MultiCheckbox}>
      <span>
        {label}
      </span>
      <ul
        aria-labelledby={labelId}
        aria-multiselectable
        role='listbox'
      >
        {mappedOptions}
      </ul>
    </div>
  )
}

export default MultiCheckbox
