import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  NOTIFICATIONS,
  TEST_CONFIG
} from '../routes'
import type { Props } from '../types'

interface Params {
  testConfigId: string
}

const LinkToTestConfigNotification = ({
  children,
  testConfigId,
  isHyperlink
}: Props & Params) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={`/${CONTROLLER}/${TEST_CONFIG}/${testConfigId}/${NOTIFICATIONS}`}
  >
    {children}
  </StyledLink>

export default LinkToTestConfigNotification
