import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { qaSummary } from '../../docs-routes'

const Dashboard = () =>
  <Paragraph>
    The Exploratory dashboard provides a high-level overview of your testing, presented in widget form.
    <br />
    <br />
    Most widgets appear blank until you've executed a model at least once. The exceptions are the Bug Hunting and Test Cases widgets, which have additional requirements. See the main documentation for specific guidance to use these widgets.
    <DocusaurusFooter path={qaSummary} />
  </Paragraph>

export default Dashboard
