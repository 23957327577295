import CustomRow from './CustomRow'
import classes from './Table.module.scss'
import * as RTable from 'reactabular-table' // Docs: https://reactabular.js.org/
import { useMemo } from 'react'
import type { ColumnProps } from '../types'
import type { ReactNode } from 'react'

interface Props {
  columns: ColumnProps[]
  isCompact?: boolean
  rowKey: string
  rows: any[]
  expandedRowList?: string[]
  selectedRowList?: string[]
  renderExpandedRowContent?: (rowId: number) => ReactNode
}

const columnMapper = (column: ColumnProps) => ({
  property: column.rowItemKey,
  header: {
    label: column.label,
    // Can be used to hijack TH element for sorting
    ...column.header
  },
  ...column
} as RTable.Column)

const customRenderers = {
  body: {
    row: CustomRow
  }
}

const Table = ({
  columns,
  rowKey,
  rows,
  expandedRowList,
  selectedRowList,
  renderExpandedRowContent,
  isCompact = false
}: Props) => {
  // Columns is an array, so it can change frequently
  // It is important to hoist `columns` in dependent components where possible
  const mappedColumns = useMemo(() => columns.map(columnMapper), [columns])

  const handleRow = (rowData: any) => {
    const rowId = rowData[rowKey]

    const hasExpandedChildren =
      renderExpandedRowContent &&
      expandedRowList &&
      expandedRowList.includes(rowId)

    const isSelected = selectedRowList && selectedRowList.includes(rowId)

    return {
      ...hasExpandedChildren && { expandedChildren: renderExpandedRowContent(rowId) },
      ...isSelected && { isSelected }
    }
  }

  const className = isCompact
    ? classes.Table + ' ' + classes.Compact
    : classes.Table

  return (
    <RTable.Provider
      className={className}
      columns={mappedColumns}
      renderers={customRenderers}
    >
      <RTable.Header />
      {
        rows.length > 0 &&
          <RTable.Body
            onRow={handleRow}
            rowKey={rowKey}
            rows={rows}
          />
      }
    </RTable.Provider>
  )
}

export default Table
