import { AccessControl } from '@eggplant/context'
import {
  Color,
  Header,
  Hyperlink,
  Paragraph
} from '@eggplant/pattern-library'
import {
  SETTINGS,
  SYSTEM
} from '@eggplant/routing'

const NotificationsEmptyState = () =>
  <>
    <Header
      hasNoTopMargin
      rank={4}
    >
      No notifications have been set up yet
    </Header>
    <AccessControl isHiddenIfDenied>
      <Paragraph>
        <Color variant='theme-description-text'>
          <span>
            After saving the test configuration, you can manage notifications here.<br />
            You can enable notifications in {' '}
            <Hyperlink
              href={`/${SYSTEM}/${SETTINGS}`}
              isExternal
            >System &gt; Settings &gt; Test config notifications
            </Hyperlink>
          </span>
        </Color>
      </Paragraph>
    </AccessControl>
  </>

export default NotificationsEmptyState
