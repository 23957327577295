import Tooltip from '../Tooltip/Tooltip'
import classes from './NavItemVerticalMini.module.scss'
import type { ReactNode } from 'react'

// Recieves a render prop and passes its own children back into it.
// This is because the Router can only supply link elements, rather than render props or hooks.

interface Props {
  children: (children: ReactNode) => ReactNode
  icon: ReactNode
  isCollapsed?: boolean
  label: string
}

const NavItemVerticalMini = ({
  isCollapsed = false,
  children: renderChildrenProp,
  icon,
  label
}: Props) =>
  <span
    className={
      isCollapsed
        ? classes.NavItemVerticalMini + ' ' + classes.isCollapsed
        : classes.NavItemVerticalMini
    }
    title={label}
  >
    {
      renderChildrenProp( // Renders an element that will receive the following elements as children
        <>
          {
            isCollapsed
              ? (
                <Tooltip label={label}>
                  {icon}
                </Tooltip>
              )
              : icon
          }
          <span className={classes.Label}>
            {label}
          </span>
        </>
      )
    }
  </span>

export default NavItemVerticalMini
