import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { coverageHeatmap } from '../../docs-routes'

const CoverageHeatmap = () =>
  <Paragraph>
    The Coverage Heatmap visualizes the coverage percentages of states and actions within a model.
    <DocusaurusFooter path={coverageHeatmap} />
  </Paragraph>

export default CoverageHeatmap
