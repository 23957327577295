import IconButton from '../IconButton/IconButton'
import classes from './SearchInput.module.scss'
import { FaSearch } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { useEffect, useRef } from 'react'
import type { FormEvent } from 'react'
import type { InputFieldType } from '@eggplant/types'

// A styled text input for use in Search fields
// Includes many potential props that can be passed by Downshift
interface Props {
  'aria-autocomplete'?: 'none' | 'inline' | 'list' | 'both'
  'aria-controls'?: string
  'aria-label'?: string
  'aria-labelledBy'?: string
  'aria-hasPopup'?: boolean // for Downshift
  autoComplete?: string
  fieldType?: InputFieldType
  id?: string
  onBlur?: (event: FormEvent) => void
  onChange: (event: FormEvent) => void
  onClear: () => void
  onKeyDown?: () => void
  onKeyUp?: () => void // Downshift
  onFocus?: () => void
  placeholder?: string
  value: string // Downshift
  isDisabled?: boolean
  isAutoFocus?: boolean
  hasSearchIcon?: boolean
}

const SearchInput = ({
  'aria-autocomplete': ariaAutocomplete = 'none',
  'aria-controls': ariaControls,
  'aria-labelledBy': ariaLabelledBy,
  'aria-label': ariaLabel = 'search',
  'aria-hasPopup': ariaHasPopup,
  autoComplete,
  fieldType = 'text',
  id,
  onBlur,
  onClear,
  onChange,
  onKeyDown,
  onKeyUp,
  onFocus,
  placeholder = 'Search...',
  isDisabled = false,
  isAutoFocus = false,
  hasSearchIcon = true,
  value
}: Props) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  const searchInputWrapperClass = isDisabled
    ? classes.SearchInput + ' ' + classes.Disabled
    : classes.SearchInput

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement
    const trimmedValue = target.value.trimStart()

    const newTarget = {
      ...target,
      value: trimmedValue
    }

    const newEvent = {
      ...event,
      target: newTarget
    }

    onChange(newEvent)
  }

  const handleOnBlur = (event: FormEvent) => {
    if (!onBlur) return

    const target = event.target as HTMLInputElement
    const trimmedValue = target.value.trim()

    const newTarget = {
      ...target,
      value: trimmedValue
    }

    const newEvent = {
      ...event,
      target: newTarget
    }

    onChange(newEvent)
    onBlur(newEvent)
  }

  useEffect(() => {
    if (isAutoFocus) {
      searchInputRef.current?.focus()
    }
  }, [isAutoFocus])

  return (
    <div className={searchInputWrapperClass}>
      {
        hasSearchIcon &&
          <span className={classes.LeadingIcon}>
            <FaSearch
              aria-hidden
              aria-label='searchIcon'
              size='16px'
            />
          </span>
      }
      <input
        aria-autocomplete={ariaAutocomplete}
        aria-controls={ariaControls}
        aria-haspopup={ariaHasPopup}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        autoComplete={autoComplete}
        disabled={isDisabled}
        id={id}
        min={
          fieldType === 'number'
            ? 1 // no point letting people select less than 1 with the spinner
            : undefined
        }
        onBlur={handleOnBlur} // Downshift
        onChange={handleOnChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        ref={searchInputRef}
        type={fieldType}
        value={value}
      />
      {
        value &&
          <span className={classes.ClearButtonn}>
            <IconButton
              onClick={onClear}
              title='Clear Search'
            >
              <IoClose aria-hidden />
            </IconButton>
          </span>
      }
    </div>
  )
}

export default SearchInput
