import InputPassword from '../InputPassword/InputPassword'
import InputText from '../InputText/InputText'
import classes from './TogglePassword.module.scss'
import {
  FaEye,
  FaEyeSlash
} from 'react-icons/fa'
import { useState } from 'react'

interface Props {
  isRequired?: boolean
  isDisabled?: boolean
  label?: string
  onChange: (value: string) => void
  value: string
  placeholder: string
}

const TogglePassword = ({
  isRequired = true,
  isDisabled = false,
  label,
  value,
  onChange,
  placeholder
}: Props) => {
  const [hidePassword, setHidePassword] = useState(true)

  const handleClick = () => {
    setHidePassword(!hidePassword)
  }

  return (
    <span className={classes.TogglePassword}>
      {
        hidePassword
          ? (
            <InputPassword
              isDisabled={isDisabled}
              isRequired={isRequired}
              label={label}
              onChange={onChange}
              placeholder={placeholder}
              value={value}
            />
          )
          : (
            <InputText
              customValidity=''
              isDisabled={isDisabled}
              isRequired={isRequired}
              label={label}
              onChange={onChange}
              pattern={/.*/}
              placeholder={placeholder}
              value={value}
            />
          )
      }
      <button
        aria-hidden
        onClick={handleClick}
        type='button'
      >
        {
          hidePassword
            ? <FaEye />
            : <FaEyeSlash />
        }
      </button>
    </span>
  )
}

export default TogglePassword
