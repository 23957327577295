import ChooseDate from '../ChooseDate/ChooseDate'
import ChooseTime from '../ChooseTime/ChooseTime'
import DateTime from '../DateTime/DateTime'
import DropdownButton from '../DropdownButton/DropdownButton'
import Header from '../Header/Header'
import PopOut from '../PopOut/PopOut'
import classes from './DateDropdown.module.scss'
import { FaCalendarAlt } from 'react-icons/fa'

interface Props {
  calendarHeader: string
  label?: string
  value: string
  isDisabled?: boolean
  isFilter?: boolean
  isPastAllowed?: boolean
  onChange: (value: string) => void
}

const DateDropdown = ({
  calendarHeader,
  label,
  value,
  isDisabled = false,
  isFilter = false,
  isPastAllowed = false,
  onChange
}: Props) => {
  const triggerDropdownButton = (ref: any, onClick: () => void) =>
    <DropdownButton
      Icon={FaCalendarAlt}
      aria-haspopup
      aria-label={label}
      isDisabled={isDisabled}
      isFilter={isFilter}
      onClick={onClick}
      ref={ref}
    >
      <DateTime value={value} />
    </DropdownButton>

  return (
    <PopOut trigger={triggerDropdownButton}>
      <div className={classes.CalanderDivStyle}>
        <Header rank={3}>
          {calendarHeader}
        </Header>
        <ChooseDate
          isPastAllowed={isPastAllowed}
          onChange={onChange}
          value={value}
        />
        <ChooseTime
          onChange={onChange}
          value={value}
        />
      </div>
    </PopOut>
  )
}

export default DateDropdown
