import DocusaurusFooter from '../DocusaurusFooter'
import { LinkToAddTestCase, useAccess } from '@eggplant/routing'
import { Paragraph } from '@eggplant/pattern-library'
import { testCases } from '../../docs-routes'

const TestCases = () => {
  const [isAccess] = useAccess()

  return (
    <Paragraph>
      {
        isAccess
          ? (
            <>
              Test Cases shows an overview of all the test cases you've configured.
              Here you can <LinkToAddTestCase>create a new test case</LinkToAddTestCase>,
              delete test cases, and export a list of your test cases in .csv format.
            </>
          )
          : 'Test Cases shows an overview of all the test cases you\'ve configured.'
      }
      <DocusaurusFooter path={testCases} />
    </Paragraph>
  )
}

export default TestCases
