import classes from './TestResultLogItem.module.scss'
import type {
  LogSeverity,
  LogStage
} from '@eggplant/types'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  severity?: LogSeverity
  stage?: LogStage
}

const TestResultLogItem = ({
  children,
  severity,
  stage
}: Props) => {
  const severityClass = (severity && classes[severity]) || ''

  const stageClass = (stage && classes[stage]) || ''

  const className = (
    classes.TestResultLogItem + ' ' + severityClass + ' ' + stageClass
  ).trim()

  return (
    <span className={className}>
      {children}
    </span>
  )
}

export default TestResultLogItem
