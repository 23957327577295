import ChooseHour from './ChooseHour'
import ChooseMeridian from './ChooseMeridian'
import ChooseMinute from './ChooseMinute'
import PropTypes from 'prop-types'
import Timezone from '../Timezone/Timezone'
import classes from './ChooseTime.module.scss'

const ChooseTime = ({
  isDisabled = false,
  onChange,
  value
}) => {
  const handleChange = date => {
    onChange(date.toISOString())
  }

  const date = new Date(value)

  return (
    <div className={classes.ChooseTime}>
      <ChooseHour
        isDisabled={isDisabled}
        onChange={handleChange}
        value={date}
      />
      {' '}
      <ChooseMinute
        isDisabled={isDisabled}
        onChange={handleChange}
        value={date}
      />
      {' '}
      <ChooseMeridian
        isDisabled={isDisabled}
        onChange={handleChange}
        value={date}
      />
      {' '}
      <Timezone value={value} />
    </div>
  )
}

ChooseTime.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default ChooseTime
