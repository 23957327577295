import type {
  HandleError,
  HandleSuccess,
  Headers,
  Method,
  Params,
  ResponseType
} from './types'

const stringifyValues = (params: Params) =>
  Object.keys(params).reduce<Record<string, string>>((accumulator, key) => ({
    ...accumulator,
    [key]: String(params[key])
  }), {})

const addParams = (
  urlString: string,
  params: Params
) => {
  const url = new URL(urlString)

  Object.keys(params)
    .forEach(key =>
      url.searchParams.append(key, params[key])
    )

  return url
}

const buildHeader = (
  method: string,
  isUrlEncoded: boolean
) => {
  const usesBody = method !== 'get' && method !== 'delete'

  return !isUrlEncoded && usesBody
    ? {
      'Content-Type': 'application/json' // otherwise it will default to text/plain
    }
    : undefined
}

const handleOkResponse = (responseType: ResponseType, response: Response) => {
  if (responseType === 'blob') return response.blob() // an uncaught error will crash

  if (responseType === 'text') return response.text()

  return response.json().catch(() => ({}))
}

// eslint-disable-next-line complexity
const getFetchArguments = (
  url: string,
  method: Method,
  config: RequestInit,
  params: Params | undefined | any[],
  isUrlEncoded: boolean
) => {
  const usesQueryString = method === 'get' || method === 'delete'

  const stringParams = params
    ? stringifyValues(params)
    : undefined

  const finalUrl = stringParams && usesQueryString
    ? addParams(url, stringParams)
    : url

  const finalConfig = usesQueryString
    ? config
    : {
      ...config,
      body: isUrlEncoded
        ? new URLSearchParams(stringParams) // automatically changes the content type header
        : JSON.stringify(params)
    }

  return [finalUrl, finalConfig]
}

const requestData = (
  baseUrl: string,
  method: Method,
  handleSuccess: HandleSuccess,
  handleError: HandleError,
  params: Params | undefined | any[],
  responseType: ResponseType,
  isUrlEncoded: boolean,
  extraHeaders?: Headers
) => {
  const headers = { ...extraHeaders, ...buildHeader(method, isUrlEncoded) }

  const isGet = method === 'get'

  // Only make a request cancellable if it is a GET
  const controller = isGet
    ? new AbortController()
    : undefined

  const baseConfig = {
    // only uppercase methods names are guaranteed acceptance
    method: method.toUpperCase(),
    headers,
    signal: controller && controller.signal
  }

  // Params can affect URL or config, so this method returns both
  const [url, config] = getFetchArguments(baseUrl, method, baseConfig, params, isUrlEncoded)

  const handleResponse = async (response: Response): Promise<void> =>
    // fetch will fail to parse empty responses
    response.ok
      ? handleSuccess(
        await handleOkResponse(responseType, response)
      )
      : handleError(
        await response.json().catch(() => ({}))
      )

  const catchFetchError = (error: { name: string; toString: () => any }) => {
    // Aborts are errors, even if intended
    if (error.name === 'AbortError') return

    handleError({
      status: 'Error',
      title: 'Network',
      detail: error.toString()
    })
  }

  fetch(url as RequestInfo, config as RequestInit)
    .then(handleResponse, catchFetchError)
    // using a catch here will swallow errors that happen after done

  // can be called to cancel the request upon component cleanup
  return controller
    ? () => { controller.abort() } // must be wrapped
    : () => {}
}

export default requestData
