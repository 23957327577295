import * as cookies from 'browser-cookies'
import {
  createContext,
  // useEffect, (for function that will be restored later)
  useState
} from 'react'
import type { ReactNode } from 'react'

const themes = [
  'eggplant',
  'eggplantDark'
] as const

export type themeType = typeof themes[number]

export interface ThemeContext {
  theme: themeType
  setTheme: (newTheme: themeType) => void
}

export const Theme = createContext<ThemeContext | undefined>(undefined)

const COOKIE_NAME = 'theme'

interface ProviderProps {
  children: ReactNode
  theme?: themeType
}

export const ThemeProvider = ({
  children,
  theme: themeProp
}: ProviderProps) => {
  const cookie = cookies.get(COOKIE_NAME) as (themeType | undefined)

  const initialTheme = themeProp || cookie || 'eggplant'

  const [theme, setTheme] = useState<themeType>(initialTheme)

  const updateCookie = (newTheme?: themeType) => {
    if (newTheme) {
      cookies.set(COOKIE_NAME, newTheme)
    } else {
      cookies.erase(COOKIE_NAME)
    }
  }

  const updateTheme = (newTheme: themeType) => {
    updateCookie(newTheme)
    setTheme(newTheme)
  }

  // // Restore this function when Dark Mode is more proven
  // useEffect(() => {
  //   const handleColorPreference = () => {
  //     const newTheme = window.confirm('Set preferred theme to Dark Mode?')
  //       ? 'eggplantDark'
  //       : 'eggplant'

  //     updateTheme(newTheme)

  //     window.alert('Theme can be changed in System page.')
  //   }

  //   // For tests and weird browsers
  //   if (!window.matchMedia) return () => {}

  //   const match = window.matchMedia('(prefers-color-scheme: dark)')

  //   if (!cookie) handleColorPreference()

  //   // This only detects on change?
  //   match.addEventListener('change', handleColorPreference)

  //   return () => {
  //     match.removeEventListener('change', handleColorPreference)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []) // run on every mount/unmount

  if (theme !== cookie) updateCookie(theme)

  const value = {
    theme,
    setTheme: updateTheme
  }

  return (
    <Theme.Provider value={value}>
      {children}
    </Theme.Provider>
  )
}

interface ConsumerProps {
  children: (context: ThemeContext) => React.ReactNode
}

export const ThemeConsumer = ({
  children: renderChildren
}: ConsumerProps) =>
  <Theme.Consumer>
    {
      (context?: ThemeContext) => {
        if (context === undefined) {
          throw new Error('<ThemeConsumer must be used within <ThemeProvider')
        }

        return renderChildren(context)
      }
    }
  </Theme.Consumer>
