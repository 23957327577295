import { AI_API_SERVICE } from '@eggplant/api-hosts'
import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Card,
  Header,
  Paragraph,
  TagsInput
} from '@eggplant/pattern-library'
import { LinkToAddEnvironment } from '@eggplant/routing'
import type { CSSProperties } from 'react'
import type {
  EnvironmentResponse,
  Tag
} from '@eggplant/types'

interface Props {
  values: string[]
  onChange: (value: Tag[]) => void
  isRequired: boolean
  isDisabled?: boolean
}

const cardSpacingStyle: CSSProperties = {
  marginBottom: '.75em',
  textAlign: 'center',
  whiteSpace: 'pre'
}

const renderNoExecutionEnvironmentPlaceholder = (isRequired: boolean) => {
  const requiredText = isRequired
    ? 'Android/IOS connection required an environment.\n'
    : ''

  return (
    <Card>
      <div style={cardSpacingStyle}>
        <Header rank={5}>No environment found</Header>
        <Paragraph>
          {requiredText}
          <LinkToAddEnvironment>
            Create an Execution Environment.
          </LinkToAddEnvironment>
        </Paragraph>
      </div>
    </Card>
  )
}

const defaultTag = {
  id: '',
  name: '',
  color: 'var(--filter-icon)'
} as Tag

// API returns EnvironmentResponse[] and parent returns string[]
const getTags = (data: EnvironmentResponse[] | string[]) => data.map(
  item => (
    {
      ...defaultTag,
      name: typeof item === 'string'
        ? item
        : item.name
    }
  ) as Tag
)

const ExecutionEnvironmentInput = ({
  values,
  onChange,
  isRequired,
  isDisabled = false
}: Props) =>
  <AuthenticatedDataRequest
    method='get'
    url={AI_API_SERVICE + '/agents'}
  >
    {
      (data: EnvironmentResponse[]) => data.length > 0 || getTags(values).length
        ? (
          <TagsInput
            availableTags={getTags(data)}
            isDisabled={isDisabled}
            isNewItemAllowed={false}
            isRequired={isRequired}
            label='Execution Environment'
            maxLength={50}
            onSelectedTagsChanged={onChange}
            placeholder='Click and choose an Execution Environment'
            selectedTags={getTags(values)}
          />
        )
        : renderNoExecutionEnvironmentPlaceholder(isRequired)

    }
  </AuthenticatedDataRequest>

export default ExecutionEnvironmentInput
