import {
  FieldLabel,
  Fieldset,
  InputPassword,
  LabeledRadio,
  TogglePassword
} from '@eggplant/pattern-library'
import { radioHorizontalWrapperStyle } from '@eggplant/layout-css'
import type {
  CSSProperties,
  ReactNode
} from 'react'

interface Props {
  isDisabled?: boolean
  isReadonly: boolean
  label: string
  onChange: (value?: string) => void
  value: string | undefined | null // not using value? ensures that the prop is always specified
  /** Is this the first time the password is being set? */
  isNew: boolean
  /** Can the password be empty? */
  isPasswordRequired: boolean
  description?: ReactNode
  isRequired?: boolean
}

const wrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1em'
}

const ProtectedPassword = ({
  onChange,
  isDisabled = false,
  isReadonly,
  isPasswordRequired,
  isNew,
  value: valueProp,
  label = 'Password',
  description,
  isRequired = false
}: Props) => {
  if (isReadonly) {
    return (
      <Fieldset
        isRequired={isRequired}
        label={label}
      >
        <InputPassword
          isDisabled={isDisabled}
          isReadOnly
          label={label}
          placeholder=''
          value='xxxxxxxx'
        />
      </Fieldset>
    )
  }

  const value = valueProp === null
    ? '' // a nullified password is represented by an empty field
    : valueProp

  const passwordField =
    <TogglePassword
      isDisabled={isDisabled}
      isRequired={isPasswordRequired}
      label={label}
      onChange={onChange}
      placeholder=''
      value={value ?? ''}
    />

  const passwordFieldWithLabel =
    <FieldLabel
      description={description}
      isRequired={isRequired}
      label={label}
    >
      {passwordField}
    </FieldLabel>

  if (isNew) return passwordFieldWithLabel

  const handleRetainCheck = () => {
    onChange(undefined)
  }

  const handleChangeCheck = () => {
    onChange('')
  }

  const hasValue = typeof value === 'string'

  return (
    <Fieldset
      description={description}
      isRequired
      label={label}
    >
      <div style={wrapperStyle}>
        <div style={radioHorizontalWrapperStyle}>
          <LabeledRadio
            isChecked={!hasValue}
            isDisabled={isDisabled}
            label={`Retain ${label} setting`}
            onChange={handleRetainCheck}
          />
          <LabeledRadio
            isChecked={hasValue}
            isDisabled={isDisabled}
            label={`Update ${label}`}
            onChange={handleChangeCheck}
          />
        </div>
        {
          hasValue && passwordField
        }
      </div>
    </Fieldset>
  )
}

export default ProtectedPassword
