import classes from './ColorBlock.module.scss'

interface Props {
  color: string
}

const ColorBlock = (prop: Props) =>
  <div
    className={classes.ColorBlock}
    style={{
      background: prop.color
    }}
  />

export default ColorBlock
