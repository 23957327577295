import { AI_API_SERVICE } from '@eggplant/api-hosts'
import { AuthenticatedPolling } from '@eggplant/data-request'
import { GroupedSingleSelect } from '@eggplant/pattern-library'
import type {
  Agent,
  GetAgentsParams
} from '@eggplant/types'
import type { Props } from '../interfaces'

const params = {
  agent_type: 'DESIGN'
} as GetAgentsParams

const url = AI_API_SERVICE + '/agents'

const agentMapper = ({ id, name, isconnected }: Agent) => ({
  label: name,
  value: id.toString(),
  isDisabled: !isconnected
})

const getGroupOptions = (agents: Agent[]) => ['Connected', 'Disconnected'].map(group => ({
  label: group,
  options: agents
    .filter(({ isconnected }: Agent) => isconnected === (group === 'Connected'))
    .map(agentMapper)
}))

const getValue = (agents: Agent[], selectedAgentId: string) => {
  const agent = agents.find(agent => agent.id === Number(selectedAgentId))

  return agent?.isconnected
    ? agent.id.toString()
    : ''
}

interface CustomProps extends Omit<Props, 'onChange'> {
  onChange: (agent?: Agent) => void
}

const SelectDesignEnvironment = ({
  label,
  onChange,
  value
}: CustomProps) => {
  const getHandleChange = (agents: Agent[]) =>
    (agentId: string) => {
      const agent = agents.find(agentOption =>
        agentOption.id.toString() === agentId
      )

      if (!agent) return

      onChange(agent)
    }

  return (
    <AuthenticatedPolling<Agent[]>
      intervalMs={5000}
      isPolling
      params={params}
      url={url}
    >
      {
        data =>
          <GroupedSingleSelect
            groups={getGroupOptions(data)}
            isRequired
            label={label}
            onChange={getHandleChange(data)}
            prompt='Select Environment'
            value={getValue(data, value)}
          />
      }
    </AuthenticatedPolling>
  )
}

export default SelectDesignEnvironment
