import classes from './Combobox.module.scss'
import { FiX } from 'react-icons/fi'
import { InvisiButton } from '../..'

interface Props {
  onClear: () => void
}

const ClearButton = ({ onClear }: Props) => (
  <InvisiButton
    hasHoverEffect
    label='Clear'
    onClick={onClear}
  >
    <div className={classes.clearWrapper}>
      <FiX
        aria-hidden
        size={16}
      />
      Clear
    </div>
  </InvisiButton>
)

export default ClearButton
