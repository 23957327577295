import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { gitConnectionSetup } from '../../docs-routes'

const GitConnectionSetup = () =>
  <Paragraph>
    Adding a connection to your Git repository allows you to access your project's suite(s) from DAI.
    <DocusaurusFooter path={gitConnectionSetup} />
  </Paragraph>

export default GitConnectionSetup
