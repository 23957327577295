import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import {
  HiOutlineDownload,
  HiPlus
} from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import {
  LinkToAddModel,
  LinkToImportModel
} from '@eggplant/routing'
import { LinkToModelsDoc } from '../links'
import { Model } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = 'Eggplant DAI models are simple visual representations of the application, interface, or website that you want to test. You can create a new model or import a model using a .json file.'

const renderButton = () =>
  <AccessControl isHiddenIfDenied>
    <span className='inline-flex items-center'>
      <LinkButton label='Import Model'>
        <LinkToImportModel>
          <HiOutlineDownload
            aria-hidden
            size='19.2px'
          /> Import Model
        </LinkToImportModel>
      </LinkButton>
      <LinkButton
        label='New Model'
        variant='primary'
      >
        <LinkToAddModel>
          <HiPlus
            aria-hidden
            size='19.2px'
          /> New Model
        </LinkToAddModel>
      </LinkButton>
    </span>
  </AccessControl>

const ModelsIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Model />}
        renderLink={<LinkToModelsDoc />}
        title='Model'
      />
    </div>
  </div>

export default ModelsIntro
