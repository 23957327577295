import classes from './Pre.module.scss'
import type { ReactNode } from 'react'

// Simple wrapper that applies themed color

interface Props {
  children: ReactNode
}

const Pre = ({ children }: Props) =>
  <pre className={classes.Pre}>
    {children}
  </pre>

export default Pre
