import classes from './ColorCard.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  borderVariance: 'lightPurple' | 'darkPurple' | 'blue' | 'darkBlue' | 'lightBlue' | 'lightGray' | 'orange' | 'violet' | 'green' | 'red'
}

const ColorCard = ({
  children,
  borderVariance
}: Props) =>
  <div className={classes.ColorCard + ' ' + classes[borderVariance]}>
    {children}
  </div>

export default ColorCard
