import ReactModal from 'react-modal'
import classes from './CentralModal.module.scss'
import type { ReactNode } from 'react'

// These are for pairing the role=dialog of react-modal
// with accessible description content.
const aria = {
  labelledby: 'modal-description',
  describedby: 'modal-label'
}

interface Props {
  children: ReactNode
  isOpen: boolean
  label: string // for now, this is purely for accessability,
  onRequestClose: () => void
}

const CentralModal = ({
  children,
  isOpen,
  label,
  onRequestClose
}: Props) =>
  <ReactModal
    aria={aria}
    ariaHideApp={false}
    className={classes.CentralModal}
    contentLabel={label}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    overlayClassName={classes.CentralModalOverlay}
  >
    {children}
  </ReactModal>

export default CentralModal
