const NoAvailableScript = () =>
  <>
    To make a new script available,
    <br />
    please refresh this page. We recommend saving
    <br />
    your test case first to prevent
    <br />
    the loss of your changes.
  </>

export default NoAvailableScript
