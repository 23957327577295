import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { SUT_SERVICE } from '@eggplant/api-hosts'
import { TagsInput } from '@eggplant/pattern-library'
import type { GetTagsResponse } from '@eggplant/types'

interface Tag {
  id: string
  color: string
  name: string
}

type SelectedCriteriaType = string[]

interface Props {
  onChange: (critera: SelectedCriteriaType) => void
  selectedCriteria: SelectedCriteriaType
  isDisabled?: boolean
}

const getSelectedTags = (items: Tag[], selectedCriteria: SelectedCriteriaType) =>
  items.filter((tag: Tag) =>
    selectedCriteria.includes(tag.id)
  )

const SutCriteria = ({
  onChange,
  selectedCriteria,
  isDisabled = false
}: Props) => {
  const handleChange = (criteria: Tag[]) => {
    onChange(
      criteria.map(({ id }) => id) // strip off the extra properties
    )
  }

  return (
    <AuthenticatedDataRequest
      method='get'
      url={SUT_SERVICE + '/criteria'}
    >
      {
        (data: GetTagsResponse) =>
          <div>
            <TagsInput
              availableTags={data.items}
              isDisabled={isDisabled}
              isNewItemAllowed={false}
              isRequired
              maxLength={50}
              onSelectedTagsChanged={handleChange}
              placeholder='Select criteria tags'
              selectedTags={getSelectedTags(data.items, selectedCriteria)}
            />
          </div>
      }
    </AuthenticatedDataRequest>
  )
}

export default SutCriteria
