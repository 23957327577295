import LinkToDocusaurus from '@eggplant/embedded-documentation/src/lib/components/LinkToDocusaurus'
import { Paragraph } from '@eggplant/pattern-library'
import { suts } from '@eggplant/embedded-documentation/src/lib/docs-routes'

const SutConfigIosDeviceHelp = () =>
  <Paragraph>
    Learn more about <LinkToDocusaurus path={suts}>iOS device SUT</LinkToDocusaurus> in our DAI documentation
  </Paragraph>

export default SutConfigIosDeviceHelp
