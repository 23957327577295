import type { CSSProperties } from 'react'

export const pageHeaderTitleWrapperStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: '32px'
}

export const pageHeaderRowStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '16px 12px',
  padding: '12px 16px'
}

export const pageWrapperStyle: CSSProperties = {
  height: '100%',
  display: 'grid',
  gridAutoRows: 'minmax(0, auto) minmax(0, 1fr)', // header, content
  rowGap: '12px'
}

export const pageHeaderLeadingWrapperStyle: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  gap: '16px 12px',
  flexWrap: 'wrap'
}

export const pageHeaderTailingWrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  columnGap: '8px',
  justifyContent: 'end',
  alignItems: 'center'
}

export const navRowWrapperStyle: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  flexFlow: 'row',
  columnGap: '12px',
  justifyContent: 'space-between',
  padding: '12px 16px'
}

export const pageHeaderDividerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center'
}

export const radioHorizontalWrapperStyle: CSSProperties = {
  display: 'grid',
  columnGap: '8px',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr 1fr' // overwrite this if more than 2 children
}

export const radioVerticalWrapperStyle: CSSProperties = {
  display: 'flex',
  rowGap: '4px',
  flexDirection: 'column',
  justifyContent: 'left'
}

export const pageHeaderDividerHeightStyle: CSSProperties = {
  height: '20px'
}
