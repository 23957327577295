// Each package could have its own .env.something file that uses REACT_APP_ variables
// but dependecy env variables are ignored and the consumer will overwrite them
// this will not be fixed in React: https://github.com/facebook/create-react-app/issues/4654

// Instead, a different set of variables will be provided based on the NODE_ENV
// This also helps align multiple packages that connect to the same service
// while reducing the number of ENV files.

// Fetch requires an exact address. NGINX will serve the UI and services from the same port.
const sameOrigin = window.location.origin

// This allows testing request interception without CORS issues.
const test = 'http://localhost'

// REACT_APP_GARDEN_HOST can be set when starting the router
const gardenHost = process.env.REACT_APP_GARDEN_HOST

const devHost = gardenHost
  ? 'https://' + gardenHost
  : sameOrigin

const basePaths = {
  AI_API_SERVICE: '/ai',
  SUT_SERVICE: '/sut_service/api/v1',
  API_COMPOSER_SERVICE: '/composer/api/v1',
  TASK_SERVICE: '/task_scheduler_service/api/v1',
  TEST_CONFIG_SERVICE: '/test_config_service/api/v2',
  RUBY_API_SERVICE: '/ttguiSinatra',
  VAM_SERVICE: '/asset_manager/api/v1',
  EXECUTION_SERVICE: '/execution_service/api/v1',
  MODELER_SERVICE: '/modeler_service/api/v2',
  API_CLIENTS_SERVICE: '/api_clients_service/api/v1',
  ACL_SERVICE: '/access_control/api/v1',
  NOTIFICATION_SERVICE: '/notifications_service/api/v1',
  USER_PREFERENCES_SERVICE: '/user_preferences/v1'
}

const hosts = {
  development: {
    DOJO_UI: devHost,
    AI_API_SERVICE: devHost + basePaths.AI_API_SERVICE,
    SUT_SERVICE: devHost + basePaths.SUT_SERVICE,
    TASK_SERVICE: devHost + basePaths.TASK_SERVICE,
    TEST_CONFIG_SERVICE: devHost + basePaths.TEST_CONFIG_SERVICE,
    API_COMPOSER_SERVICE: devHost + basePaths.API_COMPOSER_SERVICE,
    RUBY_API_SERVICE: devHost + basePaths.RUBY_API_SERVICE,
    TOGGLES: devHost,
    VAM_SERVICE: devHost + basePaths.VAM_SERVICE,
    EXECUTION_SERVICE: devHost + basePaths.EXECUTION_SERVICE,
    MODELER_SERVICE: devHost + basePaths.MODELER_SERVICE,
    API_CLIENTS_SERVICE: devHost + basePaths.API_CLIENTS_SERVICE,
    ACL_SERVICE: devHost + basePaths.ACL_SERVICE,
    NOTIFICATION_SERVICE: devHost + basePaths.NOTIFICATION_SERVICE,
    USER_PREFERENCES_SERVICE: devHost + basePaths.USER_PREFERENCES_SERVICE
  },
  production: {
    DOJO_UI: devHost,
    AI_API_SERVICE: sameOrigin + basePaths.AI_API_SERVICE,
    SUT_SERVICE: sameOrigin + basePaths.SUT_SERVICE,
    TASK_SERVICE: sameOrigin + basePaths.TASK_SERVICE,
    TEST_CONFIG_SERVICE: sameOrigin + basePaths.TEST_CONFIG_SERVICE,
    API_COMPOSER_SERVICE: sameOrigin + basePaths.API_COMPOSER_SERVICE,
    RUBY_API_SERVICE: sameOrigin + basePaths.RUBY_API_SERVICE,
    TOGGLES: sameOrigin,
    VAM_SERVICE: sameOrigin + basePaths.VAM_SERVICE,
    EXECUTION_SERVICE: sameOrigin + basePaths.EXECUTION_SERVICE,
    MODELER_SERVICE: sameOrigin + basePaths.MODELER_SERVICE,
    API_CLIENTS_SERVICE: sameOrigin + basePaths.API_CLIENTS_SERVICE,
    ACL_SERVICE: sameOrigin + basePaths.ACL_SERVICE,
    NOTIFICATION_SERVICE: sameOrigin + basePaths.NOTIFICATION_SERVICE,
    USER_PREFERENCES_SERVICE: sameOrigin + basePaths.USER_PREFERENCES_SERVICE
  },
  test: {
    DOJO_UI: test,
    AI_API_SERVICE: test,
    SUT_SERVICE: test,
    TASK_SERVICE: test,
    TEST_CONFIG_SERVICE: test,
    API_COMPOSER_SERVICE: test,
    RUBY_API_SERVICE: test,
    TOGGLES: test,
    VAM_SERVICE: test,
    EXECUTION_SERVICE: test,
    MODELER_SERVICE: test,
    API_CLIENTS_SERVICE: test,
    ACL_SERVICE: test,
    NOTIFICATION_SERVICE: test,
    USER_PREFERENCES_SERVICE: test
  }
}

const env = process.env.NODE_ENV as keyof typeof hosts

export const AI_API_SERVICE = hosts[env].AI_API_SERVICE
export const SUT_SERVICE = hosts[env].SUT_SERVICE
export const TASK_SERVICE = hosts[env].TASK_SERVICE
export const TEST_CONFIG_SERVICE = hosts[env].TEST_CONFIG_SERVICE
export const API_COMPOSER_SERVICE = hosts[env].API_COMPOSER_SERVICE
export const RUBY_API_SERVICE = hosts[env].RUBY_API_SERVICE
export const TOGGLES = hosts[env].TOGGLES
export const VAM_SERVICE = hosts[env].VAM_SERVICE
export const EXECUTION_SERVICE = hosts[env].EXECUTION_SERVICE
export const MODELER_SERVICE = hosts[env].MODELER_SERVICE
export const DOJO_UI = hosts[env].DOJO_UI
export const API_CLIENTS_SERVICE = hosts[env].API_CLIENTS_SERVICE
export const ACL_SERVICE = hosts[env].ACL_SERVICE
export const NOTIFICATION_SERVICE = hosts[env].NOTIFICATION_SERVICE
export const USER_PREFERENCES_SERVICE = hosts[env].USER_PREFERENCES_SERVICE
