import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'

const TestConfigStepFailureDescription = () =>
  <>
    <Header
      hasNoTopMargin
      rank={4}
    >
      Continue after failure
    </Header>
    <Paragraph>
      When a test step fails, by default, the test config proceeds to the remaining steps
      to run to completion.
    </Paragraph>
    <br />
    <Paragraph>
      Disabling this option stops a test config run when a test step fails,
      or after any reruns when reruns are enabled.
    </Paragraph>
  </>

export default TestConfigStepFailureDescription
