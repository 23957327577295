import { parse } from 'query-string'
import { validateMockedRoles } from '../getTestContext'
import type Keycloak from 'keycloak-js'
import type {
  KeycloakError,
  KeycloakInitOptions,
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
  KeycloakProfile,
  KeycloakPromise,
  KeycloakRegisterOptions
} from 'keycloak-js'
import type { Query } from '../types'

const token = 'A random string that is non zero length'

const userProfile = {
  username: 'test',
  email: 'test@keysight.com',
  firstName: 'Test',
  lastName: 'User'
}

const realmAccess = { roles: ['user'] }

const tokenParsed = {
  realm_access: {
    roles: ['dai:user']
  }
}

const adminTokenParsed = {
  realm_access: {
    roles: ['dai:admin']
  }
}

const getTokenParsed = () => {
  const { mockedRoles } = parse(window.location.search) as Query

  const roles = mockedRoles?.split(',')

  const hasValidMockedRole = validateMockedRoles(roles)

  if (!hasValidMockedRole) return adminTokenParsed

  return {
    realm_access: {
      roles: roles! // after validateMockedRoles roles will not undefiend or empty string
    }
  }
}

const authClient: Keycloak = {
  authenticated: true,
  authServerUrl: 'http://some-auth-server.com/auth/',
  hasRealmRole () {
    return true
  },
  hasResourceRole () {
    return true
  },
  idToken: token,
  profile: userProfile,
  realm: 'eggplant',
  realmAccess,
  refreshToken: token,
  token,
  tokenParsed,
  idTokenParsed: getTokenParsed(),

  init (_initOptions: KeycloakInitOptions): KeycloakPromise<boolean, KeycloakError> {
    throw new Error('Function not implemented.')
  },
  login (_options?: KeycloakLoginOptions | undefined): KeycloakPromise<void, void> {
    throw new Error('Function not implemented.')
  },
  logout (_options?: KeycloakLogoutOptions | undefined): KeycloakPromise<void, void> {
    throw new Error('Function not implemented.')
  },
  register (_options?: KeycloakRegisterOptions | undefined): KeycloakPromise<void, void> {
    throw new Error('Function not implemented.')
  },
  accountManagement (): KeycloakPromise<void, void> {
    throw new Error('Function not implemented.')
  },
  createLoginUrl (_options?: KeycloakLoginOptions | undefined): string {
    throw new Error('Function not implemented.')
  },
  createLogoutUrl (_options?: KeycloakLogoutOptions | undefined): string {
    throw new Error('Function not implemented.')
  },
  createRegisterUrl (_options?: KeycloakRegisterOptions | undefined): string {
    throw new Error('Function not implemented.')
  },
  createAccountUrl (): string {
    throw new Error('Function not implemented.')
  },
  isTokenExpired (_minValidity?: number | undefined): boolean {
    throw new Error('Function not implemented.')
  },
  updateToken (_minValidity: number): KeycloakPromise<boolean, boolean> {
    throw new Error('Function not implemented.')
  },
  clearToken (): void {
    throw new Error('Function not implemented.')
  },
  loadUserProfile (): KeycloakPromise<KeycloakProfile, void> {
    throw new Error('Function not implemented.')
  },
  loadUserInfo (): KeycloakPromise<object, void> {
    throw new Error('Function not implemented.')
  }
}

export default { initialized: true, keycloak: authClient }
