import { Router as ReachRouter } from '@reach/router'
import { useLayoutEffect } from 'react'
import type {
  CSSProperties,
  ReactNode
} from 'react'

interface Props {
  children: ReactNode
  id?: string
  isFlex?: boolean
}

const style: CSSProperties = {
  height: '100%',
  position: 'relative' // For proper <Overlay/> size
}

// There is probably a better way to do this.
// The problem is that ReachRouter creates a div, but it does not allow any children without path props
// So it can mess up a flex layout
const flexStyle: CSSProperties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}

const Router = ({
  children,
  id,
  isFlex = false
}: Props) => {
  useLayoutEffect(() => window.scrollTo(0, 0))

  return (
    <ReachRouter
      id={id}
      style={
        isFlex
          ? flexStyle
          : style
      }
    >
      {children}
    </ReachRouter>
  )
}

export default Router
