import { HiSearch } from 'react-icons/hi'
import { NoOptionsPlaceholder } from '@eggplant/pattern-library'

const unmatchedTunnelMessage =
'You will only see the active or open tunnels in DAI. For information' +
'\nabout tunnels you expect to see in DAI but don\'t, check the Tunnels' +
'\npage in Sauce Labs.'

const InputSauceLabUnmatchedState = () =>
  <NoOptionsPlaceholder
    icon={
      <HiSearch
        aria-hidden
        size='20px'
      />
    }
    title={unmatchedTunnelMessage}
  />

export default InputSauceLabUnmatchedState
