import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  NoOptionsPlaceholder
} from '@eggplant/pattern-library'
import { HiOutlineDocument } from 'react-icons/hi'
import { LinkToAddTestConfig } from '@eggplant/routing'
import { TEST_CONFIG_SERVICE } from '@eggplant/api-hosts'
import type {
  ButtonShape,
  GetTestConfigsResponse,
  Options,
  TestConfigOutput
} from '@eggplant/types'

interface Props {
  initialOptions?: {
    label: string
    value: string
  }[]
  isRequired?: boolean
  onChange: (value: string) => void
  placeholder?: string
  value: string
}

const url = TEST_CONFIG_SERVICE + '/test_configurations'

const getOptions = (items: TestConfigOutput[]) =>
  items.map(({
    name,
    id
  }) => ({
    label: name,
    value: id
  }))

const SelectTestConfig = ({
  initialOptions = [],
  isRequired = false,
  onChange,
  placeholder = 'Test config',
  value
}: Props) => {
  const renderButton = (options: Options) => {
    const currentOption = options.find(item => item.value === value)

    const label = currentOption?.value
      ? currentOption.label
      : placeholder

    const tooltip = currentOption?.value
      ? `Test config: ${currentOption.label}`
      : ''

    const buttonText = currentOption?.value
      ? currentOption.label
      : placeholder

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={HiOutlineDocument}
        isFilter
        isHighlighted={!!value}
        label={label}
        title={tooltip}
      >
        {buttonText}
      </DropdownButton>
  }

  const noDataPlaceholder =
    <NoOptionsPlaceholder
      icon={<HiOutlineDocument size='24px' />}
      linkTo={
        <LinkToAddTestConfig>
          Go to Test Configs
        </LinkToAddTestConfig>
      }
      title='No test config found'
    />

  return (
    <AuthenticatedDataRequest
      method='get'
      shortErrorMessage='Failed to load Test config'
      url={url}
    >
      {
        (data: GetTestConfigsResponse) =>
          <Combobox
            initialOptions={initialOptions}
            isFilter
            isRequired={isRequired}
            noOptionsPlaceholder={noDataPlaceholder}
            onChange={onChange}
            options={getOptions(data.items)}
            renderButton={renderButton(getOptions(data.items))}
            value={value}
          />
      }
    </AuthenticatedDataRequest>
  )
}

export default SelectTestConfig
