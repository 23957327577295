import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  NoOptionsPlaceholder
} from '@eggplant/pattern-library'
import { FiPlus } from 'react-icons/fi'
import { HiOutlineClipboardCheck } from 'react-icons/hi'
import { LinkToAddTestCase, useAccess } from '@eggplant/routing'
import { MODELER_SERVICE } from '@eggplant/api-hosts'
import type {
  ButtonShape,
  GetFilterIdListResponse,
  Options
} from '@eggplant/types'

interface Props {
  onChange: (value: string) => void
  placeholder?: string
  value?: string
}

const url = MODELER_SERVICE + '/test_case_names'

const getOptions = (items: GetFilterIdListResponse[] | undefined) => {
  if (!Array.isArray(items)) return []

  return items.map(item => ({
    label: item.name,
    value: item.id_list.join(',')
  }))
}

const SelectModelSuiteTestCases = ({
  onChange,
  placeholder = 'Test case',
  value
}: Props) => {
  const [isAccessible] = useAccess()

  const renderButton = (options: Options) => {
    const currentOption = value
      ? options.find(item => item.value.includes(value))
      : undefined

    const label = currentOption?.value
      ? currentOption.label
      : placeholder

    const tooltip = currentOption?.value
      ? `Test case: ${currentOption.label}`
      : label

    const buttonText = currentOption?.value
      ? currentOption.label
      : placeholder

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={HiOutlineClipboardCheck}
        isFilter
        isHighlighted={!!value}
        label={label}
        title={tooltip}
      >
        {buttonText}
      </DropdownButton>
  }

  const noDataPlaceholder =
    <NoOptionsPlaceholder
      icon={<HiOutlineClipboardCheck size='24px' />}
      linkTo={
        isAccessible
          ? (
            <LinkToAddTestCase>
              <FiPlus
                aria-hidden
                size={16}
              />
              New Test Case
            </LinkToAddTestCase>
          )
          : undefined
      }
      title='No test cases found'
    />

  return (
    <div className='flex'>
      <AuthenticatedDataRequest
        method='get'
        shortErrorMessage='Failed to load Test case'
        url={url}
      >
        {
          (data: GetFilterIdListResponse[]) =>
            <Combobox
              isFilter
              noOptionsPlaceholder={noDataPlaceholder}
              onChange={onChange}
              options={getOptions(data)}
              renderButton={renderButton(getOptions(data))}
              value={value}
            />
        }
      </AuthenticatedDataRequest>
    </div>
  )
}

export default SelectModelSuiteTestCases
