import { Color, Paragraph } from '@eggplant/pattern-library'

const DisagreeCookiesDescription = () =>
  <Paragraph>
    <Color variant='black'>
      By allowing data analytics, you allow us to collect and analyze anonymous usage data and use of cookies to enhance your user experience.
    </Color>
  </Paragraph>

export default DisagreeCookiesDescription
