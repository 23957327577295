import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { VAM_SERVICE } from '@eggplant/api-hosts'
import type { GitConnection } from '@eggplant/types'
import type { ReactElement } from 'react'

interface Props {
  gitConnectionId: string
  children?: (data: GitConnection) => ReactElement
  onSuccess?: (data: GitConnection) => void
}

const GetGitConnectionRequest = ({ gitConnectionId, children, onSuccess }: Props) => {
  const url = VAM_SERVICE + '/repositories/' + gitConnectionId

  return (
    <AuthenticatedDataRequest
      children={children}
      method='get'
      onSuccess={onSuccess}
      url={url}
    />
  )
}

export default GetGitConnectionRequest
