import StyledLink from '../StyledLink'
import makeQueryString from './makeUrl/makeUrl'
import {
  DESIGNER,
  TESTCASES
} from '../routes'
import type { Props } from '../types'

interface Params {
  model_id?: string
}

const base = `/${DESIGNER}/${TESTCASES}`

const LinkToTestCases = ({
  children,
  label,
  isHyperlink,
  ...params
}: Props & Params) =>
  <StyledLink
    aria-label={label}
    isHyperlink={isHyperlink}
    to={makeQueryString(base, { ...params as Params })}
  >
    {children}
  </StyledLink>

export default LinkToTestCases
