import AbortRun from './AbortRun'
import {
  Button,
  CentralModal
} from '@eggplant/pattern-library'
import { FaStop } from 'react-icons/fa'
import { useState } from 'react'

interface Props {
  execution_id: string
}

const AbortExecutionButton = ({
  execution_id
}: Props) => {
  const [isRequestingCancellation, setIsRequestingCancellation] = useState(false)

  const handleAbortButtonClick = () => {
    setIsRequestingCancellation(true)
  }

  // Once the button is clicked it will be replaced by the request
  // The button should be restored depending on the re-render cycle of the parent

  const handleModalClose = () => {
    setIsRequestingCancellation(false)
  }

  const handleSuccess = () => {
    setTimeout(handleModalClose, 1500)
  }

  return isRequestingCancellation
    ? (
      <CentralModal
        isOpen
        label='Cancel a Live Run'
        onRequestClose={handleModalClose}
      >
        <AbortRun
          execution_id={execution_id}
          onCancel={handleModalClose}
          onSuccess={handleSuccess}
        />
      </CentralModal>
    )
    : (
      <Button
        aria-label='Abort'
        onClick={handleAbortButtonClick}
        type='button'
        variant='danger'
      >
        <FaStop aria-hidden />
        {' '}
        Abort
      </Button>
    )
}

export default AbortExecutionButton
