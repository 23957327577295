import StyledLink from '../StyledLink'
import {
  COVERAGE,
  INSIGHTS
} from '../routes'
import type { Props } from '../types'

const LinkToCoverage = ({
  children,
  isHyperlink,
  label
}: Props) =>
  <StyledLink
    aria-label={label}
    isHyperlink={isHyperlink}
    to={'/' + INSIGHTS + '/' + COVERAGE}
  >
    {children}
  </StyledLink>

export default LinkToCoverage
