import { Redirect as ReachRedirect } from '@reach/router'

interface Props {
  to: string
}

const Redirect = ({ to }: Props) =>
  <ReachRedirect
    noThrow
    to={to}
  />

export default Redirect
