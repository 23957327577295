import classes from './ResultsFooter.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const ResultsFooter = ({
  children
}: Props) =>
  <div className={classes.ResultsFooter}>
    {children}
  </div>

export default ResultsFooter
