import Radio from '../Radio/Radio'
import classes from './LabeledRadioWithInputNumber.module.scss'
import type { ReactElement } from 'react'

interface Props {
  children: ReactElement
  group: string
  isChecked: boolean
  isDisabled?: boolean
  label: string
  onChange: (value: boolean) => void
}

const LabeledRadioWithInputNumber = ({
  children,
  group,
  isChecked = false,
  isDisabled = false,
  label,
  onChange
}: Props) => {
  const uniqueLabel = label + group // to identify each label with same name
  const labelPairingId = uniqueLabel.toLowerCase().replace(/ /g, '-') // remove all spaces

  const handleChange = () => {
    onChange(!isChecked)
  }

  const getClasses = () => {
    const baseClass = classes.LabeledRadioWithInputNumber

    const isCheckedClass = isChecked
      ? classes.isChecked
      : ''

    const isDisabledClass = isDisabled
      ? classes.Disabled
      : ''

    const isCheckedIsDisabled = isChecked && isDisabled
      ? classes.isCheckedAndIsDisabled
      : ''

    const finalClasses = [
      baseClass,
      isCheckedClass,
      isDisabledClass,
      isCheckedIsDisabled
    ].filter(classname => classname)

    return finalClasses.join(' ')
  }

  const handleInputClick = () => {
    if (children.props.isReadOnly) {
      handleChange()
    }
  }

  return (
    <label
      className={getClasses()}
      htmlFor={labelPairingId}
    >
      <span className={classes.buttonWrapper}>
        <Radio
          id={labelPairingId}
          isChecked={isChecked}
          isDisabled={isDisabled}
          name={group}
          onChange={handleChange}
        />
        {label}
      </span>
      <span className={classes.labelWrapper}>
        {
          // only for Input and Paragraph usage for now, to refactor in future if have better approach
          <children.type
            {...children.props}
            onClick={() => handleInputClick()}
          />
        }
      </span>
    </label>
  )
}

export default LabeledRadioWithInputNumber
