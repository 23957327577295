import classes from './Hyperlink.module.scss'
import { FiExternalLink } from 'react-icons/fi'
import type {
  ReactNode
} from 'react'

interface Props {
  children: ReactNode
  href: string
  isInlineLink?: boolean
  isExternal?: boolean
}

const Hyperlink = ({
  children,
  href,
  isExternal = false,
  isInlineLink = false
}: Props) => {
  const textClassName = isInlineLink
    ? ' ' + classes.InlineLink
    : ''

  const hyperlinkClassName = classes.Hyperlink + textClassName

  return isExternal
    ? (
      <a
        className={hyperlinkClassName}
        href={href}
        rel='noopener noreferrer'
        target='_blank'
      >
        <FiExternalLink
          aria-hidden
        />
        {children}
      </a>
    )
    : (
      <a
        className={hyperlinkClassName}
        href={href}
      >
        {children}
      </a>
    )
}

// For use by @eggplant/routing
export const primaryHyperlinkClass = classes.Hyperlink
export const secondaryHyperlinkClass = classes.SecondaryHyperlink

export default Hyperlink
