import StyledLink from '../StyledLink'
import {
  DESIGNER,
  MODEL_HUB
} from '../routes'
import type { Props } from '../types'

interface Params {
  id: string
}

const LinkToModelPreview = ({
  children,
  id,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={`/${DESIGNER}/${MODEL_HUB}/${id}`}
  >
    {children}
  </StyledLink>

export default LinkToModelPreview
