import { Color, Header, Hyperlink, Paragraph } from '@eggplant/pattern-library'
import type { CSSProperties } from 'react'

const formWrapperStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'rows',
  rowGap: '24px'
}

const analyticHyperlink =
  <Hyperlink
    href='https://www.keysight.com/go/privacy'
    isExternal
  >
    About Privacy Policy
  </Hyperlink>

const DataCollectionAgreement = () =>
  <div style={formWrapperStyle}>
    <Header rank={1}>Keysight diagnostic and usage data</Header>
    <Paragraph>
      We care about your experience! Help us enhance our products and services by sending diagnostic & usage data. We use cookies to collect diagnostics and usage data to enhance and improve our products and services. Thank you for helping us create a better user experience for you.
    </Paragraph>
    <Paragraph>
      You can always disable this feature later in the app settings.
    </Paragraph>
    <Paragraph>
      <Color variant='black'>
        <span>
          By clicking accept you agree to the diagnostics & usage data collection and use of cookies. Read {analyticHyperlink}.
        </span>
      </Color>
    </Paragraph>
  </div>

export default DataCollectionAgreement
