import LinkToDocusaurus from '../LinkToDocusaurus'
import { BsInfoCircle } from 'react-icons/bs'
import { environments } from '../../docs-routes'

const LinkToEnvironmentsDoc = () =>
  <span>
    <BsInfoCircle
      aria-hidden
      size={13}
      style={{ paddingRight: '0.5em' }}
    />
    <LinkToDocusaurus path={environments}>
      Execution Environment user guide
    </LinkToDocusaurus>
  </span>

export default LinkToEnvironmentsDoc
