import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { GetTogglesRequest } from '@eggplant/api-requests'
import { USER_PREFERENCES_SERVICE } from '@eggplant/api-hosts'
import {
  createContext,
  useState
} from 'react'
import type { GetDataCollectionResponse, TogglesResponse } from '@eggplant/types'
import type { ReactNode } from 'react'

interface DataCollectionContext {
  isUserReportingEnabled?: boolean
  isDataCollectionAllowed?: boolean
}

const DataCollection = createContext<DataCollectionContext | undefined>(undefined)

interface ProviderProps {
  children: ReactNode
  isUserReportingEnabled?: boolean
  isDataCollectionAllowed?: boolean
}

export const DataCollectionProvider = ({
  children,
  isUserReportingEnabled: isUserReportingEnabledProp,
  isDataCollectionAllowed: isDataCollectionAllowedProp
}: ProviderProps) => {
  const [isToggleRequested, setIsToggleRequested] = useState(false)
  const [isPreferenceRequested, setIsPreferenceRequested] = useState(false)

  const [isUserReportingEnabled, setIsUserReportingEnabled] = useState<boolean|undefined>(isUserReportingEnabledProp)
  const [isDataCollectionAllowed, setIsDataCollectionAllowed] = useState<boolean|undefined>(isDataCollectionAllowedProp)

  const handleToggleSuccess = ({
    deployment_flags
  }: TogglesResponse) => {
    setIsUserReportingEnabled(deployment_flags.ENABLE_USAGE_REPORTING)
    setIsToggleRequested(true)
  }

  const handleToggleError = () => {
    setIsUserReportingEnabled(undefined)
    setIsToggleRequested(true)
  }

  const handleDataCollectionSuccess = ({
    data_collection_allowed
  }: GetDataCollectionResponse) => {
    setIsDataCollectionAllowed(data_collection_allowed)
    setIsPreferenceRequested(true)
  }

  const handleDataCollectionError = () => {
    setIsDataCollectionAllowed(undefined)
    setIsPreferenceRequested(true)
  }

  const value = {
    isUserReportingEnabled,
    isDataCollectionAllowed
  }

  return !isToggleRequested || !isPreferenceRequested
    ? (
      <>
        <GetTogglesRequest
          onError={handleToggleError}
          onSuccess={handleToggleSuccess}
        />
        <AuthenticatedDataRequest
          method='get'
          onError={handleDataCollectionError}
          onSuccess={handleDataCollectionSuccess}
          url={USER_PREFERENCES_SERVICE + '/data_collection'}
        />
      </>
    )
    : (
      <DataCollection.Provider value={value}>
        {children}
      </DataCollection.Provider>
    )
}

interface ConsumerProps {
  children: (context: DataCollectionContext) => React.ReactNode
}

export const DataCollectionConsumer = ({
  children: renderChildren
}: ConsumerProps) =>
  <DataCollection.Consumer>
    {
      (context?: DataCollectionContext) => {
        if (context === undefined) {
          throw new Error('<DataCollectionConsumer /> must be used within <DataCollectionProvider />')
        }

        return renderChildren(context)
      }
    }
  </DataCollection.Consumer>
