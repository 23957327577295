import Downshift from 'downshift'
import classes from './TruncatedItemList.module.scss'
import { Children } from 'react'
import type { GetItemPropsOptions } from 'downshift'
import type { ReactNode } from 'react'

// TODO: this is not really a combobox, the content is always available to the user

interface Props {
  children: ReactNode
  expandedLabel?: ReactNode
  maxCollapsedWidth?: number
  maxExpandedWidth?: number
}

const renderChild = (child: ReactNode, index: number, getItemProps: (options: GetItemPropsOptions<any>) => any) =>
  <div
    {
      ...getItemProps({
        item: child,
        index,
        key: index
      })
    }
  >
    {child}
  </div>

const TruncatedItemList = ({
  expandedLabel = 'Hide',
  children,
  maxCollapsedWidth,
  maxExpandedWidth
}: Props) =>
  <Downshift>
    {({
      getToggleButtonProps,
      getMenuProps,
      getItemProps,
      isOpen
    }) =>
      <div
        className={classes.TruncatedItemList}
        style={{ maxWidth: maxCollapsedWidth }}
      >
        <button
          type='button'
          {...getToggleButtonProps()}
          title={
            isOpen
              ? 'Collapse'
              : 'Expand'
          }
        >
          {
            isOpen
              ? expandedLabel
              : '\u2026' // ellipses
          }
        </button>
        <div
          {...getMenuProps()}
          style={{ maxWidth: maxExpandedWidth }}
          tabIndex={-1} // prevent focus trap when items are not visible
        >
          {
            Children.map(
              children,
              (child, index) =>
                renderChild(child, index, getItemProps)
            )
          }
        </div>
      </div>}
  </Downshift>

export default TruncatedItemList
