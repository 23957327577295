import {
  ChartEmptyState,
  Paragraph
} from '@eggplant/pattern-library'

const ScreenshotsEmptyState = () =>
  <ChartEmptyState
    title='No screenshots found'
  >
    <Paragraph>
      Screenshots are captured automatically in test runs if there are any failures detected. To enable screenshots, please add the relevant scripts to the suite.
    </Paragraph>
  </ChartEmptyState>

export default ScreenshotsEmptyState
