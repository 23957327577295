import { AuthConsumer } from '../AuthContext'
import { defaultAllowedRoles } from './constants'
import type { ReactElement, ReactNode } from 'react'
import type { Role } from '../types'

interface Base {
  allowedRoles?: Role[]
  children: ReactElement | string // string type is use for lable
}

interface UnhideUnaccessibleProps {
  isHiddenIfDenied?: false
  placeholder?: never
}

interface HideUnaccessibleProps {
  isHiddenIfDenied: true
  placeholder?: ReactNode | string
}

type Props = Base & (UnhideUnaccessibleProps | HideUnaccessibleProps)

const AccessControl = ({
  allowedRoles = defaultAllowedRoles,
  isHiddenIfDenied = false,
  children,
  placeholder
}: Props) =>
  <AuthConsumer>
    {
      ({ accessibleRoles }) => {
        if (!accessibleRoles?.length) return children // cypress will not have any user role, when this happen just return children to pass cypress

        const isAccessible = accessibleRoles.some(accessibleRole =>
          allowedRoles.includes(accessibleRole)
        )

        if (isAccessible) return children

        if (isHiddenIfDenied) return placeholder || null

        if (typeof children === 'string') return children

        const disabledProps = {
          isDisabled: true
        }

        return (
          <children.type
            {...children.props}
            {...disabledProps}
          />
        )
      }
    }
  </AuthConsumer>

export default AccessControl
