import AuthenticatedDataRequest from '../AuthenticatedDataRequest/AuthenticatedDataRequest'
import fileDownload from 'js-file-download'
import type {
  DataRequestProps,
  FileResponse
} from '../types'

interface Props extends Omit<DataRequestProps, 'method'> {
  fileType: 'text/csv' | 'application/zip' | 'text/ini'
  filename: string
  method?: 'get' | 'post'
  onSuccess: () => void
  url: string
}

const AuthenticatedFileDownload = ({
  filename,
  fileType,
  method = 'get',
  params,
  onSuccess,
  url
}: Props) => {
  const handleSuccess = async (data: FileResponse) => {
    // This is not a promise but it does use a timeout internally
    await fileDownload(data, filename)

    onSuccess()
  }

  const extraHeaders = {
    Accept: fileType
  }

  return (
    <AuthenticatedDataRequest
      extraHeaders={extraHeaders}
      method={method}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSuccess={handleSuccess}
      params={params}
      responseType='blob'
      url={url}
    />
  )
}

export default AuthenticatedFileDownload
