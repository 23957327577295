import Tooltip from '../Tooltip/Tooltip'
import classes from './SingleSelect.module.scss'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import { Paragraph } from '../..'
import { useState } from 'react'
import type { ChangeEvent } from 'react'
import type { OptionType } from '@eggplant/types'

// onBlur is required for accesability but it can cause duplicate actions
// https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-onchange.md#no-onchange

const {
  SingleSelect: base,
  Option: optionClass,
  isSolid: solid,
  isInvalid: invalid,
  isHighlighted: highlighted,
  InvalidInfo: invalidInfo
} = classes

interface Props {
  formId?: string
  isDisabled?: boolean
  isHighlight?: boolean
  isInvalid?: boolean
  isRequired?: boolean
  isSolid?: boolean
  label?: string
  onChange: (value: any) => void
  options: OptionType[]
  prompt?: string
  // eslint-disable-next-line react/no-unused-prop-types
  tooltip?: string
  // eslint-disable-next-line react/no-unused-prop-types
  invalidMessage?: string
  value?: string
}

// eslint-disable-next-line complexity
const SingleSelectInner = ({
  isDisabled,
  isHighlight = false,
  isInvalid,
  isSolid,
  formId,
  isRequired,
  label,
  onChange,
  options,
  prompt,
  value
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setIsOpen(false)

    if (value === event.target.value) return

    onChange(event.target.value)
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const optionsContainValue = options.some(item => item.value === value)

  const newValue = optionsContainValue
    ? value
    : ''

  const isDropDownOpen = isOpen
    ? ' ' + classes.ArrowUp
    : ' ' + classes.ArrowDown

  const withHighlighted = isHighlight
    ? ' ' + highlighted
    : ''

  const withSolid = isSolid
    ? ' ' + solid
    : ''

  const withInvalid = isInvalid
    ? ' ' + invalid
    : ''

  const className = base + isDropDownOpen + withSolid + withInvalid + withHighlighted

  return (
    <select
      aria-label={label}
      className={className}
      disabled={isDisabled}
      form={formId}
      onBlur={handleChange}
      onChange={handleChange}
      onMouseDown={handleClick} // onClick triggers multiple clicks after a value from dropdown is clicked
      required={isRequired}
      value={newValue}
    >
      {
        !newValue && // If no value is given, show a placeholder
          <option
            disabled
            value=''
          >
            {prompt}
          </option>
      }
      {
        options.map(option =>
          <option
            className={
              isSolid
                ? optionClass
                : ''
            }
            disabled={option.isDisabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        )
      }
    </select>
  )
}

const SingleSelect = ({
  formId,
  isDisabled = false,
  isInvalid = false,
  isRequired = false,
  isSolid = false,
  isHighlight = false,
  label,
  onChange,
  options,
  prompt,
  value,
  tooltip,
  invalidMessage = ''
}: Props) => {
  const singleSelect =
    <>
      <SingleSelectInner
        formId={formId}
        isDisabled={isDisabled}
        isHighlight={isHighlight}
        isInvalid={isInvalid}
        isRequired={isRequired}
        isSolid={isSolid}
        label={label}
        onChange={onChange}
        options={options}
        prompt={prompt}
        value={value}
      />
      {
        isInvalid &&
          <div className={invalidInfo}>
            <HiOutlineExclamationCircle aria-hidden />
            <Paragraph>
              {invalidMessage}
            </Paragraph>
          </div>
      }
    </>

  return (
    tooltip
      ? (
        <Tooltip label={tooltip}>
          {singleSelect}
        </Tooltip>
      )
      : singleSelect
  )
}

export default SingleSelect
