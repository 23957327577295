import DateTime from '../DateTime/DateTime'
import classes from './LockDescription.module.scss'

interface Props {
  reason: string
  startTime: string
  user: string
}

const LockDescription = ({
  reason,
  user,
  startTime
}: Props) =>
  <div className={classes.LockDescription}>
    Locked for {reason} by {user} at
    {' '}
    <DateTime value={startTime} />
  </div>

export default LockDescription
