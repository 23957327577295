import queryString from 'query-string'

type QueryRecord = Record<string, string | number | undefined>

const makeUrl = (base: string, params: QueryRecord) => {
  const stringParams = queryString.stringify(params)

  return stringParams.length
    ? base + '?' + stringParams
    : base
}

export default makeUrl
