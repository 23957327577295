import { Header, Hyperlink, Paragraph } from '@eggplant/pattern-library'
import type { CSSProperties, ReactNode } from 'react'

interface SubsectionProps {
  label: string
  children: ReactNode
}

const wrapperStyle: CSSProperties = {
  display: 'grid',
  rowGap: '16px'
}

const subSectionWrapper: CSSProperties = {
  display: 'grid',
  rowGap: '2px'
}

const Section = ({
  label,
  children
}: SubsectionProps) =>
  <div style={subSectionWrapper}>
    <Header
      hasBorderBottom={false}
      hasNoTopMargin
      rank={5}
    >
      {label}
    </Header>
    <Paragraph>{children}</Paragraph>
  </div>

const Subsection = ({
  label,
  children
}: SubsectionProps) =>
  <div style={subSectionWrapper}>
    <Paragraph>
      <strong>
        {label}
      </strong>
    </Paragraph>
    <Paragraph>
      {children}
    </Paragraph>
  </div>

const CronDescriptions = () =>
  <div style={wrapperStyle}>
    <Header
      hasBorderBottom={false}
      hasNoTopMargin
      rank={4}
    >
      What is Cron syntax?
    </Header>
    <Paragraph>
      Cron is a commonly used scheduling tool and syntax. In DAI, you can use cron syntax to specify when test configs run. You can use it to tailor your scheduled runs to your specific requirements.
    </Paragraph>
    <Section label='How to write Cron syntax'>
      A cron expression is a string with five fields, separated by white space, representing a set of times. For instance, '0 0 * * 0-4' signifies a schedule executing at midnight from Monday to Friday. See more examples below:
    </Section>
    <Subsection label='Every day at midnight:'>
      0 0 * * *
    </Subsection>
    <Subsection label='Every hour:'>
      0 * * * *
    </Subsection>
    <Subsection label='Every Monday at 3:30 PM:'>
      30 15 * * 0
    </Subsection>
    <Subsection label='Every day at 9:15 AM and 9:15 PM:'>
      15 9,21 * * *
    </Subsection>
    <Subsection label='Every weekday at 5:30 PM:'>
      30 17 * * 0-4
    </Subsection>
    <Paragraph>
      <Hyperlink
        href='https://en.wikipedia.org/wiki/Cron'
        isExternal
      >
        Learn more on Cron syntax on Wikipedia
      </Hyperlink>
    </Paragraph>
  </div>

export default CronDescriptions
