import StyledLink from '../StyledLink'
import {
  ADD,
  API_ACCESS,
  SYSTEM
} from '../routes'
import type { Props } from '../types'

const LinkToAddApiAccess = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + SYSTEM + '/' + API_ACCESS + '/' + ADD}
  >
    {children}
  </StyledLink>

export default LinkToAddApiAccess
