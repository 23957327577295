import HelpBanner from '../HelpBanner/HelpBanner'
import InfoButton from '../InfoButton/InfoButton'
import PopOut from '../PopOut/PopOut'
import classes from './HelpPopOut.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  title?: ReactNode
}

const triggerInfoButton = (ref: any, onClick: () => void) =>
  <InfoButton
    onClick={onClick}
    ref={ref}
    withTooltips={false} // when info button is placed at the bottom, visibility of tooltips will cause vertical scrollbar to be visible and then the button is hard to click
  />

const HelpPopOut = ({
  children,
  title
}: Props) =>
  <PopOut
    trigger={triggerInfoButton}
  >
    <div className={classes.HelpPopOut}>
      {
        title &&
          <HelpBanner>
            <div className={classes.Banner}>
              {title}
            </div>
          </HelpBanner>
      }
      <div className={classes.Content}>
        {children}
      </div>
    </div>
  </PopOut>

export default HelpPopOut
