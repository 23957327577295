import classes from './LockIndicator.module.scss'
import {
  FaLock,
  FaLockOpen
} from 'react-icons/fa'

interface Props {
  isLocked: boolean
}

const LockIndicator = ({
  isLocked = false
}: Props) => {
  const text = isLocked
    ? 'Locked'
    : 'Unlocked'

  const IconComponent = isLocked
    ? FaLock
    : FaLockOpen

  const iconClass = isLocked
    ? classes['icon-locked']
    : classes['icon-unlocked']

  return (
    <span
      aria-label={text}
      className={classes.LockIndicator}
      title={text}
    >
      <IconComponent
        aria-hidden
        className={iconClass}
        size='1.25em'
      />
    </span>
  )
}

export default LockIndicator
