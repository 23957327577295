import Header from '../Header/Header'
import classes from './PageTitle.module.scss'
import type { ReactNode } from 'react'

interface Props {
  title: string
  children?: ReactNode
}

const PageTitle = ({
  title,
  children
}: Props) =>
  <div className={classes.PageTitle}>
    <Header rank={2}>
      {title}
    </Header>
    {
      children && children
    }
  </div>

export default PageTitle
