import StyledLink from '../StyledLink'
import {
  DESIGNER,
  MODEL_HUB
} from '../routes'
import type { Props } from '../types'

const base = '/' + DESIGNER + '/' + MODEL_HUB

interface Params {
  modelUuid: string
}

const LinkToSuiteAssociation = ({
  children,
  modelUuid,
  isHyperlink
}: Props & Params) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base + '/' + modelUuid + '/associate'}
  >
    {children}
  </StyledLink>

export default LinkToSuiteAssociation
