import styles from './TestCaseBreakDownChart.module.scss'
import { Bar } from 'react-chartjs-2'
import {
  BarElement,
  CategoryScale,
  Chart,
  LinearScale
} from 'chart.js'
import type { ChartOptions } from 'chart.js'

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement)

interface Props {
  labels: string[]
  directedPasses: number[]
  directedFails: number[]
}

const generateChartData = (
  labels: string[],
  directedPasses: number[],
  directedFails: number[]
) => ({
  labels,
  datasets: [
    {
      label: 'Directed Runs Pass',
      backgroundColor: styles.colorpassdirect,
      data: directedPasses
    },
    {
      label: 'Directed Runs Fail',
      backgroundColor: styles.colorfaildirect,
      data: directedFails
    }
  ]
})

const getDataLength = (dataSet: number[]) =>
  dataSet.length
    ? dataSet.reduce((sum, num) => sum + num)
    : 0

const TestCaseBreakDownChart = ({
  labels,
  directedPasses,
  directedFails
}: Props) => {
  const flexHeightByLabelsLength = 2 * labels.length + 5 // each bar sample size = 2em, add 5em for charts's legend and x-axis label

  const dataLength = getDataLength(directedPasses) + getDataLength(directedFails)

  const chartOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    aspectRatio: 1,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      }
    },
    scales: {
      // eslint-disable-next-line id-length
      x: {
        beginAtZero: true,
        stacked: true,
        title: {
          text: 'Number of Runs',
          display: true
        },
        ticks: {
          // when tick is <= 5, it will change the things into list of decimal
          stepSize: dataLength <= 5
            ? 1
            : undefined
        }
      },
      // eslint-disable-next-line id-length
      y: {
        stacked: true
      }
    }
  }

  return (
    <div
      className={styles.chartContainerStyle}
      style={{ height: flexHeightByLabelsLength.toString() + 'em' }}
    >
      <Bar
        data={
          generateChartData(
            labels,
            directedPasses,
            directedFails)
        }
        options={chartOptions}
      />
    </div>
  )
}

export default TestCaseBreakDownChart
