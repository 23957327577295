import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  ENVIRONMENTS
} from '../routes'
import type { Props } from '../types'

interface Params {
  environmentId: number
}

const LinkToEnvironment = ({
  children,
  environmentId,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + CONTROLLER + '/' + ENVIRONMENTS + '/' + environmentId}
  >
    {children}
  </StyledLink>

export default LinkToEnvironment
