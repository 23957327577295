import { Hyperlink } from '@eggplant/pattern-library'
import {
  gitConnectionSync,
  managingSuites
} from '../../docs-routes'

interface ExternalLinkProps {
  path: string
  label: string
}

const ExternalLink = ({
  path,
  label
}: ExternalLinkProps) =>
  <Hyperlink
    href={`http://docs.eggplantsoftware.com/${path}/`}
    isExternal
    isInlineLink
  >
    {label}
  </Hyperlink>

const ScriptedTestCaseNoScript = () =>
  <span>
    If your new script is not listed above, please
    sync your suite with DAI via {' '}
    <ExternalLink
      label='Git'
      path={'dai/' + gitConnectionSync}
    /> {' '}or {' '}
    <ExternalLink
      label='DAI internal storage'
      path={'dai/' + managingSuites}
    />,
    then refresh this page.
    To add new scripts, please see  {' '}
    <ExternalLink
      label='Creating Scripts with Eggplant Functional'
      path='studio/epf-creating-tests-with-eggplant-functional'
    />.
    Save your test case beforehand to avoid losing changes.
  </span>

export default ScriptedTestCaseNoScript
