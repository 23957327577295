import classes from './Divider.module.scss'

type colorVariant = 'themeDefault' | 'green' | 'purple'

interface HorizontalProps {
  isVertical?: false
  isCenter?: boolean
  variant?: colorVariant
}

interface VerticalProps {
  isVertical: true
  isCenter?: never
  variant?: colorVariant
}

type Props = HorizontalProps | VerticalProps

const Divider = ({
  isVertical = false,
  isCenter = false,
  variant = 'themeDefault'
}: Props) => {
  const modeClass = isVertical
    ? ' ' + classes.Vertical
    : ' ' + classes.Horizontal

  const centerClass = isCenter
    ? ' ' + classes.CenterAlign
    : ''

  const classname = classes.Divider + modeClass + centerClass

  return (
    <span className={classname}>
      <hr className={classes[variant]} />
    </span>
  )
}

export default Divider
