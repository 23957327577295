import { Hyperlink } from '@eggplant/pattern-library'
import type * as docsRoutes from '../docs-routes'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  path?: typeof docsRoutes[keyof typeof docsRoutes] | ''
}

/**
* Must reference a defined path to a Docusaurus page.
*/
const LinkToDocusaurus = ({
  children,
  path = ''
}: Props) =>
  <Hyperlink
    href={'/docs/' + path}
    isExternal
  >
    {children}
  </Hyperlink>

export default LinkToDocusaurus
