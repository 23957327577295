import { Hyperlink } from '@eggplant/pattern-library'
import { useKeycloak } from '@eggplant/context'
import type { Props } from '../types'

const LinkToManageUsers = ({
  children
}: Props) => {
  const { keycloak: keycloakInstance } = useKeycloak()

  return (
    <Hyperlink
      href={keycloakInstance.authServerUrl + 'admin/' + keycloakInstance.realm + '/console/#/' + keycloakInstance.realm + '/users'}
      isExternal
    >
      {children}
    </Hyperlink>
  )
}

export default LinkToManageUsers
