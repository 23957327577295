import { Paragraph } from '@eggplant/pattern-library'

const IosProvisionDescription = () =>
  <Paragraph>
    Enter your provisioning profile here. Provisioning profiles are authorized documents issued by Apple
    that allow your signed applications to run on specific devices. They complement the digital signature
    provided by your Apple-issued developer certificate. You can manage provisioning profiles through the
    Apple Developer Portal, Xcode, or third-party tools like Fastlane.
  </Paragraph>

export default IosProvisionDescription
