import {
  Artwork,
  Card,
  Header
} from '@eggplant/pattern-library'
import type {
  CSSProperties,
  ReactNode
} from 'react'

const wrapperStyle: CSSProperties = {
  padding: '1.5em'
}

const cardStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'column',
  padding: '1em',
  gridTemplateColumns: 'auto 1fr'
}

interface Props {
  artwork?: ReactNode
  children: ReactNode
  title: string
}

const artworkStyle: CSSProperties = {
  height: '120px',
  width: '120px'
}

const IconCard = ({
  artwork = <Artwork.Path aria-hidden />,
  children,
  title
}: Props) =>
  <Card>
    <div style={cardStyle}>
      <div style={artworkStyle}>
        {artwork}
      </div>
      <div style={wrapperStyle}>
        <Header rank={3}>
          {title}
        </Header>
        <br />
        {children}
      </div>
    </div>
  </Card>

export default IconCard
