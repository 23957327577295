import IconCard from '../presentation/IconCard'
import MyAccount from '../content/MyAccount'
import { Artwork } from '@eggplant/pattern-library'
import { LinkToKeycloakMyAccount } from '@eggplant/routing'

const MyAccountIntro = () =>
  <IconCard
    artwork={<Artwork.Account />}
    title='My Account'
  >
    <MyAccount />
    <br />
    <LinkToKeycloakMyAccount>
      Account Settings
    </LinkToKeycloakMyAccount>
  </IconCard>

export default MyAccountIntro
