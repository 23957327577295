import classes from './DefinitionList.module.scss'
import { Fragment } from 'react'
import type { ReactNode } from 'react'

type VariantType = 'bordered' | 'below' | 'beside'

interface Props {
  children: Record<string, string | ReactNode>
  variant: VariantType
}

const DefinitionList = ({
  children,
  variant
}: Props) =>
  <dl className={classes.DefinitionList + ' ' + classes[variant]}>
    {
      Object.entries(children).map(([key, value]) =>
        <Fragment key={key}>
          <dt>{key}:&nbsp;</dt>
          <dd>{value}</dd>
        </Fragment>
      )
    }
  </dl>

export default DefinitionList
