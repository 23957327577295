import classes from './SubnavItem.module.scss'
import type { ReactNode } from 'react'

// Recieves a render prop and passes its own children back into it.
// This is because the Router can only supply link elements, rather than render props or hooks.

interface Props {
  children: (child: ReactNode) => ReactNode
  label: string
}

const SubnavItem = ({
  children: renderChildrenProp,
  label
}: Props) =>
  <span className={classes.SubnavItem}>
    {
      renderChildrenProp(
        <span>
          {label}
        </span>
      )
    }
  </span>

export default SubnavItem
