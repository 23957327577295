import classes from './PollingIndicator.module.scss'

interface Props {
  durationMs: number
}

const PollingIndicator = ({
  durationMs
}: Props) =>
  <span
    className={classes.PollingIndicator}
    style={{
      animationDuration: (durationMs / 1000) + 's'
    }}
  />

export default PollingIndicator
