import CollapseButton from '../CollapseButton/CollapseButton'
import classes from './Sidebar.module.scss'
import type { ReactNode } from 'react'

// This is technically the <header> since it contains the top level navigation and logo
// Passes the isCollapsed state down via a render prop

interface Props {
  children: ReactNode
  isCollapsed?: boolean
  onCollapse: () => void
}

const Sidebar = ({
  isCollapsed = false,
  children,
  onCollapse
}: Props) => (
  <header className={classes.Sidebar}>
    {children}
    <span
      aria-hidden // There is no need for a screen reader to collapse content
      className={classes.collapseButtonWrapper}
    >
      <CollapseButton
        isCollapsed={isCollapsed}
        onClick={onCollapse}
      />
    </span>
  </header>
)

export default Sidebar
