import {
  Header,
  List,
  Paragraph
} from '@eggplant/pattern-library'

const MostCommonErrorsInfo = () =>
  <div className='grid gap-3'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Top 10 test case errors
    </Header>
    <Paragraph>
      The top 10 test case errors report provides valuable insights into the test cases that have encountered the highest number of errors within the applied filters at the dashboard level. This report helps you identify recurring errors and focus on areas that require attention.
      <br />
      <br />
      Key Features:
    </Paragraph>
    <List>
      <Paragraph>
        Error Frequency Analysis: Identify the top 10 test cases with the highest number of errors, allowing you to prioritize troubleshooting efforts effectively.
      </Paragraph>
      <Paragraph>
        Model or Suite Association: Each test case is associated with a specific model or suite, providing contextual information for targeted investigation.
      </Paragraph>
      <Paragraph>
        Detailed Test Case View: Access specific test cases directly from the report using the actions icon, enabling a focused examination of individual test case.
      </Paragraph>
      <Paragraph>
        Comprehensive Error Overview: Explore all errors within each specific test case to gain a deeper understanding of the encountered issues.
      </Paragraph>
    </List>
    <Paragraph>
      With the top 10 test case errors report, you can efficiently address recurring errors, streamline debugging efforts, and enhance the overall quality and reliability of your testing process.
    </Paragraph>
  </div>

export default MostCommonErrorsInfo
