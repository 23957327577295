import { AccessControl } from '@eggplant/context'
import { Header, Paragraph } from '@eggplant/pattern-library'

const ConnectionsInfo = () =>
  <>
    <Header
      lessWeight
      rank={3}
    >
      Connection types
    </Header>
    <Paragraph>
      A connection type is a method used to remotely access and control a computer or system, such as VNC (Virtual Network Computing) or RDP (Remote Desktop Protocol) etc.
      <AccessControl
        allowedRoles={['dai:admin']}
        isHiddenIfDenied
      >
        <>
          <br />
          <br />
          You can add a connection type below. However, please keep in mind that some connection types have limited or no optional connections available. For example, VNC and RDP cannot be used simultaneously as connection types, as they will interfere with each other's sessions.
        </>
      </AccessControl>
    </Paragraph>
  </>

export default ConnectionsInfo
