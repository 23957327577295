import Tooltip from '../Tooltip/Tooltip'
import classes from './NavGroup.module.scss'
import {
  FaCaretDown,
  FaCaretRight
} from 'react-icons/fa'
import {
  useMemo,
  useState
} from 'react'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  icon: ReactNode
  isCollapsed: boolean
  isCurrent: boolean
  label: string
}

const NavGroup = ({
  isCollapsed,
  children,
  icon,
  label,
  isCurrent
}: Props) => {
  const [isChildrenShown, setIsChildrenShown] = useState(isCurrent)

  // Normally a key would reset the state. However, we only want the state to reset when this is true.
  // The location or match values would reset the state on every route change, hiding children prematurly.
  useMemo(() => {
    if (isCurrent) setIsChildrenShown(true)
  }, [isCurrent])

  const handleClick = () => {
    setIsChildrenShown(prevState => !prevState)
  }

  const Caret = isChildrenShown
    ? FaCaretDown
    : FaCaretRight

  return (
    <>
      <button
        aria-current={isCurrent || undefined}
        aria-label={label}
        className={
          isCollapsed
            ? classes.NavGroup + ' ' + classes.isCollapsed
            : classes.NavGroup
        }
        onClick={handleClick}
        type='button'
      >
        {
          isCollapsed
            ? (
              <Tooltip label={label}>
                {icon}
              </Tooltip>
            )
            : icon
        }
        <span className={classes.Label}>
          {label}
        </span>
        <Caret className={classes.Caret} />
      </button>
      {
        isChildrenShown &&
        children
      }
    </>
  )
}

export default NavGroup
