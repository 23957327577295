import Header from '../Header/Header'
import InfoPopOut from '../InfoPopOut/InfoPopOut'
import classes from './Fieldset.module.scss'
import type { ReactNode } from 'react'

interface CommonProps {
  children?: ReactNode
  hasBoundary?: boolean
  hasPadding?: boolean
  isDisabled?: boolean
  isGrid?: boolean
}

interface LabelWithoutDescription {
  label?: string
  sectionLabel: never
  hasBorderTop?: never
  description: never
  isRequired?: boolean
}

interface LabelWithDescription {
  label: string
  sectionLabel?: never
  hasBorderTop?: never
  description?: ReactNode
  isRequired?: boolean
}

interface SectionWithoutDescription {
  label: never
  sectionLabel?: string
  hasBorderTop?: boolean
  description: never
  isRequired?: never
}

interface SectionWithDescription {
  label?: never
  sectionLabel: string
  hasBorderTop?: boolean
  description?: ReactNode
  isRequired?: never
}

interface WithoutEither {
  label?: never
  sectionLabel?: never
  hasBorderTop?: never
  description?: never
  isRequired?: never
}
type Props = CommonProps & (LabelWithoutDescription | LabelWithDescription | SectionWithoutDescription | SectionWithDescription | WithoutEither)

// eslint-disable-next-line complexity
const Fieldset = ({
  children,
  sectionLabel,
  hasBoundary = false,
  hasBorderTop = true,
  isDisabled = false,
  description,
  hasPadding = false,
  label,
  isGrid = false,
  isRequired = false
}: Props) => {
  const flexClassName = isGrid
    ? classes.FieldsetGrid
    : classes.FieldsetFlex

  const paddingClassName = hasPadding
    ? classes.Fieldset + ' ' + classes.hasPadding
    : classes.Fieldset

  const hasBoundaryClassName = hasBoundary
    ? classes.hasBoundary
    : undefined

  const hasBorderTopClassName = hasBorderTop
    ? classes.hasBorderTop
    : classes.noBorderTop

  const hasSectionLabelClassName = sectionLabel
    ? hasBorderTopClassName
    : undefined

  const classnames = [
    paddingClassName,
    hasBoundaryClassName,
    hasSectionLabelClassName
  ].filter(classnames => classnames).join(' ')

  return (
    <fieldset
      className={classnames}
      disabled={isDisabled}
    >
      {
        (label || sectionLabel) &&
          <legend>
            {
              !!sectionLabel &&
                <Header rank={3}>
                  {sectionLabel}
                </Header>
            }
            {
              !!label &&
              label
            }
            <span>
              {
                isRequired
                  ? '*'
                  : ''
              }
            </span>
            {
              description &&
                <InfoPopOut>
                  {description}
                </InfoPopOut>
            }
          </legend>
      }
      <div className={flexClassName}>
        {children}
      </div>
    </fieldset>
  )
}

export default Fieldset
