import StyledLink from '../StyledLink'
import { navigate } from '@reach/router'
import type { Props } from '../types'

const LinkToPreviousRoute = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    onClick={() => { void navigate(-1) }}// onClick in a link is an anti-pattern but there is no cleaner way to cause the same route to reload
    to=''
  >
    {children}
  </StyledLink>

export default LinkToPreviousRoute
