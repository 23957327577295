import classes from './InfoContainer.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const InfoContainer = ({
  children
}: Props) =>
  <div className={classes.InfoContainer}>
    {children}
  </div>

export default InfoContainer
