import classes from './Color.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  variant: 'purple' |
  'lightPurple' |
  'darkPurple' |
  'blue' |
  'lightblue' |
  'darkBlue' |
  'teal' |
  'red' |
  'redHover' |
  'highlightred' |
  'orange' |
  'violet' |
  'green' |
  'secondarygreen' |
  'black' |
  'gray' |
  'greyA' |
  'greyB' |
  'greyC' |
  'lightGray' |
  'stateGray' |
  'greyDivider' |
  'theme-filter-icon' |
  'theme-delete-danger' |
  'theme-color-statuschip-text' |
  'theme-color-id' |
  'theme-placeholder-text' |
  'theme-description-text'
}

const Color = ({
  children,
  variant
}: Props) =>
  <span className={classes.Color + ' ' + classes[variant]}>
    {children}
  </span>

export default Color
