import SystemNav from './SystemNav'
import { FaFileAlt } from 'react-icons/fa'
import {
  Icons,
  NavGroup,
  NavItemVertical
} from '@eggplant/pattern-library'
import {
  LOG_OUT,
  LinkToHelp,
  SYSTEM,
  renderLinkTo
} from '@eggplant/routing'
import { Match } from '@reach/router'

interface Props {
  isCollapsed: boolean
}

const Bottom = ({ isCollapsed }: Props) =>
  <>
    <Match path={'/' + SYSTEM + '/*'}>
      {
        ({
          match
        }) =>
          <NavGroup
            icon={<Icons.Settings aria-hidden />}
            isCollapsed={isCollapsed}
            isCurrent={!!match}
            label='System'
          >
            <SystemNav
              base={SYSTEM}
              isCollapsed={isCollapsed}
            />
          </NavGroup>
      }
    </Match>
    <NavItemVertical
      icon={<FaFileAlt aria-hidden />}
      isCollapsed={isCollapsed}
      label='Documentation'
    >
      {
        content =>
          <LinkToHelp>
            {content}
          </LinkToHelp>
      }
    </NavItemVertical>
    <NavItemVertical
      icon={<Icons.Logout aria-hidden />}
      isCollapsed={isCollapsed}
      label='Log Out'
    >
      {renderLinkTo(LOG_OUT)}
    </NavItemVertical>
  </>

export default Bottom
