import StyledLink from '../StyledLink'
import {
  ADD,
  CONTROLLER,
  SUTS
} from '../routes'
import type { Props } from '../types'

const LinkToAddSut = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + CONTROLLER + '/' + SUTS + '/' + ADD}
  >
    {children}
  </StyledLink>

export default LinkToAddSut
