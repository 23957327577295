import classes from './MainCardTable.module.scss'
import type { ReactNode } from 'react'

// A very basic wrapper that adds a styled frame
// typically around a larger composite application component

// Only one of these should be in the DOM

interface Props {
  children: ReactNode
}

const MainCardTable = ({ children }: Props) =>
  <main className={classes.MainCardTable}>
    {children}
  </main>

export default MainCardTable
