import StyledLink from '../StyledLink'
import {
  API_ACCESS,
  SYSTEM
} from '../routes'
import type { Props } from '../types'

const LinkToApiAccess = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + SYSTEM + '/' + API_ACCESS}
  >
    {children}
  </StyledLink>

export default LinkToApiAccess
