import Eggy from '../Eggy/Eggy'
import Name from './Name'
import classes from './Logo.module.scss'

interface Props {
  isCollapsed?: boolean
}

const Logo = ({
  isCollapsed = false
}: Props) =>
  <div
    aria-hidden // screen readers do not need the logo
    className={
      isCollapsed
        ? classes.Logo + ' ' + classes.isCollapsed
        : classes.Logo
    }
  >
    <Eggy />
    <Name />
  </div>

export default Logo
