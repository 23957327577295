import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { HiClipboardCheckTestCase } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import {
  LinkToAddTestCase,
  useAccess
} from '@eggplant/routing'
import { LinkToTestCaseDashboardDoc } from '@eggplant/embedded-documentation'

const description = `Test case dashboard provides an overview of your script-based tests. You can easily access and analyze aggregated data for all your test cases from here.

💪 Before you can view any charts, let’s create and run at least one test case.`

const renderButton = () =>
  <AccessControl isHiddenIfDenied>
    <LinkButton
      label='New test case'
      variant='primary'
    >
      <LinkToAddTestCase>
        <HiPlus
          aria-hidden
          size='19.2px'
        />
        New test case
      </LinkToAddTestCase>
    </LinkButton>
  </AccessControl>

const TestCaseDashboardIntro = () => {
  const [isAccessible] = useAccess()

  return (
    <div className='grid h-full justify-center'>
      <div className='grid w-[36em]'>
        <EmptyStatesPreview
          action={
            isAccessible &&
            renderButton()
          }
          description={description}
          icon={<HiClipboardCheckTestCase />}
          renderLink={
            isAccessible &&
              <LinkToTestCaseDashboardDoc />
          }
          title='No test case data yet'
        />
      </div>
    </div>
  )
}

export default TestCaseDashboardIntro
