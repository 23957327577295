import Header from '../Header/Header'
import classes from './ChartEmptyState.module.scss'
import type { ReactNode } from 'react'

interface Props {
  title: string
  icon?: ReactNode
  children: ReactNode
  linkTo?: ReactNode
}

const ChartEmptyState = ({
  icon,
  title,
  children,
  linkTo
}: Props) =>
  <div className={classes.ChartEmptyState}>
    {
      icon &&
        <span className={classes.Icon}>
          {icon}
        </span>
    }
    <Header
      hasNoTopMargin
      rank={4}
    >
      {title}
    </Header>
    {
      children && children
    }
    {
      linkTo &&
        <span className={classes.Link}>
          {linkTo}
        </span>
    }
  </div>

export default ChartEmptyState
