// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore // this is still an issue
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed'
import type { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  isActive?: boolean
  withAnimated?: boolean
}

const ScrollIntoView = ({
  children = <span />, // this component is meant to wrap elements but it is not required
  isActive = true,
  withAnimated = false
}: Props
) => {
  const options = {
    behavior: withAnimated
      ? 'smooth'
      : 'auto',
    scrollMode: 'if-needed'
  }

  return (
    <ScrollIntoViewIfNeeded
      active={isActive}
      elementType='span'
      options={options}
    >
      {children}
    </ScrollIntoViewIfNeeded>
  )
}

export default ScrollIntoView
