import LinkToDocusaurus from '../LinkToDocusaurus'
import { Paragraph } from '@eggplant/pattern-library'
import { suts } from '../../docs-routes'

const SutConfigAndroidDeviceIdHelp = () =>
  <Paragraph>
    Learn more about <LinkToDocusaurus path={suts}>Android Device SUT</LinkToDocusaurus> in DAI documentation.
  </Paragraph>

export default SutConfigAndroidDeviceIdHelp
