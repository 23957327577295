import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  DUPLICATE,
  TEST_CONFIG
} from '../routes'
import type { Props } from '../types'

interface Params {
  testConfigId: string
}

const LinkToDuplicateTestConfig = ({
  children,
  testConfigId,
  openInNewTab,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    openInNewTab={openInNewTab}
    to={'/' + CONTROLLER + '/' + TEST_CONFIG + '/' + testConfigId + '/' + DUPLICATE}
  >
    {children}
  </StyledLink>

export default LinkToDuplicateTestConfig
