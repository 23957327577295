import IconCard from '../presentation/IconCard'
import Licenses from '../content/Licenses'
import { Artwork } from '@eggplant/pattern-library'

const LicensesIntro = () =>
  <IconCard
    artwork={<Artwork.Licence />}
    title='Licenses'
  >
    <Licenses />
  </IconCard>

export default LicensesIntro
