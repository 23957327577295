import {
  createContext,
  useState
} from 'react'
import {
  erase,
  get,
  set
} from 'browser-cookies'
import type { ReactNode } from 'react'

type modelIdType = string

interface Context {
  selectedModelId?: modelIdType
  setSelectedModelId: (newModelId?: modelIdType) => void
}

const Model = createContext<Context | undefined>(undefined)

const COOKIE_NAME = 'selectedModelId'

interface ProviderProps {
  children: ReactNode
  selectedModelId?: modelIdType // can be used to change the cookie
}

export const ModelProvider = ({
  children,
  selectedModelId: modelIdProp
}: ProviderProps) => {
  const cookie = get(COOKIE_NAME) || undefined

  const [selectedModelId, setSelectedModelIdState] = useState<modelIdType | undefined>(modelIdProp || cookie)

  const updateCookie = (newModelId?: modelIdType) => {
    if (newModelId) {
      set(COOKIE_NAME, newModelId)
    } else {
      erase(COOKIE_NAME)
    }
  }

  if (selectedModelId !== cookie) updateCookie(selectedModelId)

  const setSelectedModelId = (newModelId?: modelIdType) => {
    updateCookie(newModelId)
    setSelectedModelIdState(newModelId)
  }

  const value = {
    selectedModelId,
    setSelectedModelId
  }

  return (
    <Model.Provider value={value}>
      {children}
    </Model.Provider>
  )
}

interface ConsumerProps {
  children: (context: Context) => ReactNode
}

export const ModelConsumer = ({
  children: renderChildren
}: ConsumerProps) =>
  <Model.Consumer>
    {
      (context?: Context) => {
        if (context === undefined) {
          throw new Error('<ModelConsumer /> must be used within <ModelProvider />')
        }

        return renderChildren(context)
      }
    }
  </Model.Consumer>
