import Radio from '../Radio/Radio'
import classes from './LabeledRadio.module.scss'

interface Props{
  isChecked?: boolean
  isDisabled?: boolean
  label: string
  onChange: (value: any) => void
  value?: string
}

const LabeledRadio = ({
  label,
  isChecked = false,
  isDisabled = false,
  onChange,
  value
}: Props) => {
  const getClasses = () => {
    const baseClass = classes.LabeledRadio

    const isCheckedClass = isChecked
      ? classes.isChecked
      : ''

    const isDisabledClass = isDisabled
      ? classes.Disabled
      : ''

    const isCheckedIsDisabled = isChecked && isDisabled
      ? classes.isCheckedAndIsDisabled
      : ''

    const finalClasses = [
      baseClass,
      isCheckedClass,
      isDisabledClass,
      isCheckedIsDisabled
    ].filter(classname => classname)

    return finalClasses.join(' ')
  }

  return (
    <label className={getClasses()}>
      <Radio
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
      />
      {label}
    </label>
  )
}

export default LabeledRadio
