import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { liveRun } from '../../docs-routes'

const LiveRun = () =>
  <Paragraph>
    Run tests while you build them. Use the log output to help you to debug the selected resources.
    <br />
    You'll be redirected to the test result as soon as it is generated.
    <DocusaurusFooter path={liveRun} />
  </Paragraph>

export default LiveRun
