import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { TagsInput } from '@eggplant/pattern-library'
import { useKeycloak } from '@eggplant/context'
import type {
  Principal,
  Tag
} from '@eggplant/types'
import type { ReactNode } from 'react'

export interface Props {
  onChange: (value: Principal[]) => void
  values: Principal[]
  isRequired?: boolean
  description?: ReactNode
  isDisabled?: boolean
}

const getTags = (users: Principal[]) => users.map(
  user => (
    {
      ...user,
      color: 'DDA0DD'
    } as Tag
  )
)

const getVerifiedUsersTags = (users: Principal[]) =>
  users.filter(user =>
    user.emailStatus === 'VERIFIED'
  ).map(user => ({
    ...user,
    color: 'DDA0DD'
  }))

const SelectNotificationRecipients = ({
  onChange,
  values,
  isRequired = false,
  description,
  isDisabled = false
}: Props) => {
  const { keycloak: keycloakInstance } = useKeycloak()

  const url = keycloakInstance.authServerUrl + 'realms/' + keycloakInstance.realm + '/dai/v1/users'

  const handleOnSelectedTagsChanged = (tags: Tag[]) => {
    const users = tags.map(
      tag => (
        {
          name: tag.name,
          id: tag.id,
          type: 'user'
        } as Principal
      )
    )

    onChange(users)
  }

  return (
    <AuthenticatedDataRequest
      method='get'
      url={url}
    >
      {
        (data: Principal[]) =>
          <TagsInput
            availableTags={getVerifiedUsersTags(data)}
            description={description}
            isDisabled={isDisabled}
            isNewItemAllowed={false}
            isRequired={isRequired}
            label='Recipients'
            maxLength={50}
            onSelectedTagsChanged={handleOnSelectedTagsChanged}
            placeholder='Select users to add to receive notifications'
            selectedTags={getTags(values)}
          />
      }
    </AuthenticatedDataRequest>
  )
}

export default SelectNotificationRecipients
