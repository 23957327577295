import classes from './ErrorStateComponentButton.module.scss'
import { BsExclamationCircle } from 'react-icons/bs'
import { forwardRef } from 'react'

interface Props {
  onClick: () => void
  title: string
}

const ErrorStateComponentButton = forwardRef((
  {
    onClick,
    title
  }: Props,
  ref: any
) =>
  <button
    className={classes.ErrorStateComponentButton}
    onClick={onClick}
    ref={ref}
    title={title}
    type='button'
  >
    <BsExclamationCircle
      aria-hidden
      aria-label='alert'
      size={20}
    />
    <div className={classes.textWrapper}>
      {title}
    </div>
  </button>

)

export default ErrorStateComponentButton
