import classes from './BackButton.module.scss'
import { FiArrowLeft } from 'react-icons/fi'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const BackButton = ({
  children
}: Props) =>
  <span className={classes.BackButton}>
    <FiArrowLeft
      aria-hidden
      size={18}
    />
    {children}
  </span>

export default BackButton
