import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { LinkToAddTestCase } from '@eggplant/routing'
import { MODELER_SERVICE } from '@eggplant/api-hosts'
import {
  Paragraph,
  SingleSelect
} from '@eggplant/pattern-library'
import type {
  GetTestCasesResponse,
  Options
} from '@eggplant/types'

interface Props {
  initialOptions?: Options
  modelId?: string
  prompt?: string
  onChange: (value: string) => void
  value: string
  isRequired?: boolean
}

const url = MODELER_SERVICE + '/test_cases'

const getOptions = (data: GetTestCasesResponse) =>
  data.items
    .filter(item =>
      !!item.id
    )
    .map(item => ({
      label: item.name,
      value: item.id
    }))

const SelectModelerTestCase = ({
  initialOptions = [],
  modelId,
  prompt = 'Select a Test Case',
  onChange,
  value,
  isRequired
}: Props) => {
  const params = {
    model_id: modelId,
    sort: '+name'
  }

  const handleChange = (value: string) => {
    onChange(value)
  }

  return (
    <AuthenticatedDataRequest
      method='get'
      params={params}
      url={url}
    >
      {
        (data: GetTestCasesResponse) => data.total_count
          ? (
            <SingleSelect
              isRequired={isRequired}
              label='Select a Test Case'
              onChange={handleChange}
              options={[
                ...initialOptions,
                ...getOptions(data)
              ]}
              prompt={prompt}
              value={value}
            />
          )
          : (
            <Paragraph>
              <LinkToAddTestCase
                model_id={modelId}
              >
                Create
              </LinkToAddTestCase>
              &nbsp;a Test Case
            </Paragraph>
          )

      }
    </AuthenticatedDataRequest>
  )
}

export default SelectModelerTestCase
