import StyledLink from '../StyledLink'
import {
  DESIGNER,
  SUITES
} from '../routes'
import type { Props } from '../types'

const LinkToSuites = ({
  children,
  isHyperlink,
  openInNewTab
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    openInNewTab={openInNewTab}
    to={'/' + DESIGNER + '/' + SUITES}
  >
    {children}
  </StyledLink>

export default LinkToSuites
