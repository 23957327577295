import {
  FieldLabel,
  InputText
} from '@eggplant/pattern-library'
import { modelName } from '@eggplant/regex-patterns'
import type { Props } from '../interfaces'

const InputModelName = ({
  onChange,
  value
}: Props) =>
  <FieldLabel
    isRequired
    label='New Model Name'
    minCharWidth={50}
  >
    <InputText
      customValidity='Begin with an alphanumeric followed by alphanumeric or ~ _ - without spaces, max length is 50 characters.'
      isRequired
      label='Model Name'
      maxLength={50}
      onChange={onChange}
      pattern={modelName}
      placeholder='Model Name'
      value={value}
    />
  </FieldLabel>

export default InputModelName
