import StyledLink from '../StyledLink'
import {
  LICENSES,
  SYSTEM
} from '../routes'
import type { Props } from '../types'

const LinkToLicenses = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + SYSTEM + '/' + LICENSES}
  >
    {children}
  </StyledLink>

export default LinkToLicenses

