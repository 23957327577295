/* eslint-disable react/boolean-prop-naming */
// New ESlint bug as of react-scripts 3.4.0
/* eslint-disable react/no-unused-prop-types */
import styles from './Button.module.scss'
import { type ReactNode } from 'react'

export type VariantType = 'danger' | 'primary' | 'success' | 'secondary'

interface BaseProps {
  // This prop is forwarded from Downshift to match the DOM attribute name, it cannot be changed.
  'aria-activedescendant'?: string // MultiselectDropdown
  'aria-haspopup'?: boolean
  'aria-label'?: string
  children: ReactNode
  id?: string
  form?: string
  isDisabled?: boolean
  onBlur?: () => void // Downshift
  onKeyDown?: () => void // Downshift
  onKeyUp?: () => void // Downshift
  role?: string
  title?: string
  variant?: VariantType
}

interface ButtonProps {
  type?: 'button' | 'reset'
  onClick?: () => void
}

interface SubmitProps {
  type: 'submit'
  onClick?: () => void
}

type Props = BaseProps & (ButtonProps | SubmitProps)

const Button = ({
  'aria-activedescendant': ariaActivedescendant,
  'aria-haspopup': ariaHasPopup = false,
  'aria-label': ariaLabel,
  children,
  id,
  isDisabled = false,
  form,
  onBlur,
  onClick,
  onKeyDown,
  onKeyUp,
  role,
  title,
  type = 'button',
  variant = 'primary'
}: Props) => {
  const buttonClassName = styles.Button + ' ' + styles[variant]

  return (
    <button
      aria-activedescendant={ariaActivedescendant}
      aria-haspopup={ariaHasPopup}
      aria-label={ariaLabel}
      className={buttonClassName}
      disabled={isDisabled}
      form={form}
      id={id}
      onBlur={onBlur}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      role={role}
      title={title}
      // eslint-disable-next-line react/button-has-type
      type={type} // The button type is dynamic and the props lock down the options
    >
      {children}
    </button>
  )
}

export default Button
