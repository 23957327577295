import {
  Header,
  List,
  Paragraph
} from '@eggplant/pattern-library'

const RunPerDayInfo = () =>
  <div className='flex flex-col gap-4'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Test case runs per day
    </Header>
    <Paragraph>
      The Test Case Run Status Graph provides a visual representation of passed, failed, errored,
      and cancelled test case run statuses per day, based on selected filters.
      This graph offers valuable insights into the overall performance of
      your test cases within a specified timeframe
    </Paragraph>
    <div>
      <Paragraph>Key Features:</Paragraph>
      <List>
        <Paragraph>
          Graphical Representation: The data is presented in an intuitive graph format,
          making it easy to grasp the distribution of different test case run statuses over time.
        </Paragraph>
        <Paragraph>
          Filter Options: Customize the graph using filters such as date range and filtering by model or suite.
          This allows you to focus on specific subsets of your test cases for deeper analysis.
        </Paragraph>
        <Paragraph>
          Comprehensive View: Gain a comprehensive understanding of how your test cases perform each day,
          tracking changes and trends in their run statuses.
        </Paragraph>
      </List>
    </div>
    <Paragraph>
      The Test Case Run Status Graph empowers you to assess the effectiveness of your testing efforts,
      identify patterns, and make informed decisions based on the performance of your test cases.
    </Paragraph>
  </div>

export default RunPerDayInfo
