import SelectDateRange from '../SelectDateRange/SelectDateRange'
import {
  Color,
  DateDropdown,
  Paragraph
} from '@eggplant/pattern-library'
import { HiArrowNarrowRight } from 'react-icons/hi'
import type { CSSProperties } from 'react'
import type { DateRangeOptions } from '@eggplant/types'

const frameDivStyle: CSSProperties = {
  display: 'grid',
  alignItems: 'center',
  gap: '.5em',
  gridAutoFlow: 'column'
}

const now = new Date(new Date().setSeconds(0, 0)).toISOString()
const sevenMidnight = new Date(new Date().setDate(new Date().getDate() - 6))

sevenMidnight.setHours(0, 0, 0, 0)

const seven = sevenMidnight.toISOString()

const dateRangeMap = {
  'last-3-days': 2,
  'last-7-days': 6,
  'last-2-weeks': 13,
  'last-3-weeks': 20,
  'last-month': 29,
  'last-3-months': 89
}

interface Props {
  isDisabled?: boolean
  onChange: (dateFrom: string, dateTo: string, dateRange: DateRangeOptions) => void
  starttime?: string
  endtime?: string
  showRange?: boolean
  dateRange?: DateRangeOptions
  isFilter?: boolean
}

const DateRange = ({
  isDisabled = false,
  onChange,
  starttime,
  endtime,
  showRange = true,
  dateRange,
  isFilter = false
}: Props) => {
  const handleDateRange = (value: DateRangeOptions) => {
    const currentTime = new Date(new Date().setSeconds(0, 0)).toISOString()
    const displayDateFrom = new Date(currentTime)
    const displayDateTo = new Date(currentTime)

    if (value === 'custom-range') {
      onChange(starttime || currentTime, endtime || currentTime, value)

      return
    }

    if (value === 'yesterday') {
      displayDateFrom.setDate(displayDateTo.getDate() - 1)

      const newDateFrom = (new Date(displayDateFrom.setHours(0, 0, 0, 0))).toISOString()
      const newDateTo = (new Date(displayDateFrom.setHours(23, 59, 59, 0))).toISOString()

      onChange(newDateFrom, newDateTo, value)

      return
    }

    if (value === 'today') {
      displayDateFrom.setDate(displayDateTo.getDate())

      const newDateFrom = (new Date(displayDateFrom.setHours(0, 0, 0, 0))).toISOString()

      onChange(newDateFrom, currentTime, value)

      return
    }

    const days = dateRangeMap[value]

    displayDateFrom.setDate(displayDateTo.getDate() - days)
    displayDateFrom.setHours(0, 0, 0, 0)

    const newDateFrom = displayDateFrom.toISOString()

    onChange(newDateFrom, currentTime, value)
  }

  const handleDateTo = (value: string) => {
    if (value === endtime) return

    const isValid = new Date(starttime || seven) >= new Date(value)

    if (isValid) {
      const newDateFrom = (new Date(new Date(value).setHours(0, 0, 0, 0))).toISOString()

      onChange(newDateFrom, value, 'custom-range')

      return
    }

    onChange(starttime || seven, value, 'custom-range')
  }

  const handleDateFrom = (value: string) => {
    if (value === starttime) return

    const isValid = new Date(value) >= new Date(endtime || now)

    if (!isValid) {
      onChange(value, endtime || now, 'custom-range')

      return
    }

    const currentTime = new Date(new Date().setSeconds(0, 0)).toISOString()
    const isSame = new Date(value).setHours(0, 0, 0, 0) === new Date(currentTime).setHours(0, 0, 0, 0)

    if (isSame) {
      const newDateFrom = (new Date(new Date(value).setHours(0, 0, 0, 0))).toISOString()

      onChange(newDateFrom, currentTime, 'custom-range')
    } else {
      const newDateTo = (new Date(new Date(value).setHours(23, 59, 59, 0))).toISOString()

      onChange(value, newDateTo, 'custom-range')
    }
  }

  return (
    <div style={frameDivStyle}>
      {
        showRange &&
          <SelectDateRange
            isDisabled={isDisabled}
            onChange={handleDateRange}
            value={dateRange || 'last-7-days'}
          />
      }
      <Color variant='greyC'>
        <HiArrowNarrowRight
          aria-hidden
        />
      </Color>
      <DateDropdown
        calendarHeader='Range from'
        isDisabled={isDisabled}
        isFilter={isFilter}
        isPastAllowed
        label='Start datetime'
        onChange={handleDateFrom}
        value={starttime || seven}
      />
      <Paragraph>
        <strong>
          <Color variant='greyB'>to
          </Color>
        </strong>
      </Paragraph>
      <DateDropdown
        calendarHeader='Range until'
        isDisabled={isDisabled}
        isFilter={isFilter}
        isPastAllowed
        label='End datetime'
        onChange={handleDateTo}
        value={endtime || now}
      />
    </div>
  )
}

export default DateRange
