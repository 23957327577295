import Tooltip from '../Tooltip/Tooltip'
import classes from './NumberCircle.module.scss'
import type { NumberCircleVariant } from '@eggplant/types'
import type { ReactNode } from 'react'

type size = 'lg' | 'md'

interface Props {
  value: ReactNode
  variant: NumberCircleVariant
  label?: string
  size?: size
}

const NumberCircle = ({
  value,
  variant,
  label = '',
  size = 'lg'
}: Props) =>
  <span className={classes.NumberCircle + ' ' + classes[variant] + ' ' + classes[size]}>
    {
      label
        ? (
          <Tooltip label={label}>
            {value}
          </Tooltip>
        )
        : value
    }
  </span>

export default NumberCircle
