import classes from './GitStatusBadge.module.scss'

export const GIT_STATUSES = {
  ERROR: 'Failed',
  SUCCESS: 'Success',
  PENDING: 'Synchronizing'
}

interface Props {
  value: 'SUCCESS' | 'ERROR' | 'PENDING'
}

const GitStatusBadge = ({ value }: Props) =>
  <span className={classes.GitStatusBadge + ' ' + classes[value]}>
    {GIT_STATUSES[value]}
  </span>

export default GitStatusBadge
