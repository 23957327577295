import SortIndicator from '../SortIndicator/SortIndicator'
import classes from './SortButton.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  isAscending?: boolean | undefined
  onClick: () => void
}

const SortButton = ({
  children,
  isAscending,
  onClick
}: Props) => {
  const handleClick = () => {
    onClick()
  }

  const sortChangeLabel = isAscending
    ? 'descending'
    : 'ascending'

  return (
    <button
      aria-label={`Sort this in ${sortChangeLabel} order`}
      className={classes.SortButton}
      onClick={handleClick}
      type='button'
    >
      {children}
      {' '}
      <SortIndicator
        isAscending={isAscending}
        key={1} // https://github.com/reactabular/reactabular/issues/373
      />
    </button>
  )
}

export default SortButton
