import styles from './Radio.module.scss'

interface Props {
  id?: string
  isChecked: boolean // for label pairing
  isDisabled?: boolean
  name?: string
  onChange: (value: any) => void
  value?: string
}

const Radio = ({
  id,
  isChecked = false,
  isDisabled = false,
  name,
  onChange,
  value = ''
}: Props) => {
  const handleChange = () => {
    !isChecked &&
    onChange(value)
  }

  // The [checked] attribute does not actually change in the DOM
  // Though the browser still knows which is checked
  // So this needs another indicator to style with
  const className = isChecked
    ? styles.Radio + ' ' + styles.isChecked
    : styles.Radio

  return (
    <input
      checked={isChecked}
      className={className}
      disabled={isDisabled}
      id={id}
      name={name}
      onChange={handleChange}
      type='radio'
      value={value}
    />
  )
}

export default Radio
