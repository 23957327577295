/* eslint-disable fp/no-mutating-methods */
import {
  Combobox,
  DropdownButton,
  FormAlert
} from '@eggplant/pattern-library'
import type { ButtonShape, GetGitRefsResponse, GitRef, GroupedOptionType, GroupedOptions, OptionType, Options } from '@eggplant/types'

interface Props {
  initialOptions?: GroupedOptions
  onChange: (value: string) => void
  value: string
  gitRefs: GetGitRefsResponse
}

const mapRefToOption = (ref: GitRef) => ({ label: ref.name, value: ref.ref } as OptionType)

const getOptions = (refs: GetGitRefsResponse) => {
  const branchOptions = refs.branches.map(mapRefToOption)
  const tagOptions = refs.tags.map(mapRefToOption)

  return [{
    label: 'Branches',
    options: branchOptions
  }, {
    label: 'Tags',
    options: tagOptions
  }]
}

const groupedOptionReducer = (acc: Options, curr: GroupedOptionType) =>
  curr.options.length
    ? acc.concat(curr.options)
    : acc

const SelectGitRef = ({
  initialOptions = [],
  onChange,
  value,
  gitRefs
}: Props) => {
  const getDefaultLabel = () => {
    const defaultLabel = 'Select branch or tag'

    if (!initialOptions.length) return defaultLabel

    const combinedOptions = initialOptions.reduce(groupedOptionReducer, [])

    const overridenDefaultLabel = combinedOptions.find(item => item.value === '')

    return overridenDefaultLabel?.label || defaultLabel
  }

  const getRenderButton = (options: any) => {
    const combinedOptions = options.reduce(groupedOptionReducer, [])

    const currentOption = combinedOptions.find((option: OptionType) => option.value === value)

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        label={
          currentOption?.value
            ? currentOption.label
            : undefined
        }
      >
        {
          currentOption
            ? currentOption.label
            : getDefaultLabel()
        }
      </DropdownButton>
  }

  return (
    gitRefs.branches.length > 0 || gitRefs.tags.length > 0
      ? (
        <Combobox
          groupedOptions={getOptions(gitRefs)}
          initialGroupedOptions={initialOptions}
          onChange={onChange}
          renderButton={getRenderButton(getOptions(gitRefs))}
          value={value}
        />
      )
      : (
        <FormAlert type='warning'>
          No Branches or Tags found
        </FormAlert>
      )
  )
}

export default SelectGitRef

