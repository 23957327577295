import classes from './TableEmptyState.module.scss'
import {
  Color,
  Header
} from '@eggplant/pattern-library'
import { HiOutlineSearch } from 'react-icons/hi'
import type {
  ReactNode
} from 'react'

interface Props {
  description: ReactNode
  documentLink?: ReactNode
  action?: ReactNode
  title: string
  hasTopPadding?: boolean
}

const TableEmptyState = ({
  action,
  description,
  documentLink,
  hasTopPadding = false,
  title
}: Props) => {
  const emptyStateClass = hasTopPadding
    ? classes.TableEmptyState + ' ' + classes.hasTopPadding
    : classes.TableEmptyState

  return (
    <div className={emptyStateClass}>
      <Color variant='stateGray'>
        <HiOutlineSearch
          aria-hidden
          size={120}
        />
      </Color>
      <Header rank={4}>
        {title}
      </Header>
      {description}
      {action && action}
      {documentLink && documentLink}
    </div>
  )
}

export default TableEmptyState
