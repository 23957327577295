import { Paragraph } from '@eggplant/pattern-library'

const TestCaseOriginDescription = () =>
  <Paragraph>
    Indicate where the test case came from.
    <br />
    This can be an external system.
  </Paragraph>

export default TestCaseOriginDescription
