import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import classes from './DraggableList.module.scss'
import {
  DragDropContext,
  Draggable,
  Droppable
} from '@react-forked/dnd'
import {
  useEffect,
  useRef
} from 'react'

// If this is used inside a modal, the drag handle will be out of place due to a nested CSS transform.
// We need a React Portal to create an element that is outside of the transform.
// Because DND relies on fixed positioning, it does not matter where this portal actually goes.

const DraggableList = ({
  children,
  droppableId,
  hasCustomDragHandle,
  items,
  setItems
}) => {
  const portalRef = useRef()

  useEffect(() => {
    const portal = portalRef.current

    // The portal is appended to the body after it is created within the render method of this component.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (portal) {
      portal.parentNode.removeChild(portal)
      document.body.appendChild(portal)
    }

    // To prevent a stack of dead and empty portals, we must clean up the portal when this component unmounts.
    return () => {
      portal.parentNode.removeChild(portal)
    }
  }, [])

  /**
   * The `react-beautiful-dnd` `onDragEnd` callback prop
   * takes a `result` parameter which tells us about the
   * drag/drop operation. We assume dragging only takes place
   * within one list, and so we call the `setItems` prop
   * with the reordered list.
   *
   * @param {*} result - from `react-beautiful-dnd`
   */
  const onDragEnd = result => {
    const { destination, source } = result

    // Ignore invalid destinations
    if (!destination) { return }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    // Remove the item at source.index by slicing
    const itemsWithoutDraggedItem = [
      ...items.slice(0, source.index),
      ...items.slice(source.index + 1)
    ]

    // Insert the item at destination.index by slicing again
    const newItems = [
      ...itemsWithoutDraggedItem.slice(0, destination.index),
      items[source.index], // insert the item
      ...itemsWithoutDraggedItem.slice(destination.index)
    ]

    setItems(newItems)
  }

  const getdraggableItemClass = isSelected => isSelected
    ? classes.DraggableListItem + ' ' + classes.isSelected
    : classes.DraggableListItem

  /**
   * The `react-beautiful-dnd` `Draggable` component
   * has as its child a function which takes a single `provided`
   * parameter and returns a react component.
   *
   * This function actually takes an item, and _returns_
   * another function which can be a child of `Draggable`
   *
   * @param {*} item
   */
  const draggableFunc = (item, index) =>
    (provided, snapshot) => {
      const child =
        <div
          className={getdraggableItemClass(!!item.isSelected)}
          {...provided.draggableProps}
          {
            ...(
              hasCustomDragHandle
                ? {}
                : provided.dragHandleProps
            )
          }
          ref={provided.innerRef}
        >
          {
            children(
              item,
              hasCustomDragHandle
                ? provided.dragHandleProps
                : undefined,
              index
            )
          }
        </div>

      if (!snapshot.isDragging) return child

      // If the `children` function provides a component
      // which implements a custom drag handle, pass the
      // `dragHandleProps` for it to use
      return ReactDOM.createPortal(
        child, portalRef.current
      )
    }

  /**
   * The `react-beautiful-dnd` `Droppable` component
   * has as its child a function which takes a single `provided`
   * parameter and returns a react component
   *
   * @param {*} provided
   */
  const droppableFunc = provided => (
    // This outer div contains the list, and is a drop target
    <div
      {...provided.droppableProps}
      ref={provided.innerRef}
    >
      {
        // For each item, return a Draggable
        items.map((item, index) => (
          <Draggable
            draggableId={index + ''}
            index={index}
            isDragDisabled={items.length <= 1}
            key={index}
          >
            {
              draggableFunc(item, index)
            }
          </Draggable>
        ))
      }
      {
        // The placeholder is needed in case there are no list items
        provided.placeholder
      }
    </div>
  )

  return (
    <div className={classes.DraggableList}>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable
          droppableId={droppableId}
        >
          {droppableFunc}
        </Droppable>
        <div ref={portalRef} />
      </DragDropContext>
    </div>
  )
}

DraggableList.propTypes = {
  children: PropTypes.func.isRequired,
  droppableId: PropTypes.string.isRequired,
  hasCustomDragHandle: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  setItems: PropTypes.func.isRequired
}

DraggableList.defaultProps = {
  hasCustomDragHandle: false
}

export default DraggableList
