import StyledLink from '../StyledLink'
import {
  AGENT_TOOLS,
  SYSTEM
} from '../routes'
import type { Props } from '../types'

const LinkToAgentTools = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + SYSTEM + '/' + AGENT_TOOLS}
  >
    {children}
  </StyledLink>

export default LinkToAgentTools

