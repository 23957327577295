import { Paragraph } from '@eggplant/pattern-library'

const ProtectedApiKeyDescription = () =>
  <Paragraph>
    API keys are write-only and saved keys are not displayed.
    <br />
    You must re-enter the key for each submission.
  </Paragraph>

export default ProtectedApiKeyDescription
