// Everything in this file will be exported by this package
export const ACCOUNT = 'account'
export const ACCESS = 'access'
export const ADD = 'add'
export const ADD_LICENSE = 'add'
export const AGENT_TOOLS = 'agent-tools'
export const API_ACCESS = 'api-access'
export const ASSOCIATE = 'associate'
export const BUGHUNTING = 'bughunting'
export const BUILDER = 'builder'
export const CAPTURE = 'capture'
export const CLOUD_EULA_URL = 'eula/Keysight-On-Premises-Cloud-SaaS-EULA.pdf'
export const CONTROLLER = 'controller'
export const COVERAGE = 'coverage'
export const DAI_AGENT = 'DAIAgent'
export const DASHBOARD = 'dashboard'
export const DASHBOARDS = 'dashboards'
export const DESIGNER = 'designer'
export const DOJO_UI = window.location.origin // NGINX will send this to the Dojo UI until a new login page is ready
export const DUPLICATE = 'duplicate'
export const EDIT = 'edit'
export const EDITOR = 'editor'
export const ENVIRONMENTS = 'environments'
export const FILES = 'files'
export const GIT_CONNECTIONS = 'git-connections'
export const HEATMAP = 'heatmap'
export const HISTORY = 'history'
export const HELP = 'help'
export const IMPORT = 'import'
export const INSTALLER_DOWNLOAD = 'https://downloads.eggplantsoftware.com/downloads'
export const INSIGHTS = 'insights'
export const LATEST_VERSION = 'latest_version'
export const LICENSES = 'licenses'
export const LIVE_RUN = 'live-run'
export const LOGIN = DOJO_UI
export const LOG_OUT = 'log-out'
export const LOGS = 'logs'
export const MODEL_HUB = 'models'
export const MY_ACCOUNT = 'my-account'
export const NEW = 'new'
export const NOTIFICATIONS = 'notifications'
export const QA_SUMMARY = 'qa-summary'
export const PRIVACY_NOTICE_URL = 'DAI-Software-Privacy-Notice.pdf'
export const RCI = 'import'
export const REACT_LOGIN = 'login'
export const RESULTS = 'results'
export const RUNHISTORY = 'run-history'
export const RUNNER = 'runner'
export const SCHEDULE = 'schedule'
export const SETTINGS = 'settings'
export const SMTP = 'smtp'
export const SCRIPTED = 'scripted'
export const SNIPPETS = 'snippets'
export const SOFTWARE_EULA_URL = 'eula/Keysight-Software-EULA.pdf'
export const STATUS = 'status'
export const STEPS = 'steps'
export const SUPPORT = 'support'
export const SUTS = 'suts'
export const SYSTEM = 'system'
export const SETUP = 'setup'
export const SUITES = 'suites'
export const TESTCASES = 'test-cases'
export const TESTCASE_DASHBOARD = 'test-cases-dashboard'
export const TEST_CONFIG = 'test-config'
export const VIEW = 'view'
export const VERSIONS = 'versions'
