import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { TOGGLES } from '@eggplant/api-hosts'
import type { Error, TogglesResponse } from '@eggplant/types'
import type { ReactElement } from 'react'

interface Props {
  children?: (response: TogglesResponse) => ReactElement
  onSuccess?: (response: TogglesResponse) => void
  onError?: (error: Error) => void
}

const GetTogglesRequest = ({
  children,
  onSuccess,
  onError
}: Props) => {
  const url = TOGGLES + '/toggles'

  return (
    <AuthenticatedDataRequest
      children={children}
      method='get'
      onError={onError}
      onSuccess={onSuccess}
      url={url}
    />
  )
}

export default GetTogglesRequest
