import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AuthConsumer } from '@eggplant/context'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAddSut } from '@eggplant/routing'
import { LinkToSutsEmptyStateDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = 'You need connections to systems under test (SUTs) to execute your tests.'

const renderButton = () =>
  <AuthConsumer>
    {
      ({ isAdmin }) =>
        isAdmin &&
          <LinkButton variant='primary'>
            <LinkToAddSut>
              <HiPlus
                aria-hidden
                size='19.2px'
              /> New SUT
            </LinkToAddSut>
          </LinkButton>
    }
  </AuthConsumer>

const SUTsIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Path />}
        renderLink={<LinkToSutsEmptyStateDoc />}
        title='SUTs'
      />
    </div>
  </div>

export default SUTsIntro
