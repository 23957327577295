import type {
  Params
} from './types'

const filterParams = (params: Params) =>
  Object.keys(params).filter(key =>
    params[key] !== null && params[key] !== undefined
  ).reduce((cleanParams, key) => ({
    ...cleanParams,
    [key]: params[key]
  }), {})

export default filterParams
