import * as routes from './routes'

// Defines the sidebar labels and <title> for each route

export const routeLabels = {
  [routes.DASHBOARD]: 'Exploratory',
  [routes.QA_SUMMARY]: 'Model',
  [routes.MODEL_HUB]: 'Models',
  [routes.SUITES]: 'Suites',
  [routes.EDITOR]: 'Editor',
  [routes.CAPTURE]: 'Capture',
  [routes.LIVE_RUN]: 'Live Run',
  [routes.RUNNER]: 'Runner',
  [routes.TESTCASES]: 'Test Cases',
  [routes.TESTCASE_DASHBOARD]: 'Test Case',
  [routes.RESULTS]: 'Test Results',
  [routes.TEST_CONFIG]: 'Test Config',
  [routes.SCHEDULE]: 'Schedule',
  [routes.SUTS]: 'SUTs',
  [routes.ENVIRONMENTS]: 'Environments',
  [routes.COVERAGE]: 'Coverage',
  [routes.BUGHUNTING]: 'Bug Hunting',
  [routes.STATUS]: 'Status',
  [routes.API_ACCESS]: 'API Access',
  [routes.GIT_CONNECTIONS]: 'Git Connections',
  [routes.ACCESS]: 'Access',
  [routes.MY_ACCOUNT]: 'My Account',
  [routes.LOGS]: 'Logs',
  [routes.LICENSES]: 'Licenses',
  [routes.AGENT_TOOLS]: 'Agent Tools',
  [routes.SNIPPETS]: 'Snippets',
  [routes.STEPS]: 'Step Logs',
  [routes.RUNHISTORY]: 'Run History',
  [routes.SETTINGS]: 'Settings'
}

export default routeLabels
