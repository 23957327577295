import classes from './StatusChipButton.module.scss'
import type { ReactNode } from 'react'
import type { Status } from '../types'

interface Props {
  children: ReactNode
  title?: string
  variant: Status
}

const StatusChipButton = ({
  children,
  title,
  variant
}: Props) =>
  <span
    className={classes.StatusChipButton + ' ' + classes[variant]}
    title={title}
  >
    {children}
  </span>

export default StatusChipButton
