import { LinkToTestCasesDoc } from '../links'
import { PreviewEmptyState } from '@eggplant/pattern-library'

const TestCasesEmptyState = () =>
  <div className='grid items-center justify-center'>
    <div className='grid max-w-xl'>
      <PreviewEmptyState
        description='First, select the type of test case; model based or script based. Next, fill out the rest of the form. Then click create to build your test case.'
        documentLink={<LinkToTestCasesDoc />}
        title='New test case'
      />
    </div>
  </div>

export default TestCasesEmptyState
