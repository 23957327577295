import Polling from '../Polling/Polling'
import { useKeycloak } from '@eggplant/context'
import type { PollingProps } from '../types'

const KeycloakPolling = <ResponseInterface, >(props: PollingProps<ResponseInterface>) => {
  const { keycloak } = useKeycloak()

  // This will be called before each request to ensure a fresh token
  const getToken = () => keycloak.token || ''

  return (
    <Polling<ResponseInterface>
      getToken={getToken}
      {...props}
    />
  )
}

export default KeycloakPolling
