import classes from './ActionTypeIndicator.module.scss'
import {
  FaArrowAltCircleLeft,
  FaCode,
  FaProjectDiagram,
  FaSignOutAlt
} from 'react-icons/fa'

interface Props {
  value: 'submodel'|'previous'|'exit'|'snippet'|'defaultAction'
}

export const iconList = {
  submodel: <FaProjectDiagram aria-hidden />,
  previous: <FaArrowAltCircleLeft aria-hidden />,
  exit: <FaSignOutAlt aria-hidden />,
  snippet: <FaCode aria-hidden />,
  defaultAction: <FaCode aria-hidden />
}

const ActionTypeIndicator = ({
  value
}: Props) =>
  <div
    aria-label={value}
    className={classes.ActionTypeIndicator + ' ' + classes[value]}
  >
    {iconList[value]}
  </div>

export default ActionTypeIndicator
