import {
  Card,
  ChartEmptyState,
  Color,
  Paragraph
} from '@eggplant/pattern-library'
import type { CSSProperties } from 'react'

const chartEmptyStateWrapper: CSSProperties = {
  display: 'grid',
  height: '78px'
}

const TestCasesNoScriptedTestStep = () =>
  <div className='grid'>
    <div style={chartEmptyStateWrapper}>
      <Card>
        <ChartEmptyState
          title='No scripts added yet'
        >
          <Paragraph>
            <Color variant='theme-filter-icon'>
              Please add scripts from the list on left.
            </Color>
          </Paragraph>
        </ChartEmptyState>
      </Card>
    </div>
  </div>

export default TestCasesNoScriptedTestStep
