import { Link } from '@reach/router'
import type * as routes from './routes'
import type { ReactNode } from 'react'

// Must be one of the imported route values
type route = typeof routes[keyof typeof routes]

// Creates the required render prop function for NavItem

interface Args {
  isPartiallyCurrent: boolean
}

const getIsPartiallyActive = ({
  isPartiallyCurrent
}: Args) => isPartiallyCurrent
  ? { 'aria-current': true }
  : {}

// isPartial allows the link to be set to [aria-current] if the link's address is partially included in the current window address
// this works for most navigation links, but we do not want it for the base link because the base is in every url, so it would always be aria-current
const renderLinkTo = (destination: route, isPartial = true) =>
  (contents: ReactNode) =>
    <Link
      getProps={
        isPartial
          ? getIsPartiallyActive
          : () => ({}) // do not get the props
      }
      to={destination}
    >
      {contents}
    </Link>

export default renderLinkTo
