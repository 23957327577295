import classes from './Artwork.module.scss'

export const Path = () =>
  <svg
    fill='currentColor'
    height='120'
    preserveAspectRatio='none'
    viewBox='0 0 296 296'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      className={classes.PinPath}
      d='M225.7 238.03H60.82a25.78 25.78 0 010-51.57h77.53a41.98 41.98 0 000-83.97H58.33c-12.5-5.78-33.13 9.96-9.7 16.36l.1-.16h89.63a25.78 25.78 0 110 51.57H60.83a41.98 41.98 0 000 83.96H235.3l.09.16z'
    />
    <path
      className={classes.PinStart}
      d='M40.43 117.74C6.33 68.3 0 63.23 0 45.06a45.06 45.06 0 0190.12 0c0 18.17-6.33 23.24-40.43 72.68a5.63 5.63 0 01-9.26 0zm4.63-53.9a18.77 18.77 0 10-18.78-18.78 18.77 18.77 0 0018.78 18.78z'
    />
    <path
      className={classes.PinEnd}
      d='M230.06 251.66c-38.29-55.5-45.4-61.2-45.4-81.6a50.6 50.6 0 01101.2 0c0 20.4-7.11 26.1-45.4 81.6a6.33 6.33 0 01-10.4 0zm5.2-60.52a21.08 21.08 0 10-21.08-21.08 21.08 21.08 0 0021.08 21.08z'
    />
  </svg>

export const Lock = () =>
  <svg
    fill='currentColor'
    height='120'
    viewBox='0 0 105.68 105.68'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M52.84 0A52.84 52.84 0 110 52.84 52.84 52.84 0 0152.84 0z'
      fill='#907dba'
    />
    <path
      d='M104.55 63.78L67.6 26.84l-18.48 1.44L38.6 42.3l-6.51 8.77-3.78 33.96 20.5 20.49q2 .15 4.03.15a52.86 52.86 0 0051.7-41.9z'
      fill='#6f588d'
    />
    <path
      d='M52.84 20.64A20.8 20.8 0 0032.1 41.4v11.57H43.4V41.4a9.43 9.43 0 0118.87 0v11.57H73.6V41.4a20.8 20.8 0 00-20.76-20.76z'
      fill='#5a5a5a'
    />
    <path
      d='M73.6 41.4v11.57H62.28V41.4a9.44 9.44 0 00-9.44-9.44V20.64A20.8 20.8 0 0173.6 41.4z'
      fill='#444'
    />
    <path
      d='M28.31 51.08v33.96h49.06V51.08z'
      fill='#62c6c4'
    />
    <path
      d='M52.84 51.08h24.53v33.96H52.84z'
      fill='#3b8b8a'
    />
    <g fill='#5a5a5a'>
      <path d='M52.84 58.63a5.66 5.66 0 105.66 5.66 5.65 5.65 0 00-5.66-5.66z' />
      <path d='M50.96 68.06h3.77v9.43h-3.77z' />
    </g>
    <g fill='#444'>
      <path d='M58.5 64.29a5.65 5.65 0 01-5.66 5.66V58.63a5.65 5.65 0 015.66 5.66z' />
      <path d='M52.84 68.06h1.9v9.43h-1.9z' />
    </g>
  </svg>

export const Snippets = () =>
  <svg
    height='120'
    viewBox='0 0 88.471 76'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='translate(190 1339)'>
      <ellipse
        cx='37.5'
        cy='38'
        fill='#62c6c4'
        rx='37.5'
        ry='38'
        transform='translate(-190 -1339)'
      />
      <g fill='#479b9a'>
        <path
          d='M-142.954-1320.993v-7.226h-21.111a3.472 3.472 0 00-3.47 3.453v38.762a3.472 3.472 0 003.47 3.453h28.746a3.472 3.472 0 003.47-3.453v-31.983h-8.073a3.01 3.01 0 01-3.032-2.987zm-11.709 23.348a.874.874 0 11-1.337 1.126l-3.478-4.134a.873.873 0 010-1.125l3.479-4.133c.311-.369-1.947.755-2.258 1.125l.589 3.567zm6.155-10.154l-1.373 13.6a.874.874 0 01-1.727-.264l2.1-13.775a.873.873 0 011-.732c.477.073.073.69 0 1.167zm5.123 11.28a.874.874 0 11-1.336-1.125l3.005-3.575-3.005-3.571a.873.873 0 111.336-1.125z'
        />
        <path
          d='M-139.922-1319.734h6.7l-7.985-7.36v6.1a1.261 1.261 0 001.263 1.259zm0 0'
        />
      </g>
      <path
        d='M-144.808-1273.925h-28.746a3.48 3.48 0 01-3.47-3.453v-38.761a3.479 3.479 0 013.47-3.454h4.293v35.314a3.48 3.48 0 003.47 3.454h24.453v3.447a3.48 3.48 0 01-3.47 3.453z'
        fill='#479b9a'
      />
      <g fill='#fff'>
        <path
          d='M-141.061-1322.887v-7.226h-21.111a3.472 3.472 0 00-3.47 3.453v38.762a3.472 3.472 0 003.47 3.453h28.746a3.472 3.472 0 003.47-3.453v-31.983h-8.073a3.01 3.01 0 01-3.032-2.987zm-11.709 23.348a.874.874 0 11-1.337 1.126l-3.478-4.134a.873.873 0 010-1.125l3.479-4.133a.874.874 0 011.336 1.125l-3.005 3.567zm6.887-10.326l-2.1 13.776a.874.874 0 01-1.727-.264l2.1-13.775a.874.874 0 111.727.264zm7.871 7.318l-3.48 4.134a.874.874 0 11-1.336-1.125l3.005-3.575-3.005-3.571a.873.873 0 111.336-1.125l3.479 4.133a.873.873 0 010 1.129zm0 0'
        />
        <path
          d='M-138.029-1321.628h6.7l-7.985-7.36v6.1a1.261 1.261 0 001.263 1.259zm0 0'
        />
      </g>
      <path
        d='M-105.817-1319.97l-4.288-4.288-5 5 3.573 9.291z'
        fill='#ff3e3a'
      />
      <path
        d='M-105.817-1319.97l-7.861 7.86 7.147 1.429 5-5z'
        fill='#cc3245'
      />
      <path
        d='M-133.722-1296.872l-2.305 7.112 7.565-4.707z'
        fill='#373e9f'
      />
      <path
        d='M-136.029-1289.76l7.108-2.305-.972-3.831z'
        fill='#340d66'
      />
      <path
        d='M-123.338-1299.593l-8.576-2.859-1.811 5.584 2.4 2.4z'
        fill='#ffe7b5'
      />
      <path
        d='M-131.32-1294.465l2.4 2.4 5.584-1.811-1.429-7.147z'
        fill='#ffd06a'
      />
      <path
        d='M-127.623-1298.164l4.288 4.288 16.8-16.8-4.288-4.288-11.2 5.479z'
        fill='#3e2b56'
      />
      <path
        d='M-131.912-1302.453l16.803-16.803 4.288 4.288-16.803 16.803z'
        fill='#907dba'
      />
      <path
        d='M-142.914-1275.819h-28.746a3.48 3.48 0 01-3.47-3.453v-38.761a3.479 3.479 0 013.47-3.454h4.293v35.314a3.48 3.48 0 003.47 3.454h24.453v3.447a3.48 3.48 0 01-3.47 3.453z'
        fill='#fff'
      />
    </g>
  </svg>

export const Bug = () =>
  <svg
    height='120'
    viewBox='0 0 77 96.981'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <clipPath id='a'>
        <path
          d='M0 0h2v34H0z'
          fill='none'
        />
      </clipPath>
    </defs>
    <ellipse
      cx='38.5'
      cy='39'
      fill='#3e2b56'
      rx='38.5'
      ry='39'
      transform='translate(-.001)'
    />
    <g
      clipPath='url(#a)'
      transform='translate(46 17)'
    >
      <path
        d='M0 0h2v3H0zM0 6h2v3H0zM0 12h2v3H0zM0 18h2v3H0zM0 24h2v3H0zM0 30h2v3H0z'
        fill='#fff'
      />
    </g>
    <path
      d='M26.958 22.127h2.729v1.365h-2.729zm9.553-5.459v4.094h-4.094v1.365h1.365v1.365h-2.73v-1.365h-1.364v-1.365h-2.73v1.365h-1.364v1.365h-2.73v-1.365h1.365v-1.365h-4.094v-4.094H21.5v-1.365h4.094v-1.364h5.459v1.365h4.094v1.365zm-9.553 1.365h-2.729v1.365h2.729zm5.459 0h-2.729v1.365h2.729zm4.094 6.823v-1.365h-2.729v1.365zm-16.376 0h2.729v-1.365h-2.729z'
      fill='#f04f50'
    />
    <path
      d='M9.011 46.732h2.729v1.365H9.011zm9.553-5.459v4.094H14.47v1.365h1.365v1.365h-2.73v-1.365h-1.364v-1.365h-2.73v1.365H7.647v1.365h-2.73v-1.365h1.365v-1.365H2.188v-4.094h1.365v-1.365h4.094v-1.364h5.459v1.365H17.2v1.365zm-9.553 1.365H6.282v1.365h2.729zm5.459 0h-2.729v1.365h2.729zm4.094 6.823v-1.365h-2.729v1.365zm-16.376 0h2.729v-1.365H2.188zM45.335 12.53h2.729v1.365h-2.729zm9.553-5.459v4.094h-4.094v1.365h1.365v1.365h-2.73V12.53h-1.364v-1.365h-2.73v1.365h-1.364v1.365h-2.73V12.53h1.365v-1.365h-4.094V7.071h1.365V5.706h4.094V4.342h5.459v1.365h4.094v1.365zm-9.553 1.365h-2.729v1.365h2.729zm5.459 0h-2.729v1.365h2.729zm4.094 6.823v-1.365h-2.729v1.365zm-16.376 0h2.729v-1.365h-2.729zM64.335 34.53h2.729v1.365h-2.729zm9.553-5.459v4.094h-4.094v1.365h1.365v1.365h-2.73V34.53h-1.364v-1.365h-2.73v1.365h-1.364v1.365h-2.73V34.53h1.365v-1.365h-4.094v-4.094h1.365v-1.365h4.094v-1.364h5.459v1.365h4.094v1.365zm-9.553 1.365h-2.729v1.365h2.729zm5.459 0h-2.729v1.365h2.729zm4.094 6.823v-1.365h-2.729v1.365zm-16.376 0h2.729v-1.365h-2.729z'
      fill='#f04f50'
    />
    <path
      d='M46.738 57.808a3.172 3.172 0 103.121 3.23 3.179 3.179 0 00-3.12-3.23z'
      fill='#62c6c4'
    />
    <path
      d='M47.073 38.481l-.891.861a33.425 33.425 0 00-10.02 23.927l-.295 17.41 20.961.356.296-17.41a34.022 34.022 0 00-2.062-12.815 33.1 33.1 0 00-7.99-12.329zm-.484 28.145a5.646 5.646 0 115.737-5.546 5.647 5.647 0 01-5.737 5.546z'
      fill='#62c6c4'
    />
    <path
      d='M44.832 96.924l.154-9.098 2.474.042-.155 9.098z'
      fill='orange'
    />
    <path
      d='M49.945 91.15L50 87.888l2.472.042-.055 3.26z'
      fill='orange'
    />
    <path
      d='M39.919 90.98l.055-3.242 2.472.042-.055 3.242z'
      fill='orange'
    />
    <path
      d='M60.438 66.843l-.895-.016-.242 14.25 4.441.075.135-7.895z'
      fill='#479b9a'
    />
    <path
      d='M29.076 72.665l-.134 7.896 4.451.075.242-14.25-.895-.015z'
      fill='#479b9a'
    />
    <path
      d='M37.91 85.697l16.706.283.957-2.493-18.535-.314z'
      fill='#479b9a'
    />
  </svg>

export const Cogs = () =>
  <svg
    height='120'
    viewBox='0 0 70 73'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M35.2 0A35.2 35.2 0 110 35.2 35.2 35.2 0 0135.2 0z'
      fill='#62c6c4'
    />
    <path
      d='M53.7 19.4l-1.5 1.4-7.4-7.3-11.3 11.8 4.9 4.8-2.8 6.9h1.7l7.5 8.4h-5L37.3 43l-.1.2-12.5-12.5-1.8.4-5-5-6.8 4-.7 8.7v5l-.5 1.8 25 25c22.4-.8 33.9-15.7 35.6-34.3z'
      fill='#4f9f9d'
    />
    <path
      d='M29 38.8V34h-2.7a9.4 9.4 0 00-1-2.5l1.9-1.9-3.4-3.3-1.8 1.9a9.4 9.4 0 00-2.5-1v-2.7h-4.7v2.7a9.4 9.4 0 00-2.4 1l-2-2-3.3 3.4 2 2A9.4 9.4 0 008 34H5.3v4.7H8a9.4 9.4 0 001 2.4l-1.9 2 3.4 3.2 1.9-1.8a9.4 9.4 0 002.4 1v2.6h4.7v-2.6a9.4 9.4 0 002.5-1l1.8 1.8 3.4-3.3-1.9-1.9a9.4 9.4 0 001-2.4zm-11.8 2a4.3 4.3 0 114.3-4.4 4.3 4.3 0 01-4.3 4.3z'
      fill='#eb6868'
    />
    <path
      d='M29 38.8V34h-2.7a9.4 9.4 0 00-1-2.5l1.9-1.9-3.4-3.3-1.9 1.9a9.4 9.4 0 00-2.4-1v-2.7h-2.3v7.5a4.3 4.3 0 010 8.6v7.5h2.3v-2.6a9.4 9.4 0 002.4-1l2 1.8 3.3-3.3-2-1.9a9.4 9.4 0 001.1-2.4H29z'
      fill='#bf4d4d'
    />
    <path
      d='M47.9 53.4v-3.3H46a6.6 6.6 0 00-.7-1.7l1.3-1.3-2.3-2.3L43 46a6.6 6.6 0 00-1.7-.7v-1.9h-3.4v1.9a6.6 6.6 0 00-1.7.7L35 44.8 32.6 47l1.3 1.3a6.6 6.6 0 00-.7 1.7h-1.9v3.3h1.9a6.6 6.6 0 00.7 1.7l-1.3 1.4 2.3 2.3 1.3-1.3a6.6 6.6 0 001.7.7V60h3.4v-1.8a6.6 6.6 0 001.7-.7l1.3 1.3 2.3-2.3-1.3-1.4a6.6 6.6 0 00.7-1.7zm-8.3 1.4a3 3 0 113-3 3 3 0 01-3 3z'
      fill='#907dba'
    />
    <path
      d='M47.9 53.4v-3.3H46a6.6 6.6 0 00-.7-1.7l1.3-1.3-2.3-2.3L43 46a6.6 6.6 0 00-1.7-.7v-1.9h-1.7v5.3a3 3 0 010 6V60h1.7v-1.8a6.6 6.6 0 001.7-.7l1.3 1.3 2.3-2.3-1.3-1.4a6.6 6.6 0 00.7-1.7H48z'
      fill='#6d599a'
    />
    <path
      d='M62 28.9v-6.2h-3.4a12.2 12.2 0 00-1.3-3.2l2.4-2.4-4.3-4.4-2.5 2.5a12.2 12.2 0 00-3.1-1.3v-3.5h-6.2V14a12.2 12.2 0 00-3.1 1.3L38 12.7l-4.3 4.4 2.4 2.4a12.2 12.2 0 00-1.3 3.2h-3.5V29h3.5A12.2 12.2 0 0036 32l-2.4 2.5 4.3 4.3 2.5-2.4a12.2 12.2 0 003.1 1.3V41h6.2v-3.4a12.2 12.2 0 003.1-1.3l2.5 2.4 4.3-4.3-2.4-2.5a12.2 12.2 0 001.3-3.1h3.5zm-15.3 2.5a5.6 5.6 0 115.6-5.6 5.6 5.6 0 01-5.6 5.6z'
      fill='#485eb7'
    />
    <path
      d='M62 28.9v-6.2h-3.4a12.2 12.2 0 00-1.3-3.2l2.4-2.4-4.3-4.4-2.5 2.5a12.2 12.2 0 00-3.1-1.3v-3.5h-3v9.8a5.6 5.6 0 010 11.2V41h3v-3.4a12.2 12.2 0 003.1-1.3l2.5 2.4 4.3-4.3-2.4-2.5a12.2 12.2 0 001.3-3.1z'
      fill='#5b6fc4'
    />
  </svg>

export const Dash = () =>
  <svg
    height='120'
    viewBox='0 0 76 78'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='translate(-600 1004)'>
      <ellipse
        cx='38'
        cy='39'
        fill='#62c6c4'
        rx='38'
        ry='39'
        transform='translate(600 -1004)'
      />
      <path
        d='M669.929-964.872a31.462 31.462 0 01-9.813 22.871l-5.134-5.379a24.051 24.051 0 007.521-17.49z'
        fill='#219644'
      />
      <path
        d='M638.369-996.43v7.426a24.123 24.123 0 00-19.983 37.647l-5.925 4.5a31.559 31.559 0 0125.908-49.573z'
        fill='#907dba'
      />
      <path
        d='M669.929-964.872h-7.426a24.16 24.16 0 00-24.133-24.133v-7.426a31.559 31.559 0 0131.559 31.558z'
        fill='orange'
      />
      <path
        d='M660.116-942.001a31.56 31.56 0 01-47.656-4.853l5.926-4.5a24.1 24.1 0 0036.6 3.972z'
        fill='#ef5261'
      />
      <g
        fill='#479b9a'
      >
        <path
          d='M643.79-971.352h6.54v20.685h-6.54z'
        />
        <path
          d='M621.869-966.932h6.54v16.265h-6.54z'
        />
        <path
          d='M632.828-975.949h6.542v25.282h-6.542z'
        />
      </g>
      <g fill='#fff'>
        <path
          d='M646.06-973.623h6.54v20.685h-6.54z'
        />
        <path
          d='M624.139-969.203h6.54v16.265h-6.54z'
        />
        <path
          d='M635.098-978.22h6.542v25.282h-6.542z'
        />
      </g>
    </g>
  </svg>

export const Suites = () =>
  <svg
    height='120'
    viewBox='0 0 75 76'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='translate(54 1018)'>
      <ellipse
        cx='37.5'
        cy='38'
        fill='#62c6c4'
        rx='37.5'
        ry='38'
        transform='translate(-54 -1018)'
      />
      <path
        d='M15.548-976.303l-9.82 18.586a3.884 3.884 0 01-2.894 1.9l-39.382.007a3.135 3.135 0 01-3.137-3.134l.006-30.581a3.135 3.135 0 013.132-3.134h2.879v2.671h-1.926a1.422 1.422 0 00-1.422 1.422v28.667a1.422 1.422 0 001.423 1.422h1.975l9.307-18.826a3.155 3.155 0 012.57-1.9H1.052l.008-3.814a3.025 3.025 0 01.657.137 2.9 2.9 0 012.006 2.759v.918h10.454c1.238.007 2.274 1.228 1.371 2.9z'
        fill='#479b9a'
      />
      <g fill='#479b9a'>
        <path
          d='M-10.918-1003.44v-6.072h-17.741a2.917 2.917 0 00-2.916 2.9v32.576a2.917 2.917 0 002.916 2.9h24.157a2.917 2.917 0 002.916-2.9v-26.876H-8.37a2.53 2.53 0 01-2.548-2.51zm-9.84 16.612a.734.734 0 11-1.123.946l-2.923-3.473a.733.733 0 010-.945l2.923-3.473a.734.734 0 011.035-.088c.31.261-2.05 1.415-2.311 1.726l-.126 2.309zm5.783-8.678l-1.764 11.577a.734.734 0 11-1.451-.222l1.768-11.576a.734.734 0 111.451.222zm4.772 6.15l-1.072 3.474a.734.734 0 11-1.123-.946l2.526-3-2.526-3a.734.734 0 111.123-.945l2.923 3.473c.226.273-1.616.671-1.846.945zm.321.343'
        />
        <path
          d='M-2.74-1002.382l-6.71-6.185v5.127a1.06 1.06 0 001.062 1.058zm-5.63 0'
        />
      </g>
      <g
        fill='#fff'
      >
        <path
          d='M-8.918-1004.44v-6.072h-17.741a2.917 2.917 0 00-2.916 2.9v32.576a2.917 2.917 0 002.916 2.9h24.157a2.917 2.917 0 002.916-2.9v-26.876H-6.37a2.53 2.53 0 01-2.548-2.51zm-9.84 16.612a.734.734 0 11-1.123.946l-2.923-3.473a.733.733 0 010-.945l2.923-3.473a.734.734 0 011.123.946l-2.526 3zm5.783-8.678l-1.764 11.577a.734.734 0 11-1.451-.222l1.768-11.576a.734.734 0 111.451.222zm6.614 6.15l-2.914 3.474a.734.734 0 11-1.123-.946l2.526-3-2.526-3a.734.734 0 111.123-.945l2.923 3.473a.733.733 0 01-.004.945zm0 0'
        />
        <path
          d='M-6.37-1003.382h5.63l-6.71-6.185v5.127a1.06 1.06 0 001.062 1.058zm0 0'
        />
        <path
          d='M16.548-977.303l-9.82 18.586a3.884 3.884 0 01-2.894 1.9l-39.382.007a3.135 3.135 0 01-3.137-3.134l.006-30.581a3.135 3.135 0 013.132-3.134h2.879v2.671h-1.926a1.422 1.422 0 00-1.422 1.422v28.667a1.422 1.422 0 001.423 1.422h1.975l9.307-18.826a3.155 3.155 0 012.57-1.9H2.052l.008-3.814a3.025 3.025 0 01.657.137 2.9 2.9 0 012.006 2.759v.918h10.454c1.238.007 2.274 1.228 1.371 2.9z'
          fill='#3e2b56'
        />
      </g>
    </g>
  </svg>

export const Journey = () =>
  <svg
    height='120'
    viewBox='0 0 77 81'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='translate(-209 1023)'>
      <ellipse
        cx='38.5'
        cy='39'
        fill='#62c6c4'
        rx='38.5'
        ry='39'
        transform='translate(209 -1020)'
      />
      <g fill='#479b9a'>
        <path
          d='M249.985-1010.921h21.95l5.487 5.487-5.487 5.488h-21.95z'
        />
        <path
          d='M249.985-972.508h16.462l5.488 5.487-5.488 5.488h-16.462z'
        />
        <path
          d='M249.985-991.715h27.437l5.488 5.488-5.488 5.487h-27.437z'
        />
        <path
          d='M243.139-997.202h-27.437l-5.488-5.488 5.488-5.487h27.437z'
        />
        <path
          d='M243.14-977.996h-21.95l-5.488-5.487 5.488-5.488h21.95z'
        />
        <path
          d='M265.089-1006.805h-10.975a1.372 1.372 0 100 2.744h10.975a1.372 1.372 0 100-2.744z'
        />
        <path
          d='M260.614-968.393h-5.488a1.372 1.372 0 100 2.744h5.488a1.372 1.372 0 000-2.744z'
        />
        <path
          d='M270.576-987.599h-16.462a1.372 1.372 0 100 2.744h16.462a1.372 1.372 0 100-2.744z'
        />
        <path
          d='M221.189-1001.318h16.463a1.372 1.372 0 100-2.744h-16.463a1.372 1.372 0 100 2.744z'
        />
        <path
          d='M225.664-982.111h10.975a1.372 1.372 0 100-2.744h-10.975a1.372 1.372 0 000 2.744z'
        />
        <path
          d='M246-1021v79h-2v-77a2 2 0 012-2z'
        />
        <path
          d='M246-1021h1a2 2 0 012 2v77h-3v-79z'
        />
      </g>
      <path
        d='M251.198-1013.349h21.95l5.487 5.487-5.487 5.488h-21.95z'
        fill='#fff'
        stroke='#479b9a'
        strokeWidth='.5'
      />
      <path
        d='M251.198-974.936h16.462l5.488 5.487-5.488 5.488h-16.462z'
        fill='#fff'
        stroke='#479b9a'
        strokeWidth='.5'
      />
      <path
        d='M251.198-994.143h27.437l5.488 5.488-5.488 5.487h-27.437z'
        fill='#fff'
        stroke='#479b9a'
        strokeWidth='.5'
      />
      <path
        d='M244.352-999.63h-27.438l-5.487-5.488 5.487-5.487h27.438z'
        fill='#fff'
        stroke='#479b9a'
        strokeWidth='.5'
      />
      <path
        d='M244.352-980.424h-21.95l-5.488-5.487 5.488-5.488h21.95z'
        fill='#fff'
        stroke='#479b9a'
        strokeWidth='.5'
      />
      <path
        d='M266.302-1009.233h-10.975a1.372 1.372 0 100 2.744h10.975a1.372 1.372 0 100-2.744z'
        fill='#479b9a'
      />
      <path
        d='M261.827-970.821h-5.488a1.372 1.372 0 100 2.744h5.488a1.372 1.372 0 000-2.744z'
        fill='#479b9a'
      />
      <path
        d='M271.789-990.027h-16.462a1.372 1.372 0 100 2.744h16.462a1.372 1.372 0 100-2.744z'
        fill='#479b9a'
      />
      <path
        d='M226.877-984.539h10.975a1.372 1.372 0 100-2.744h-10.975a1.372 1.372 0 000 2.744z'
        fill='#479b9a'
      />
      <path
        d='M247-1023h1v81h-3v-79a2 2 0 012-2z'
        fill='#907dba'
      />
      <path
        d='M247-1023h1a2 2 0 012 2v79h-3v-81z'
        fill='#3e2b56'
      />
      <path
        d='M222.402-1003.745h16.463a1.372 1.372 0 100-2.744h-16.463a1.372 1.372 0 100 2.744z'
        fill='#479b9a'
      />
    </g>
  </svg>

export const Logs = () =>
  <svg
    height='120'
    viewBox='0 0 77 100.451'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='translate(-745.517 -616.031)'>
      <ellipse
        cx='38.5'
        cy='39'
        fill='#62c6c4'
        rx='38.5'
        ry='39'
        transform='translate(745.517 616.031)'
      />
      <rect
        fill='#479b9a'
        height='17'
        rx='2'
        transform='translate(763.517 655.031)'
        width='44'
      />
      <g fill='#479b9a'>
        <path
          d='M772.69 678.523h26.4a4 4 0 003.96-4.027v-28.369h-9.239a4 4 0 01-3.96-4.024v-9.4H772.69a4 4 0 00-3.96 4.027v37.765a4 4 0 003.96 4.028zm5.28-26.937h15.841a1.343 1.343 0 010 2.685h-15.84a1.343 1.343 0 010-2.685zm0 5.369h15.841a1.343 1.343 0 010 2.685h-15.84a1.343 1.343 0 010-2.685zm0 5.369h15.841a1.343 1.343 0 010 2.685h-15.84a1.343 1.343 0 010-2.685zm0-16.262h10.56a1.343 1.343 0 010 2.685h-10.559a1.343 1.343 0 010-2.685z'
        />
        <path
          d='M793.811 643.263h8.467l-9.787-9.787v8.467a1.321 1.321 0 001.32 1.32z'
        />
      </g>
      <g
        fill='#fff'
      >
        <path
          d='M770.632 676.389h26.4a3.965 3.965 0 003.96-3.96V644.53h-9.239a3.965 3.965 0 01-3.96-3.96v-9.24h-17.161a3.965 3.965 0 00-3.96 3.96v37.14a3.965 3.965 0 003.96 3.959zm5.28-26.49h15.841a1.32 1.32 0 010 2.64h-15.84a1.32 1.32 0 010-2.64zm0 5.28h15.841a1.32 1.32 0 010 2.64h-15.84a1.32 1.32 0 010-2.64zm0 5.28h15.841a1.32 1.32 0 010 2.64h-15.84a1.32 1.32 0 010-2.64zm0-15.992h10.56a1.32 1.32 0 010 2.64h-10.559a1.32 1.32 0 010-2.64z'
        />
        <path
          d='M791.753 641.89h8.467l-9.787-9.787v8.467a1.321 1.321 0 001.32 1.32z'
        />
      </g>
      <rect
        fill='#3e2b56'
        height='18'
        rx='2'
        transform='translate(762.517 653.031)'
        width='43'
      />
      <text
        fill='#fff'
        fontFamily='OpenSans-Extrabold, Open Sans'
        fontSize='11'
        fontWeight='800'
        transform='translate(769.517 654.031)'
      >
        <tspan
          x='0'
          y='12'
        >
          LOGS
        </tspan>
      </text>
      <path
        d='M798.82 700.516a.75.75 0 00-.692-.463h-8.207V681.33a.749.749 0 00-.749-.749h-5.796c0-.071-.426 35.875-.393 35.9a1.246 1.246 0 00.688-.153l14.987-14.995a.747.747 0 00.162-.817z'
        fill='#907dba'
      />
      <path
        d='M767.9 700.516a.751.751 0 01.692-.463h8.207V681.33a.749.749 0 01.749-.749h5.796c0-.071.426 35.875.393 35.896a1.246 1.246 0 01-.69-.154l-14.985-14.99a.747.747 0 01-.162-.817z'
        fill='#716193'
      />
    </g>
  </svg>

export const Model = () =>
  <svg
    height='120'
    viewBox='0 0 75 76'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='translate(-588 -317)'>
      <ellipse
        cx='37.5'
        cy='38'
        fill='#62c6c4'
        rx='37.5'
        ry='38'
        transform='translate(588 317)'
      />
      <g fill='#36b15b'>
        <path
          d='M602.476 358.703h-9.544v-5.256a1.3 1.3 0 011.3-1.3h6.944a1.3 1.3 0 011.3 1.3z'
        />
        <path
          d='M619.845 358.703h-9.544v-5.256a1.3 1.3 0 011.3-1.3h6.944a1.3 1.3 0 011.3 1.3z'
        />
      </g>
      <path
        d='M622.836 373.445h-32.895a1.3 1.3 0 01-1.3-1.3v-12.133a2.6 2.6 0 012.6-2.6h30.294a2.6 2.6 0 012.6 2.6v12.132a1.3 1.3 0 01-1.299 1.301z'
        fill='#219644'
      />
      <path
        d='M624.136 360.012v12.133a1.3 1.3 0 01-1.3 1.3h-5.114a1.3 1.3 0 001.3-1.3v-12.133a2.6 2.6 0 00-2.6-2.6h5.114a2.6 2.6 0 012.6 2.6z'
        fill='#197234'
      />
      <g
        fill='#6376c1'
      >
        <path
          d='M639.868 358.703h-9.544v-5.256a1.3 1.3 0 011.3-1.3h6.944a1.3 1.3 0 011.3 1.3z'
        />
        <path
          d='M657.237 358.703h-9.544v-5.256a1.3 1.3 0 011.3-1.3h6.944a1.3 1.3 0 011.3 1.3z'
        />
      </g>
      <path
        d='M660.228 373.445h-32.895a1.3 1.3 0 01-1.3-1.3v-12.133a2.6 2.6 0 012.6-2.6h30.294a2.6 2.6 0 012.6 2.6v12.132a1.3 1.3 0 01-1.299 1.301z'
        fill='#6172b6'
      />
      <path
        d='M661.528 360.012v12.133a1.3 1.3 0 01-1.3 1.3h-5.114a1.3 1.3 0 001.3-1.3v-12.133a2.6 2.6 0 00-2.6-2.6h5.114a2.6 2.6 0 012.6 2.6z'
        fill='#425396'
      />
      <g
        fill='#a290cb'
      >
        <path
          d='M620.527 343.231h-9.544v-5.256a1.3 1.3 0 011.3-1.3h6.944a1.3 1.3 0 011.3 1.3z'
        />
        <path
          d='M637.896 343.231h-9.544v-5.256a1.3 1.3 0 011.3-1.3h6.944a1.3 1.3 0 011.3 1.3z'
        />
      </g>
      <path
        d='M640.887 357.973h-32.895a1.3 1.3 0 01-1.3-1.3V344.54a2.6 2.6 0 012.6-2.6h30.294a2.6 2.6 0 012.6 2.6v12.132a1.3 1.3 0 01-1.299 1.301z'
        fill='#907dba'
      />
      <path
        d='M642.187 344.54v12.133a1.3 1.3 0 01-1.3 1.3h-5.114a1.3 1.3 0 001.3-1.3V344.54a2.6 2.6 0 00-2.6-2.6h5.114a2.6 2.6 0 012.6 2.6z'
        fill='#716193'
      />
    </g>
  </svg>

export const Account = () =>
  <svg
    height='120'
    viewBox='0 0 73.609 73.609'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='ACCOUNT'
      transform='translate(-257 -3240.391)'
    >
      <g
        data-name='Group 632'
        id='Group_632'
        transform='translate(56 3155)'
      >
        <path
          d='M36.8,0A36.8,36.8,0,1,1,0,36.8,36.8,36.8,0,0,1,36.8,0Z'
          data-name='Path 717'
          fill='#907dba'
          id='Path_717'
          transform='translate(201 85.391)'
        />
        <path
          d='M189.514,154.371,164.571,125.51l-19.981,1.157-1.009,12.756-4.535,6.106,27.492,27.505-15.85,10.42q1.393.105,2.813.106A36.816,36.816,0,0,0,189.514,154.371Z'
          data-name='Path 680'
          fill='#6f588d'
          id='Path_680'
          transform='translate(84.304 -24.561)'
        />
        <path
          d='M49.409,29.192A16.049,16.049,0,1,1,33.189,45.24,16.135,16.135,0,0,1,49.409,29.192Zm0,62.734a35.474,35.474,0,0,1-27-12.437A20.578,20.578,0,0,1,40.562,68.583a4.554,4.554,0,0,1,1.309.2,23.212,23.212,0,0,0,15.078,0,4.554,4.554,0,0,1,1.309-.2A20.578,20.578,0,0,1,76.413,79.489,35.474,35.474,0,0,1,49.409,91.926Z'
          fill='#62c6c4'
          id='user-circle-solid'
          transform='translate(188.395 67.073)'
        />
      </g>
    </g>
  </svg>

export const Licence = () =>
  <svg
    height='120'
    viewBox='0 0 73.609 73.609'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      id='LICENCE'
      transform='translate(-211 -4388.391)'
    >
      <g
        data-name='lock (2)'
        id='lock_2_'
        transform='translate(211 4388.391)'
      >
        <path
          d='M36.8,0A36.8,36.8,0,1,1,0,36.8,36.8,36.8,0,0,1,36.8,0Z'
          data-name='Path 717'
          fill='#907dba'
          id='Path_717'
        />
        <path
          d='M190.27,155.736l-11.609-17.88-4.3,1.317-11.121,1.458H139.91l-8.616,24.205,20.149,19.983q1.393.105,2.813.106A36.817,36.817,0,0,0,190.27,155.736Z'
          data-name='Path 680'
          fill='#6f588d'
          id='Path_680'
          transform='translate(-117.451 -111.316)'
        />
      </g>
      <path
        d='M44.394,32H4.036A4.037,4.037,0,0,0,0,36.036v1.345H48.43V36.036A4.037,4.037,0,0,0,44.394,32ZM0,65.632a4.037,4.037,0,0,0,4.036,4.036H44.394a4.037,4.037,0,0,0,4.036-4.036V40.072H0ZM29.6,46.125a.675.675,0,0,1,.673-.673H42.376a.675.675,0,0,1,.673.673v1.345a.675.675,0,0,1-.673.673H30.269a.675.675,0,0,1-.673-.673Zm0,5.381a.675.675,0,0,1,.673-.673H42.376a.675.675,0,0,1,.673.673v1.345a.675.675,0,0,1-.673.673H30.269a.675.675,0,0,1-.673-.673Zm0,5.381a.675.675,0,0,1,.673-.673H42.376a.675.675,0,0,1,.673.673v1.345a.675.675,0,0,1-.673.673H30.269a.675.675,0,0,1-.673-.673ZM14.8,45.453a5.381,5.381,0,1,1-5.381,5.381A5.386,5.386,0,0,1,14.8,45.453ZM5.642,62.622a5.389,5.389,0,0,1,5.12-3.716h.689a8.66,8.66,0,0,0,6.693,0h.689a5.389,5.389,0,0,1,5.12,3.716,1.324,1.324,0,0,1-1.312,1.665H6.953A1.327,1.327,0,0,1,5.642,62.622Z'
        fill='#62c6c4'
        id='id-card-solid'
        transform='translate(223.59 4374.361)'
      />
    </g>
  </svg>

export const ApiAccess = () =>
  <svg
    height='120'
    viewBox='0 0 100.254 106.363'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      data-name='API ICON'
      id='API_ICON'
      transform='translate(-0.001)'
    >
      <path
        d='M49.943,106.363a53.473,53.473,0,0,1-8.642-.727,5,5,0,0,1-3.781-3.7l-2.6-11.862a40.79,40.79,0,0,1-9.14-5.3l-11.563,3.7a4.973,4.973,0,0,1-1.5.233,4.643,4.643,0,0,1-3.588-1.646,54.86,54.86,0,0,1-4.594-6.5l-.972-1.683A51.855,51.855,0,0,1,.285,71.753,4.7,4.7,0,0,1,1.6,66.643l8.987-8.186a40.241,40.241,0,0,1,0-10.553L1.6,39.72A4.711,4.711,0,0,1,.285,34.61a52.06,52.06,0,0,1,3.278-7.125l.97-1.683a54.052,54.052,0,0,1,4.6-6.492,4.619,4.619,0,0,1,3.58-1.651,4.965,4.965,0,0,1,1.511.236l11.563,3.689a39.986,39.986,0,0,1,9.14-5.291l2.6-11.862A4.7,4.7,0,0,1,41.3.729a53.829,53.829,0,0,1,17.658,0,4.7,4.7,0,0,1,3.781,3.7l2.6,11.862a44.329,44.329,0,0,1,9.14,5.291l11.571-3.689a4.923,4.923,0,0,1,1.5-.236,4.66,4.66,0,0,1,3.585,1.651A53.916,53.916,0,0,1,95.729,25.8l.976,1.683a52.72,52.72,0,0,1,3.262,7.125,4.421,4.421,0,0,1,.133,2.734,4.983,4.983,0,0,1-1.441,2.376l-9,8.185a39.53,39.53,0,0,1,0,10.553l9,8.186a4.982,4.982,0,0,1,1.441,2.376,4.421,4.421,0,0,1-.133,2.734,52.724,52.724,0,0,1-3.262,7.125l-.976,1.683a54.94,54.94,0,0,1-4.591,6.5,4.685,4.685,0,0,1-3.593,1.646,4.93,4.93,0,0,1-1.5-.233l-11.571-3.7a45.361,45.361,0,0,1-9.14,5.3l-2.6,11.862a5,5,0,0,1-3.781,3.7A54.245,54.245,0,0,1,49.943,106.363Zm.186-85.116a31.834,31.834,0,1,0,12.43,2.51A31.736,31.736,0,0,0,50.129,21.247Z'
        data-name='Subtraction 6'
        fill='#3e2b56'
        id='Subtraction_6'
        transform='translate(0.001 0)'
      />
      <path
        d='M33.332,109.391,20.879,96.937a3.113,3.113,0,0,0-4.4,4.4l7.146,7.136H3.113a3.113,3.113,0,0,0,0,6.227H23.622l-7.139,7.139a3.113,3.113,0,0,0,4.4,4.4l12.453-12.454A3.116,3.116,0,0,0,33.332,109.391Z'
        fill='#62c6c4'
        id='arrow-right-to-bracket-solid'
        transform='translate(36.521 -69.215)'
      />
      <path
        d='M.913,109.391,13.366,96.937a3.113,3.113,0,0,1,4.4,4.4l-7.146,7.136H31.132a3.113,3.113,0,0,1,0,6.227H10.623l7.139,7.139a3.113,3.113,0,1,1-4.4,4.4L.906,113.791A3.116,3.116,0,0,1,.913,109.391Z'
        data-name='arrow-right-to-bracket-solid'
        fill='#5b89d5'
        id='arrow-right-to-bracket-solid-2'
        transform='translate(29.497 -47.604)'
      />
    </g>
  </svg>

export const ScheduleManagement = () =>
  <svg
    fill='none'
    height='120'
    viewBox='0 0 39 39'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M33.7086 2.50204H31.9857L31.4935 4.30707C31.0831 5.94779 29.4833 7.17851 27.8426 7.17851C26.9401 7.17851 26.0788 6.80938 25.5863 6.11202C25.0529 5.41469 24.889 4.4713 25.135 3.56875L25.4223 2.50226L19.884 2.50198L19.3919 4.307C18.9406 5.94772 17.3817 7.17844 15.7001 7.17844C14.7976 7.17844 13.9772 6.80931 13.4438 6.11195C12.9105 5.41459 12.7465 4.47123 12.9926 3.56869L13.2799 2.5022H11.4748C8.72638 2.5022 5.978 4.63539 5.28063 7.38384L0.193587 26.1307C-0.216746 27.6075 0.0296252 29.0843 0.890918 30.1508C1.71128 31.2173 2.98295 31.8327 4.41878 31.8327H6.9211V32.9404C6.9211 35.8531 9.30035 38.232 12.2127 38.232H33.7081C36.6208 38.232 38.9997 35.8528 38.9997 32.9404L39 7.79413C39 4.88145 36.6209 2.50204 33.7086 2.50204ZM4.46014 28.6328C4.13193 28.6328 3.7219 28.5507 3.47555 28.2225C3.22947 27.9352 3.18826 27.4842 3.35251 26.9918L7.53679 11.3215H33.0522L29.0731 26.1713C28.704 27.525 27.2681 28.6737 25.9556 28.6737L4.46024 28.6739L4.46014 28.6328ZM35.8007 32.9399C35.8007 34.0885 34.8572 35.0319 33.7086 35.0319L12.2543 35.0322C11.1057 35.0322 10.1623 34.0888 10.1623 32.9402V31.8325H25.9556C28.7041 31.8325 31.4524 29.6993 32.1498 26.9509L35.8007 13.3727L35.8007 32.9399Z'
      fill='#3E2B56'
    />
    <path
      d='M15.7001 5.70171C16.6847 5.70171 17.6691 4.92223 17.9151 3.93793L18.4894 1.76378C18.7355 0.779191 18.1612 0 17.2178 0C16.2332 0 15.2489 0.779484 15.0028 1.76378L14.4282 3.93793C14.1821 4.88135 14.7564 5.70171 15.7001 5.70171Z'
      fill='#907DBA'
    />
    <path
      d='M27.8425 5.70171C28.8271 5.70171 29.8115 4.92223 30.0575 3.93793L30.6318 1.76378C30.8779 0.779191 30.3036 0 29.3602 0C28.3756 0 27.3912 0.779484 27.1452 1.76378L26.53 3.93793C26.2836 4.88135 26.8579 5.70171 27.8425 5.70171Z'
      fill='#907DBA'
    />
    <path
      d='M19.6027 21.1957H15.7879C15.5418 21.1957 15.2545 21.4008 15.1726 21.6469L14.4344 24.4362C14.3523 24.6823 14.5165 24.8875 14.7626 24.8875H18.5775C18.8236 24.8875 19.1108 24.6823 19.1927 24.4362L19.9309 21.6469C20.0128 21.4008 19.8488 21.1957 19.6027 21.1957Z'
      fill='#62C6C4'
    />
    <path
      d='M12.8696 21.1957H9.05472C8.80863 21.1957 8.52135 21.4008 8.43951 21.6469L7.70127 24.4362C7.61915 24.6823 7.78339 24.8875 8.02948 24.8875H11.8443C12.0904 24.8875 12.3777 24.6823 12.4596 24.4362L13.1978 21.6469C13.2796 21.4008 13.1566 21.1957 12.8696 21.1957Z'
      fill='#62C6C4'
    />
    <path
      d='M26.3246 20.9619H22.5098C22.2637 20.9619 21.9764 21.167 21.8946 21.4131L21.1557 24.2027C21.0736 24.4488 21.2379 24.654 21.484 24.654H25.2988C25.5449 24.654 25.8322 24.4488 25.914 24.2027L26.6523 21.4134C26.7347 21.167 26.5707 20.9619 26.3246 20.9619Z'
      fill='#62C6C4'
    />
    <path
      d='M19.6027 15.2609H15.7879C15.5418 15.2609 15.2545 15.466 15.1727 15.7121L14.4344 18.5014C14.3523 18.7475 14.5165 18.9527 14.7626 18.9527H18.5775C18.8236 18.9527 19.1108 18.7475 19.1927 18.5014L19.9309 15.7121C20.0131 15.466 19.89 15.2609 19.6027 15.2609Z'
      fill='#62C6C4'
    />
    <path
      d='M13.6679 15.2609H9.85308C9.60699 15.2609 9.31971 15.466 9.23787 15.7121L8.49963 18.5014C8.4175 18.7475 8.58175 18.9527 8.82784 18.9527H12.6427C12.8888 18.9527 13.1761 18.7475 13.2579 18.5014L13.9962 15.7121C14.078 15.466 13.914 15.2609 13.6679 15.2609Z'
      fill='#62C6C4'
    />
    <path
      d='M21.5486 18.9527H25.3637C25.6098 18.9527 25.8971 18.7475 25.9789 18.5014L26.7172 15.7121C26.7993 15.466 26.635 15.2609 26.389 15.2609H22.5741C22.328 15.2609 22.0407 15.466 21.9589 15.7121L21.2206 18.5014C21.1385 18.7066 21.2616 18.9527 21.5486 18.9527Z'
      fill='#62C6C4'
    />
  </svg>

export const ClearFilter = () =>
  <svg
    fill='none'
    height='16'
    viewBox='0 0 16 16'
    width='16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      clipRule='evenodd'
      d='M9.52876 5.52729C9.78911 5.26694 10.2112 5.26694 10.4716 5.52729L11.3335 6.38922L12.1954 5.52729C12.4558 5.26694 12.8779 5.26694 13.1382 5.52729C13.3986 5.78764 13.3986 6.20975 13.1382 6.4701L12.2763 7.33203L13.1382 8.19396C13.3986 8.45431 13.3986 8.87642 13.1382 9.13677C12.8779 9.39712 12.4558 9.39712 12.1954 9.13677L11.3335 8.27484L10.4716 9.13677C10.2112 9.39712 9.78911 9.39712 9.52876 9.13677C9.26841 8.87642 9.26841 8.45431 9.52876 8.19396L10.3907 7.33203L9.52876 6.4701C9.26841 6.20975 9.26841 5.78764 9.52876 5.52729Z'
      fill='#55595C'
      fillRule='evenodd'
    />
    <path
      d='M2.66683 1.33203C2.31321 1.33203 1.97407 1.47251 1.72402 1.72256C1.47397 1.9726 1.3335 2.31174 1.3335 2.66536V4.38951C1.33357 4.74307 1.47414 5.08219 1.72416 5.33217L6.00016 9.60817V13.9987C6.00016 14.2683 6.16259 14.5114 6.41171 14.6146C6.66082 14.7178 6.94757 14.6608 7.13823 14.4701L9.8049 11.8034C9.92992 11.6784 10.0002 11.5088 10.0002 11.332L10.0002 10.6654H8.66683V11.0559L7.3335 12.3892V9.60789C7.33342 9.25433 7.19285 8.91521 6.94283 8.66522L2.66683 4.38922V2.66536H13.3335L13.3334 3.9987H14.6668V2.66536C14.6668 2.31174 14.5264 1.9726 14.2763 1.72256C14.0263 1.47251 13.6871 1.33203 13.3335 1.33203H2.66683Z'
      fill='#55595C'
    />
    <path
      d='M14.6668 3.9987C14.6668 4.36689 14.3684 4.66536 14.0002 4.66536C13.632 4.66536 13.3334 4.36689 13.3334 3.9987C13.3334 3.63051 13.632 3.33203 14.0002 3.33203C14.3684 3.33203 14.6668 3.63051 14.6668 3.9987Z'
      fill='#55595C'
    />
    <path
      d='M10.0002 10.6654C10.0002 11.0336 9.70169 11.332 9.3335 11.332C8.96531 11.332 8.66683 11.0336 8.66683 10.6654C8.66683 10.2972 8.96531 9.9987 9.3335 9.9987C9.70169 9.9987 10.0002 10.2972 10.0002 10.6654Z'
      fill='#55595C'
    />
  </svg>

export const HiClipboardCheckTestCase = () =>
  <svg
    fill='none'
    height='120'
    viewBox='0 0 120 120'
    width='120'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M54 12C52.4087 12 50.8826 12.6321 49.7574 13.7574C48.6321 14.8826 48 16.4087 48 18C48 19.5913 48.6321 21.1174 49.7574 22.2426C50.8826 23.3679 52.4087 24 54 24H66C67.5913 24 69.1174 23.3679 70.2426 22.2426C71.3679 21.1174 72 19.5913 72 18C72 16.4087 71.3679 14.8826 70.2426 13.7574C69.1174 12.6321 67.5913 12 66 12H54Z'
      fill='#E5CEFF'
    />
    <path
      clipRule='evenodd'
      d='M24 30C24 26.8174 25.2643 23.7652 27.5147 21.5147C29.7652 19.2643 32.8174 18 36 18C36 22.7739 37.8964 27.3523 41.2721 30.7279C44.6477 34.1036 49.2261 36 54 36H66C70.7739 36 75.3523 34.1036 78.7279 30.7279C82.1036 27.3523 84 22.7739 84 18C87.1826 18 90.2348 19.2643 92.4853 21.5147C94.7357 23.7652 96 26.8174 96 30V96C96 99.1826 94.7357 102.235 92.4853 104.485C90.2348 106.736 87.1826 108 84 108H36C32.8174 108 29.7652 106.736 27.5147 104.485C25.2643 102.235 24 99.1826 24 96V30ZM82.242 64.242C83.3349 63.1104 83.9397 61.5948 83.926 60.0216C83.9124 58.4484 83.2814 56.9435 82.1689 55.8311C81.0565 54.7186 79.5516 54.0876 77.9784 54.074C76.4052 54.0603 74.8896 54.6651 73.758 55.758L54 75.516L46.242 67.758C45.1104 66.6651 43.5948 66.0603 42.0216 66.074C40.4484 66.0876 38.9435 66.7186 37.8311 67.8311C36.7186 68.9435 36.0876 70.4484 36.074 72.0216C36.0603 73.5948 36.6651 75.1104 37.758 76.242L49.758 88.242C50.8832 89.3668 52.409 89.9987 54 89.9987C55.591 89.9987 57.1168 89.3668 58.242 88.242L82.242 64.242Z'
      fill='#7E57C5'
      fillRule='evenodd'
    />
  </svg>
