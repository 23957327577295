import { GroupedSingleSelect } from '@eggplant/pattern-library'
import type {
  GetSuiteScriptsResponse,
  GroupedOptions,
  SuiteScript
} from '@eggplant/types'

interface Props {
  isRequired?: boolean
  isDisabled?: boolean
  prompt?: string
  value: string
  onChange: (value: string) => void
  label?: string
  data: GetSuiteScriptsResponse
}

const getOptions = (items: GetSuiteScriptsResponse) =>
  items.map((suite: SuiteScript) => ({
    label: suite.suite_name,
    options: suite.scripts.map(script => ({
      label: script,
      value: script
    }))
  })) as GroupedOptions

const SelectScript = ({
  isRequired = false,
  isDisabled,
  prompt = 'Select a Script',
  value,
  onChange,
  label = 'Scripts',
  data
}: Props) =>
  <GroupedSingleSelect
    groups={getOptions(data)}
    isDisabled={isDisabled}
    isRequired={isRequired}
    label={label}
    onChange={onChange}
    prompt={prompt}
    value={value}
  />

export default SelectScript
