import {
  ErrorPlaceholder,
  ErrorPopOut
} from '@eggplant/pattern-library'
import type { CSSProperties } from 'react'
import type { Error } from '@eggplant/types'

const wrapperStyle: CSSProperties = {
  minWidth: '240px',
  maxWidth: '560px'
}

interface Props {
  error: Error
}

const ErrorDetails = ({ error }: Props) =>
  <ErrorPlaceholder
    status={(error.status || '').toString()}
    title={error.title || ''}
  >
    {error.detail}
  </ErrorPlaceholder>

// Not all errors are complete or of the correct shape.
const renderError = (error: Error, shortErrorMessage?: string) =>
  shortErrorMessage
    ? (
      <ErrorPopOut title={shortErrorMessage}>
        <div style={wrapperStyle}>
          <ErrorDetails error={error} />
        </div>
      </ErrorPopOut>
    )
    : <ErrorDetails error={error} />

export default renderError
