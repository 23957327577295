import ReactDOM from 'react-dom'
import classes from './PopOut.module.scss'
import { FocusOn } from 'react-focus-on'
import {
  Manager,
  Popper,
  Reference
} from 'react-popper'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import type { PopperProps } from 'react-popper'
import type { ReactNode } from 'react'

// It is possible to crash the UI in rare cases due to the portal.
// https://github.com/facebook/react/issues/14811

// Checklist:
// Click-away closes.
// ESC closes.
// Back button works when PopOut is open.
// Popout does not trap focus if it contains no focusable elements.

interface Props {
  trigger: (ref: any, onClick: () => void, isOpen?: boolean) => ReactNode
  children: ReactNode
  placement?: PopperProps['placement']
  hasIndicator?: boolean
  hasBorder?: boolean
}

const PopOut = ({
  trigger,
  children,
  placement,
  hasIndicator = false,
  hasBorder = true
}: Readonly<Props>) => {
  const portalRef = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) return undefined

    const portal = portalRef.current

    if (!portal) return undefined

    // The portal is rendered in this component
    if (portal.parentNode) portal.parentNode.removeChild(portal)

    // Move it from this component to the body
    document.body.appendChild(portal)

    // To prevent a stack of dead and empty portals, we must clean up the portal when this component unmounts.
    return () => {
      if (!portal.parentNode) return

      portal.parentNode.removeChild(portal)
    }
  }, [isOpen])

  const handleOpenToggle = () => {
    setIsOpen(!isOpen)
  }

  const getChildrenWrapperStyle = (hasIndicator: boolean, hasBorder: boolean) => {
    if (!hasBorder) {
      return classes.PopOut + ' ' + classes.PopOutBorderless
    }

    return hasIndicator
      ? classes.PopOut + ' ' + classes.PopOutBorder + ' ' + classes.WithArrow
      : classes.PopOut + ' ' + classes.PopOutBorder
  }

  return (
    <span className={classes.ButtonWrapper}>
      <Manager>
        <>
          <Reference>
            {
              ({ ref }) => trigger(ref, handleOpenToggle, isOpen)
            // create  trigger prop in the Props interface
            }
          </Reference>
          {
            isOpen &&
            portalRef.current &&
            ReactDOM.createPortal(
              <FocusOn
                onClickOutside={handleOpenToggle}
                onEscapeKey={handleOpenToggle}
              >
                <Popper placement={placement}>
                  {
                    ({
                      ref,
                      style,
                      placement,
                      arrowProps
                    }) =>
                      <div
                        className={getChildrenWrapperStyle(hasIndicator, hasBorder)}
                        data-placement={placement}
                        ref={ref}
                        style={style}
                      >
                        {children}
                        {
                          hasIndicator &&
                            <div
                              className={classes.Arrow}
                              data-placement={placement}
                              ref={arrowProps.ref}
                              style={arrowProps.style}
                            />
                        }
                      </div>
                  }
                </Popper>
              </FocusOn>,
              portalRef.current
            )
          }
          <div ref={portalRef} />
        </>
      </Manager>
    </span>
  )
}

export default PopOut
