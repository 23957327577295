import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  RUNHISTORY
} from '../routes'
import type { Props } from '../types'

const base = '/' + CONTROLLER + '/' + RUNHISTORY

const LinkToRunHistory = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base}
  >
    {children}
  </StyledLink>

export default LinkToRunHistory
