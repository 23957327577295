import classes from './Frame.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  hasBackground?: boolean
  hasBorderTop?: boolean
  hasBorderBottom?: boolean
  hasBorderLeft?: boolean
  hasBorderRight?: boolean
  height?: string
}

const getClasses = (hasBackground: boolean,
  hasBorderTop: boolean,
  hasBorderBottom: boolean,
  hasBorderLeft: boolean,
  hasBorderRight: boolean) => [
  classes.Frame,
  !hasBackground || classes.hasBackground,
  !hasBorderTop || classes.hasBorderTop,
  !hasBorderBottom || classes.hasBorderBottom,
  !hasBorderLeft || classes.hasBorderLeft,
  !hasBorderRight || classes.hasBorderRight
]
  .filter(item => item !== true)
  .join(' ')

const Frame = ({
  children,
  hasBackground = false,
  hasBorderTop = false,
  hasBorderBottom = false,
  hasBorderLeft = false,
  hasBorderRight = false,
  height
}: Props) => (
  <div
    className={getClasses(hasBackground, hasBorderTop, hasBorderBottom, hasBorderLeft, hasBorderRight)}
    style={{ height }}
  >
    {children}
  </div>
)

export default Frame
