import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { Dash } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { LinkToCoverageReportDoc } from '../links'

const description = 'The Coverage report shows how much of your model has been explored during testing. It also shows predictions for how long it will take to achieve 90% coverage for each model based on actual test results. Models must have at least one run to display a Coverage report.'

const InsightsCoverageIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Dash />}
        renderLink={<LinkToCoverageReportDoc />}
        title='Coverage Report'
      />
    </div>
  </div>

export default InsightsCoverageIntro
