import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { testResults } from '../../docs-routes'

const TestResults = () =>
  <Paragraph>
    Test Results shows an overview of results from your test runs. You also can view full details of tests and download logs from this area.
    <DocusaurusFooter path={testResults} />
  </Paragraph>

export default TestResults
