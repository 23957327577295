// Props that can be passed via routes
export const PROP_GIT_CONNECTION_ID = 'id'
export const PROP_MODEL_ID = 'modelId'
export const PROP_TASK_INSTANCE_ID = 'taskInstanceId'
export const PROP_TEST_CASE = 'testcase'
export const PROP_TEST_CONFIG_ID = 'test_config_id'

// Adding a wildcard to the end of the `path` prop will ensure that matching path is rendered
// Even if a much more specific URL is used
export const WILDCARD = '/*'
