import {
  Header,
  Spinner
} from '@eggplant/pattern-library'
import type {
  CSSProperties,
  ReactNode
} from 'react'

const wrapperStyle: CSSProperties = {
  height: '100%',
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

interface Props {
  children: ReactNode
}

const AssertivePlaceholder = ({
  children
}: Props) =>
  <div style={wrapperStyle}>
    <Spinner />
    <Header rank={4}>
      <div aria-live='assertive'>
        {children}
      </div>
    </Header>
  </div>

export default AssertivePlaceholder
