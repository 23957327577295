import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { LinkToAgentToolsDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import type { CSSProperties } from 'react'

const paddingStyle: CSSProperties = {
  paddingBottom: '110px'
}

const description = 'Agent Tools include installers and API keys that enable you to use the Eggplant DAI Agent. First, download and install the appropriate Eggplant DAI Agent installer for your operating system. You can then create an Execution Environment and generate an Access Key to allow the Agent to authenticate.'

const AgentToolsIntro = () =>
  <div
    className='grid justify-center'
    style={paddingStyle}
  >
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Path />}
        renderLink={<LinkToAgentToolsDoc />}
        title='Agent Tools'
      />
    </div>
  </div>

export default AgentToolsIntro
