import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { TagsInput } from '@eggplant/pattern-library'
import { useKeycloak } from '@eggplant/context'
import type {
  Principal,
  User
} from '@eggplant/types'

interface Props {
  onChange: (selectedUsers: User[]) => void
  selectedUsers: User[]
}

const mapper = ({ id, name }: User) => ({
  name,
  id,
  color: '#666'
})

const SelectMultipleUser = ({
  onChange,
  selectedUsers
}: Props) => {
  const { keycloak: keycloakInstance } = useKeycloak()

  const url = keycloakInstance.authServerUrl + 'realms/' + keycloakInstance.realm + '/dai/v1/users'

  const selectedTags = selectedUsers.map(mapper)

  return (
    <AuthenticatedDataRequest
      method='get'
      url={url}
    >
      {
        (data: Principal[]) => {
          const handleChange = (tags: User[]) => {
            const someTags = data.filter(({ id }) =>
              tags.some(tag => id === tag.id)
            )

            onChange(someTags)
          }

          return (
            <TagsInput
              availableTags={data.map(mapper)}
              isNewItemAllowed
              label='Model Access users'
              maxLength={50}
              onSelectedTagsChanged={handleChange}
              placeholder='Add a user'
              selectedTags={selectedTags}
            />
          )
        }
      }
    </AuthenticatedDataRequest>
  )
}

export default SelectMultipleUser
