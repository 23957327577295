import {
  AuthenticatedDataRequest,
  AuthenticatedPolling
} from '@eggplant/data-request'
import { FaSync } from 'react-icons/fa'
import {
  IconButton,
  Paragraph,
  PollingIndicator,
  ResultsCount,
  ResultsFooter,
  Timezone,
  Tooltip
} from '@eggplant/pattern-library'
import type { GetCollectionResponseBase } from '@eggplant/types'
import type { HandleError } from '../types'

interface Props<T> {
  isPolling: boolean
  intervalMs?: number
  onError?: HandleError
  onSuccess: (response: T) => void
  onPollingToggle: () => void
  params: Record<string, string | number | undefined>
  url: string
}

const style = {
  display: 'grid',
  gap: '12px',
  gridAutoFlow: 'column'
}

// Abstracts the switch between a single request and polling

const ResultsPollingToggle = <T extends GetCollectionResponseBase, >({
  isPolling,
  intervalMs = 5000,
  onError,
  onSuccess,
  onPollingToggle,
  params,
  url
}: Props<T>) => {
  const buttonContent = isPolling
    ? <PollingIndicator durationMs={5000} />
    : <FaSync aria-hidden />

  const title = isPolling
    ? 'Updating results every 5 seconds. Click to stop.'
    : 'Refresh results and enable automatic updates.'

  const children = ({ total_count }: T) =>
    <ResultsFooter>
      <Paragraph>
        <span style={style}>
          <ResultsCount value={total_count} />
          <Tooltip
            label={title}
          >
            <IconButton
              onClick={onPollingToggle}
              title={title}
            >
              {buttonContent}
            </IconButton>
          </Tooltip>
        </span>
      </Paragraph>
      <Timezone />
    </ResultsFooter>

  const props = {
    onError,
    onSuccess,
    params,
    url,
    children
  }

  return isPolling
    ? (
      <AuthenticatedPolling<T>
        intervalMs={intervalMs}
        isPolling
        key={JSON.stringify(params)} // refreshes the component to restart polling
        {...props}
      />
    )
    : (
      <AuthenticatedDataRequest
        method='get'
        {...props}
      />
    )
}

export default ResultsPollingToggle
