/* eslint-disable react/boolean-prop-naming */
// New ESlint bug as of react-scripts 3.4.0
/* eslint-disable react/no-unused-prop-types */
import styles from './SmallButton.module.scss'
import type { ReactNode } from 'react'

interface Props {
  // This prop is forwarded from Downshift to match the DOM attribute name, it cannot be changed.
  'aria-activedescendant'?: string // MultiselectDropdown
  'aria-haspopup'?: boolean
  'aria-label'?: string
  children: ReactNode
  id?: string
  isDisabled?: boolean
  onBlur?: () => void // Downshift
  onKeyDown?: () => void // Downshift
  onKeyUp?: () => void // Downshift
  role?: string
  type?: 'button' | 'reset'| 'link'
  onClick?: () => void
  title?: string
}

const SmallButton = ({
  'aria-activedescendant': ariaActivedescendant,
  'aria-haspopup': ariaHasPopup = false,
  'aria-label': ariaLabel,
  children,
  id,
  isDisabled = false,
  onBlur,
  onClick,
  onKeyDown,
  onKeyUp,
  role,
  title,
  type = 'button'
}: Props) => {
  const buttonClassName = styles.SmallButton + ' ' + styles.secondary

  if (type === 'link') {
    return (
      <span
        className={buttonClassName}
        title={title}
      >
        {children}
      </span>
    )
  }

  return (
    <button
      aria-activedescendant={ariaActivedescendant}
      aria-haspopup={ariaHasPopup}
      aria-label={ariaLabel}
      className={buttonClassName}
      disabled={isDisabled}
      id={id}
      onBlur={onBlur}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      role={role}
      title={title}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </button>
  )
}

export default SmallButton
