import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { coverageReport } from '../../docs-routes'

const CoverageHistory = () =>
  <Paragraph>
    The Coverage report shows the coverage percentage against the number of runs for the default coverage types (All Nodes, All Pairs, Extended, Full Exploratory). Models must have at least one run to display a Coverage report.
    <DocusaurusFooter path={coverageReport} />
  </Paragraph>

export default CoverageHistory
