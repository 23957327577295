import TaskAbort from './TaskAbort'
import {
  CentralModal,
  StopButton
} from '@eggplant/pattern-library'
import { useState } from 'react'

interface Props {
  taskInstanceId: string
}

const AbortTestConfigIconButton = ({
  taskInstanceId
}: Props) => {
  const [openModal, setOpenModal] = useState(false)

  const handleAbortButtonClick = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleSuccess = () => {
    setTimeout(handleModalClose, 1500)
  }

  // Once the button is clicked it will be replaced by the request
  // The button should be restored depending on the re-render cycle of the parent

  return (
    <>
      <CentralModal
        isOpen={openModal}
        label='Cancel a Test Config run'
        onRequestClose={handleModalClose}
      >
        {openModal &&
          <TaskAbort
            onCancel={handleModalClose}
            onSuccess={handleSuccess}
            taskInstanceId={taskInstanceId}
          />}
      </CentralModal>
      <StopButton
        onClick={handleAbortButtonClick}
        title='Cancel test config run'
      />
    </>
  )
}

export default AbortTestConfigIconButton
