import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { TagsInput } from '@eggplant/pattern-library'
import { useKeycloak } from '@eggplant/context'
import type {
  Group,
  Principal
} from '@eggplant/types'

interface Props {
  onChange: (selectedGroups: Group[]) => void
  selectedGroups: Group[]
  isAdmin: boolean
}

const mapper = ({ id, name }: Group) => ({
  name,
  id,
  color: '#666'
})

const SelectMultipleGroup = ({
  onChange,
  selectedGroups,
  isAdmin
}: Props) => {
  const selectedTags = selectedGroups.map(mapper)

  const { keycloak: keycloakInstance } = useKeycloak()

  const url = isAdmin
    ? keycloakInstance.authServerUrl + 'realms/' + keycloakInstance.realm + '/dai/v1/groups'
    : keycloakInstance.authServerUrl + 'realms/' + keycloakInstance.realm + '/dai/v1/users/' + keycloakInstance.subject + '/groups'

  const getFilterGroups = (groups: Principal[]) =>
    groups.filter(
      (group: Principal) =>
        group.name !== 'group:dai:user' &&
        group.name !== 'group:dai:admin' &&
        group.name !== 'administrators'
    ).map(mapper)

  return (
    <AuthenticatedDataRequest
      acceptType='application/json'
      method='get'
      url={url}
    >
      {
        (data: Principal[]) => {
          const handleChange = (tags: Group[]) => {
            const someTags = data.filter(({ id }) =>
              tags.some(tag => id === tag.id)
            )

            onChange(someTags)
          }

          return (
            <TagsInput
              availableTags={getFilterGroups(data)}
              isNewItemAllowed
              label='Model Access groups'
              maxLength={50}
              onSelectedTagsChanged={handleChange}
              placeholder='Add a group'
              selectedTags={selectedTags}
            />
          )
        }
      }
    </AuthenticatedDataRequest>
  )
}

export default SelectMultipleGroup
