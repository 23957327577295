import {
  Color,
  Header,
  Icons,
  LinkButton,
  Paragraph
} from '@eggplant/pattern-library'
import { LinkToModelHub } from '@eggplant/routing'
import type { CSSProperties } from 'react'

interface Props {
  isUser?: boolean
}

const placeHolderWrapperStyle: CSSProperties = {
  display: 'grid',
  justifyItems: 'center',
  overflow: 'hidden',
  rowGap: '2em',
  textAlign: 'center',
  whiteSpace: 'pre-line'
}

const paddingTopStyle: CSSProperties = {
  display: 'grid',
  justifyContent: 'center',
  paddingTop: '81.5px'
}

const placeHolderOuterStyle: CSSProperties = {
  display: 'grid',
  maxWidth: '36rem',
  alignContent: 'baseline'
}

const AccessDenied = ({
  isUser = false
}: Props) => {
  const role = isUser
    ? 'an user'
    : 'a viewer'

  return (
    <div style={paddingTopStyle}>
      <div style={placeHolderOuterStyle}>
        <div style={placeHolderWrapperStyle}>
          <Color variant='highlightred'>
            <Icons.HeartBroken
              aria-hidden
              size='120px'
            />
          </Color>
          <Header rank={2}>
            Oops! We are sorry...
          </Header>
          <Paragraph>
            As {role} role on this account, you don't have permission to access this page.
            If you need to access this page or believe this is a mistake, please contact your admin to upgrade your role accordingly.
            They will be able to assist you in granting the necessary permissions.
          </Paragraph>
          <span>
            <LinkButton variant='primary'>
              <LinkToModelHub>
                Back to home page
              </LinkToModelHub>
            </LinkButton>
          </span>
        </div>
      </div>
    </div>
  )
}

export default AccessDenied
