import { AI_API_SERVICE } from '@eggplant/api-hosts'
import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  NoOptionsPlaceholder
} from '@eggplant/pattern-library'
import { FiPlus } from 'react-icons/fi'
import { HiOutlineServer } from 'react-icons/hi'
import { LinkToAddEnvironment, useAccess } from '@eggplant/routing'
import type {
  ButtonShape,
  EnvironmentResponse,
  Options
} from '@eggplant/types'

interface Props {
  onChange: (value: string) => void
  placeholder?: string
  value?: string
}

const url = AI_API_SERVICE + '/agents'

const getOptions = (items: EnvironmentResponse[] | undefined) => {
  if (!Array.isArray(items)) return []

  return items.map(item => ({
    label: item.name,
    value: '' + item.id
  }))
}

const SelectExecutionEnvironment = ({
  onChange,
  placeholder = 'Execution environment',
  value
}: Props) => {
  const [isAccessible] = useAccess()

  const renderButton = (options: Options) => {
    const currentOption = options.find(item => item.value === value)

    const label = currentOption?.value
      ? currentOption.label
      : placeholder

    const tooltip = currentOption?.value
      ? `Execution environment: ${currentOption.label}`
      : label

    const buttonText = currentOption?.value
      ? currentOption.label
      : placeholder

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={HiOutlineServer}
        isFilter
        isHighlighted={!!value}
        label={label}
        title={tooltip}
      >
        {buttonText}
      </DropdownButton>
  }

  const noDataPlaceholder =
    <NoOptionsPlaceholder
      icon={<HiOutlineServer size='24px' />}
      linkTo={
        isAccessible
          ? (
            <LinkToAddEnvironment>
              <FiPlus
                aria-hidden
                size={16}
              />
              New Environment
            </LinkToAddEnvironment>
          )
          : undefined
      }
      title='No execution environment found'
    />

  return (
    <div className='flex'>
      <AuthenticatedDataRequest
        method='get'
        shortErrorMessage='Failed to load Execution environment'
        url={url}
      >
        {
          (data: EnvironmentResponse[]) =>
            <Combobox
              isFilter
              noOptionsPlaceholder={noDataPlaceholder}
              onChange={onChange}
              options={getOptions(data)}
              renderButton={renderButton(getOptions(data))}
              value={value}
            />
        }
      </AuthenticatedDataRequest>
    </div>
  )
}

export default SelectExecutionEnvironment
