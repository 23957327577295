import { Paragraph } from '@eggplant/pattern-library'

const TestConfigSutsInfo = () =>
  <Paragraph>
    When scheduling and running test configs, you need to first make connections to systems under test (SUTs). Choose from the options below to determine whether you want to use DAI Eggplant Cloud Execution Environment (including agent and EPF) with Cloud SUTs or your local SUTs defined in the SUTs tab:
    <br />
    <br />
    <strong>Local:</strong> Lets you select a predefined SUT or define a SUT based on criteria. To define a SUT based on criteria, enter a SUT criteria and press Enter.
    <br />
    <br />
    <strong>Cloud:</strong> Lets you execute tests on Cloud SUTs where Eggplant will provision the Cloud Execution Environment (including agent and EPF) for you.
  </Paragraph>

export default TestConfigSutsInfo
