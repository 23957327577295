/* eslint-disable complexity */
import styles from './DropdownButton.module.scss'
import {
  HiChevronDown,
  HiChevronUp
} from 'react-icons/hi'
import { Tooltip } from '../..'
import { forwardRef } from 'react'
import type { ButtonShape } from '@eggplant/types'

interface IconButtonShape extends ButtonShape {
  Icon?: ({ size }: {size: string}) => JSX.Element
  isSolidIcon?: boolean
  isDisabled?: boolean
  isFocus?: boolean
  isHighlighted?: boolean
  label?: string
  isFilter?: boolean
  title?: string
}

const DropdownButton = forwardRef((
  {
    'aria-haspopup': ariaHasPopup,
    'aria-label': ariaLabel,
    children,
    id,
    isSolidIcon,
    isDisabled,
    onBlur,
    onClick,
    onKeyDown,
    onKeyUp,
    Icon,
    isFocus = false,
    isHighlighted = false,
    label,
    isFilter = false,
    title
  }: IconButtonShape,
  ref: any
) => {
  const getHighlightedStyle =
  isFilter && isHighlighted
    ? ' ' + styles.isHighlighted
    : ''

  const hasControlledWidthStyle = isFilter
    ? ' ' + styles.FilterDropdownMinMaxWidth
    : ''

  const getButtonSyles = () => Icon
    ? styles.DropdownButtonIcon
    : styles.DropdownButtonNoIcon

  const buttonClass = getButtonSyles() + getHighlightedStyle + hasControlledWidthStyle

  const downIconDisableStyle = isDisabled
    ? ' ' + styles.disabled
    : ''

  const downIconClass = styles.downIconWrapper + downIconDisableStyle

  const iconStyle = isSolidIcon
    ? styles.filledModelIconWrapper
    : styles.modelIconWrapper

  const dropdownButtonContent = (
    <button
      aria-haspopup={ariaHasPopup}
      aria-label={ariaLabel}
      aria-labelledby={label} // This is added to handle labeling of the button which used in cypress test, as title is change to handle tooltip, and aria-label is used by downshift.
      className={buttonClass}
      disabled={isDisabled}
      id={id}
      onBlur={onBlur}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      ref={ref}
      type='button'
    >
      {
        Icon &&
          <span
            aria-hidden
            className={iconStyle}
          >
            <Icon size='20px' />
          </span>
      }
      <span className={styles.Children}>
        {children}
      </span>
      <span
        aria-hidden
        className={downIconClass}
      >
        {
          ariaLabel === 'close menu' || isFocus
            ? <HiChevronUp size={17} />
            : <HiChevronDown size={17} />
        }
      </span>
    </button>
  )

  return title
    ? (
      <Tooltip label={title}>
        {dropdownButtonContent}
      </Tooltip>
    )
    : dropdownButtonContent
}
)

export default DropdownButton
