import classes from './Sequence.module.scss'
import type { IconType } from 'react-icons/lib'
import type { ReactNode } from 'react'

interface Props {
  children: {
    [key: string]: {
      icon: IconType
      children: ReactNode[]
    }
  }
}

const Sequence = ({
  children
}: Props) =>
  <div className={classes.Sequence}>
    {
      Object.keys(children).map(label => {
        const Icon = children[label].icon
        const keyChildren: ReactNode[] = children[label].children

        return (
          <div
            className={classes.SequenceInner}
            key={label}
          >
            <span
              aria-hidden
              className={classes.IconWrapper}
            >
              <Icon />
            </span>
            <span className={classes.Label}>
              {label}
            </span>
            {
              keyChildren.map((child: ReactNode, index: number) =>
                <div
                  className={classes.ChildrenWrapper}
                  key={index}
                >
                  {child}
                </div>
              )
            }
          </div>
        )
      })
    }
  </div>

export default Sequence
