import SearchInput from '../SearchInput/SearchInput'
import {
  useEffect,
  useState
} from 'react'
import type { FormEvent } from 'react'
import type { InputFieldType } from '@eggplant/types'

interface Props {
  fieldType?: InputFieldType
  onSubmit: (value: string) => void
  placeholder?: string
  value: string
  max?: number
  min?: number
}

const SearchForm = ({
  fieldType = 'text',
  onSubmit,
  placeholder,
  value: valueProp = '',
  min = 1,
  max = 2147483647
}: Props) => {
  const [value, setValue] = useState(valueProp)

  // The state exists because there is a delay between keypress and submission, as a kind of denounce.
  // The input must hold its own state so it can immediately display the value
  // without waiting the 300ms for the value to be sent to the parent and returned as a prop.
  useEffect(() => {
    // Do not run the effect until there is a change. Otherwise, this will loop.
    if (value === valueProp) return () => {}

    const timer = setTimeout(() => {
      onSubmit(value)
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    // Do not run the effect until there is a change. Otherwise, this will loop.
    if (value === valueProp) return () => {}

    // Sync the prop and state, allow the field to be cleared by parent
    setValue(valueProp)

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp])

  const handleChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement

    const newValue = target.value.trimStart()

    if (newValue === value) return

    if (fieldType === 'text' || newValue === '') {
      setValue(newValue)

      return
    }

    // Number field
    const inputValue = Number(newValue)
    const numericValue = Math.min(max, Math.max(min, inputValue))

    const stringValue = numericValue
      ? Math.abs(numericValue).toString()
      : ''

    setValue(stringValue)
  }

  const handleClear = () => {
    setValue('')
  }

  return (
    <SearchInput
      fieldType={fieldType}
      onBlur={handleChange}
      onChange={handleChange}
      onClear={handleClear}
      placeholder={placeholder}
      value={value}
    />
  )
}

export default SearchForm
