import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { LinkToSuitesState } from '../links'
import { Snippets } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = `Eggplant DAI models can be paired with Eggplant Functional suites to automate testing.

You can add suites using our internal storage via the Agent or you can add suites from your Git repository (repo).

To add suites to your project using our internal storage, open the Agent. Make sure you have a Suites root folder defined, then click Manage Suites. Choose the suite you want to add, then click Upload.

To add suites from your Git repository, set up a Git connection to your repo and add suites from your repo to DAI via the Manage Suites page. You can get to the Manage Suites page from the action menu on the Git Connections page under System > Git Connections.`

const SuitesIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Snippets />}
        renderLink={<LinkToSuitesState />}
        title='Suites'
      />
    </div>
  </div>

export default SuitesIntro
