import styles from './TestResultsRecordChart.module.scss'
import { Bar } from 'react-chartjs-2'
import {
  generateChartData,
  getMaxDataLength
} from './processChartData'
import type { ChartOptions } from 'chart.js'

interface Props {
  labels: string[]
  exploratoryPasses: number[]
  exploratoryFails: number[]
  directedPasses: number[]
  directedFails: number[]
}

const TestResultsRecordChart = ({
  labels,
  exploratoryPasses,
  exploratoryFails,
  directedPasses,
  directedFails
}: Props) => {
  const maxDataLength = getMaxDataLength(directedFails, directedPasses, exploratoryFails, exploratoryPasses)

  const chartOptions: ChartOptions<'bar'> = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      }
    },
    scales: {
      // eslint-disable-next-line id-length
      x: {
        stacked: true,
        title: {
          text: 'Date of Runs',
          display: true
        }
      },
      // eslint-disable-next-line id-length
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          text: 'Number of Runs',
          display: true
        },
        ticks: {
          // when tick is <= 5, it will change the things into list of decimal
          stepSize: maxDataLength <= 5
            ? 1
            : undefined
        }
      }
    }
  }

  return (
    <div className={styles.chartContainerStyle}>
      <Bar
        data={
          generateChartData(
            labels,
            exploratoryPasses,
            exploratoryFails,
            directedPasses,
            directedFails)
        }
        options={chartOptions}
      />
    </div>
  )
}

export default TestResultsRecordChart
