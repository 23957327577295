import { AccessControl } from '@eggplant/context'
import {
  CAPTURE,
  LIVE_RUN,
  MODEL_HUB,
  SNIPPETS,
  SUITES,
  TESTCASES,
  renderLinkTo,
  routeLabels
} from '@eggplant/routing'
import {
  FaCode,
  FaDesktop,
  FaFolderOpen,
  FaListAlt,
  FaPlay
} from 'react-icons/fa'
import {
  Icons,
  NavItemVerticalMini
} from '@eggplant/pattern-library'

interface Props {
  base: string
  isCollapsed: boolean
}

const DesignerNav = ({
  base,
  isCollapsed
}: Props) =>
  <>
    <NavItemVerticalMini
      icon={<Icons.ModelHub aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[MODEL_HUB]}
    >
      {renderLinkTo(base + '/' + MODEL_HUB)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<FaListAlt aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[TESTCASES]}
    >
      {renderLinkTo(base + '/' + TESTCASES)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<FaFolderOpen aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[SUITES]}
    >
      {renderLinkTo(base + '/' + SUITES)}
    </NavItemVerticalMini>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<FaCode aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[SNIPPETS]}
      >
        {renderLinkTo(base + '/' + SNIPPETS)}
      </NavItemVerticalMini>
    </AccessControl>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<FaDesktop aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[CAPTURE]}
      >
        {renderLinkTo(base + '/' + CAPTURE)}
      </NavItemVerticalMini>
    </AccessControl>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<FaPlay aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[LIVE_RUN]}
      >
        {renderLinkTo(base + '/' + LIVE_RUN)}
      </NavItemVerticalMini>
    </AccessControl>
  </>

export default DesignerNav
