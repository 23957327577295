import {
  DASHBOARD,
  QA_SUMMARY,
  TESTCASE_DASHBOARD,
  renderLinkTo,
  routeLabels
} from '@eggplant/routing'
import { FaClipboardCheck } from 'react-icons/fa'
import { HiViewGrid } from 'react-icons/hi'
import {
  Icons,
  NavItemVerticalMini
} from '@eggplant/pattern-library'

interface Props {
  base: string
  isCollapsed: boolean
}

const DashboardsNav = ({
  base,
  isCollapsed
}: Props) =>
  <>
    <NavItemVerticalMini
      icon={<FaClipboardCheck aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[TESTCASE_DASHBOARD]}
    >
      {renderLinkTo(base + '/' + TESTCASE_DASHBOARD)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<HiViewGrid aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[QA_SUMMARY]}
    >
      {renderLinkTo(base + '/' + QA_SUMMARY)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<Icons.ModelHub aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[DASHBOARD]}
    >
      {renderLinkTo(base + '/' + DASHBOARD)}
    </NavItemVerticalMini>
  </>

export default DashboardsNav
