import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToRunner } from '@eggplant/routing'
import { LinkToScheduleManagementDoc } from '../links'
import { ScheduleManagement } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = `The Schedule is where you can view all scheduled runs in a calendar view.

Before you can use the Schedule, you must have at least one scheduled test run in Runner.

🗓️ Let’s try to schedule a test run in Runner now!`

const renderButton = () =>
  <AccessControl isHiddenIfDenied>
    <LinkButton variant='primary'>
      <LinkToRunner>
        Go to Runner
      </LinkToRunner>
    </LinkButton>
  </AccessControl>

const ScheduleManagementIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<ScheduleManagement />}
        renderLink={<LinkToScheduleManagementDoc />}
        title='Schedule Management'
      />
    </div>
  </div>

export default ScheduleManagementIntro
