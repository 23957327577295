import StyledLink from '../StyledLink'
import {
  EDIT,
  SETTINGS,
  SMTP,
  SYSTEM
} from '../routes'
import type { Props } from '../types'

const base = '/' + SYSTEM + '/' + SETTINGS + '/' + SMTP + '/' + EDIT

const LinkToEditSmtp = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base}
  >
    {children}
  </StyledLink>

export default LinkToEditSmtp
