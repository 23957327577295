import StyledLink from '../StyledLink'
import {
  DESIGNER,
  SUITES
} from '../routes'
import type { Props } from '../types'

interface Params {
  id: string
}

const LinkToSuiteDetails = ({
  children,
  id,
  isHyperlink
}: Props & Params) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={`/${DESIGNER}/${SUITES}/${id}`}
  >
    {children}
  </StyledLink>

export default LinkToSuiteDetails
