import Downshift from 'downshift'
import classes from './Dropdown.module.scss'
import { type CSSProperties, type ReactNode, useRef, useState } from 'react'
import { ScrollIntoView } from '../..'
import type { GetItemPropsOptions } from 'downshift'
import type { options as optionsType } from '../types'

const itemToString = (option?: { value: string } | null) => option
  ? option.value
  : ''

interface Props {
  preferedPosition?: 'right-auto' | 'right'
  onSelect: (value: string) => void
  options: optionsType
  renderButton: (renderButtonProps: any) => ReactNode
}

const Dropdown = ({
  options,
  onSelect,
  renderButton,
  preferedPosition
}: Props) => {
  const comboBoxRef = useRef<HTMLDivElement>(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const renderOptions = (getItemProps: (options: GetItemPropsOptions<any>) => any) =>
    options.map(option => {
      const itemProps = getItemProps({
        item: option
      })

      return (
        <li
          key={option.value}
          {...itemProps}
        >
          {option.label}
        </li>
      )
    })

  const handleChange = (option: {value: string} | null) => {
    if (!option) return

    onSelect(option.value)
  }

  const handleResize = () => {
    if (preferedPosition === 'right-auto') setWindowWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleResize)

  const getComboBoxLeftPositionStyle = (comboBoxRef: React.RefObject<HTMLDivElement>): CSSProperties => {
    if (preferedPosition !== 'right-auto') return { }

    if (!comboBoxRef.current) {
      return { left: '0' }
    }

    const comboxBoxPosition = comboBoxRef.current.getBoundingClientRect().left + 302 // 302 = dropdown width + shadow + gap position below icon

    const MAX_HORIZONTAL_SCROLL_WIDTH = 1400 // body width (1366) + browser frame width with scroll (34)
    const MAX_SIDE_CONTENT_WIDTH = 716 // side content = side navigation bar width + container innter padding left

    const contentWidth = windowWidth < MAX_HORIZONTAL_SCROLL_WIDTH
      ? MAX_HORIZONTAL_SCROLL_WIDTH
      : windowWidth

    // handling when dropdown is render just next to side navigation on the left, partial of the dropdown will be blocked
    if (comboxBoxPosition < contentWidth - MAX_SIDE_CONTENT_WIDTH) {
      return { left: '0' }
    }

    // handling when dropdown is render at the end of the windows on the right, partial of the dropdown will be block or horizontal scroll happend to show the dropdown
    if (comboxBoxPosition > windowWidth) {
      return { left: `${windowWidth - comboxBoxPosition}px` }
    }

    return { }
  }

  const className = classes.DropdownContainer + (
    preferedPosition && preferedPosition.includes('right')
      ? ' ' + classes.RightAligned
      : ''
  )

  return (
    <div
      ref={
        preferedPosition === 'right-auto'
          ? comboBoxRef
          : undefined
      }
    >
      <Downshift
        itemToString={itemToString}
        onChange={handleChange}
        // Prevents downshift keeping track of a "selected" item
        // otherwise it won't let us click a menu action more than once
        // see https://github.com/downshift-js/downshift#control-props
        selectedItem={null}
      >
        {
          ({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen
          }) =>
            <span className={className}>
              {renderButton(getToggleButtonProps)}
              <span
                className={classes.listWrapper}
                style={getComboBoxLeftPositionStyle(comboBoxRef)}
              >
                <ScrollIntoView
                  isActive={isOpen}
                  withAnimated
                >
                  <ul {...getMenuProps()}>
                    {
                      isOpen &&
                    renderOptions(getItemProps)
                    }
                  </ul>
                </ScrollIntoView>
              </span>
            </span>
        }
      </Downshift>
    </div>
  )
}

export default Dropdown
