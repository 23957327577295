import classes from './RemoveButton.module.scss'
import { FaTimesCircle } from 'react-icons/fa'

export interface Props {
  onClick: () => void
}

const RemoveButton = ({
  onClick
}: Props) =>
  <button
    aria-label='Remove'
    className={classes.RemoveButton}
    onClick={onClick}
    type='button'
  >
    <FaTimesCircle
      aria-hidden
      className={classes.FaTimesCircle}
      size='13px'
    />
  </button>

export default RemoveButton
