import 'react-tabs/style/react-tabs.css'
import classes from './Tabs.module.scss'
import { Children } from 'react'
import {
  Tabs as ReactTabs,
  Tab,
  TabList,
  TabPanel
} from 'react-tabs'
import type { ReactNode } from 'react'

interface BaseProps {
  children: ReactNode[]
  tabLabels: ReactNode[]
  isDisabled?: boolean
  showTabLabels?: boolean
}

interface VisibilityPanelProps extends BaseProps {
  onTabIndexChange: (value: number) => void
  selectedTabIndex: number
  isInvisiblePanel: true
}

interface NonInvisibleProps extends BaseProps {
  onTabIndexChange?: (value: number) => void
  selectedTabIndex?: number
  isInvisiblePanel?: false
}

type Props = NonInvisibleProps | VisibilityPanelProps

const visibilityPanelStyle = (tabIndex: number, selectedTab?: number) => tabIndex === selectedTab
  ? classes.TabPanelChildrenVisible
  : classes.TabPanelChildrenInvisible

const Tabs = ({
  children,
  tabLabels,
  showTabLabels = true,
  onTabIndexChange,
  selectedTabIndex,
  isDisabled = false,
  isInvisiblePanel = false
}: Props) =>
  <ReactTabs
    className={classes.Tabs}
    onSelect={
      onTabIndexChange
        ? index => onTabIndexChange(index)
        : undefined
    }
    selectedIndex={selectedTabIndex}
    selectedTabClassName={classes.TabSelected}
  >
    {
      showTabLabels &&
        <TabList className={classes.TabList}>
          {
            tabLabels.map((label, index) =>
              <Tab
                className={
                  isDisabled
                    ? classes.Tab + ' ' + classes.DisabledTab
                    : classes.Tab
                }
                disabled={isDisabled}
                key={index}
              >
                <span className={classes.TabLabel}>
                  {label}
                </span>
              </Tab>
            )
          }
        </TabList>
    }
    {
      Children.map(children, (child, index) =>
        <TabPanel
          className={classes.TabPanel}
          disabled={isDisabled}
          forceRender={isInvisiblePanel}
          key={index}
          selectedClassName={classes.TabPanelSelected}
        >
          <div
            className={
              isInvisiblePanel
                ? visibilityPanelStyle(index, selectedTabIndex)
                : classes.TabPanelChildrenBase
            }
          >
            {child}
          </div>
        </TabPanel>
      )
    }
  </ReactTabs>

export default Tabs
