import classes from './PageEmptyState.module.scss'
import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'
import type { ReactNode } from 'react'

interface Props {
  description: ReactNode
  documentLink?: ReactNode
  action?: ReactNode
  icon: ReactNode
  title: string
}

const PageEmptyState = ({
  action,
  description,
  documentLink,
  icon,
  title
}: Props) => {
  const renderDescription = () =>
    typeof description === 'string'
      ? (
        <Paragraph>
          {description}
        </Paragraph>
      )
      : description

  return (
    <div className={classes.PageEmptyState}>
      {icon}
      <Header rank={2}>
        {title}
      </Header>
      {renderDescription()}
      {action && action}
      {documentLink && documentLink}
    </div>
  )
}

export default PageEmptyState
