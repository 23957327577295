import classes from './StateStep.module.scss'
import { FaCode } from 'react-icons/fa'
import type { StateType } from '@eggplant/types'

interface Props {
  name: string
  type?: StateType | 'Stateless'
  isFailed?: boolean
  hasSnippet?: boolean
}

const StateStep = ({
  name,
  type = 'Normal',
  isFailed = false,
  hasSnippet = false
}: Props) => {
  const backgroundColor = isFailed
    ? 'failed'
    : type

  return (
    <div className={classes.StateStep + ' ' + classes[backgroundColor]}>
      {name}
      {
        hasSnippet &&
          <FaCode aria-hidden />
      }
    </div>
  )
}

export default StateStep
