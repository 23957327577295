import InfoPopOut from '../InfoPopOut/InfoPopOut'
import classes from './FieldLabel.module.scss'
import type {
  ReactElement,
  ReactNode
} from 'react'

interface Props {
  children: ReactElement<HTMLFormElement>
  focusId?: string
  isRequired?: boolean
  label: string
  minCharWidth?: number
  description?: ReactNode
  isCondensed?: boolean
}

const FieldLabel = ({
  children,
  focusId,
  isRequired = false,
  label,
  minCharWidth,
  description,
  isCondensed = false
}: Props) => {
  const getClasses = () =>
    isCondensed
      ? classes.FieldLabel + ' ' + classes.isCondensed
      : classes.FieldLabel

  return (
    <div>
      {
        description &&
          <div className={classes.Tooltips}>
            <div className={classes.TooltipLegend}>
              <span aria-hidden>
                {label}
              </span>
              <InfoPopOut>
                {description}
              </InfoPopOut>
            </div>
          </div>
      }
      <label
        className={getClasses()}
        htmlFor={focusId} // to focus on inner <input> tag
        style={{
          minWidth: minCharWidth
          // Modern browsers support `ch` but Ubuntu does not? `em` is safer.
            ? (minCharWidth / 2) + 'em'
            : undefined
        }}
      >
        <span className={classes.Legend}>
          <span className={classes.Label}>
            {label}
            <span>
              {
                isRequired
                  ? '*'
                  : ''
              }
            </span>
          </span>
        </span>
        {children}
      </label>
    </div>
  )
}

export default FieldLabel
