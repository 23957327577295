import Button from '../Button/Button'
import ResizeObserver from 'resize-observer-polyfill'
import Table from '../Table/Table'
import classes from './ScrollableTable.module.scss'
import { useCallback } from 'react'
import type { ComponentProps, UIEventHandler } from 'react' // Docs: https://reactabular.js.org/

// Iterating a nodeList is weird because they are not Array instances
// https://css-tricks.com/a-bunch-of-options-for-looping-over-queryselectorall-nodelists/#article-header-id-0
const nodeListForEach = (array: NodeListOf<HTMLTableCellElement>, callback: { (td: HTMLTableCellElement, index: number): void}) => {
  // eslint-disable-next-line fp/no-loops, fp/no-let, fp/no-mutation
  for (let index = 0; index < array.length; index++) {
    callback(array[index], index)
  }
}

interface Props extends ComponentProps<typeof Table> {
  buttonContent?: string
  onScrollBottom?: () => void
}

// eslint-disable-next-line complexity
const ScrollableTable = ({
  buttonContent = 'Load More Rows',
  columns,
  expandedRowList,
  onScrollBottom,
  renderExpandedRowContent,
  rowKey,
  rows,
  selectedRowList
}: Props) => {
  const hasRows = rows.length

  // add 2px border offset if has expanded first row
  const borderOffset = Array.isArray(expandedRowList) &&
    expandedRowList.length &&
    hasRows &&
    rows.findIndex((row: { [x: string]: any }) => row[rowKey] === expandedRowList[0]) === 0
    ? -2
    : 0

  const tableContainerRef = useCallback(
    (containerRef: HTMLDivElement | null) => {
      if (!hasRows || !containerRef) return

      const thead = containerRef.firstChild as HTMLTableRowElement
      const tbody = containerRef.lastChild as HTMLTableRowElement

      const theadList = thead.querySelectorAll<HTMLTableCellElement>('thead th')
      const tbodyList = tbody.querySelectorAll<HTMLTableCellElement>('tbody > tr:first-child > td')

      nodeListForEach(tbodyList, (td, index) => {
        const handleThChange = (observation: ResizeObserverEntry[]) => {
          const newWidth = observation[0].contentRect.width

          // skip this update to prevent jolting
          if (!newWidth) return

          // extra pixel is slipping in somehow
          const adjustedWidth = (Math.floor(newWidth) - 1) + 'px'

          // eslint-disable-next-line fp/no-mutation
          theadList[index].style.minWidth = adjustedWidth
          // eslint-disable-next-line fp/no-mutation
          theadList[index].style.width = adjustedWidth
        }

        new ResizeObserver(handleThChange).observe(td)

        // useCallback does not appear to use cleanups
      })

      // Get the height of the floating table
      const floatingTable = thead.querySelector('table') as HTMLTableElement

      // So it can be used on the real table
      const realTable = tbody.querySelector('table') as HTMLTableElement

      const handleHeaderHeightChange = (observation: ResizeObserverEntry[]) => {
        // Pull the real table up underneath the floating table to hide the real header
        // eslint-disable-next-line fp/no-mutation
        realTable.style.marginTop = `-${observation[0].contentRect.height + borderOffset}px`
      }

      new ResizeObserver(handleHeaderHeightChange).observe(floatingTable)
    }, [hasRows, borderOffset]
  )

  const handleScroll: UIEventHandler<HTMLDivElement> | undefined = onScrollBottom
    ? event => {
      const {
        offsetHeight,
        scrollHeight,
        scrollTop
      } = event.target as HTMLDivElement

      // TODO: Allow a tolerance before hitting the bottom
      const isAtBottom = scrollTop + offsetHeight >= scrollHeight

      if (isAtBottom) onScrollBottom()
    }
    : undefined

  return (
    <div
      className={classes.ScrollableTable}
      ref={tableContainerRef}
    >
      <div
        aria-hidden
        className={classes.Header}
      >
        <Table
          columns={columns}
          rowKey={rowKey}
          rows={[]}
        />
      </div>
      <div
        className={classes.ScrollableContainer}
        onScroll={handleScroll}
      >
        <Table
          columns={columns}
          expandedRowList={expandedRowList}
          renderExpandedRowContent={renderExpandedRowContent}
          rowKey={rowKey}
          rows={rows}
          selectedRowList={selectedRowList}
        />
        {
          onScrollBottom &&
            <div className={classes.buttonWrapper}>
              <Button
                onClick={onScrollBottom}
                variant='secondary'
              >
                {buttonContent}
              </Button>
            </div>
        }
      </div>
    </div>
  )
}

export default ScrollableTable
