import classes from './CustomRow.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  expandedChildren: ReactNode
  isSelected?: boolean
}

const CustomRow = ({
  children: tdList,
  expandedChildren,
  isSelected = false
}: Props) => {
  const basicRow =
    <tr
      aria-selected={isSelected}
      className={
        expandedChildren
          ? classes.CustomRow + ' ' + classes.isExpanded
          : classes.CustomRow
      }
    >
      {tdList}
    </tr>

  if (!expandedChildren) return basicRow

  return (
    <>
      {basicRow}
      <tr>
        <td colSpan={999}>
          {expandedChildren}
        </td>
      </tr>
    </>
  )
}

export default CustomRow
