/* eslint-disable complexity */
import ClearButton from './ClearButton'
import Divider from '../Divider/Divider'
import Downshift from 'downshift'
import EmptyState from './EmptyState/EmptyState'
import SearchInput from '../SearchInput/SearchInput'
import classes from './Combobox.module.scss'
import natsort from 'natsort'
import { HiCheck } from 'react-icons/hi'
import { Tooltip } from '../..'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import type {
  ButtonShape,
  GroupedOptions,
  OptionType,
  Options
} from '@eggplant/types'
import type { CSSProperties, ReactNode } from 'react'
import type {
  DownshiftState,
  GetItemPropsOptions,
  StateChangeOptions
} from 'downshift'

interface Props {
  customValidity?: string
  groupedOptions?: GroupedOptions
  initialGroupedOptions?: GroupedOptions
  options?: Options
  initialOptions?: Options
  isRequired?: boolean
  hasSearch?: boolean
  onChange: (value: string) => void
  renderButton: (props: ButtonShape) => void
  value?: string
  isDisabled?: boolean
  noOptionsPlaceholder?: ReactNode
  isFilter?: boolean
  externalQuery?: ReactNode
  onClear?: () => void
}

const itemToString = (option?: OptionType | null) => option
  ? option.value
  : ''

const Combobox = ({
  customValidity = 'Please select an item in the list.',
  groupedOptions = [],
  options = [],
  onChange,
  renderButton,
  initialOptions = [],
  initialGroupedOptions = [],
  isRequired = false,
  value = '',
  isDisabled = false,
  noOptionsPlaceholder,
  hasSearch = true,
  isFilter = false,
  externalQuery,
  onClear
}: Props) => {
  // This help controls the InputText's input
  const [input, setInput] = useState('')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const inputRef = useRef<HTMLInputElement>(null)
  const comboBoxRef = useRef<HTMLDivElement>(null)

  const sorter = natsort({ insensitive: true })

  const filteredOptions = input
    ? options.filter(option =>
      option.label.toLowerCase().includes(input.toLowerCase())
    )
    : options

  const filteredGroupedOptions = input
    ? groupedOptions.map(group => (
      {
        ...group,
        options: group.options.filter(option =>
          option.label.toLowerCase().includes(input.toLowerCase()))
      }
    ))
    : groupedOptions

  const hasInitialOptions = !!filteredOptions.length || !!filteredGroupedOptions.map(group => group.options).flat().length

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleResize)

  const onInputValueChange = (inputValue: string | null) => {
    setInput(inputValue || '')
  }

  const onOuterClick = () => {
    setInput('')
  }

  const handleSelection = (option: OptionType | null) => {
    if (!option || option.value === value) return

    onChange(option.value)

    setInput('')
  }

  // AUB-2625
  const stateReducer = (state: DownshiftState<OptionType>, changes: StateChangeOptions<OptionType>) => {
    if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
      return {
        ...changes,
        selectedItem: state.selectedItem,
        highlightedIndex: null,
        isOpen: false,
        inputValue: ''
      }
    }

    // AUB-17991
    return {
      ...changes,
      inputValue: ''
    }
  }

  const renderOptionLabel = (item: OptionType) =>
    <div className={classes.checkboxStyle}>
      <div className={classes.textStyle}>
        {item.label}
      </div>
      {item.value === value &&
        <HiCheck
          aria-hidden
          className={classes.CheckIcon}
          size={16}
        />}
    </div>

  const renderInitialOptions = (getItemProps: (initialOptions: GetItemPropsOptions<OptionType>) => OptionType) => initialOptions.length
    ? (
      <>
        {
          initialOptions.map(item =>
            <li
              key={item.value}
              {...getItemProps({ item })}
            >
              <Tooltip label={item.label}>
                {renderOptionLabel(item)}
              </Tooltip>
            </li>
          )
        }
        <Divider />
      </>
    )
    : null

  const renderOptions = (getItemProps: (filteredOptions: GetItemPropsOptions<OptionType>) => OptionType) => {
    // eslint-disable-next-line fp/no-mutating-methods
    const sortedOptions = filteredOptions.sort((current, next) =>
      sorter(current.label, next.label)
    )

    const renderOptionsList = sortedOptions.length
      ? sortedOptions.map(item =>
        <li
          key={item.value}
          {...getItemProps({ item })}
        >
          <Tooltip label={item.label}>
            {renderOptionLabel(item)}
          </Tooltip>
        </li>
      )
      : null

    return sortedOptions.length
      ? renderOptionsList
      : <EmptyState />
  }

  const renderInitialGroupedOptions = (getItemProps: ({ item }: any) => OptionType) => initialGroupedOptions.length
    ? initialGroupedOptions.map(group =>
      <>
        <strong className={classes.GroupStyle}>
          {group.label}
        </strong>
        {
          group.options.map(item =>
            <li
              key={item.value}
              {...getItemProps({ item })}
              className={classes.GroupStyle}
            >
              <Tooltip label={item.label}>
                {renderOptionLabel(item)}
              </Tooltip>
            </li>
          )
        }
      </>
    )
    : null

  const renderGroupedOptions = (getItemProps: ({ item }: any) => OptionType) => {
    const sortedOptions = filteredGroupedOptions.map(group => (
      {
        ...group,
        // eslint-disable-next-line fp/no-mutating-methods
        options: group.options.sort((current, next) =>
          sorter(current.label, next.label)
        )
      }
    ))

    const availableOptions = sortedOptions.map(group => group.options).flat()

    return availableOptions.length
      ? sortedOptions.map(group =>
        <>
          <strong className={classes.GroupStyle}>
            {group.label}
          </strong>
          {
            group.options.map(item =>
              <li
                key={item.value}
                {...getItemProps({ item })}
                className={classes.GroupStyle}
              >
                <Tooltip label={item.label}>
                  {renderOptionLabel(item)}
                </Tooltip>
              </li>
            )
          }
        </>
      )
      : <EmptyState />
  }

  const validate = () => {
    const ref = inputRef.current

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!ref) return

    // reset CustomValidity when value changed
    ref.setCustomValidity('')
  }

  const handleClear = () => {
    onChange('')

    onClear && onClear()
  }

  const clearSectionClassName = externalQuery
    ? classes.clearSection + ' ' + classes.justifySpaceBetween
    : classes.clearSection

  const renderClearSection =
    <div className={clearSectionClassName}>
      {
        externalQuery && externalQuery
      }
      {
        value && <ClearButton onClear={handleClear} />
      }
    </div>

  useEffect(validate, [value])

  const enabledStyle = !isDisabled
    ? ' ' + classes.Enabled
    : ''

  const hasControlledWidthStyle = isFilter
    ? ' ' + classes.FilterComboboxMinMaxWidth
    : ''

  const comboboxStyle = classes.Combobox + enabledStyle + hasControlledWidthStyle

  const getComboBoxLeftPositionStyle = (comboBoxRef: React.RefObject<HTMLDivElement>): CSSProperties => {
    if (!comboBoxRef.current) {
      return { left: '0px' }
    }

    const comboxBoxPos = comboBoxRef.current.getBoundingClientRect().left + 410

    if (comboxBoxPos > windowWidth) {
      return { left: `${windowWidth - comboxBoxPos}px` }
    }

    return { left: '0px' }
  }

  const handleInputClear = () => { setInput('') }

  return (
    <div ref={comboBoxRef}>
      <Downshift
        inputValue={input}
        itemToString={itemToString}
        key={value} // resolve AUB-19498 where parent call to change value downshift state is not updated
        onChange={handleSelection}
        onInputValueChange={onInputValueChange}
        onOuterClick={onOuterClick}
        stateReducer={stateReducer}
      >
        {
        // eslint-disable-next-line complexity
          ({
            getInputProps,
            getItemProps,
            getMenuProps,
            getRootProps,
            getToggleButtonProps,
            isOpen
          }) =>
            <div
              className={comboboxStyle}
              {...getRootProps(undefined, { suppressRefError: true })}
              aria-disabled={isDisabled}
              role={undefined}
            >
              <input
                checked={!!value}
                onInvalid={(event: React.ChangeEvent<HTMLInputElement>) => event.target.setCustomValidity(customValidity)}
                readOnly
                ref={inputRef}
                required={isRequired}
                type='checkbox'
              />
              {renderButton(getToggleButtonProps())}
              {
                isOpen && !isDisabled &&
                  <div
                    className={classes.dropdownWrapper}
                    style={getComboBoxLeftPositionStyle(comboBoxRef)}
                  >
                    {
                      options.length || groupedOptions.length
                        ? (
                          <>
                            {
                              hasSearch &&
                                <div className={classes.searchWrapper}>
                                  <SearchInput
                                    {
                                      ...getInputProps()
                                    }
                                    isAutoFocus
                                    onClear={handleInputClear}
                                  />
                                </div>
                            }
                            <ul {...getMenuProps()}>
                              {
                                hasInitialOptions && renderInitialOptions(getItemProps)
                              }
                              {
                                hasInitialOptions && renderInitialGroupedOptions(getItemProps)
                              }
                              {
                                !!options.length && renderOptions(getItemProps)
                              }
                              {
                                !!groupedOptions.length && renderGroupedOptions(getItemProps)
                              }
                            </ul>
                            {
                              (!!externalQuery || value) && renderClearSection
                            }
                          </>
                        )
                        : noOptionsPlaceholder
                    }
                  </div>
              }
            </div>
        }
      </Downshift>
    </div>
  )
}

export default Combobox
