import Keycloak from 'keycloak-js'
import * as cookies from 'browser-cookies'
import {
  AI_API_SERVICE,
  API_COMPOSER_SERVICE
} from '@eggplant/api-hosts'
import {
  DataRequest,
  requestData
} from '@eggplant/data-request'
import { LoadingPlaceholder } from '@eggplant/pattern-library'
import { ReactKeycloakProvider } from './react-keycloak/provider'
import { TOKEN_INFO } from '../common'
import { realmAccessRoles } from '../UserAccessControl/constants'
import { useKeycloak } from './react-keycloak/useKeycloak'
import type {
  Consumer,
  Provider,
  Role
} from '../types'
import type {
  KeycloakConfig,
  KeycloakTokenParsed
} from 'keycloak-js'
import type { KeycloakEnabledResponse } from '@eggplant/types'
import type { ReactElement } from 'react'

const initOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false
} as Keycloak.KeycloakInitOptions

export interface KeycloakRoles {
  roles: Role[]
}

export interface KeycloakResourceAccess {
  [key: string]: KeycloakRoles
}

export interface LatestTokenParsed extends KeycloakTokenParsed {
  resource_access?: KeycloakResourceAccess
  // The types.d.ts file for keycloak is outdated even at v15
  preferred_username: string
}

interface AuthClientTokens {
  idToken?: string
  refreshToken?: string
  token?: string
}

const decodeAuthToken = (authToken: string) => JSON.parse(
  atob(
    authToken.split('.')[1]
  )
) as KeycloakTokenParsed

const SELECTED_MODEL = 'selectedModelId'

const handleTokenChange = (newToken?: AuthClientTokens) => {
  if (!newToken || !newToken.token) {
    window.location.reload()
  } else {
    const parsedToken = decodeAuthToken(newToken.token)

    window.localStorage.setItem(TOKEN_INFO, JSON.stringify({
      access_token: newToken.token,
      expires_in: parsedToken.exp,
      user_id: parsedToken.sub
    }))
  }
}

const handleEvent = (event: string) => {
  // This can happen if they decline the Terms and Conditions. The reload takes them back to the login form.
  if (event === 'onInitError') window.location.reload()

  if (event !== 'onAuthSuccess') return

  // ugly extra arguments but changing the requestData at this time would be super risky
  requestData(AI_API_SERVICE + '/usage_report', 'get', () => {}, () => {}, undefined, 'json', false)
}

export const KeycloakProvider = ({
  children
}: Provider) =>
  <DataRequest
    method='get'
    url={API_COMPOSER_SERVICE + '/services/iam/v1.0.0'}
  >
    {
      (config: KeycloakEnabledResponse) => {
        const keycloakConfig = {
          clientId: config.keycloak_clientId,
          realm: config.keycloak_realm,
          url: config.keycloak_base_url
        } as KeycloakConfig

        const keycloak = new Keycloak(keycloakConfig)

        return (
          <ReactKeycloakProvider
            LoadingComponent={<LoadingPlaceholder />}
            authClient={keycloak}
            initOptions={initOptions}
            onEvent={handleEvent}
            onTokens={handleTokenChange}
          >
            {children}
          </ReactKeycloakProvider>
        )
      }
    }
  </DataRequest>

export const KeycloakConsumer = ({
  children: renderChildren
}: Consumer) => {
  const {
    keycloak: keycloakInstance,
    initialized
  } = useKeycloak()

  const endSession = async () => {
    window.localStorage.removeItem(TOKEN_INFO)

    cookies.erase(SELECTED_MODEL)

    await keycloakInstance.logout({
      redirectUri: window.location.origin
    })
  }

  if (!keycloakInstance.authenticated) void keycloakInstance.login()

  const tokenParsed = keycloakInstance.tokenParsed as LatestTokenParsed

  const idTokenParsed = keycloakInstance.idTokenParsed as LatestTokenParsed

  const getAccessibleRoles = () => {
    const roles = idTokenParsed.realm_access
      ? idTokenParsed.realm_access.roles
      : []

    return roles.filter(role => realmAccessRoles.some(realmRole => realmRole === role)) as Role[]
  }

  const isAdmin = () => {
    const roles = getAccessibleRoles()

    return roles.includes('dai:admin')
  }

  const context = {
    authToken: keycloakInstance.token || '',
    isAdmin: isAdmin(),
    accessibleRoles: getAccessibleRoles(),
    isKeycloakOn: true, // Always TRUE if KeycloakContext is active
    isInitialized: initialized, // from useKeycloak
    username: tokenParsed.preferred_username || '',
    onLogOut: endSession,
    onAuthReject: endSession,
    onTokenChange: () => { } // not applicable in Keycloak
  }

  return renderChildren(context) as ReactElement
}
