import ColorBlock from './ColorBlock'
import classes from './ChartLegend.module.scss'

interface Row {
  color?: string
  label: string
  amount: number | string | null
}

interface Props {
  rows: Row[]
}

const ChartLegend = ({ rows }: Props) =>
  <ul
    className={classes.ChartLegend}
    role='table'
  >
    {
      rows.map((row: Row) =>
        <li
          key={row.label}
          role='row'
        >
          {
            row.color &&
              <span
                aria-hidden
                className={classes.colorTag}
              >
                <ColorBlock color={row.color} />
              </span>
          }
          <span
            className={classes.label}
            role='cell'
          >
            {row.label}
          </span>
          <span
            className={classes.amount}
            role='cell'
          >
            {row.amount}
          </span>
        </li>
      )
    }
  </ul>

export default ChartLegend
