import Footer from './Footer'
import LinkToDocusaurus from './LinkToDocusaurus'
import { FaFileAlt } from 'react-icons/fa'
import { useAccess } from '@eggplant/routing'
import type { ComponentProps } from 'react'

interface Props {
  path: ComponentProps<typeof LinkToDocusaurus>['path']
}

/**
* Must reference a defined path to a Docusaurus page.
*/
const DocusaurusFooter = ({
  path
}: Props) => {
  const [isAccess] = useAccess()

  return (
    <Footer
      Icon={FaFileAlt}
      info={
        !isAccess &&
          <span>Please be aware that the "Learn More" link directs you to content designed for admin or user roles only. As you are currently logged in as a viewer, certain documentation may not be relevant to you.</span>
      }
    >
      <span><LinkToDocusaurus path={path}>Learn more in DAI documentation</LinkToDocusaurus></span>
    </Footer>
  )
}

export default DocusaurusFooter
