import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { testCaseView } from '../../docs-routes'

const TestCaseView = () =>
  <Paragraph>
    Test Case View lets you visualize the test case steps in your model. This view is read-only and does not allow editing. A test case must have at least one step to display a visualization.
    <DocusaurusFooter path={testCaseView} />
  </Paragraph>

export default TestCaseView
