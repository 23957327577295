import { ComboboxSingleSelect } from '@eggplant/pattern-library'
import type {
  Options,
  TestStatus
} from '@eggplant/types'

interface Props {
  value: string | undefined
  onChange: (value: TestStatus | string) => void
}

const statusOptions: Options = [
  { label: 'Passed', value: 'PASSED' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'In Progress', value: 'INCOMPLETE' },
  { label: 'Error', value: 'ERROR' },
  { label: 'Cancelled', value: 'CANCELLED' }
]

const SelectTestResultsStatus = ({
  onChange,
  value
}: Props) =>
  <ComboboxSingleSelect
    isFilter
    isHighlighted={value !== ''}
    key={value}
    onChange={onChange}
    options={statusOptions}
    prompt='Run status'
    value={value}
  />

export default SelectTestResultsStatus
