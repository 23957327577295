import classes from './Eggy.module.scss'

interface Props {
  size?: number
}

export default ({
  size = 32
}: Props) =>
  <svg
    className={classes.Eggy}
    style={{ width: `${size}px` }}
    viewBox='0 0 199.3 217.7'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      className={classes.purple}
      d='M84.2 26.8a66.7 66.7 0 0 1 24 4.3c10.7 4 20 10.8 25.8 20.8a7.4 7.4 0 0 1 .4 7.1c-2 4.1-7 4.4-11 4a79.3 79.3 0 0 1-10.7-2l-6.9-1.8c-.7 2-1.5 3.8-2.3 5.6-2.7 5.6-9.7 18.3-16.4 19.6s-18-7.9-22.5-12a66.6 66.6 0 0 1-4.2-4.3l-5.7 4.2a78 78 0 0 1-9.2 5.8c-3.6 1.9-8.1 3.5-11.7.5a7.4 7.4 0 0 1-2.3-6.7c2-17.6 16.5-32.4 32.2-40A83 83 0 0 0 50.3 19l-2.7-2.2.8-3.4C50.4 4 60.7-.3 69.4 0l3.2.1 1.5 3a129.8 129.8 0 0 1 10 23.7'
    />
    <path
      className={classes.green}
      d='M37 72.5c2.3-19.4 21.6-33.3 35.3-38a73 73 0 0 0-18.4-19.9c1.1-5.2 7.5-9.2 15.2-9A117.5 117.5 0 0 1 80 32.8c15-1.7 39 4 49.2 22 3.7 6.5-19.1-.1-27-2.4-2.7 11.3-12.3 25.8-16 26.5s-18.2-9.1-24.8-18.6c-6.6 5-25 19.7-24.2 12.3'
    />
    <path
      className={classes.purple}
      d='M128.5 34.1c6.3-4.3 12.9-6.7 20.8-8.5 3-.7 6.3-1 9 1 3.2 2.3 3.9 6.1 4.3 9.8l3.3 24.9c4.8 2.2 9.6 4.5 14.1 7a86.4 86.4 0 0 1 15.5 11.2c2.4 2.1 4 4.7 3.7 8-.4 4.3-4 6.6-6.9 9.3L180.7 108a65 65 0 0 1 4 7.2c1.7 4 2.5 8.6 3.2 13 1.3 8.7-4.1 13.3-12.4 12.5-13.6-1.2-27.2-2.4-40.9-3.1-38.5-2.1-75.8-1-113.8 6.2-4.3.8-9.5 1-12.5-2.9-3.3-4.2-1.3-9.9.8-14.2 1.4-3 2.5-6 3.7-9A52.7 52.7 0 0 1 20 105c-4.3-5.6-8.9-10.8-13.7-16l-2.5-2.2a10.1 10.1 0 0 1-3.6-5.5c-1.1-5.2 3-9 7-11.5a67 67 0 0 1 18.4-8.7c3.3-1 6.6-1.8 9.9-2.6l-1.1-9.3A62.2 62.2 0 0 0 33 41c-1.2-4.2-1.5-9 1.7-12.4 3.3-3.6 8.5-3.5 13-3 9.7 1 17 1.8 25.6 5.3 4-5.9 7.6-12 11.7-17.8 4.2-6 11.8-6.8 17.4-2.5 7.9 6 19 15 26 23.6'
    />
    <path
      className={classes.green}
      d='M150.5 31a50 50 0 0 0-23.2 11c-1.4 1.3-5.4 4.7-4.9 2.6l1.7-7c-6.4-8-17.2-16.7-25-22.7-2.7-2-6.9-2.7-9.6 1.2C84.8 23 82 27.8 78 33.8c1 3 5.6 11 3.2 9C69.6 33 61.9 32.6 47 31c-7.6-.9-10.9.5-8.8 8.4 1.2 4.5 1.8 11.8 2.8 19.3 2.3 0 9.8 1 7.6 1.8-11.8 4-24.8 4.9-38.5 14-8.7 5.8-3.1 7.3.1 10.8a220 220 0 0 1 14.8 17.3c2.3-1 8.3-5.3 6.7-3.3-9.8 12.3-11 15.6-17.7 29.7-3.8 7.9-2.4 10.7 5.7 9.2 55.2-10.3 104.9-7.9 156.2-3 4.6.3 7.2-1.1 6.4-6.2-2.8-18.2-13.5-25.4-13.3-25.4l7.6.5 11.8-11.3c4.1-3.9 7.5-5.5 3.4-9.1-14.5-13-38.5-20.3-38.4-20.4l6.7-2.8c-.8-6.2-2-14.3-3-23.5-.8-5.7-1.7-7.2-6.7-6'
    />
    <path
      className={classes.purple}
      d='M99.5 47.2c17 0 35.7 13.6 49.6 35a135.3 135.3 0 0 1 21.2 72.5c0 30.3-18.4 49.7-42 58.1a86.7 86.7 0 0 1-57.5 0c-23.6-8.4-42-27.8-42-58.1A135.3 135.3 0 0 1 50 82.2c13.9-21.4 32.5-35 49.5-35'
    />
    <path
      className={classes.face}
      d='M156 185.2l.2-.4.8-1.3.4-.7.6-1 .4-.8.6-1 .4-.9.5-1c0-.3.2-.6.3-.8l.5-1.2.3-.8.5-1.3.2-.6.6-2 .1-.3.5-1.8.2-.8.3-1.4.2-.8.2-1.3.1-1 .2-1.3.1-1 .2-1.3v-1l.1-1.5v-.8a66 66 0 0 0 0-7v-1.5a127.6 127.6 0 0 0-.3-4.8l-.3-2.8a120.6 120.6 0 0 0-.6-4.6l-.2-1.6-.6-3.3-.2-1-.9-4.4-.3-1-.7-3.2-.5-1.5-.7-2.7-.5-1.6-.8-2.5-.5-1.6-1-2.6-.5-1.3-1.5-3.9-.2-.5-1.5-3.2-.6-1.3-1.1-2.4-.8-1.4-.6-1.2v-.1l-5.2-8.9a107.3 107.3 0 0 0-6.5-8.9c-6.1-2.4-13.8-5.3-17.6-3.6-15 7-25 6.8-42 0-3.7-1.5-11 1.2-17 3.6a107 107 0 0 0-6.6 9 129.5 129.5 0 0 0-20.2 69.4 54.3 54.3 0 0 0 38.2 52.9 81.5 81.5 0 0 0 53.7 0 58.4 58.4 0 0 0 29.6-22.4z'
    />
    <path
      className={classes.purple}
      d='M128.5 119.4a13.7 13.7 0 1 1-13.7 13.7 13.7 13.7 0 0 1 13.7-13.7m-58 0A13.7 13.7 0 1 1 57 133a13.7 13.7 0 0 1 13.7-13.7z'
    />
    <path
      className={classes.white}
      d='M75 123.2a3.7 3.7 0 1 1-3.8 3.7 3.7 3.7 0 0 1 3.8-3.7'
    />
    <path
      className={classes.white}
      d='M133.2 123.2a3.7 3.7 0 1 1-3.7 3.7 3.7 3.7 0 0 1 3.7-3.7'
    />
    <path
      className={classes.green}
      d='M52 72.7c5.5-13 25.2-23.5 39.5-25.8l16.7-1.7c14.5 2.3 32.5 14.5 38.4 27.5 2.4 5.4-19.1-9.3-26.3-5.7-15 7.6-25 7.3-42 0-7.4-3.2-28.5 11.1-26.2 5.7'
    />
    <path
      className={classes.purple}
      d='M130.1 156a2.9 2.9 0 1 1 5.4 2 38.3 38.3 0 0 1-72 0 2.9 2.9 0 0 1 5.5-2 32.6 32.6 0 0 0 61.1 0'
    />
    <g className={classes.EggyTrademark}>
      <path
        className={classes.white}
        d='M184 50.2h-2.4v5.7h-.8v-5.7h-2.4v-.8h5.6z'
      />
      <path
        className={classes.white}
        d='M184.9 49.4h1.2l2.5 5.1 2.4-5h1.3v6.4h-.9v-5.4l-2.6 5.4h-.5l-2.5-5.4v5.4h-1z'
      />
    </g>
  </svg>
