import LinkToDocusaurus from '../LinkToDocusaurus'
import { Header, Paragraph } from '@eggplant/pattern-library'
import { daiSettings } from '../../docs-routes'

const TestConfigNotificationDescription = () =>
  <>
    <Header rank={5}>
      Global test config notifications settings
    </Header>
    <br />
    <Paragraph>
      The global test config notifications settings allows you to stay informed by enabling email notifications for every test configuration execution. To receive email alerts, start by enabling global notifications in the global settings.
      <br /><br />
      To set up email notifications for specific test configurations, navigate to the specific test configuration and click on manage notifications.
      <br /><br />
      You have the flexibility to enable or disable email notifications individually for each test configuration. Furthermore, you can add multiple email recipients, tailoring the notifications based on test result status - be it for failed, errored, cancelled, or passed test results. With this feature, you have complete control over how you stay updated on your test configurations, ensuring timely and relevant notifications for effective test management.
      <br /><br />
      <LinkToDocusaurus path={daiSettings}>Learn more in DAI documentation</LinkToDocusaurus>
    </Paragraph>
    <br />
  </>

export default TestConfigNotificationDescription
