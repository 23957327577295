import StyledLink from '../StyledLink'
import makeQueryString from './makeUrl/makeUrl'
import {
  ADD,
  DESIGNER,
  TESTCASES
} from '../routes'
import type { Props } from '../types'

interface Params {
  model_id?: string
  asset_id?: string
}

const url = `/${DESIGNER}/${TESTCASES}/${ADD}`

const LinkToAddTestCase = ({
  children,
  isHyperlink,
  openInNewTab = false,
  ...params
}: Props & Params) => (
  <StyledLink
    isHyperlink={isHyperlink}
    openInNewTab={openInNewTab}
    to={makeQueryString(url, { ...params as Params })}
  >
    {children}
  </StyledLink>
)

export default LinkToAddTestCase
