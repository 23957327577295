import styles from './TestCasesRunsChart.module.scss'
import { DOUGHNUT_CHART_OPTIONS } from '../../constants'
import { Doughnut } from 'react-chartjs-2'
import type { LayoutPosition } from 'chart.js'

const chartOptions = {
  ...DOUGHNUT_CHART_OPTIONS,
  plugins: {
    legend: {
      position: 'right' as LayoutPosition,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      }
    },
    usePointStyle: true
  },
  tooltips: {
    enabled: false
  }
}

const generateChartData = (failures: number, passes: number) => ({
  labels: [
    `Total test case passes: ${passes}`,
    `Total test case fails: ${failures}`
  ],
  datasets: [
    {
      backgroundColor: [
        styles.colorpass,
        styles.colorfail
      ],
      data: [
        passes,
        failures
      ]
    }
  ]
})

interface Props {
  failures: number
  passes: number
}

const TestCasesRunsChart = ({
  failures,
  passes
}: Props) =>
  <Doughnut
    data={generateChartData(failures, passes)}
    height={120}
    options={chartOptions}
  />

export default TestCasesRunsChart
