import { realmAccessRoles } from '../UserAccessControl/constants'
import type { Context, Role } from '../types'

const baseContext = {
  isInitialized: false,
  authToken: '',
  onAuthReject: () => { },
  onLogOut: () => { },
  onTokenChange: () => { }
}

const adminContext: Context = {
  ...baseContext,
  isAdmin: true,
  accessibleRoles: ['dai:admin']
}

export const validateMockedRoles = (roles: string[]|undefined) => {
  if (!roles || !roles.length) return false

  const availableRealmRoles = realmAccessRoles.map(role => String(role)) // convert the type from Role to string for urlRoles checking
  const validUrlRoles = roles.every(role => availableRealmRoles.includes(role))

  // upon any role wrongly coded in urlparams throw error to prevent test carried forward with default context
  if (!validUrlRoles) throw new Error('One of the ?roles is not a role of the current realm.')

  return true
}

export const getTestContext = (mockedRoles?: string) => {
  if (!mockedRoles) return adminContext // jest/cypress will directly return as admin when no urlRoles

  const roles = mockedRoles.split(',')
  const hasValidMockedRole = validateMockedRoles(roles)

  if (!hasValidMockedRole) return adminContext

  const mockedContext: Context = {
    ...baseContext,
    isAdmin: roles.includes('dai:admin'),
    accessibleRoles: roles as Role[]
  }

  return mockedContext
}
