// A list of all pages that are linked to from within DAI
// This file can be verified in a pipeline job to prevent linking to missing pages

// Do not combine paths or else it will break the validation job
// The validation does not prevent invalid anchors usage

export const managingSuites = 'dai-manage-suites'
export const environments = 'dai-environments'
export const suts = 'dai-sut-connections/#adding-sut-connections'
export const agentTools = 'dai-system-settings/#agent'
export const bugHuntingHeatmap = 'bug-hunting/#bug-hunting-heatmap'
export const bugHuntingHistory = 'bug-hunting'
export const coverageHeatmap = 'coverage#heatmap'
export const coverageReport = 'coverage#history-chart'
export const modelDashboard = 'dai-dashboard-model'
export const rciImport = 'dai-rci-user-journeys'
export const licenses = 'dai-system-settings/#licenses'
export const liveRun = 'live-run'
export const models = 'dai-models'
export const qaSummary = 'dai-dashboard-exploratory'
export const runner = 'dai-runner'
export const logs = 'dai-system-settings/#logs'
export const snippetEditor = 'dai-designer/#using-the-script-editor'
export const steps = 'test-result-view/#steps'
export const suites = 'dai-designer/#viewing'
export const sutConnections = 'dai-sut-connections'
export const testCases = 'dai-testcases-managing'
export const testCaseView = 'test-case-view'
export const testConfig = 'dai-test-configuration'
export const testResults = 'dai-test-results'
export const modelPreview = 'model-preview'
export const apiAccess = 'dai-system-settings/#api-access'
export const schedule = 'dai-schedule'
export const gitIntegration = 'git-integration'
export const gitConnectionEdit = 'git-connection-edit'
export const gitConnectionSetup = 'git-connection-setup'
export const gitConnectionsManage = 'git-connections-manage'
export const gitConnectionSync = 'git-connection-sync'
export const gitManageSuites = 'git-manage-suites'
export const daiSettings = 'dai-system-settings/#manage-dai-settings'
export const testCaseDashboard = 'dai-dashboard-test-case'
