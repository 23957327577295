import classes from './ErrorPlaceholder.module.scss'
import { BsExclamationCircle } from 'react-icons/bs'
import { useId } from 'react'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  status: string
  title?: string
}

const ErrorPlaceholder = ({
  children,
  status,
  title
}: Props) => {
  // There can be multiple errors on the page
  // So each needs a unique ID to prevent conflict
  const id = useId()

  const labelId = id + 'label'
  const descriptionId = id + 'description'

  return (
    <div
      aria-describedby={descriptionId}
      aria-labelledby={labelId}
      className={classes.ErrorPlaceholder}
      role='alert'
    >
      <BsExclamationCircle
        aria-hidden
        size={25}
      />
      <div>
        <strong id={labelId}>
          <dfn>
            {status}
          </dfn>
          {
            title && (
              <>
                {' '}
                &mdash;
                {' '}
                {title}
              </>)
          }
        </strong>
        <pre id={descriptionId}>
          {children}
        </pre>
      </div>
    </div>
  )
}

export default ErrorPlaceholder
