import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { environments } from '../../docs-routes'

const EnvironmentSelect = () =>
  <Paragraph>
    You must select an execution environment to view snippets.
    <br />
    <DocusaurusFooter path={environments} />
  </Paragraph>

export default EnvironmentSelect
