import classes from './TestConfigTagBadge.module.scss'

interface Props {
  label: string
}

const TestConfigTagBadge = ({
  label
}: Props) =>
  <span className={classes.TestConfigTagBadge}>
    {label}
  </span>

export default TestConfigTagBadge
