import RelayFeatureFlags from 'relay-runtime/lib/util/RelayFeatureFlags'
import fetchGraphQL from './fetchGraphQL'
import {
  Environment,
  Network,
  RecordSource,
  Store
} from 'relay-runtime'

// Using middleware: https://github.com/relay-tools/react-relay-network-modern

// Recommended https://github.com/facebook/relay/releases/tag/v10.0.0
// @ts-expect-error
// eslint-disable-next-line fp/no-mutation
RelayFeatureFlags.ENABLE_PRECISE_TYPE_REFINEMENT = true

// Here we can eventually create one environment per user

const environment = new Environment({
  network: Network.create(fetchGraphQL),
  store: new Store(new RecordSource())
})

export default environment
