import ScrollableTable from '../ScrollableTable/ScrollableTable'
import SortButton from '../SortButton/SortButton'
import type { ColumnProps } from '../types'
import type { ComponentProps, ReactElement } from 'react'

type ScrollableTableProps = ComponentProps<typeof ScrollableTable>

interface Props<T extends string> extends ScrollableTableProps {
  columns: ColumnProps[]
  isSortedAscending?: boolean
  onSortChange: (rowItemKey: T) => void
  sortableKeys: T[] // TODO custom prop to compare against columns
  // can be empty for no active sort
  sortingByKey: T // TODO custom prop to compare against columns
}

const getIsAscending = (sortingByKey: string, rowItemKey: string, isAscending: boolean) => {
  const isSortedBy = sortingByKey === rowItemKey

  if (!isSortedBy) return undefined

  if (isAscending) return true

  return false
}

const getAriaSort = (isAscending?: boolean) => {
  if (isAscending) return 'ascending'

  if (isAscending === false) return 'descending'

  return undefined
}

const SortableTable = <T extends string, >({
  columns,
  expandedRowList,
  isSortedAscending = false,
  onScrollBottom,
  onSortChange,
  renderExpandedRowContent,
  rowKey,
  rows,
  selectedRowList,
  sortableKeys,
  sortingByKey
}: Props<T>) => {
  const renderButton = (rowItemKey: T, label: ReactElement | string) => {
    const handleClick = () => {
      onSortChange(rowItemKey)
    }

    const isAscending = getIsAscending(sortingByKey, rowItemKey, isSortedAscending)

    return (
      <SortButton
        isAscending={isAscending}
        onClick={handleClick}
      >
        {label}
      </SortButton>
    )
  }

  const transform = (column: ColumnProps) => ({
    children: renderButton(column.rowItemKey as T, column.label),
    'aria-sort': getAriaSort(getIsAscending(sortingByKey, column.rowItemKey, isSortedAscending)),
    role: 'columnheader',
    scope: 'col'
  })

  const sortedColumns = columns.map(column =>
    sortableKeys.includes(column.rowItemKey)
      ? {
        ...column,
        header: {
          transforms: [() => transform(column)]
        }
      }
      : {
        ...column,
        props: {
          ...column.props,
          scope: 'col'
        }
      }
  )

  return (
    <ScrollableTable
      columns={sortedColumns}
      expandedRowList={expandedRowList}
      onScrollBottom={onScrollBottom}
      renderExpandedRowContent={renderExpandedRowContent}
      rowKey={rowKey}
      rows={rows}
      selectedRowList={selectedRowList}
    />
  )
}

export default SortableTable
