import CentralModal from '../CentralModal/CentralModal'
import classes from './ConfirmationDialog.module.scss'
import { useState } from 'react'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  timeoutMS: 3000
}

const ConfirmationDialog = ({
  children,
  timeoutMS
}: Props) => {
  // Initially set the dialog to be open
  const [isOpen, setIsOpen] = useState(true)

  const closeModal = () => {
    setIsOpen(false)
  }

  // When the timeout elapses, automatically close the dialog
  setTimeout(closeModal, timeoutMS)

  return (
    <CentralModal
      isOpen={isOpen}
      label='Confirm'
      onRequestClose={closeModal}
    >
      <div className={classes.ConfirmationDialog}>
        {children}
      </div>
    </CentralModal>
  )
}

ConfirmationDialog.defaultProps = {
  timeoutMS: 3000
}

export default ConfirmationDialog
