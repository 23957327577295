import { Hyperlink } from '@eggplant/pattern-library'
import { PRIVACY_NOTICE_URL } from '../routes'
import type { Props } from '../types'

const LinkToPrivacyNotice = ({
  children
}: Props) =>
  <Hyperlink
    href={'/' + PRIVACY_NOTICE_URL}
    isExternal
  >
    {children}
  </Hyperlink>

export default LinkToPrivacyNotice
