import classes from './ValidationMessage.module.scss'
import type { ReactNode } from 'react'

interface Props {
  isValid: boolean
  children: ReactNode
}

const ValidationMessage = ({
  isValid,
  children
}: Props) =>
  <span
    className={isValid
      ? classes.ValidationMessageValid
      : classes.ValidationMessageInvalid}
  >
    {children}
  </span>

export default ValidationMessage
