import classes from './InputPassword.module.scss'
import type { ChangeEvent } from 'react'

interface BaseProps {
  value: string
  label?: string // Can be wrapped in a <label/> instead of providing this text
  maxLength?: number
  isDisabled?: boolean
}

interface Editable {
  isRequired?: boolean
  onChange: (value: string) => void
  placeholder?: string
  isReadOnly?: false
}

interface ReadOnly {
  isRequired?: never
  onChange?: never
  placeholder: string
  isReadOnly: true
}

type Props = BaseProps & (Editable | ReadOnly)

const InputPassword = ({
  isReadOnly,
  isRequired = true,
  isDisabled = false,
  label,
  maxLength,
  onChange,
  placeholder,
  value
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return

    onChange(event.target.value)
  }

  const isInputDisableClass = isDisabled
    ? ' ' + classes.Disabled
    : ''

  const inputWrapperClass = classes.Layout + isInputDisableClass

  return (
    <div className={classes.InputPassword}>
      <div className={inputWrapperClass}>
        <input
          aria-label={label}
          autoComplete='new-password' // AUB-4615
          disabled={isDisabled}
          maxLength={maxLength}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={isReadOnly}
          required={isRequired}
          spellCheck={false}
          type='password'
          value={value}
        />
      </div>
    </div>
  )
}

export default InputPassword
