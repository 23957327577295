import ModelPreviewContent from '../content/ModelPreview/ModelPreview'
import type { CSSProperties } from 'react'

const contentWrapperStyle: CSSProperties = {
  padding: '1em'
}

const ModelPreview = () =>
  <div style={contentWrapperStyle}>
    <ModelPreviewContent />
  </div>

export default ModelPreview
