import Color from '../Color/Color'
import classes from './StatusButton.module.scss'
import { FaCaretDown } from 'react-icons/fa'
import {
  HiCheckCircle,
  HiExclamation
} from 'react-icons/hi'
import { forwardRef } from 'react'

interface Props {
  onClick: () => void
  error: number
}

const getVariant = (error: number) =>
  error === 0
    ? 'green'
    : 'orange'

const getCount = (error: number) =>
  error === 0
    ? 'No problem'
    : error + ' problem(s)'

const StatusButton = forwardRef((
  {
    onClick,
    error
  }: Props,
  ref: any
) =>
  <button
    className={classes.StatusButton}
    onClick={onClick}
    ref={ref}
    title=''
    type='button'
  >
    <div className={classes.contentWrapper}>
      <Color variant={getVariant(error)}>
        {
          error
            ? (
              <HiExclamation
                aria-hidden
                size='18'
              />
            )
            : (
              <HiCheckCircle
                aria-hidden
                size='18'
              />
            )
        }
      </Color>
      {getCount(error)}
      {
        error > 0 &&
          <Color variant='greyB'>
            <FaCaretDown
              aria-hidden
              size='16'
              title='dropdown'
            />
          </Color>
      }
    </div>
  </button>
)

export default StatusButton
