import StyledLink from '../StyledLink'
import {
  DESIGNER,
  TESTCASES
} from '../routes'
import type { Props } from '../types'

const base = `/${DESIGNER}/${TESTCASES}/`

interface Params {
  test_case_id: string
  isSubmodel?: boolean
}

const LinkToModelTestCaseView = ({
  children,
  test_case_id,
  isSubmodel = false,
  isHyperlink
}: (Props & Params)) => {
  const url = isSubmodel
    ? base + test_case_id + '?isSubmodel'
    : base + test_case_id

  return (
    <StyledLink
      isHyperlink={isHyperlink}
      to={url}
    >
      {children}
    </StyledLink>
  )
}

export default LinkToModelTestCaseView
