// import GraphQlDemo from './GraphQlDemo'
import '@reach/skip-nav/styles.css'
import LogOut from './GateKeeper/LogOut'
import SideNav from './SideNav/SideNav'
import Suspense from './Suspense'
import {
  CONTROLLER,
  DASHBOARDS,
  DESIGNER,
  HELP,
  INSIGHTS,
  LOG_OUT,
  Redirect,
  Router as RoutingRouter,
  SYSTEM,
  routeLabels
} from '@eggplant/routing'
import { Help } from '@eggplant/embedded-documentation'
import { SKIP_NAVIGATION } from './constants'
import { SkipNavLink } from '@reach/skip-nav'
import {
  lazy,
  useEffect
} from 'react'
import { useLocation } from '@reach/router'
import type { CSSProperties } from 'react'

const Dashboards = lazy(() => import(/* webpackChunkName: 'Dashboards' */ './DashboardsRouter'))
const Insights = lazy(() => import(/* webpackChunkName: 'Insights' */ './InsightsRouter'))
const Designer = lazy(() => import(/* webpackChunkName: 'Designer' */ './DesignerRouter'))
const ControllerRouter = lazy(() => import(/* webpackChunkName: 'ControllerRouter' */ './ControllerRouter'))
const SystemRouter = lazy(() => import(/* webpackChunkName: 'SystemRouter' */ './SystemRouter'))

const wrapperStyle: CSSProperties = {
  display: 'flex',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'flex-start'
}

const srOnly: CSSProperties = {
  position: 'absolute',
  padding: '10px',
  overflow: 'hidden'
}

const WILDCARD = '/*'

const labels = Object.keys(routeLabels)

const Router = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    // eslint-disable-next-line fp/no-mutating-methods
    const label = pathname
      .split('/')
      .reverse() // match children before parents
      .find(
        token => labels.includes(token)
      ) as keyof typeof routeLabels | undefined

    // eslint-disable-next-line fp/no-mutation
    document.title = label
      ? `DAI - ${routeLabels[label]}`
      : 'Eggplant DAI'
  }, [pathname])

  return (
    <div style={wrapperStyle}>
      {
        // Prevent the sidebar from loading until Redirect resolve
        // this loads faster and prevents the sidebar from setting isCurrent based on null match
        pathname !== '/' &&
          <>
            <SkipNavLink
              contentId={SKIP_NAVIGATION}
              style={srOnly}
            >
              Skip navigation
            </SkipNavLink>
            <SideNav />
          </>
      }
      <Suspense>
        <RoutingRouter isFlex>
          <Redirect
            // @ts-expect-error
            path='/'
            to={DASHBOARDS}
          />
          <Dashboards
            // @ts-expect-error
            path={DASHBOARDS + WILDCARD}
          />
          <Insights path={INSIGHTS + WILDCARD} />
          <Designer path={DESIGNER + WILDCARD} />
          <ControllerRouter path={CONTROLLER + WILDCARD} />
          <SystemRouter path={SYSTEM + WILDCARD} />
          <Help
            // @ts-expect-error
            path={HELP}
          />
          <LogOut
            // @ts-expect-error
            path={LOG_OUT}
          />
          {
            /* <GraphQlDemo
                  // @ts-ignore
                  path='/graphql'
                /> */
          }
        </RoutingRouter>
      </Suspense>
    </div>
  )
}

export default Router
