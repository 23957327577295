import {
  Header,
  List,
  Paragraph
} from '@eggplant/pattern-library'

const AutoRerunDescription = () =>
  <>
    <Header
      hasNoTopMargin
      rank={4}
    >
      Automated reruns
    </Header>
    <Paragraph>
      The automated rerun feature allows you to save time and effort by automatically rerunning failed and errored test steps up to 50 times
      without executing the entire test configuration. Customize the rerun settings as follows:
    </Paragraph>
    <br />
    <List isOrderedList>
      <Paragraph>
        <strong>Rerun count:</strong> Set the maximum number of reruns for failed and errored test steps (up to 50 times).
      </Paragraph>
      <Paragraph>
        <strong>Wait duration:</strong> Define the time interval between each rerun, optimizing the rerun process.
      </Paragraph>
    </List>
    <br />
    <Paragraph>
      By using rerun settings, you can improve the efficiency of your testing workflow and ensure thorough evaluation of problematic test cases and exploratory tests.
    </Paragraph>
  </>

export default AutoRerunDescription
