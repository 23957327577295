import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { LinkToTestResultsDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = 'Test Results shows an overview of results from your test runs. You also can view full details of tests and download logs from this area.'

const TestResultsIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Path />}
        renderLink={<LinkToTestResultsDoc />}
        title='Test Results'
      />
    </div>
  </div>

export default TestResultsIntro
