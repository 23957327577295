import { LinkToTestCasesDoc } from '@eggplant/embedded-documentation'
import { PreviewEmptyState } from '@eggplant/pattern-library'
import { useAccess } from '@eggplant/routing'
import type { ReactNode } from 'react'

interface Props {
  renderLink: ReactNode
}

const description = 'No steps for current test case and the test case cannot be run. To add steps,'

const GenericTestCasesEmptySteps = ({
  renderLink
}: Props) => {
  const [isAccess] = useAccess()

  return (
    <div className='grid h-full items-center'>
      <div style={{ paddingTop: '110px' }}>
        <PreviewEmptyState
          action={renderLink}
          description={isAccess && description}
          documentLink={<LinkToTestCasesDoc />}
          title='No steps in the test case'
        />
      </div>
    </div>
  )
}

export default GenericTestCasesEmptySteps
