import DateTime from '../DateTime/DateTime'
import classes from './ExecutionResultMessages.module.scss'

export interface Message {
  created: string
  id: number
  message: string
  severity: string
}

interface Props {
  messages: Message[]
  title?: string
}

const ExecutionResultMessages = ({
  messages,
  title = 'Message log'
}: Props) =>
  <div className={classes.ExecutionResultMessages}>
    <div className={classes.Header}>{title}</div>
    <div className={classes.BodyContent}>
      {
        messages.map(message =>
          <div
            className={classes.LogEntry}
            key={message.id}
          >
            <span className={classes.Timestamp}>
              <DateTime value={message.created} />
            </span>
            <span className={classes[message.severity]}>{message.message}</span>
          </div>
        )
      }
    </div>
  </div>

export default ExecutionResultMessages
