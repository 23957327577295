import StyledLink from '../StyledLink'
import {
  DESIGNER,
  IMPORT,
  MODEL_HUB
} from '../routes'
import type { Props } from '../types'

const LinkToImportModel = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + DESIGNER + '/' + MODEL_HUB + '/' + IMPORT}
  >
    {children}
  </StyledLink>

export default LinkToImportModel
