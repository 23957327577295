import type { GraphQLResponse } from 'relay-runtime'

const fetchGraphQL = async (query: {name: string; text: string | null | undefined}, variables: object) => {
  const body = JSON.stringify({
    query: query.text,
    variables
  })

  const request = {
    method: 'POST',
    headers: {
      // Authorization: `bearer ${YOUR_DUMB_TOKEN}`,
      'Content-Type': 'application/json'
    },
    body
  }

  const response = await fetch('http://localhost:4568/graphql', request)

  return await response.json() as GraphQLResponse
}

export default fetchGraphQL
