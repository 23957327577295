import Color from '../Color/Color'
import classes from './ExecutionDot.module.scss'
import {
  HiCheckCircle,
  HiExclamation,
  HiExclamationCircle,
  HiXCircle
} from 'react-icons/hi'
import { InProgressCircle } from '../Icons/Icons'
import { LOCALE_DATE_STRING_OPTIONS } from '../../constants'
import type { ComponentProps } from 'react'

const titles = {
  CANCELLED: 'Cancelled',
  CANCELLING: 'In Progress',
  ERROR: 'Error',
  FAILED: 'Fail',
  PENDING: 'In Progress',
  STARTED: 'In Progress',
  INCOMPLETE: 'In Progress',
  SUCCESS: 'Success',
  PASSED: 'Success'
}

type colors = ComponentProps<typeof Color>['variant']

const statusToColorMap: Record<typeof titles[keyof typeof titles], colors> = {
  Cancelled: 'greyC',
  Error: 'orange',
  Success: 'secondarygreen',
  Fail: 'redHover',
  'In Progress': 'purple'
}

const iconMap = {
  Cancelled: HiXCircle,
  'In Progress': InProgressCircle,
  Error: HiExclamation,
  Fail: HiExclamationCircle,
  Success: HiCheckCircle
}

interface Props {
  dateTime: string
  id: string
  isSelected: boolean
  onClick: () => void
  timeZone?: string
  value: keyof typeof titles
}

const formatDate = (dateTime: string, timeZone?: string) =>
  new Date(dateTime).toLocaleDateString(undefined, { ...LOCALE_DATE_STRING_OPTIONS, timeZone })

const ExecutionDot = ({
  dateTime,
  id,
  isSelected = false,
  onClick,
  timeZone,
  value
}: Props) => {
  const Icon =
    iconMap[titles[value] as keyof typeof iconMap]

  const variant = statusToColorMap[titles[value]]

  return (
    <button
      aria-selected={isSelected}
      className={classes.ExecutionDot + ' ' + classes[value]} // might need to convert this to a string
      id={id}
      onClick={() => { onClick() }} // strip out the event
      role='tab'
      type='button'
    >
      <Color variant={variant}>
        <time
          className={classes.tooltip}
          dateTime={dateTime}
        >
          {titles[value] + ', ' + formatDate(dateTime, timeZone)}
        </time>
        <Icon
          aria-hidden
          size={18}
        />
      </Color>
    </button>
  )
}

export default ExecutionDot
