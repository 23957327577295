import classes from './ReadOnlyTextArea.module.scss'

interface Props {
  value: string
  isBorderless?: boolean
  maxHeight?: number
  minHeight?: number
}

const ReadOnlyTextArea = ({
  value,
  isBorderless = false,
  minHeight = 30,
  maxHeight
}: Props) => {
  const style = maxHeight
    ? { maxHeight, minHeight }
    : { minHeight }

  return (
    <textarea
      className={
        isBorderless
          ? classes.Borderless
          : classes.Bordered
      }
      readOnly
      rows={
        isBorderless
          ? 6
          : 2
      }
      style={style}
      value={value}
    />
  )
}

export default ReadOnlyTextArea
