import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { environments } from '../../docs-routes'

const Environments = () =>
  <Paragraph>
    Execution environments are made up of three pieces: An Eggplant DAI agent, an instance of Eggplant Functional, and a system under test (SUT). Execution environments allow you to connect a model in Eggplant DAI to a suite in Eggplant Functional, and execute specific SenseTalk code snippets against a SUT.
    <br />
    <br />
    <DocusaurusFooter path={environments} />
  </Paragraph>

export default Environments
