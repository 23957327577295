import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { gitConnectionSync } from '../../docs-routes'

const LastSyncAttempt = () =>
  <Paragraph>
    Suites and snippets will be automatically synced every few minutes. If you need to sync them immediately you can use the `Sync suites and snippets` button.
    <DocusaurusFooter path={gitConnectionSync} />
  </Paragraph>

export default LastSyncAttempt
