import { Color, Paragraph } from '@eggplant/pattern-library'
import { FiInfo } from 'react-icons/fi'
import type { CSSProperties } from 'react'

const infoPlaceHolder: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: '8px',
  padding: '12px 16px'
}

const justifyWrapper: CSSProperties = {
  display: 'grid',
  justifyItems: 'start'
}

const EmptyParameterValue = () =>
  <div
    style={infoPlaceHolder}
  >
    <Color variant='greyB'>
      <FiInfo
        aria-hidden
        size={24}
      />
    </Color>
    <div style={justifyWrapper}>
      <Paragraph>
        <b>
          Parameters values not entered
        </b>
      </Paragraph>
      <Paragraph>
        If you do not enter values for generated parameter(s), the DAI engine will select these for you.
      </Paragraph>
    </div>
  </div>

export default EmptyParameterValue
