import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAddTestConfig } from '@eggplant/routing'
import { LinkToRunnerDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = `The Runner is where you can schedule and run test config and view the results of the runs.

Before you can use the Runner, you must have at least one Test Config.

🤟 Let’s start by creating a new test config!`

const renderButton = () =>
  <AccessControl isHiddenIfDenied>
    <LinkButton
      title='New Test Config'
      variant='primary'
    >
      <LinkToAddTestConfig>
        <HiPlus
          aria-hidden
          size='19.2px'
        /> New Test Config
      </LinkToAddTestConfig>
    </LinkButton>
  </AccessControl>

const RunnerIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Path />}
        renderLink={<LinkToRunnerDoc />}
        title='Runner'
      />
    </div>
  </div>

export default RunnerIntro
