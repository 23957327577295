import PopOut from '../PopOut/PopOut'
import StatusButton from '../StatusButton/StatusButton'
import classes from './StatusDropDown.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children?: ReactNode[]
}

const StatusDropDown = ({
  children
}: Props) => {
  const triggerStatusButton = (ref: any, onClick: () => void) =>
    <StatusButton
      error={children?.length || 0}
      onClick={onClick}
      ref={ref}
    />

  return (
    <PopOut trigger={triggerStatusButton}>
      {
        children !== undefined &&
          <div className={classes.StatusDropDown}>
            <ul role='listbox'>
              {
                children.map((child, index) =>
                  <li key={index}>
                    {child}
                  </li>
                )
              }
            </ul>
          </div>
      }
    </PopOut>
  )
}

export default StatusDropDown
