import classes from './RunTypeBadge.module.scss'

interface Props {
  label: 'Replay' | 'Live run' | 'Exploratory'
}

const RunTypeBadge = ({
  label
}: Props) =>
  <div className={classes.RunTypeBadge}>
    {label}
  </div>

export default RunTypeBadge
