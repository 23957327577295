import styles from './DateTime.module.scss'
import { LOCALE_DATE_STRING_OPTIONS } from '../../constants'

interface Props {
  value: string
  second?: '2-digit'
  timeZone?: string
}

// Shows a UTC ISO date in localized time format with the local timezone

const DateTime = ({
  value,
  second,
  timeZone
}: Props) =>
  <time
    className={styles.DateTime}
    dateTime={value}
  >
    {new Date(value).toLocaleDateString(undefined, { ...LOCALE_DATE_STRING_OPTIONS, second, timeZone })}
  </time>

export default DateTime
