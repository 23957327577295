import DocusaurusFooter from '../DocusaurusFooter'
import { LinkToRunner } from '@eggplant/routing'
import { Paragraph } from '@eggplant/pattern-library'
import { schedule } from '../../docs-routes'

const ScheduleManagement = () =>
  <Paragraph>
    Use the Schedule Management to view all scheduled runs in calendar view.
    <br />
    <br />
    Before you can use the Schedule Management, you must have at least one scheduled record in <LinkToRunner>Runner</LinkToRunner>.
    <DocusaurusFooter path={schedule} />
  </Paragraph>

export default ScheduleManagement
