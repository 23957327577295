import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { Dash } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { LinkToDashboardDoc } from '../links'
import { ModelConsumer } from '@eggplant/context'
import { SelectModel } from '@eggplant/input-controls'

const description = `The exploratory dashboard provides a high-level overview of your testing, presented in widget form. Most widgets appear blank until you've executed a model at least once. The exceptions are the Bug Hunting and Test Cases widgets, which have additional requirements.

💪 Let’s select a model to begin!`

const renderButton = () =>
  <ModelConsumer>
    {
      ({
        selectedModelId,
        setSelectedModelId
      }) =>
        <SelectModel
          isFilter
          onChange={setSelectedModelId}
          placeholder='Model'
          shortErrorMessage='Failed to load models'
          value={selectedModelId || ''}
        />
    }
  </ModelConsumer>

const DashboardIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Dash />}
        renderLink={<LinkToDashboardDoc />}
        title='Exploratory dashboard'
      />
    </div>
  </div>

export default DashboardIntro
