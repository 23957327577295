import classes from './CardTable.module.scss'
import type { ReactNode } from 'react'

// Wrap each child in an element that will be styled to have margins

interface Props {
  children: ReactNode
}

const CardTable = ({
  children
}: Props) =>
  <div className={classes.CardTable}>
    {children}
  </div>

export default CardTable
