import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { gitConnectionSetup } from '../../docs-routes'

const GitConnectionBranchSelectionHelp = () =>
  <Paragraph>
    Enter the branch you would like to use for test executions within DAI. For most users, this will be your repository's primary branch (e.g. main, master, prod).
    <br /><br />
    Please note: Currently, only a single branch or tag may be defined for each repository configured within DAI.
    <DocusaurusFooter path={gitConnectionSetup} />
  </Paragraph>

export default GitConnectionBranchSelectionHelp
