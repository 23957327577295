import { Divider } from '@eggplant/pattern-library'
import {
  SelectModel,
  SelectSuite
} from '@eggplant/input-controls'
import type { CSSProperties, ReactNode } from 'react'
import type { QueryParams } from '../../../../../router/src/lib/components/TestResultsManagement/TestResultsCollection/types'

interface Props {
  isDisabled?: boolean
  hasBridgeDivider?: boolean
  modelId?: string
  suiteId?: string
  isShowSubmodel?: boolean
  onChange: (value: QueryParams) => void
  externalQuery?: ReactNode
  onClearSubmodelToggleButton?: () => void
}

const wrapperStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'column', // one row
  gridColumnGap: '12px',
  alignItems: 'center'
}

const wrapperDependentStyle: CSSProperties = {
  ...wrapperStyle,
  gridColumnGap: 0,
  padding: 'none'
}

const dividerWrapperStyle: CSSProperties = {
  width: '12px'
}

const initialModelOptions = [
  {
    label: 'Only models (Hide suites)',
    value: 'ALL_MODEL'
  }
]

const initialSuiteOptions = [
  {
    label: 'Only suites (Hide models)',
    value: 'ALL_SUITES'
  }
]

const SelectModelSuite = ({
  isDisabled = false,
  hasBridgeDivider = true,
  modelId,
  suiteId,
  onChange,
  externalQuery,
  onClearSubmodelToggleButton,
  isShowSubmodel
}: Props) => {
  const handleModelChange = (value: string) => {
    onChange({
      model_id: value,
      asset_id: ''
    })
  }

  const handleSuiteChange = (value: string) => {
    onChange({
      model_id: '',
      asset_id: value
    })
  }

  return (
    <div style={wrapperDependentStyle}>
      <SelectModel
        externalQuery={externalQuery}
        initialOptions={initialModelOptions}
        isDisabled={isDisabled}
        isFilter
        isShowSubmodel={isShowSubmodel}
        onChange={handleModelChange}
        onClearSubmodelToggleButton={onClearSubmodelToggleButton}
        placeholder='Model'
        shortErrorMessage='Failed to load models'
        value={modelId || ''}
      />
      {
        hasBridgeDivider &&
          <div style={dividerWrapperStyle}>
            <Divider isCenter />
          </div>
      }
      <SelectSuite
        initialOptions={initialSuiteOptions}
        isDisabled={isDisabled}
        isFilter
        onChange={handleSuiteChange}
        placeholder='Suite'
        shortErrorMessage='Failed to load suites'
        value={suiteId || ''}
      />
    </div>
  )
}

export default SelectModelSuite
