import {
  ENVIRONMENTS,
  RESULTS,
  RUNNER,
  SCHEDULE,
  SUTS,
  TEST_CONFIG,
  renderLinkTo,
  routeLabels
} from '@eggplant/routing'
import {
  FaDesktop,
  FaFileAlt,
  FaPlay,
  FaServer
} from 'react-icons/fa'
import {
  Icons,
  NavItemVerticalMini
} from '@eggplant/pattern-library'

interface Props {
  base: string
  isCollapsed: boolean
}

const ControllerNav = ({
  base,
  isCollapsed
}: Props) =>
  <>
    <NavItemVerticalMini
      icon={<FaPlay aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[RUNNER]}
    >
      {renderLinkTo(base + '/' + RUNNER)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<Icons.Results aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[RESULTS]}
    >
      {renderLinkTo(base + '/' + RESULTS)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<FaFileAlt aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[TEST_CONFIG]}
    >
      {renderLinkTo(base + '/' + TEST_CONFIG)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<Icons.ScheduleManagement aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[SCHEDULE]}
    >
      {renderLinkTo(base + '/' + SCHEDULE)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<FaDesktop aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[SUTS]}
    >
      {renderLinkTo(base + '/' + SUTS)}
    </NavItemVerticalMini>
    <NavItemVerticalMini
      icon={<FaServer aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[ENVIRONMENTS]}
    >
      {renderLinkTo(base + '/' + ENVIRONMENTS)}
    </NavItemVerticalMini>
  </>

export default ControllerNav
