import classes from './GenericList.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode[]
  label: string
}

const GenericList = ({
  children,
  label
}: Props) =>
  <ul className={classes.GenericList}>
    <li key={label}>{label}</li>
    {children.map((item: ReactNode, index: number) =>
      <li key={index}>{item}</li>
    )}
  </ul>

export default GenericList
