import Redirect from './Redirect'
import {
  CAPTURE,
  DESIGNER
} from '../routes'

interface Props {
  model: string
  state: string
}

const RedirectToModelState = ({
  model,
  state
}: Props) =>
  <Redirect to={`/${DESIGNER}/${CAPTURE}/model-${model}/state-${state}`} />

export default RedirectToModelState
