import classes from './Main.module.scss'
import type { ReactNode } from 'react'

// A very basic wrapper that adds a styled frame
// typically around a larger composite application component

// Only one of these should be in the DOM

interface Props {
  children: ReactNode
}

const Main = ({ children }: Props) =>
  <div className={classes.Main}>
    <div>
      {children}
    </div>
  </div>

export default Main
