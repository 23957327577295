import LinkToDocusaurus from '../LinkToDocusaurus'
import {
  Header,
  Hyperlink,
  List,
  Paragraph
} from '@eggplant/pattern-library'
import {
  SETTINGS,
  SYSTEM
} from '@eggplant/routing'
import { daiSettings } from '../../docs-routes'

const NotificationsSettings = () =>
  <>
    <Header
      hasNoTopMargin
      rank={4}
    >
      Email notifications settings
    </Header>
    <br />
    <Paragraph>
      Manage and customize email notifications for your test configurations based on your preferences and requirements.
    </Paragraph>
    <br />
    <List isOrderedList>
      <Paragraph>
        <strong>Specify notification types and recipients: </strong>
        In the settings, you can specify the recipients who will receive these notifications.
        Additionally, you can choose the types of email notifications you want to receive,
        such as for failed, errored, cancelled, or passed tests.
      </Paragraph>
      <Paragraph>
        <strong>Trigger notifications: </strong>
        The email notifications will be sent automatically when the test configuration is executed
        and meets the specified conditions (e.g., a test fails or is cancelled).
      </Paragraph>
      <Paragraph>
        <strong>Enable/disable email notifications: </strong>
        You have the flexibility to turn on or off email notifications for each individual test configuration.
        If you disable email notifications, the recipients will not receive any email notifications for that particular configuration.
      </Paragraph>
      <Paragraph>
        <strong>Account-level notification control: </strong>
        As an admin user,
        you can also choose to disable all notifications at an account level if you want to stop all notifications from being sent.
        To enable or disable notifications on an account level, access the notification settings under
        <Hyperlink
          href={`/${SYSTEM}/${SETTINGS}`}
          isExternal
          isInlineLink
        >
          System &gt; Settings &gt; Test config notifications
        </Hyperlink>
      </Paragraph>
    </List>
    <br />
    <Paragraph>
      <LinkToDocusaurus path={daiSettings}>Learn more in DAI documentation</LinkToDocusaurus>
    </Paragraph>
  </>

export default NotificationsSettings
