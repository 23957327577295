import classes from './NoOptionPlaceholder.module.scss'
import {
  Color,
  LinkButton,
  Paragraph
} from '@eggplant/pattern-library'
import type {
  ReactElement,
  ReactNode
} from 'react'

interface Props {
  title: string
  icon: ReactNode
  hasIcon?: boolean
  linkTo?: ReactElement<{ href: string }>
  description?: ReactNode
}

const NoOptionsPlaceholder = ({
  icon,
  title,
  hasIcon = true,
  linkTo,
  description
}: Props) => {
  const noOptionsPlaceholderWrapper = hasIcon
    ? classes.NoOptionsPlaceholderWithIcon
    : classes.NoOptionsPlaceholder

  return (
    <span className={noOptionsPlaceholderWrapper}>
      {
        hasIcon &&
          <Color variant='greyC'>
            {icon}
          </Color>
      }
      <Paragraph>
        <strong>
          <Color variant='greyB'>
            {title}
          </Color>
        </strong>
      </Paragraph>
      {
        linkTo &&
          <span>
            <LinkButton>
              {linkTo}
            </LinkButton>
          </span>
      }
      {
        description &&
          <Paragraph>
            <Color variant='theme-description-text'>
              {description}
            </Color>
          </Paragraph>
      }
    </span>
  )
}

export default NoOptionsPlaceholder
