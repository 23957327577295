import ThemeSwitcher from 'react-css-vars'
import eggplant from '../../styling/themes/eggplant.module.scss'
import eggplantDark from '../../styling/themes/eggplant-dark.module.scss'

const themes = {
  eggplantDark,
  eggplant
}

interface Props {
  theme: 'eggplantDark' | 'eggplant'
}

const ThemeWatcher = ({
  theme
}: Props) =>
  // @ts-expect-error // Very old package
  <ThemeSwitcher theme={themes[theme]} />

export default ThemeWatcher

