import classes from './FieldsetDescriptionButton.module.scss'
import { FiHelpCircle } from 'react-icons/fi'
import { forwardRef } from 'react'

interface Props {
  onClick: () => void
}

const FieldsetDescriptionButton = forwardRef((
  { onClick }: Props,
  ref: any
) =>
  <button
    aria-label='description'
    className={classes.FieldsetDescriptionButton}
    onClick={onClick}
    ref={ref}
    type='button'
  >
    <span>
      <FiHelpCircle
        aria-hidden
        size={14}
      />
    </span>
  </button>
)

export default FieldsetDescriptionButton
