import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { LinkToCoverageHeatmapDoc } from '../links'
import { Path } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = 'The Coverage Heatmap visualizes the coverage percentages of states and actions within a model.'

const CoverageHeatmapIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Path />}
        renderLink={<LinkToCoverageHeatmapDoc />}
        title='Coverage Heatmap'
      />
    </div>
  </div>

export default CoverageHeatmapIntro
