import NumberCircle from '../NumberCircle/NumberCircle'
import styles from './LogGroup.module.scss'
import { Children } from 'react'
import type { NumberCircleVariant } from '@eggplant/types'
import type { ReactElement } from 'react'

interface Props {
  children: ReactElement[]
  number?: number
  variant?: NumberCircleVariant
}

const LogGroup = ({
  children,
  number,
  variant = 'grey'
}: Props) =>
  <div className={styles.LogGroup}>
    {
      number &&
        <span className={styles.StepNumber}>
          <NumberCircle
            value={number}
            variant={variant}
          />
        </span>
    }
    <ul className={styles.LogItemList}>
      {
        Children.map(children, child =>
          <li key={child.key}>
            {child}
          </li>
        )
      }
    </ul>
  </div>

export default LogGroup
