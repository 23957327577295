import {
  KeycloakConsumer,
  KeycloakProvider
} from './KeycloakContext/KeycloakContext'
import { TestContext } from './KeycloakContext/react-keycloak/TestContext'
import { createContext } from 'react'
import type {
  AuthContext,
  Consumer,
  Provider
} from './types'

const KeycloakContext = createContext(
  {
    isKeycloakOn: false
  } as AuthContext
)

interface ProviderProps extends Provider {
  isKeycloakOn?: boolean
  isAdmin?: boolean
}

export const AuthProvider = ({
  children,
  isKeycloakOn = false,
  isAdmin
}: ProviderProps) =>
  <KeycloakContext.Provider
    value={{
      isKeycloakOn,
      isAdmin
    }}
  >
    {
      isKeycloakOn
        ? <KeycloakProvider children={children} />
        : children
    }
  </KeycloakContext.Provider>

export const AuthConsumer = ({
  children
}: Consumer) => (
  <KeycloakContext.Consumer>
    {
      ({
        isKeycloakOn
      }: AuthContext) =>
        isKeycloakOn
          ? <KeycloakConsumer children={children} />
          : <TestContext children={children} />
    }
  </KeycloakContext.Consumer>
)
