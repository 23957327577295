interface Props {
  seconds: number
}

const Duration = ({
  seconds
}: Props) => {
  const duration = Math.floor(seconds / 3600) + 'h ' +
    Math.floor(seconds % 3600 / 60) + 'm ' +
    Math.floor(seconds % 3600 % 60) + 's'

  return (
    <time dateTime={duration}>
      {duration}
    </time>
  )
}

export default Duration
