import {
  ACCESS,
  AGENT_TOOLS,
  API_ACCESS,
  GIT_CONNECTIONS,
  LICENSES,
  LOGS,
  MY_ACCOUNT,
  SETTINGS,
  STATUS,
  renderLinkTo,
  routeLabels
} from '@eggplant/routing'
import { AccessControl } from '@eggplant/context'
import {
  FaCogs,
  FaFileDownload,
  FaGitAlt,
  FaIdCard,
  FaKey,
  FaThermometerHalf,
  FaUserCircle,
  FaWrench
} from 'react-icons/fa'
import {
  Icons,
  NavItemVerticalMini
} from '@eggplant/pattern-library'

interface Props {
  base: string
  isCollapsed: boolean
}

const SystemNav = ({
  base,
  isCollapsed
}: Props) =>
  <>
    <NavItemVerticalMini
      icon={<FaThermometerHalf aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[STATUS]}
    >
      {renderLinkTo(base + '/' + STATUS)}
    </NavItemVerticalMini>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<Icons.ApiAccess aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[API_ACCESS]}
      >
        {renderLinkTo(base + '/' + API_ACCESS)}
      </NavItemVerticalMini>
    </AccessControl>
    <NavItemVerticalMini
      icon={<FaGitAlt aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[GIT_CONNECTIONS]}
    >
      {renderLinkTo(base + '/' + GIT_CONNECTIONS)}
    </NavItemVerticalMini>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<FaKey aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[ACCESS]}
      >
        {renderLinkTo(base + '/' + ACCESS)}
      </NavItemVerticalMini>
    </AccessControl>
    <NavItemVerticalMini
      icon={<FaUserCircle aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[MY_ACCOUNT]}
    >
      {renderLinkTo(base + '/' + MY_ACCOUNT)}
    </NavItemVerticalMini>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<FaFileDownload aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[LOGS]}
      >
        {renderLinkTo(base + '/' + LOGS)}
      </NavItemVerticalMini>
    </AccessControl>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<FaIdCard aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[LICENSES]}
      >
        {renderLinkTo(base + '/' + LICENSES)}
      </NavItemVerticalMini>
    </AccessControl>
    <AccessControl isHiddenIfDenied>
      <NavItemVerticalMini
        icon={<FaWrench aria-hidden />}
        isCollapsed={isCollapsed}
        label={routeLabels[AGENT_TOOLS]}
      >
        {renderLinkTo(base + '/' + AGENT_TOOLS)}
      </NavItemVerticalMini>
    </AccessControl>
    <NavItemVerticalMini
      icon={<FaCogs aria-hidden />}
      isCollapsed={isCollapsed}
      label={routeLabels[SETTINGS]}
    >
      {renderLinkTo(base + '/' + SETTINGS)}
    </NavItemVerticalMini>
  </>

export default SystemNav
