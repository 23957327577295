import { Hyperlink } from '@eggplant/pattern-library'
import { SOFTWARE_EULA_URL } from '../routes'

const LinkToSoftwareEula = () =>
  <Hyperlink
    href={'/' + SOFTWARE_EULA_URL}
    isExternal
  >
    Software License Agreement
  </Hyperlink>

export default LinkToSoftwareEula
