import { Paragraph } from '@eggplant/pattern-library'

const ProtectedPasswordDescription = () =>
  <Paragraph>
    Passwords are write-only and saved passwords are not displayed.
    <br />
    You must re-enter the password for each submission.
  </Paragraph>

export default ProtectedPasswordDescription
