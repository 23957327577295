import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { agentTools } from '../../docs-routes'

const AgentTools = () =>
  <Paragraph>
    Agent Tools include installers and API keys that enable you to use the Eggplant DAI Agent. First, download and install the appropriate Eggplant DAI Agent installer for your operating system. You can then create an Execution Environment and generate an Access Key to allow the Agent to authenticate.
    <DocusaurusFooter path={agentTools} />
  </Paragraph>

export default AgentTools
