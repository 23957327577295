import StyledLink from '../StyledLink'
import {
  COVERAGE,
  HEATMAP,
  INSIGHTS
} from '../routes'
import type { Props } from '../types'

const LinkToCoverageHeatmap = ({
  children,
  isHyperlink
}: Props) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + INSIGHTS + '/' + COVERAGE + '/' + HEATMAP}
  >
    {children}
  </StyledLink>

export default LinkToCoverageHeatmap
