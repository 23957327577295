import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { SuccessMessage } from '@eggplant/pattern-library'
import { VAM_SERVICE } from '@eggplant/api-hosts'

interface Props {
  gitConnectionId: string
  onSuccess: (data: any) => void
  suitePath: string
}

interface ImportParams {
  relative_path: string
}

const ImportSuiteRequest = ({ gitConnectionId, onSuccess, suitePath }: Props) => {
  const importUrl = VAM_SERVICE + '/repositories/' + gitConnectionId + '/suites'

  const importParams: ImportParams = {
    relative_path: suitePath
  }

  return (
    <AuthenticatedDataRequest
      method='post'
      onSuccess={onSuccess}
      params={importParams}
      url={importUrl}
    >
      {
        () =>
          <SuccessMessage>
            Your suite has been added successfully to DAI.
          </SuccessMessage>

      }
    </AuthenticatedDataRequest>
  )
}

export default ImportSuiteRequest
