import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  TEST_CONFIG
} from '../routes'
import type { Props } from '../types'

interface Params {
  testConfigId: string
}

const LinkToEditTestConfig = ({
  children,
  testConfigId,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={'/' + CONTROLLER + '/' + TEST_CONFIG + '/' + testConfigId}
  >
    {children}
  </StyledLink>

export default LinkToEditTestConfig
