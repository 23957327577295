import ReactModal from 'react-modal'
import classes from './Modal.module.scss'
import type { ReactNode } from 'react'

type from = 'left' | 'right' | 'bottom'

// Transition and position styling to apply to the modal when it is opened/closed
const classStyles: Record<from, ReactModal.Classes> = {
  left: {
    base: classes.ModalContentFromLeft,
    afterOpen: classes.ModalContentFromLeftAfterOpen,
    beforeClose: classes.ModalContentFromLeftBeforeClose
  },
  right: {
    base: classes.ModalContentFromRight,
    afterOpen: classes.ModalContentFromRightAfterOpen,
    beforeClose: classes.ModalContentFromRightBeforeClose
  },
  bottom: {
    base: classes.ModalContentFromBottom,
    afterOpen: classes.ModalContentFromBottomAfterOpen,
    beforeClose: classes.ModalContentFromBottomBeforeClose
  }
}

// Styles to apply to the overlay when it is opened/closed
const overlayStyles = {
  base: classes.ModalOverlay,
  afterOpen: classes.ModalOverlayAfterOpen,
  beforeClose: classes.ModalOverlayBeforeClose
}

interface Props {
  children: ReactNode
  closeTimeoutMS?: number
  isOpen: boolean
  from?: from
  label: string
  onRequestClose: () => void
}

const Modal = ({
  children,
  closeTimeoutMS = 500,
  isOpen = false,
  from = 'right',
  label,
  onRequestClose
}: Props) =>
  <ReactModal
    ariaHideApp={false} // this should be revisited if Modals always use the same title element
    className={classStyles[from]}
    closeTimeoutMS={closeTimeoutMS} // allow for exit animation
    contentLabel={label}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    overlayClassName={overlayStyles.base}
    shouldCloseOnEsc
    style={{
      content: {
        transition: `transform ${closeTimeoutMS / 1000}s`
      }
    }}
  >
    {children}
  </ReactModal>

export default Modal
