/* eslint-disable complexity */
import classes from './ExpandableString.module.scss'
import {
  Color,
  IconButton,
  TruncatedString
} from '@eggplant/pattern-library'
import {
  FaChevronCircleDown,
  FaChevronCircleUp
} from 'react-icons/fa'
import { useState } from 'react'

interface Props {
  limit: number
  value: string
}

const icons = {
  up: <FaChevronCircleUp aria-hidden />,
  down: <FaChevronCircleDown aria-hidden />
}

type IconType = 'up' | 'down'

const ExpandableString = ({
  limit,
  value
}: Props) => {
  const [isShowMore, setIsShowMore] = useState(false)

  const iconType = (isShowMore
    ? 'up'
    : 'down') as IconType

  const isExpandedClass = isShowMore
    ? ' ' + classes.isExpanded
    : ''

  return (
    <span className={classes.ExpandableString + isExpandedClass}>
      {
        isShowMore
          ? value
          : (
            <TruncatedString
              limit={limit}
              value={value}
            />
          )
      }
      {
        (value && value.length > limit) &&
          <Color variant='darkPurple'>
            <IconButton
              onClick={() => { setIsShowMore(!isShowMore) }}
              title={
                isShowMore
                  ? 'Show Less'
                  : 'Show More'
              }
            >
              {
                icons[iconType]
              }
            </IconButton>
          </Color>
      }
    </span>
  )
}

export default ExpandableString
