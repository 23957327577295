import classes from './Header.module.scss'
import type { ReactNode } from 'react'

interface BaseProps {
  children: ReactNode
  hasBorderBottom?: boolean
  hasNoBottomMargin?: boolean
  hasNoTopMargin?: boolean
}

interface Rank3Props {
  rank: 3
  lessWeight?: boolean
}

interface RankProps {
  rank: 1 | 2 | 4 | 5
  lessWeight?: never
}

type Props = BaseProps & (RankProps | Rank3Props)

const getClasses = (hasBorderBottom: boolean, hasNoTopMargin: boolean, hasNoBottomMargin: boolean, lessWeight: boolean) => {
  const classNames = [
    classes.Header,
    hasBorderBottom
      ? classes.hasBorderBottom
      : '',
    hasNoTopMargin
      ? classes.noTopMargin
      : '',
    hasNoBottomMargin
      ? classes.noBottomMargin
      : '',
    lessWeight
      ? classes.lessWeight
      : ''
  ]

  return classNames.reduce(
    (accumulator, currentClass) => (accumulator + ' ' + currentClass).trim()
  )
}

const Header = ({
  children,
  rank,
  hasBorderBottom = false,
  hasNoTopMargin = false,
  hasNoBottomMargin = false,
  lessWeight = false
}: Props) => {
  const Tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' = `h${rank}`

  return (
    <Tag
      className={getClasses(hasBorderBottom, hasNoTopMargin, hasNoBottomMargin, lessWeight)}
    >
      {children}
    </Tag>
  )
}

export default Header
