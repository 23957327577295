import classes from './GroupedSingleSelect.module.scss'
import { useState } from 'react'
import type { ChangeEvent } from 'react'

// onBlur is required for accesability but it can cause duplicate actions
// https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-onchange.md#no-onchange

interface Options {
  isDisabled?: boolean
  label: string
  value: string
}

export interface Group {
  label: string
  options: Options[]
}

const {
  GroupedSingleSelect: base,
  isSolid: solid,
  ArrowUp: arrowUp,
  ArrowDown: arrowDown
} = classes

interface Props {
  isDisabled?: boolean
  isRequired?: boolean
  isSolid?: boolean
  label?: string
  onChange: (value: string) => void
  groups: Group[]
  prompt?: string
  value?: string
}

const GroupedSingleSelect = ({
  isDisabled = false,
  isRequired = false,
  isSolid = false,
  label,
  onChange,
  groups,
  prompt = 'Select Test Step to Add',
  value = ''
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value)
    setIsOpen(false)
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const isDropDownOpen = isOpen
    ? ' ' + arrowUp
    : ' ' + arrowDown

  const withSolid = isSolid
    ? ' ' + solid
    : ''

  const className = base + isDropDownOpen + withSolid

  return (
    <select
      aria-label={label}
      className={className}
      disabled={isDisabled}
      onBlur={handleChange}
      onChange={handleChange}
      onMouseDown={handleClick} // onClick triggers multiple clicks after a value from dropdown is clicked
      required={isRequired}
      value={value}
    >
      {
        (!value && // If no value is given, show a placeholder
          <option
            disabled
            value=''
          >
            {prompt}
          </option>)
      }
      {
        groups.map(group =>
          <optgroup
            key={group.label}
            label={group.label}
          >
            {
              group.options.map(option =>
                <option
                  disabled={option.isDisabled}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              )
            }
          </optgroup>
        )
      }
    </select>
  )
}

export default GroupedSingleSelect
