/* eslint-disable react-hooks/exhaustive-deps */
import classes from '../Combobox/Combobox.module.scss'
import natsort from 'natsort'
import {
  DropdownButton,
  InvisiButton
} from '../..'
import { FiX } from 'react-icons/fi'
import { HiCheck } from 'react-icons/hi'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import { useSelect } from 'downshift'
import type {
  ButtonShape,
  GroupedOptions,
  OptionType,
  Options
} from '@eggplant/types'
import type { GetItemPropsOptions } from 'downshift'
import type { ReactNode } from 'react'

interface Props {
  customValidity?: string
  defaultSelectionValue?: string
  disableSorting?: boolean
  isHighlighted: boolean
  groupedOptions?: GroupedOptions
  initialGroupedOptions?: GroupedOptions
  options?: Options
  initialOptions?: Options
  isRequired?: boolean
  onChange: (value: string) => void
  value?: string
  isDisabled?: boolean
  noOptionsPlaceholder?: ReactNode
  prompt?: string
  isFilter?: boolean
  ariaLabel?: string
}

const itemToString = (option?: OptionType | null) => option
  ? option.label
  : ''

// eslint-disable-next-line complexity
const ComboboxSingleSelect = ({
  ariaLabel,
  customValidity = 'Please select an item in the list.',
  defaultSelectionValue = '',
  disableSorting = false,
  isHighlighted,
  // groupedOptions = [],
  options = [],
  onChange,
  initialOptions,
  // initialGroupedOptions,
  isRequired = false,
  value = '',
  isDisabled = false,
  noOptionsPlaceholder,
  prompt = 'Please select an option',
  isFilter = false
}: Props) => {
  const sorter = natsort({ insensitive: true })

  // eslint-disable-next-line fp/no-mutating-methods
  const sortedOptions = options.slice().sort((current, next) =>
    sorter(current.label, next.label)
  )

  const [isUpdating, setIsUpdating] = useState(false)

  const handleFinishSelect = () => {
    setIsUpdating(false) // Set updating state to true when selection starts
  }

  const handleUpdateSelect = () => {
    setIsUpdating(true) // Set updating state to false when selection finishes
  }

  const handleClear = () => {
    onChange(defaultSelectionValue)
    handleFinishSelect()
  }

  const renderClearButton = (
    <div className={classes.clearSection}>
      <InvisiButton
        hasHoverEffect
        label='Clear'
        onClick={handleClear}
      >
        <div className={classes.clearWrapper}>
          <FiX
            aria-hidden
            size={16}
          />
          Clear
        </div>
      </InvisiButton>
    </div>
  )

  const isSelectedClass = (item: OptionType) => item.value === value
    ? ' ' + classes.isSelected
    : ''

  const renderOptionLabel = (item: OptionType) =>
    <div className={classes.checkboxStyle}>
      <div className={classes.textStyle}>
        {item.label}
      </div>
      {item.value === value &&
        <HiCheck
          aria-hidden
          className={classes.CheckIcon}
          size={16}
        />}
    </div>

  const renderOptions = (getItemProps: (options: GetItemPropsOptions<OptionType>) => OptionType) => {
    const optionsList = disableSorting
      ? options
      : sortedOptions

    const combinedOptions = initialOptions
      ? [...initialOptions, ...optionsList]
      : optionsList

    return combinedOptions.length
      ? combinedOptions.map((item, index) =>
        <li
          key={item.value}
          {...getItemProps({ item, index })}
          className={
            `${isSelectedClass(item)} 
            ${index === 0 && disableSorting
              ? classes.dividerStyle
              : ''
          }`
          }
          title={item.label}
        >
          {renderOptionLabel(item)}
        </li>
      )
      : null
  }

  // const renderGroupedOptions = (getItemProps: ({ item }: any) => OptionType) => {
  //   const sorter = natsort({ insensitive: true })

  //   const sortedOptions = groupedOptions.map(group => (
  //     {
  //       ...group,
  //       // eslint-disable-next-line fp/no-mutating-methods
  //       options: group.options.sort((current, next) =>
  //         sorter(current.label, next.label)
  //       )
  //     }
  //   ))

  //   const combinedOptions = initialGroupedOptions
  //     ? [...initialGroupedOptions, ...sortedOptions]
  //     : sortedOptions

  //   return combinedOptions.map(group =>
  //     <>
  //       <strong style={groupStyle}>
  //         {group.label}
  //       </strong>
  //       {
  //         group.options.map(item =>
  //           <li
  //             key={item.value}
  //             {...getItemProps({ item })}
  //             style={listStyle}
  //             title={item.label}
  //           >
  //             {item.label}
  //           </li>
  //         )
  //       }
  //     </>
  //   )
  // }

  const inputRef = useRef<HTMLInputElement>(null)

  const enabledStyle = !isDisabled
    ? ' ' + classes.Enabled
    : ''

  const hasControlledWidthStyle = isFilter
    ? ' ' + classes.FilterComboboxMinMaxWidth
    : ''

  const comboboxStyle = classes.Combobox + enabledStyle + hasControlledWidthStyle

  const {
    isOpen,
    selectedItem,
    selectItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps
  } = useSelect({
    items: disableSorting
      ? options
      : sortedOptions,
    itemToString,
    onStateChange: state => {
      if (!state.isOpen && isOpen && selectedItem) {
        handleUpdateSelect() // Call handleUpdateSelect when menu open
      }
    }
  })

  const getRenderButton = () => {
    const currentOption = options.find(item =>
      item.value === value
    )

    const filterTooltip = isFilter
      ? 'Status: ' + currentOption?.label
      : currentOption?.label

    const title = currentOption?.value
      ? filterTooltip
      : prompt

    const getTitle = () => !isUpdating
      ? title
      : ''

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        isDisabled={isDisabled}
        isFilter={isFilter}
        isFocus={isOpen}
        isHighlighted={value !== '' && isHighlighted}
        label={
          currentOption
            ? currentOption.label
            : prompt
        }
        title={getTitle()}
      >
        {
          currentOption
            ? currentOption.label
            : prompt
        }
      </DropdownButton>
  }

  const validate = () => {
    const ref = inputRef.current

    if (!ref) return

    // reset CustomValidity when value changed
    ref.setCustomValidity('')

    if (value === selectedItem?.value) return

    if (value) {
      const selectedOption = options.find(option => option.value === value)

      if (selectedOption) selectItem(selectedOption)
    }
  }

  useEffect(validate, [value])

  useEffect(() => {
    if (value === selectedItem?.value) {
      handleFinishSelect()

      return
    }

    if (selectedItem) {
      onChange(selectedItem.value)

      handleFinishSelect()
    }
  }, [selectedItem, isOpen])

  const dropdownWrapperClass = !isOpen
    ? classes.dropdownWrapper + ' ' + classes.Hidden
    : classes.dropdownWrapper

  return (
    <span
      aria-disabled={isDisabled}
      aria-label={ariaLabel}
      className={comboboxStyle}
      role={undefined}
    >
      <input
        checked={!!value}
        onInvalid={(event: React.ChangeEvent<HTMLInputElement>) => event.target.setCustomValidity(customValidity)}
        readOnly
        ref={inputRef}
        required={isRequired}
        type='checkbox'
      />
      {
        getRenderButton()(getToggleButtonProps())
      }
      <span className={dropdownWrapperClass}>
        {
          options.length // || groupedOptions.length
            ? (
              <>
                <ul {...getMenuProps({}, { suppressRefError: true })}>
                  {
                    isOpen && !isDisabled &&
                      <>
                        {
                          !!options.length && renderOptions(getItemProps)
                        }
                        {/* {
                        !!groupedOptions.length && renderGroupedOptions(getItemProps)
                      } */}
                      </>
                  }
                </ul>
                {
                  (value && value !== defaultSelectionValue) && renderClearButton
                }
              </>
            )
            : noOptionsPlaceholder
        }
      </span>
    </span>
  )
}

export default ComboboxSingleSelect
