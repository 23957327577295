import { AuthenticatedDataRequest } from '@eggplant/data-request'
import {
  Combobox,
  DropdownButton,
  NoOptionsPlaceholder
} from '@eggplant/pattern-library'
import { FiPlus } from 'react-icons/fi'
import { HiOutlineDesktopComputer } from 'react-icons/hi'
import { LinkToAddSut, useAccess } from '@eggplant/routing'
import { SUT_SERVICE } from '@eggplant/api-hosts'
import type {
  ButtonShape,
  Options,
  SutResponse
} from '@eggplant/types'

interface Props {
  isDisabled?: boolean
  isFilter?: boolean
  onChange: (value: string) => void
  placeholder?: string
  value?: string
}

const url = SUT_SERVICE + '/suts'

const getOptions = (items: SutResponse[] | undefined) => {
  if (!Array.isArray(items)) return []

  return items.map(item => ({
    label: item.name,
    value: item.id
  }))
}

const SelectSUTFilter = ({
  isDisabled = false,
  isFilter = false,
  onChange,
  placeholder = 'SUT',
  value
}: Props) => {
  const [isAccessible] = useAccess()

  const renderButton = (options: Options) => {
    const currentOption = options.find(item => item.value === value)

    const label = currentOption?.value
      ? currentOption.label
      : placeholder

    const tooltip = currentOption?.value
      ? `SUT: ${currentOption.label}`
      : label

    const buttonText = currentOption?.value
      ? currentOption.label
      : placeholder

    return (buttonProps: ButtonShape) =>
      <DropdownButton
        {...buttonProps}
        Icon={HiOutlineDesktopComputer}
        isDisabled={isDisabled}
        isFilter={isFilter}
        isHighlighted={!!value}
        label={label}
        title={tooltip}
      >
        {buttonText}
      </DropdownButton>
  }

  const noDataPlaceholder =
    <NoOptionsPlaceholder
      icon={<HiOutlineDesktopComputer size='24px' />}
      linkTo={
        isAccessible
          ? (
            <LinkToAddSut>
              <FiPlus
                aria-hidden
                size={16}
              />
              New SUT
            </LinkToAddSut>
          )
          : undefined
      }
      title='No SUT found'
    />

  return (
    <div className='flex'>
      <AuthenticatedDataRequest
        method='get'
        shortErrorMessage='Failed to load SUT'
        url={url}
      >
        {
          ({ items }) =>
            <Combobox
              isDisabled={isDisabled}
              isFilter={isFilter}
              noOptionsPlaceholder={noDataPlaceholder}
              onChange={onChange}
              options={getOptions(items)}
              renderButton={renderButton(getOptions(items))}
              value={value}
            />
        }
      </AuthenticatedDataRequest>
    </div>
  )
}

export default SelectSUTFilter
