import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { Journey } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { LinkToQASummaryDoc } from '@eggplant/embedded-documentation'
import { ModelConsumer } from '@eggplant/context'
import { SelectModel } from '@eggplant/input-controls'

const description = `The model dashboard page shows a recap of your recent directed and exploratory testing in DAI. To populate the summary, select a Model from the drop-down list. The summary shows results from the last 7 days by default, but you can customize the date range as needed.

💪 Let’s select a model to begin!`

const renderButton = () =>
  <ModelConsumer>
    {
      ({
        selectedModelId,
        setSelectedModelId
      }) =>
        <SelectModel
          isFilter
          onChange={setSelectedModelId}
          placeholder='Model'
          shortErrorMessage='Failed to load models'
          value={selectedModelId || ''}
        />
    }
  </ModelConsumer>

const QASummaryIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<Journey />}
        renderLink={<LinkToQASummaryDoc />}
        title='Model dashboard'
      />
    </div>
  </div>

export default QASummaryIntro
