import {
  List,
  Paragraph
} from '@eggplant/pattern-library'

const IosUdidDescription = () =>
  <>
    <Paragraph>
      UDID (Unique Device Identifier) is a unique ID assigned to each device running iOS operating system.
      <br />
      It is either a 25-digit uppercase hexadecimal or 40-digit lowercase hexadecimal.
      <br />
      <br />
      Example shown below:
    </Paragraph>
    <List>
      <Paragraph>123456789abcdef0123456789abcdef012345678</Paragraph>
      <Paragraph>00008020-007D4548007B4F28</Paragraph>
    </List>
  </>

export default IosUdidDescription
