import InvisiButton from '../InvisiButton/InvisiButton'
import classes from './TextArea.module.scss'
import { FiAlertCircle } from 'react-icons/fi'
import type {
  FormEvent,
  ReactNode
} from 'react'

interface Props {
  customValidity?: string|ReactNode
  isDisabled?: boolean // used for AccessControl disabled the Element
  isRequired?: boolean
  isInvalid?: boolean
  rows?: number
  maxHeight?: number
  maxLength: number
  onChange: (value: string) => void
  value: string
  placeholder?: string
  isReadOnly?: boolean
  minHeight?: number
  title?: string
  label?: string
  trailingIcon?: ReactNode
}

// eslint-disable-next-line complexity
const TextArea = ({
  customValidity,
  isDisabled,
  isRequired,
  isInvalid,
  rows = 2,
  isReadOnly,
  maxHeight,
  maxLength,
  minHeight = 30,
  onChange,
  title,
  label,
  placeholder,
  value,
  trailingIcon
}: Props) => {
  const handleChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement

    onChange(target.value)
  }

  const handleClear = () => {
    onChange('')
  }

  const style = maxHeight
    ? { maxHeight, minHeight }
    : { minHeight }

  const isInputDisableClass = isDisabled
    ? ' ' + classes.Disabled
    : ''

  const isInputReadonlyClass = isReadOnly
    ? ' ' + classes.Readonly
    : ''

  const isInputInvalidClass = isInvalid
    ? ' ' + classes.Invalid
    : ''

  const inputTextWrapperClass = classes.Layout + isInputDisableClass + isInputReadonlyClass + isInputInvalidClass

  return (
    <div className={classes.TextArea}>
      <div className={inputTextWrapperClass}>
        <textarea
          aria-label={label}
          disabled={isDisabled}
          maxLength={maxLength}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={isReadOnly} // only readonly style allow to drag and resize
          required={isRequired}
          rows={rows}
          style={style}
          title={title}
          value={value}
        />
        {
          trailingIcon &&
            <div className={classes.TrailingIcon}>
              {trailingIcon}
            </div>
        }
      </div>
      <div className={classes.LayoutSecondary}>
        {
          isInvalid && customValidity &&
            <div className={classes.ErrorMessage}>
              <FiAlertCircle
                aria-hidden
                className={classes.ErrorIcon}
                size='12px'
              />
              <span>{customValidity}</span>
            </div>
        }
        <div className={classes.Spacer} />
        {
          value && !isReadOnly && !isDisabled &&
            <div className={classes.ClearBtn}>
              <InvisiButton
                onClick={handleClear}
                title='Clear all'
              >
                Clear all
              </InvisiButton>
            </div>
        }
      </div>
    </div>
  )
}

export default TextArea

