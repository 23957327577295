import { Hyperlink } from '@eggplant/pattern-library'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const LinkToKickStartVideos = ({
  children
}: Props) =>
  <Hyperlink
    href='https://info.eggplantsoftware.com/kickstart-testing-eggplant-dai'
    isExternal
  >
    {children}
  </Hyperlink>

export default LinkToKickStartVideos
