import IconCard from '../presentation/IconCard'
import NoSteps from '../content/NoSteps'
import { Artwork } from '@eggplant/pattern-library'

const NoStepsIntro = () =>
  <IconCard
    artwork={<Artwork.Cogs />}
    title='Adding Steps to Tests'
  >
    <NoSteps />
  </IconCard>

export default NoStepsIntro
