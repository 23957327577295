import {
  DASHBOARDS,
  TESTCASE_DASHBOARD
} from '../routes'

// cannot use navigate when the route is different
const RedirectToTestCaseDashboard = () => {
// eslint-disable-next-line fp/no-mutation
  window.location.href = '/' + DASHBOARDS + '/' + TESTCASE_DASHBOARD
}

export default RedirectToTestCaseDashboard
