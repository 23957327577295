import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { LinkToSnippetsDoc } from '../links'
import { Snippets } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'

const description = `You can associate Eggplant Functional code snippets with states and actions in your model. Those snippets then execute on your system under test as the model runs.

To view snippets here, you must first download relevant suites.`

const SnippetsIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Snippets />}
        renderLink={<LinkToSnippetsDoc />}
        title='Snippets'
      />
    </div>
  </div>

export default SnippetsIntro
