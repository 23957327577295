import { AuthConsumer } from '@eggplant/context'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAddGitConnection } from '@eggplant/routing'

const NewGitConnectionButton = () =>
  <AuthConsumer>
    {
      ({ isAdmin }) =>
        isAdmin &&
          (
            <LinkButton
              title='New Connection'
              variant='primary'
            >
              <LinkToAddGitConnection>
                <HiPlus
                  aria-hidden
                  size='19.2px'
                /> New Connection
              </LinkToAddGitConnection>
            </LinkButton>
          )
    }
  </AuthConsumer>

export default NewGitConnectionButton
