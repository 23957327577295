import ErrorStateComponentButton from '../ErrorStateComponentButton/ErrorStateComponentButton'
import PopOut from '../PopOut/PopOut'
import classes from './ErrorPopOut.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
  title?: string
}

const ErrorPopOut = ({
  children,
  title = 'Failed to load element'
}: Props) => {
  const triggerErrorButton = (ref: any, onClick: () => void) =>
    <ErrorStateComponentButton
      onClick={onClick}
      ref={ref}
      title={title}
    />

  return (
    <PopOut
      hasBorder={false}
      placement='bottom'
      trigger={triggerErrorButton}
    >
      <div className={classes.ErrorPopOut}>
        {children}
      </div>
    </PopOut>
  )
}

export default ErrorPopOut
