import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  SUTS
} from '../routes'
import type { Props } from '../types'

const base = '/' + CONTROLLER + '/' + SUTS

interface Params {
  sut_id: string
}

const LinkToEditSut = ({
  children,
  sut_id,
  isHyperlink
}: (Props & Params)) =>
  <StyledLink
    isHyperlink={isHyperlink}
    to={base + '/' + sut_id}
  >
    {children}
  </StyledLink>

export default LinkToEditSut
