import Dropdown from '../Dropdown/Dropdown'
import Tooltip from '../Tooltip/Tooltip'
import classes from './ItemMenu.module.scss'
import { FiMoreHorizontal } from 'react-icons/fi'

interface Props {
  preferredPosition?: 'right-auto' | 'right'
  onSelect: (value: string) => void
  tooltip?: string
  options: {
    label: string
    value: string
  }[]
}

const ItemMenu = ({
  preferredPosition = 'right',
  onSelect,
  options,
  tooltip = 'Actions'
}: Props) => {
  const renderButton = (getToggleButtonProps: () => {'aria-label': string}) => {
    const toggleButtonProps = getToggleButtonProps()

    const isDropdownOpen = toggleButtonProps['aria-label'] === 'close menu'

    const button =
      <button
        className={classes.ItemMenuButton}
        type='button'
        {...toggleButtonProps}
        aria-label={
          isDropdownOpen
            ? 'close actions menu'
            : 'open actions menu'
        }
      >
        <FiMoreHorizontal
          aria-hidden
          size='20px'
        />

      </button>

    // Prevent the tooltip from colliding with the open menu
    return isDropdownOpen
      ? button
      : (
        <Tooltip label={tooltip}>
          {button}
        </Tooltip>
      )
  }

  return (
    <Dropdown
      onSelect={onSelect}
      options={options}
      preferedPosition={preferredPosition}
      renderButton={renderButton}
    />
  )
}

export default ItemMenu
