import classes from './InputFile.module.scss'
import { useState } from 'react'
import type { SyntheticEvent } from 'react'

interface Props {
  onChange: (fileText: string) => void
}

// Coordinate a screen-reader label and visual label
const LABEL = 'Choose File'

const InputFile = ({
  onChange
}: Props) => {
  const [fileName, setFileName] = useState('')

  const reader = new FileReader()

  const handleFileChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement

    // A cancelled file dialog will trigger a change without providing a file
    const file = (target.files as FileList)[0] as (File | undefined)

    if (file) {
      // eslint-disable-next-line fp/no-mutation
      reader.onload = function () {
        // @ts-expect-error
        // eslint-disable-next-line fp/no-this
        onChange(this.result)
        setFileName(file.name)
      }
      reader.readAsText(file)
    }
  }

  // Check the SCSS for the layout reasoning
  return (
    <span className={classes.InputFile}>
      <label>
        <span>
          {LABEL}
        </span>
        <input
          accept='.json'
          className={classes.InputFile}
          onChange={handleFileChange}
          type='file'
        />
      </label>
      <span aria-hidden>
        {fileName}
      </span>
    </span>
  )
}

export default InputFile
