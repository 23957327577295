import StyledLink from '../StyledLink'
import makeQueryString from './makeUrl/makeUrl'
import {
  DESIGNER,
  LIVE_RUN
} from '../routes'
import type { Props } from '../types'

interface Params {
  agent_id?: string
  asset_id?: string
  model_id?: string
  rerun_id?: number
  test_case_id?: string
}

const base = '/' + DESIGNER + '/' + LIVE_RUN

const LinkToLiveRun = ({
  children,
  isHyperlink,
  ...params
}: Props & Params) =>
  <StyledLink
    isHyperlink={isHyperlink}
    title='Live Run Now'
    to={makeQueryString(base, { ...params as Params })}
  >
    {children}
  </StyledLink>

export default LinkToLiveRun
