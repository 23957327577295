import styles from './TestResultsRecordChart.module.scss'

const maxBarWidth = 50 // the UOM that is using is in 'px' for react-chart-js-2

const getSum = (dataSet: number[]) =>
  dataSet.reduce((sum, num) => sum + num)

const groupDataByDate = (dataSet: number[][]) =>
  dataSet[0].map((_col, colIndex) => dataSet.map(item => item[colIndex]))

const sumEachRow = (dataSet: number[][]) =>
  dataSet.map(row => getSum(row))

export const getMaxDataLength = (...args: number[][]) => {
  const dataSet = [...args]
  const transposedData = groupDataByDate(dataSet)
  const dataLengthList = sumEachRow(transposedData)

  return Math.max(...dataLengthList)
}

export const generateChartData = (
  labels: string[],
  exploratoryPasses: number[],
  exploratoryFails: number[],
  directedPasses: number[],
  directedFails: number[]
) => ({
  labels,
  datasets: [
    {
      maxBarThickness: maxBarWidth,
      label: 'Exploratory Runs Pass',
      backgroundColor: styles.colorpass,
      data: exploratoryPasses
    },
    {
      maxBarThickness: maxBarWidth,
      label: 'Exploratory Runs Fail',
      backgroundColor: styles.colorfail,
      data: exploratoryFails
    },
    {
      maxBarThickness: maxBarWidth,
      label: 'Directed Runs Pass',
      backgroundColor: styles.colorpassdirect,
      data: directedPasses
    },
    {
      maxBarThickness: maxBarWidth,
      label: 'Directed Runs Fail',
      backgroundColor: styles.colorfaildirect,
      data: directedFails
    }
  ]
})
