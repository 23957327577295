import LinkToDocusaurus from '../LinkToDocusaurus'
import { Header, Paragraph } from '@eggplant/pattern-library'
import { daiSettings } from '../../docs-routes'

const UsageDataCollectionDescription = () =>
  <>
    <Header rank={5}>
      Diagnostics & usage data collection
    </Header>
    <br />
    <Paragraph>
      To provide you with a good user experience, we need your consent to collect diagnostics & usage data. Rest assured that the data we gather will be utilized solely to improve our product's features, performance, and usability. Thank you for helping us create a better user experience for you.
    </Paragraph>
    <br />
    <Paragraph>
      <span style={{ fontSize: '11px' }}>
        Keysight may continue to collect anonymous information or license information even when disabled.
      </span>
    </Paragraph>
    <br />
    <Paragraph>
      <LinkToDocusaurus path={daiSettings}>Learn more in DAI documentation</LinkToDocusaurus>
    </Paragraph>
  </>

export default UsageDataCollectionDescription
