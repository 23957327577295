import StyledLink from '../StyledLink'
import {
  CONTROLLER,
  RESULTS
} from '../routes'
import type { Props } from '../types'

interface Params {
  isSubmodel?: boolean
  runId: number
}

const base = '/' + CONTROLLER + '/' + RESULTS

const LinkToTestResultView = ({
  children,
  isSubmodel = false,
  runId,
  isHyperlink
}: Props & Params) => {
  const subModel = isSubmodel
    ? '?isSubmodel'
    : ''

  return (
    <StyledLink
      isHyperlink={isHyperlink}
      to={`${base}/${runId}${subModel}`}
    >
      {children}
    </StyledLink>
  )
}

export default LinkToTestResultView
