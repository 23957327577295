import DayPicker from 'react-day-picker'
import classes from './ChooseDate.module.scss'
import type { DayModifiers } from 'react-day-picker'

interface Props {
  isPastAllowed?: boolean
  onChange: (value: string) => void
  value: string
}

const weekdaysShort = [
  'S',
  'M',
  'T',
  'W',
  'T',
  'F',
  'S'
]

const today = new Date()

const getDisabledDays = (isPastAllowed: boolean) =>
  isPastAllowed
    ? { after: today }
    : { before: today }

const ChooseDate = ({
  isPastAllowed = false,
  onChange,
  value
}: Props) => {
  const selectedDate = new Date(value)

  const handleChange = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) return

    const newDate = new Date(value) // copy the date to prevent mutation with unknown side effects, for example time section migth reset to 12

    date.setHours(newDate.getHours())
    date.setMinutes(newDate.getMinutes())

    onChange(date.toISOString())
  }

  return (
    <DayPicker
      className={classes.ChooseDate}
      disabledDays={getDisabledDays(isPastAllowed)}
      enableOutsideDaysClick
      firstDayOfWeek={1} // Monday
      initialMonth={selectedDate}
      onDayClick={handleChange}
      selectedDays={[selectedDate]}
      showOutsideDays
      weekdaysShort={weekdaysShort}
    />
  )
}

export default ChooseDate
