import classes from './ResultsCount.module.scss'

interface Props {
  value: number
}

const ResultsCount = ({
  value
}: Props) =>
  <span className={classes.ResultsCount}>
    {value}
    {' '}
    <span className={classes.staticText}>
      Result{value !== 1 && 's'}
    </span>
  </span>

export default ResultsCount
