import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { gitConnectionsManage } from '../../docs-routes'

const GitConnections = () =>
  <Paragraph>
    View and manage your Git connections from this page. You can see the last commit date and last sync status of your Git connections which will help you identify if there are any connectivity or synchronization issues.
    <br />
    <br />
    You can manage suites, edit the Git connection details and delete Git connections from the action menu on each row.
    <DocusaurusFooter path={gitConnectionsManage} />
  </Paragraph>

export default GitConnections
