import {
  Color,
  Paragraph
} from '@eggplant/pattern-library'
import { FiInfo } from 'react-icons/fi'
import type { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  columnGap: '6px',
  paddingLeft: '2px',
  fontSize: '13px'
}

const iconWrapper: CSSProperties = {
  alignSelf: 'baseline',
  marginTop: '3px'
}

const SauceLabsTunnelSaveInfo = () =>
  <Paragraph>
    <span style={wrapperStyle}>
      <Color variant='theme-filter-icon'>
        <span style={iconWrapper}>
          <FiInfo
            aria-hidden
            size='12px'
          />
        </span>
      </Color>
      You need to enter both tunnel owner and tunnel name to save tunnel setting.
    </span>
  </Paragraph>

export default SauceLabsTunnelSaveInfo
