import { AccessControl } from '@eggplant/context'
import {
  Card,
  ChartEmptyState,
  Color,
  Paragraph
} from '@eggplant/pattern-library'
import type { CSSProperties } from 'react'

const chartEmptyStateWrapper: CSSProperties = {
  display: 'grid',
  height: '78px'
}

const TestConfigNoTestStep = () =>
  <div className='grid'>
    <div style={chartEmptyStateWrapper}>
      <Card>
        <ChartEmptyState
          title='No steps added yet'
        >
          <AccessControl isHiddenIfDenied>
            <Paragraph>
              <Color variant='theme-filter-icon'>
                Please add step from the list on left.
              </Color>
            </Paragraph>
          </AccessControl>
        </ChartEmptyState>
      </Card>
    </div>
  </div>

export default TestConfigNoTestStep
