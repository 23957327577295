import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { SuccessMessage } from '@eggplant/pattern-library'
import { VAM_SERVICE } from '@eggplant/api-hosts'

interface Props {
  id: string
  onConnectionSuccessful: () => void
}

const SyncSuitesRequest = ({
  id,
  onConnectionSuccessful
}: Props) => {
  const handleSuccess = () => {
    onConnectionSuccessful()
  }

  return (
    <AuthenticatedDataRequest
      method='put'
      onSuccess={handleSuccess}
      url={VAM_SERVICE + '/repositories/' + id + '/sync'}
    >
      {
        () =>
          <SuccessMessage>
            Suites & snippets sync successfully requested
          </SuccessMessage>
      }
    </AuthenticatedDataRequest>
  )
}

export default SyncSuitesRequest
