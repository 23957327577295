import { Color } from '@eggplant/pattern-library'
import type {
  CSSProperties,
  ReactNode
} from 'react'
import type { IconType } from 'react-icons/lib'

const footerStyle: CSSProperties = {
  paddingTop: '2em',
  marginTop: '2em',
  borderTop: '1px solid #ccc',
  display: 'grid',
  rowGap: '8px'
}

const childrenWrapperStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: '.5em',
  alignItems: 'center',
  justifyContent: 'left'
}

interface Props {
  children: ReactNode
  Icon: IconType
  info?: ReactNode
}

const Footer = ({
  Icon,
  info,
  children
}: Props) =>
  <div>
    <div style={footerStyle}>
      <span style={childrenWrapperStyle}>
        <Color variant='theme-filter-icon'>
          <Icon
            aria-hidden
            size='2em'
          />
        </Color>
        {children}
      </span>
      <span style={childrenWrapperStyle}>
        <span style={{ width: '2em' }} /> {/* Act as 2em Icon */}
        {
          !!info && info
        }
      </span>
    </div>
  </div>

export default Footer
