import Button from '../Button/Button'
import classes from './ConfirmActionDialog.module.scss'
import {
  Color,
  Header,
  Paragraph
} from '../..'
import type {
  FormEvent,
  ReactNode
} from 'react'

// IDs are for pairing the role=dialog of react-modal
// with accessible description content.
interface Props {
  actionText: string
  message: ReactNode
  onCancel: () => void
  onConfirm: () => void
  title: ReactNode
  variant?: 'danger' | 'primary' | 'success' | 'secondary'
  isFormSubmit?: boolean
}

const ConfirmActionDialog = ({
  actionText,
  message,
  onCancel,
  onConfirm,
  title,
  variant = 'danger',
  isFormSubmit = true
}: Props) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    onConfirm()
  }

  const renderDialog = () =>
    <div className={classes.ConfirmActionDialog}>
      <div className={classes.TextDialog}>
        <div id='modal-label'>
          <Header
            hasNoTopMargin
            lessWeight
            rank={3}
          >
            {title}
          </Header>
        </div>
        <div id='modal-description'>
          <Paragraph>
            <strong>
              <Color variant='theme-filter-icon'>
                {message}
              </Color>
            </strong>
          </Paragraph>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={onCancel}
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          onClick={
            isFormSubmit
              ? undefined
              : onConfirm
          }
          type={
            isFormSubmit
              ? 'submit'
              : 'button'
          }
          variant={variant}
        >
          {actionText}
        </Button>
      </div>
    </div>

  return (
    isFormSubmit
      ? (
        <form onSubmit={handleSubmit}>
          {renderDialog()}
        </form>
      )
      : renderDialog()
  )
}

export default ConfirmActionDialog
