import ChartLegend from '../ChartLegend/ChartLegend'
import styles from './SuccessfulRunsChart.module.scss'
import { DOUGHNUT_CHART_OPTIONS } from '../../constants'
import { Doughnut } from 'react-chartjs-2'

const generateChartData = (complete: number, incomplete: number) => ({
  labels: [
    'Completed Runs',
    'Incomplete Runs'
  ],
  datasets: [
    {
      backgroundColor: [
        styles.colorcomplete,
        styles.colorincomplete
      ],
      data: [
        complete,
        incomplete
      ]
    }
  ]
})

const generateLegendRows = (complete: number, incomplete: number) =>
  [
    {
      label: 'Completed Test Cases',
      amount: complete,
      color: styles.colorcomplete
    }, {
      label: 'Incomplete Test Cases',
      amount: incomplete,
      color: styles.colorincomplete
    }
  ]

interface Props {
  complete: number
  incomplete: number
}

const SuccessfulRunsChart = ({
  complete,
  incomplete
}: Props) =>
  <div>
    <div className={styles.chartContainerStyle}>
      <Doughnut
        data={generateChartData(complete, incomplete)}
        options={DOUGHNUT_CHART_OPTIONS}
      />
    </div>
    <ChartLegend rows={generateLegendRows(complete, incomplete)} />
  </div>

export default SuccessfulRunsChart
