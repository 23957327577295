import AssertivePlaceholder from './AssertivePlaceholder'
import { AuthConsumer } from '@eggplant/context'
import { useEffect } from 'react'

interface Props {
  onLogOut: () => void
}

const LogOutWrapped = ({
  onLogOut
}: Props) => {
  useEffect(() => {
    const timer = setTimeout(
      onLogOut,
      1000
    )

    return () => clearTimeout(timer)
  }, [onLogOut])

  return (
    <AssertivePlaceholder>
      Ending Session
    </AssertivePlaceholder>
  )
}

const LogOut = () =>
  <AuthConsumer>
    {
      ({ onLogOut }) =>
        <LogOutWrapped onLogOut={onLogOut} />
    }
  </AuthConsumer>

export default LogOut
