import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { sutConnections } from '../../docs-routes'

const SUTs = () =>
  <Paragraph>
    You need connections to systems under test (SUTs) to execute your tests.
    <DocusaurusFooter path={sutConnections} />
  </Paragraph>

export default SUTs
