import { Link } from '@reach/router'
import {
  primaryHyperlinkClass,
  secondaryHyperlinkClass
} from '@eggplant/pattern-library'
import type {
  ComponentProps,
  ReactNode
} from 'react'

// Style the Reach Router link to match our own Hyperlink
interface CustomProps {
  variance?: 'primary' | 'secondary'
  isHyperlink?: boolean
  title?: string
  openInNewTab?: false
}

interface OpenNewTabProps {
  children: ReactNode
  openInNewTab: true
  to: string
}

type LinkProps = (ComponentProps<typeof Link> & CustomProps) | OpenNewTabProps

const StyledLink = (props: LinkProps) => {
  const {
    openInNewTab,
    children,
    to
  } = props

  if (openInNewTab) {
    return (
      <a
        href={to}
        rel='noopener noreferrer'
        target='_blank'
      >
        {children}
      </a>
    )
  }

  const {
    title,
    isHyperlink,
    variance = 'primary'
  } = props

  const secondaryHyperLinkStyle = variance === 'secondary'
    ? ' ' + secondaryHyperlinkClass
    : ''

  const hyperlinkClassName = primaryHyperlinkClass + secondaryHyperLinkStyle

  return (
    <Link
      className={
        isHyperlink
          ? hyperlinkClassName
          : undefined // link as button is Styled in LinkButton, wrapped Link with LinkButton will inherit the required style
      }
      title={title}
      {...props}
    />
  )
}

export default StyledLink
