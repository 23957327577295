import IconButton from '../IconButton/IconButton'
import Tooltip from '../Tooltip/Tooltip'
import classes from './InputText.module.scss'
import { FiAlertCircle } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'
import {
  useEffect,
  useRef
} from 'react'
import type {
  FormEvent,
  ReactNode
} from 'react'

interface Editable {
  customValidity: string
  isDisabled?: boolean
  isRequired?: boolean
  isReadOnly?: false
  label?: string // Can be wrapped in a <label/> instead of providing this text
  maxLength?: number
  onChange: (value: string) => void
  pattern: RegExp
  placeholder?: string
  value: string
  formId?: string
  hasFocus?: boolean
  leadingIcon?: ReactNode
  trailingIcon?: ReactNode
}

interface IsReadOnly {
  customValidity?: never
  isDisabled?: never
  isRequired?: never
  isReadOnly: true
  label?: string // Can be wrapped in a <label/> instead of providing this text
  maxLength?: never
  onChange?: never
  pattern?: never
  placeholder?: string
  value: string
  formId?: string
  hasFocus?: false
  leadingIcon?: ReactNode
  trailingIcon?: ReactNode
}

type Props = Editable | IsReadOnly

// eslint-disable-next-line complexity
const InputText = ({
  customValidity,
  isDisabled = false,
  isRequired = true,
  isReadOnly,
  label,
  maxLength,
  onChange,
  pattern,
  placeholder,
  value,
  formId,
  hasFocus = false,
  leadingIcon,
  trailingIcon
}: Props) => {
  const handleChange = (event: FormEvent) => {
    if (!onChange) return

    const target = event.target as HTMLInputElement

    onChange(target.value.trimStart())
  }

  const handleOnBlur = (event: FormEvent) => {
    if (!onChange) return

    const target = event.target as HTMLInputElement

    onChange(target.value.trim())
  }

  const handleClear = () => {
    if (!onChange) return

    onChange('')
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const validate = () => {
    const ref = inputRef.current

    if (!ref) return

    // Set as valid first, then check if still invalid
    ref.setCustomValidity('')

    if (hasFocus) {
      inputRef.current.focus()
    }

    // place after setCustomValidity('') to reset customValidity that handle user backspace the field value until empty string
    if (!value || !customValidity) return

    // customValidity message only shows on submit
    const isValid = ref.checkValidity()

    if (!isValid) {
      ref.setCustomValidity(customValidity)
    }
  }

  useEffect(validate, [customValidity, value, hasFocus])

  const isInvalid = value && pattern && !pattern.test(value)

  const isInputDisableClass = isDisabled
    ? ' ' + classes.Disabled
    : ''

  const isInputReadonlyClass = isReadOnly
    ? ' ' + classes.Readonly
    : ''

  const isInputInvalidClass = isInvalid
    ? ' ' + classes.Invalid
    : ''

  const inputTextWrapperClass = classes.Layout + isInputDisableClass + isInputReadonlyClass + isInputInvalidClass

  const InputField = (
    <div className={classes.InputText}>
      <div className={inputTextWrapperClass}>
        {
          leadingIcon &&
            <div className={classes.LeadingIcon}>
              {leadingIcon}
            </div>
        }
        <input
          aria-label={label}
          disabled={isDisabled}
          form={formId}
          maxLength={maxLength}
          onBlur={handleOnBlur}
          onChange={handleChange}
          pattern={pattern?.source}
          placeholder={placeholder}
          readOnly={isReadOnly}
          ref={inputRef}
          required={isRequired}
          spellCheck={false}
          type='text'
          value={value}
        />
        {
          value && !isReadOnly && !isDisabled &&
            <div className={classes.ClearBtn}>
              <IconButton
                onClick={handleClear}
                title='Clear'
              >
                <IoClose aria-hidden />
              </IconButton>
            </div>
        }
        {
          trailingIcon &&
            <div className={classes.TrailingIcon}>
              {trailingIcon}
            </div>
        }
      </div>
      {
        isInvalid &&
          <div className={classes.ErrorMessage}>
            <FiAlertCircle
              aria-hidden
              className={classes.ErrorIcon}
              size='12px'
            />
            <span>{customValidity}</span>
          </div>
      }
    </div>
  )

  return isReadOnly && value
    ? (
      <Tooltip label={value}>
        {InputField}
      </Tooltip>
    )
    : InputField
}

export default InputText
