import { Header, Paragraph } from '@eggplant/pattern-library'
import type { CSSProperties } from 'react'

const wrapperStyle: CSSProperties = {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '40px'
}

const NoRoles = () =>
  <div style={wrapperStyle}>
    <Header
      hasNoTopMargin
      rank={1}
    >
      No role is assigned to this account
    </Header>
    <Paragraph>
      This account haven't been assigned any role yet. Without a role you can't access, browse or edit anything on DAI. Please contact your DAI admin to assign a role to this account.
    </Paragraph>
  </div>

export default NoRoles
