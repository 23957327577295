import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { AccessControl } from '@eggplant/context'
import { ApiAccess } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { HiPlus } from 'react-icons/hi'
import { LinkButton } from '@eggplant/pattern-library'
import { LinkToAPIAccessDoc } from '../links'
import { LinkToAddApiAccess } from '@eggplant/routing'

const description = 'API access keys can be generated to enable direct API requests to our service endpoints for use with third party clients.'

const renderButton = () =>
  <AccessControl
    allowedRoles={['dai:admin']}
    isHiddenIfDenied
  >
    <LinkButton variant='primary'>
      <LinkToAddApiAccess>
        <HiPlus
          aria-hidden
          size='19.2px'
        /> New API Access
      </LinkToAddApiAccess>
    </LinkButton>
  </AccessControl>

const ApiAccessIntro = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        action={renderButton()}
        description={description}
        icon={<ApiAccess />}
        renderLink={<LinkToAPIAccessDoc />}
        title='API Access'
      />
    </div>
  </div>

export default ApiAccessIntro
