import LinkToDocusaurus from '../LinkToDocusaurus'
import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'
import { testConfig } from '../../docs-routes'

const TestConfigSutsTypeInfo = () =>
  <>
    <Header rank={4}>
      Choosing SUT
    </Header>
    <Paragraph>
      Selecting a specific SUT allows you to choose the execution environment for your application. You can select from the options below:
      <br /><br />
      <strong>
        None
      </strong>
      <br />
      Choose not to use any SUT if the SUT is defined in the code or not required.
      <br /><br />
      <strong>
        By Name
      </strong>
      <br />
      Selecting this option allows you to browse all SUTs by name.
      <br /><br />
      <strong>
        By criteria tag
      </strong>
      <br />
      Selecting a Criteria Tag allows you to add a SUT based on the specified criteria. When a criteria tag is chosen, the next available SUT belonging to that tag is automatically selected.
      <br /><br />
      If multiple tags are selected, the system finds an available SUT that's tagged with all the selected criteria. The criteria are applied at the time of execution, and a new SUT that meets the criteria may be selected every time you run a test.
      <br /><br />
      Note, that if multiple criteria tags are selected, the SUT must have all the specified criteria tags defined in the SUT settings. If a SUT is only tagged with one of the selected criteria tags, it won’t be selected.
      <br /><br />
      For example, if two or more SUTs are tagged with the same criteria (e.g. "tag01"), and you choose the same criteria tag in your test config, the system will randomly select one of those SUTs. Every time you run the test, the system may pick a different SUT that matches the specified criteria tags.
      <br /><br />
      <strong>
        Eggplant hosted cloud SUTs
      </strong>
      <br />
      This option allows you to choose from the SUTs that are hosted by Eggplant. This options only applicable to Eggplant Cloud.
      <br /><br />
      <LinkToDocusaurus path={testConfig}>Learn more in DAI documentation</LinkToDocusaurus>
    </Paragraph>
  </>

export default TestConfigSutsTypeInfo
