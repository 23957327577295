import filterParams from '../filterParams'
import renderError from '../renderError'
import requestData from '../requestData'
import * as Visibility from 'visibilityjs'
import { LoadingPlaceholder } from '@eggplant/pattern-library'
import {
  useEffect,
  useRef,
  useState
} from 'react'
import type { Error } from '@eggplant/types'
import type { PollingProps } from '../types'
import type { ReactElement } from 'react'

interface Props<ResponseInterface> extends PollingProps<ResponseInterface> {
  getToken?: () => string
}

const Polling = <ResponseInterface, >({
  children: renderChildren,
  intervalMs,
  extraHeaders: extraHeadersProp,
  isPolling,
  onError,
  onSuccess,
  params,
  url,
  getToken,
  shortErrorMessage
}: Props<ResponseInterface>) => {
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState<ResponseInterface | null>(null)
  const [tick, setTick] = useState(0)

  const timer = useRef<ReturnType<typeof setTimeout>>()

  const increment = () => {
    setTick(previousTick => previousTick + 1)
  }

  const continuePolling = () => {
    // Catches the prop change before a new tick is set
    if (!isPolling) return

    // eslint-disable-next-line fp/no-mutation
    timer.current = setTimeout(increment, intervalMs)
  }

  const handleData = (data: ResponseInterface) => {
    if (onSuccess) onSuccess(data)

    setData(data)
    setError(null)

    continuePolling()
  }

  const handleError = (error: Error) => {
    if (onError) onError(error)

    setError(error)

    continuePolling()
  }

  const filteredParams = params
    ? filterParams(params)
    : params

  useEffect(() => {
    Visibility.change(() => {
      if (!Visibility.hidden()) increment()
    })
  }, [])

  useEffect(() => {
    // Catches the prop change after a new tick is set
    if (!isPolling || Visibility.hidden()) return () => {}

    // Token must be checked here or freshness
    const extraHeaders = getToken
      ? {
        Authorization: 'Bearer ' + getToken(),
        ...extraHeadersProp
      }
      : extraHeadersProp

    const cancel = requestData(url, 'get', handleData, handleError, filteredParams, 'json', false, extraHeaders)

    return () => {
      cancel()

      if (timer.current) clearTimeout(timer.current)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tick, isPolling]) // Do not trigger requests for param changes. They will be used on the next poll.

  if (error) return renderError(error, shortErrorMessage)

  if (!data) return <LoadingPlaceholder />

  if (renderChildren) return renderChildren(data) as ReactElement

  return null
}

export default Polling
