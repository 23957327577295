import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'

const AverageInfo = () =>
  <div className='grid gap-3'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Average time to execute
    </Header>
    <Paragraph>
      The average time it took to execute for all test runs.
    </Paragraph>
  </div>

export default AverageInfo
