import DocusaurusFooter from '../DocusaurusFooter'
import { Paragraph } from '@eggplant/pattern-library'
import { bugHuntingHeatmap } from '../../docs-routes'

const BugHuntingHeatmap = () =>
  <Paragraph>
    The Bug Hunting heatmap visualizes the failure rate of actions within a model. Any action with more failures than passes has a colored background.
    <DocusaurusFooter path={bugHuntingHeatmap} />
  </Paragraph>

export default BugHuntingHeatmap
