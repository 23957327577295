import EmptyStatesPreview from '../content/EmptyStatesPreview'
import { Bug } from '@eggplant/pattern-library/src/lib/components/Artwork/Artwork'
import { LinkToBugHuntingHeatmapDoc } from '../links'

const description = 'The Bug Hunting heatmap visualizes the failure rate of actions within a model. Any action with more failures than passes has a colored background.'

const BugHuntingHeatmap = () =>
  <div className='grid h-full justify-center'>
    <div className='grid w-[36em]'>
      <EmptyStatesPreview
        description={description}
        icon={<Bug />}
        renderLink={<LinkToBugHuntingHeatmapDoc />}
        title='Bug Hunting Heatmap'
      />
    </div>
  </div>

export default BugHuntingHeatmap
