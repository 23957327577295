import Color from '../../Color/Color'
import Paragraph from '../../Paragraph/Paragraph'
import classes from './EmptyState.module.scss'
import { HiSearch } from 'react-icons/hi'

const EmptyState = () =>
  <span className={classes.EmptyState}>
    <Color variant='greyC'>
      <HiSearch
        aria-hidden
        size={24}
      />
    </Color>
    <Paragraph>
      <strong>No results found</strong>
    </Paragraph>
  </span>

export default EmptyState
