import { realmAccessRoles, useKeycloak } from '@eggplant/context'
import type { Role } from '@eggplant/context'

const defaultRoles: Role[] = [
  'dai:admin',
  'dai:user'
]

const useAccess: (allowedRoles?: Role[]) => [boolean] = (
  allowedRoles = defaultRoles
) => {
  const { keycloak: keycloakInstance } = useKeycloak()

  const realmAccess = !!keycloakInstance.idTokenParsed && keycloakInstance.idTokenParsed.realm_access

  const roles = realmAccess
    ? realmAccess.roles as Role[]
    : []

  const realmRoles = roles.filter(role =>
    realmAccessRoles.includes(role)
  )

  const isAllowed = realmRoles.some(realmRole =>
    allowedRoles.includes(realmRole)
  )

  return [isAllowed]
}

export default useAccess
