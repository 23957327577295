import LinkToDocusaurus from '../LinkToDocusaurus'
import {
  Header,
  List,
  Paragraph
} from '@eggplant/pattern-library'
import { testCaseDashboard } from '../../docs-routes'

const TestCaseDashboard = () =>
  <div className='grid gap-3'>
    <Header
      hasNoBottomMargin
      hasNoTopMargin
      rank={5}
    >
      Test case dashboard
    </Header>
    <Paragraph>
      Test case dashboard provides an overview of your script-based tests. You can easily access and analyze aggregated data for all your test cases from here.
    </Paragraph>
    <Paragraph>
      Key Features:
    </Paragraph>
    <List>
      <Paragraph>
        View test case results per day over a specified period, allowing you to track performance trends.
      </Paragraph>
      <Paragraph>
        Gain insights through percentage summaries of test case result statuses, helping you assess overall test outcomes.
      </Paragraph>
      <Paragraph>
        Analyze the slowest running, most failed, and errored test cases for focused investigation.
      </Paragraph>
    </List>
    <Paragraph>
      Customize Your View:
    </Paragraph>
    <List>
      <Paragraph>
        Filter the dashboard according to your specific needs.
      </Paragraph>
      <Paragraph>
        Set up a date range to analyze test performance over a specific period.
      </Paragraph>
      <Paragraph>
        Choose to filter by a specific model or suite to narrow down the results to the desired scope.
      </Paragraph>
    </List>
    <Paragraph>
      With the Test Case Dashboard, you have a powerful tool to review, analyze, and gain valuable insights from your test case data, empowering you to make informed decisions and optimize your testing processes.
    </Paragraph>
    <Paragraph>
      <LinkToDocusaurus path={testCaseDashboard}>
        Learn more in DAI documentation
      </LinkToDocusaurus>
    </Paragraph>
  </div>

export default TestCaseDashboard
