import classes from './TableErrorState.module.scss'
import {
  Button,
  Color,
  ErrorPlaceholder,
  Header,
  Paragraph
} from '@eggplant/pattern-library'
import { FiRefreshCw } from 'react-icons/fi'
import { HeartBroken } from '../Icons/Icons'
import type { Error } from '@eggplant/types'
import type {
  ReactNode
} from 'react'

interface Props {
  documentLink?: ReactNode
  error: Error
  hasNoTopMargin?: boolean
}

const renderRefreshButton = () =>
  <Button
    onClick={() => window.location.reload()}
    title='Refresh Browser'
    variant='secondary'
  >
    <FiRefreshCw aria-hidden />
    Refresh Browser
  </Button>

const TableErrorState = ({
  documentLink,
  error,
  hasNoTopMargin = false
}: Props) => {
  const marginTopClass = !hasNoTopMargin
    ? classes.TopMargin
    : ''

  const classname = classes.TableErrorState + ' ' + marginTopClass

  return (
    <div className={classname}>
      <Color variant='highlightred'>
        <HeartBroken
          aria-hidden
          size='120px'
        />
      </Color>
      <Header
        hasNoTopMargin
        rank={4}
      >
        Oops... Something went wrong!
      </Header>
      <Paragraph>
        Please see the error logs below and refresh browser.
      </Paragraph>
      <div className={classes.ErrorMessage}>
        <ErrorPlaceholder
          status={(error.status || '').toString()}
          title={error.title || ''}
        >
          {error.detail}
        </ErrorPlaceholder>
      </div>
      <span className={classes.RefreshButton}>
        {renderRefreshButton()}
      </span>
      {documentLink && documentLink}
    </div>
  )
}

export default TableErrorState
