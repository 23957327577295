import {
  createContext,
  useState
} from 'react'
import {
  get,
  set
} from 'browser-cookies'
import type { ReactNode } from 'react'

type stateType = boolean

interface Context {
  isNavCollapsed: stateType
  setIsNavCollapsed: (newState?: stateType) => void
}

const Preferences = createContext<Context | undefined>(undefined)

const COOKIE_NAME = 'sideNavCollapsed'

interface ProviderProps {
  children: ReactNode
  isNavCollapsed?: stateType // can be used to change the cookie
}

export const PreferencesProvider = ({
  children,
  isNavCollapsed: collapsedProp
}: ProviderProps) => {
  const cookie = get(COOKIE_NAME) === 'true'

  const [isNavCollapsed, setIsNavCollapsedState] = useState<stateType | undefined>(collapsedProp || cookie)

  const updateCookie = (newState?: stateType) => {
    set(COOKIE_NAME,
      newState
        ? 'true'
        : 'false')
  }

  if (isNavCollapsed !== cookie) updateCookie(isNavCollapsed)

  // onClick event that change boolean value true to false and vice versa
  const setIsNavCollapsed = () => {
    const newState = !isNavCollapsed

    setIsNavCollapsedState(newState)

    updateCookie(newState)
  }

  const value = {
    isNavCollapsed: isNavCollapsed || false,
    setIsNavCollapsed
  }

  return (
    <Preferences.Provider value={value}>
      {children}
    </Preferences.Provider>
  )
}

interface ConsumerProps {
  children: (context: Context) => ReactNode
}

export const PreferencesConsumer = ({
  children: renderChildren
}: ConsumerProps) =>
  <Preferences.Consumer>
    {
      (context?: Context) => {
        if (context === undefined) {
          throw new Error('<PreferencesConsumer /> must be used within <PreferencesProvider />')
        }

        return renderChildren(context)
      }
    }
  </Preferences.Consumer>
