import { AuthenticatedDataRequest } from '@eggplant/data-request'
import { VAM_SERVICE } from '@eggplant/api-hosts'
import type { ReactElement } from 'react'
import type { Suite } from '@eggplant/types'

interface Props {
  suiteId: string
  children?: (data: Suite) => ReactElement
  onSuccess?: (data: Suite) => void
}

const GetSuiteRequest = ({ suiteId, children, onSuccess }: Props) => {
  const url = VAM_SERVICE + '/assets/' + suiteId

  return (
    <AuthenticatedDataRequest
      children={children}
      method='get'
      onSuccess={onSuccess}
      url={url}
    />
  )
}

export default GetSuiteRequest
