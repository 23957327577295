import { DataRequest } from '@eggplant/data-request'
import {
  Header,
  Paragraph
} from '@eggplant/pattern-library'
import type { GetReleaseJsonResponse } from '@eggplant/types'

const headers = {
  pragma: 'no-cache',
  'cache-control': 'no-cache'
}

const Version = () =>
  <DataRequest
    extraHeaders={headers}
    method='get'
    url={window.location.origin + '/eggplant-release.json'}
  >
    {
      ({ version }: GetReleaseJsonResponse) => version
        ? (
          <>
            <Header rank={5}>
              Eggplant DAI Version
            </Header>
            <Paragraph>
              {version}
            </Paragraph>
          </>
        )
        : null
    }
  </DataRequest>

export default Version
