import FieldsetDescriptionButton from '../FieldsetDescriptionButton/FieldsetDescriptionButton'
import PopOut from '../PopOut/PopOut'
import classes from './InfoPopOut.module.scss'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const triggerInfoButton = (ref: any, onClick: () => void) =>
  <FieldsetDescriptionButton
    onClick={onClick}
    ref={ref}
  />

const InfoPopOut = ({
  children
}: Props) =>
  <PopOut
    placement='auto'
    trigger={triggerInfoButton}
  >
    <div className={classes.InfoPopOut}>
      {children}
    </div>
  </PopOut>

export default InfoPopOut
