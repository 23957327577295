import classes from './ToggleButton.module.scss'
import type { ReactNode } from 'react'

interface Props {
  ariaLabel?: string
  iconOff?: ReactNode
  isOn: boolean
  iconOn?: ReactNode
  isDisabled?: boolean
  label: string
  type?: 'button' | 'submit'
  onChange: (value: boolean) => void
}

const ToggleButton = ({
  isOn,
  iconOff,
  iconOn,
  isDisabled = false,
  label,
  type = 'button',
  ariaLabel = 'Toggle Button',
  onChange
}: Props) => {
  const handleChange = () => {
    onChange(!isOn)
  }

  return (
    <div
      className={
        isOn
          ? classes.ToggleButton + ' ' + classes.isOn
          : classes.ToggleButton
      }
    >
      <button
        aria-label={ariaLabel}
        disabled={isDisabled}
        onClick={handleChange}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        <span>
          {
            isOn
              ? iconOn
              : iconOff
          }
        </span>
      </button>
      {label}
    </div>
  )
}

export default ToggleButton
