import { Color, Paragraph } from '@eggplant/pattern-library'

const AgreeCookiesDescription = () =>
  <Paragraph>
    <Color variant='black'>
      We collect and analyze anonymous usage data and use of cookies to improve your user experience. You can disable this anytime.
    </Color>
  </Paragraph>

export default AgreeCookiesDescription
